
export const getInfoDocumentoEdit = async (row) => {
  //const emisorValidate = await getEmisorData(infovet);

    let update = {
      venta_cabecera_id: row.venta_cabecera_id,
      editar: "SI",
      x_key: row.x_key,
      x_password: row.x_password,
      anulado: row.anulado,
      facturado: row.facturado,
      identificacion_cliente: row.identificacion_cliente,
      tipo_emision: row.tipo_emision,
      codigo_identificacion: row.codigo_identificacion,
      codigo_documento: row.codigo_documento,
      codigo_forma_pago: row.codigo_forma_pago,
      codigo_forma_pago_sri: row.codigo_forma_pago_sri,
      fecha_venta: row.fecha_venta,
      n_establecimiento: row.n_establecimiento,
      n_punto_venta: row.n_punto_venta,
      n_factura: row.n_factura,
      subtotal_iva: row.subtotal_iva,
      subtotal_siniva: row.subtotal_siniva,
      subtotal_cinco: row.subtotal_cinco,
      subtotal_quince: row.subtotal_quince,
      subtotal_no_objeto_iva: row.subtotal_no_objeto_iva,
      subtotal_exento_iva: row.subtotal_exento_iva,
      subtotal_sin_impuestos: row.subtotal_sin_impuestos,
      total_descuento: row.total_descuento,
      ice: row.ice,
      iva: row.iva,
      ivacinco: row.ivacinco,
      ivaquince: row.ivaquince,
      total_devolucion_iva: row.total_descuento,
      irbpnr: row.irbpnr,
      propina: row.propina,
      valor_total: row.valor_total,
      empresa_id: row.veterinaria_id,
      activo: row.activo,
      ambiente: row.ambiente,
      ventas_detalle: row.ventas_detalle,
      emisor: JSON.parse(row.emisor),
      moneda: row.moneda,
      comprador: JSON.parse(row.comprador),
      numero: row.numero,
      docsustento: JSON.parse(row.docsustento),
      info_adicional: JSON.parse(row.info_adicional),
      traslado: JSON.parse(row.traslado),
      pagos: JSON.parse(row.pagos),
    };


    return { validate: "ok", data: update, message: "" };
  
};
