import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { toast } from "react-toastify";
import { crearPagoAfiliadoApi } from "../../../api/afiliaciones_socios/pagos";
import { GetServicioAsignacionEmpresaUsuario } from "../../../api/afiliaciones_socios/servicioContratado";
import { getTipoPago } from "../../../api/contable/configuraciones";

const CrearEditarPagoServicio = ({
  editar,
  pago,
  setPago,
  actualizarLista,
  resetPago,
  setEditar,
  afiliado,
  empresa,
}) => {
  const [loading, setLoading] = useState(false);
  const [servicios, setServicios] = useState([]);
  const [fomasPago, setFormasPago] = useState([]);
  const [valorTotal, setValorTotal] = useState(0);
  const [loadingServicios, setLoadingServicios] = useState(false);

  useEffect(() => {
    const getFormasPago = async () => {
      const formas = await getTipoPago();
      setFormasPago(formas[0].data);
    };

    getFormasPago();
    getRecordsEmpresa();
  }, []);

  const getRecordsEmpresa = async () => {
    setLoadingServicios(true);
    const res = await GetServicioAsignacionEmpresaUsuario({
      empresa_id: empresa,
      afiliado_id: afiliado,
    });
    if (res.sms === "ok") {
      setServicios(res.data);
    } else {
      toast.error(res.mensaje);
    }
    setLoadingServicios(false);
  };

  useEffect(() => {
    if (pago.afiliado_servicio_asignacion !== "") {
      const servicio = servicios.find(
        (servicio) =>
          servicio.afiliado_servicio_asignacion ===
          parseInt(pago.afiliado_servicio_asignacion)
      );

      setPago({
        ...pago,
        valor: servicio.afiliados_servicios_config.valor,
        valor_total: servicio.afiliados_servicios_config.valor,
        detalle_pago: servicio.afiliados_servicios_config.detalle_pago,
        codigo_producto:
          servicio.afiliados_servicios_config.productos.codigo_producto,
        descripcion_producto:
          servicio.afiliados_servicios_config.productos.descripcion_producto,
        forma_pago: servicio.forma_pago,
      });
    }
  }, [pago.afiliado_servicio_asignacion]);

  const guardarPago = async (e) => {
    e.preventDefault();

    if (
      pago.forma_pago === "" ||
      pago.fecha_pago === "" ||
      pago.afiliado_servicio_asignacion === ""
    ) {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    setLoading(true);
    try {
      const apiFunc = editar ? async (editar) => {} : crearPagoAfiliadoApi;
      const respuesta = await apiFunc(pago);
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        actualizarLista();
        resetPago();
        setEditar(false);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar la petición");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form inline className="row mt-2">
      <div className="col-md-4">
        <div className="form-group">
          <label className="negrita">Servicio contratado</label>
          <select
            className="inputtext bordecompleto mx-1"
            id="afiliado_servicio_asignacion"
            name="afiliado_servicio_asignacion"
            value={pago.afiliado_servicio_asignacion}
            onChange={(e) => {
              setPago({
                ...pago,
                [e.target.name]: e.target.value,
              });
            }}
          >
            <option value="">Seleccionar</option>
            {servicios.map((servicio) => (
              <option
                key={servicio.afiliado_servicio_asignacion}
                value={servicio.afiliado_servicio_asignacion}
              >
                {servicio.afiliados_servicios_config.detalle_pago}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-md-1">
        <div className="form-group">
          <label className="negrita">ㅤ</label>
          <button
            className="inputtext bordecompleto mx-1"
            onClick={(e) => {
              e.preventDefault();
              getRecordsEmpresa();
            }}
          >
            <i
              className={
                `fas fa-sync-alt ` + (loadingServicios ? "fa-spin" : "")
              }
            ></i>
          </button>
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="negrita">Fecha de pago</label>
          <input
            className="inputtext bordecompleto mx-1"
            id="fecha_pago"
            name="fecha_pago"
            value={pago.fecha_pago}
            type="date"
            onChange={(e) => {
              setPago({
                ...pago,
                [e.target.name]: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label className="negrita">Forma de pago</label>
          <select
            className="inputselect bordecompleto"
            id="forma_pago"
            name="forma_pago"
            onChange={(e) => {
              setPago({
                ...pago,
                [e.target.name]: e.target.value,
              });
            }}
            value={pago.forma_pago}
          >
            <option value="">Seleccionar</option>
            {fomasPago.map((data, index) => {
              return (
                <option value={data.code} key={index}>
                  {data.forma_pago}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-center flex-row align-items-center my-3">
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        ) : (
          <>
            <button
              className="w-50 bg-btn-vet-opciones bordesombreado mx-1"
              disabled={loading}
              onClick={guardarPago}
            >
              {editar ? "Editar Pago" : "Crear Pago"}
            </button>
            {editar && (
              <button
                className="w-5 bg-btn-vet-opciones bordesombreado"
                onClick={() => {
                  resetPago();
                  setEditar(false);
                }}
              >
                X
              </button>
            )}
          </>
        )}
      </div>
    </Form>
  );
};

export default CrearEditarPagoServicio;
