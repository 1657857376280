import React, { useEffect, useState } from "react";
import "../documentos.css";
const DetalleDocumentos = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setData(props.data);
  };


  return (
    <div className="row">
      {props.data
        ? props.data.codigo_documento===""?"":(
            <div className="col-md-12">
              <div className="row d-flex justify-content-start flex-column">
                <hr />
                <div className="col-md-12">
                  <div className="row py-2">
                    <div className="py-2">MENSAJES DE RECEPCIÓN </div>
                    <br />
                    <div>
                      {props.data.recepcion ? (
                        <div>
                          <div>
                            Estado:{JSON.parse(props.data.recepcion).estado}
                          </div>
                          <div>
                            {JSON.parse(props.data.recepcion).comprobantes ? (
                              <div>
                                <div>
                                  Mensaje:
                                  {
                                    JSON.parse(props.data.recepcion)
                                      .comprobantes.comprobante.mensajes.mensaje
                                      .mensaje
                                  }
                                </div>
                                <div>
                                  informacionAdicional:
                                  {
                                    JSON.parse(props.data.recepcion)
                                      .comprobantes.comprobante.mensajes.mensaje
                                      .informacionAdicional
                                  }
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="py-2">MENSAJES DE AUTORIZACIÓN</div>
                    <div>
                      {" "}
                      {props.data.autorizacion ? (
                        <div>
                          <div>
                            Estado:
                            {
                              JSON.parse(props.data.autorizacion).autorizacion
                                .estado
                            }
                          </div>
                          <div>
                            {JSON.parse(props.data.autorizacion).autorizacion
                              .mensajes ? (
                              <div>
                                <div>
                                  Mensaje:
                                  {
                                    JSON.parse(props.data.autorizacion)
                                      .autorizacion.mensajes.mensaje.mensaje
                                  }
                                </div>
                                <div>
                                  informacionAdicional:
                                  {
                                    JSON.parse(props.data.autorizacion)
                                      .autorizacion.mensajes.mensaje
                                      .informacionAdicional
                                  }
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        : null}
    </div>
  );
};

export default DetalleDocumentos;
