import React, { useState, useEffect, useContext } from "react";
import ModalDefault from "../../modalDefault";

import {
  ApiPOSTGetCajasXEmpresas,
  ApiPOSTActivarDesactivarCaja,
  ApiPOSTEliminarCaja,
  ApiPOSTEditarCaja
} from "../../../api/contable/cajas";
import { toast } from "react-toastify";
import moment from "moment";
import AuthContext from "../../../Context/auth/authContext";

const TablaCategorias = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [selectData, setselectData] = useState(null);
  const [load, setLoad] = useState(false);
  const [loadeliminar, setLoadEliminar] = useState(false);
  const [loadActivarDesactivar, setLoadActivaDesactivar] = useState(false);

  const [modalActivaDesactivar, setModalActivarDesactivar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  const [data, setdata] = useState([]);

  useEffect(() => {
    getCajasEmpresa();
  }, [props.actualizarLista]);
  const getCajasEmpresa = async () => {
    setLoad(true);
    const res = await ApiPOSTGetCajasXEmpresas({
      empresa_id: veterinaria_id,
    });
    if (res[0].sms === "ok") {
      /*  toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      }); */
      setdata(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
    setLoad(false);
  };

  const ActivarDesactivarCaja = async (estado, caja_id) => {
    setLoadActivaDesactivar(true);
    const res = await ApiPOSTActivarDesactivarCaja({
      estado: estado,
      caja_id: caja_id,
    });
    if (res[0].sms === "ok") {
      setModalActivarDesactivar(false);

      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      getCajasEmpresa();
    } else {
      toast.error(`${res[0].mensaje}`);
    }
    setLoadActivaDesactivar(false);
  };

   const EditarCaja = async () => {
    if(!selectData.nombre){
      toast.error("El nombre no debe estar vacío ",{
        position:"bottom-left",
        theme:"colored"
      })
      return 
    }

    if (!selectData.codigo) {
      toast.error("El código no debe estar vacío ", {
        position: "bottom-left",
        theme: "colored",
      });
      return 
    }

    if (!selectData.caja_id) {
      toast.error("No se ah podido identificar la caja, porfavor actualize la pagina ", {
        position: "bottom-left",
        theme: "colored",
      });
      return 
    }


     setLoadActivaDesactivar(true);

     const res = await ApiPOSTActivarDesactivarCaja(selectData);
     if (res[0].sms === "ok") {
       setModalActivarDesactivar(false);

       toast.success(`${res[0].mensaje}`, {
         position: "bottom-left",
       });
       getCajasEmpresa();
     } else {
       toast.error(`${res[0].mensaje}`);
     }
     setLoadActivaDesactivar(false);
   };

  const EliminarCaja = async (caja_id) => {
    setLoadEliminar(true);
    const res = await ApiPOSTEliminarCaja({
      caja_id: caja_id,
    });
    if (res[0].sms === "ok") {
      setModalEliminar(false);

      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      getCajasEmpresa();
    } else {
      toast.error(`${res[0].mensaje}`);
    }
    setLoadEliminar(false);
  };

  const modalActionsaActivarDesactivar = (modal) => {
    setModalActivarDesactivar(modal);
    setModalEliminar(false);
  };

  const returnactioneditar = (data) => {
   props.ejecutarOpciones(data, true);
  };

  const modalActionsEliminar = (modal) => {
    setModalEliminar(modal);
    setModalActivarDesactivar(false);
  };

  return (
    <div className="row p-0 m-0">
      <div className="col-md-12 p-0 m-0 h-100 ">
        <div className="row p-0 m-0">
          {load ? (
            <div
              style={{ opacity: "0.3" }}
              className=" d-flex flex-column justify-content-center align-items-center"
            >
              <i
                className="fa-solid fa-computer"
                style={{ fontSize: "100px" }}
              ></i>
              <div className="spinner-grow spinner-grow-lg my-3" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="d-flex justify-content-center flex-column align-items-center">
                <p className="m-0">Estamos consultando las cajas</p>
                <p className="m-0">Espere un momento porfavor</p>
                <p className="m-0">...</p>
              </div>
            </div>
          ) : data.length > 0 ? (
            data.map((data, index) => (
              <div className="card  m-0 py-0 mb-3 col-md-6 " key={index}>
                <div className="row  p-0 m-0 g-0 bordesombreado2  ">
                  <div
                    className={
                      data.activo
                        ? "bgactivo col-md-2  m-0  bordecompleto bordesombreado   d-flex justify-content-center align-items-center"
                        : "bginactivo col-md-2  m-0 bordecompleto  d-flex justify-content-center align-items-center"
                    }
                  >
                    <div className=" p-2">
                      <i
                        class="fa-solid fa-desktop  w-100 h-100 center"
                        style={{
                          fontSize: "50px",
                          color: data.activo ? "white" : "black",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-10 p-0 m-0 d-flex flex-column p-2 ">
                    <div className="p-1  d-flex justify-content-between flex-row align-items-center">
                      <div className="d-flex flex-column">
                        <h6 className="">{data.nombre}</h6>
                        <small className="text-muted">
                          {data.codigo}{" "}
                          {data.activo ? (
                            <span class="badge  redondeado rounded-pill bg-success mx-1">
                              Activo
                            </span>
                          ) : (
                            <span class="badge  redondeado rounded-pill bg-warning colorblack mx-1">
                              Inactivo
                            </span>
                          )}
                        </small>
                      </div>
                      <div>
                        {data.activo ? (
                          <button
                            onClick={() => {
                              setselectData(data);
                              setModalEliminar(false);

                              setModalActivarDesactivar(true);
                            }}
                            class="botoncircular bordesombreado bordecompleto bgwarning"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Desactivar caja"
                          >
                            <i className="fas fa-close mx-1"></i>{" "}
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setselectData(data);
                              setModalEliminar(false);

                              setModalActivarDesactivar(true);
                            }}
                            class="botoncircular bordesombreado bordecompleto bgsuccess"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Activar caja"
                          >
                            <i className="fas fa-check mx-1"></i>{" "}
                          </button>
                        )}

                        <button
                          onClick={() => {
                            setselectData(data);
                            setModalEliminar(true);
                            setModalActivarDesactivar(false);
                          }}
                          class="botoncircular bordesombreado bordecompleto bginfo"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Detalles"
                        >
                          <i className="fas fa-list mx-2"></i>{" "}
                        </button>
                        <button
                          onClick={()=>returnactioneditar(data)}
                          class="botoncircular bordesombreado bordecompleto bgprimary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Editar"
                        >
                          <i className="fas fa-edit mx-2"></i>{" "}
                        </button>
                        <button
                          onClick={() => {
                            setselectData(data);
                            setModalEliminar(true);
                            setModalActivarDesactivar(false);
                          }}
                          class="botoncircular bordesombreado bordecompleto bgdanger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Eliminar caja"
                        >
                          <i className="fas fa-trash mx-2"></i>{" "}
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-1 m-0 d-flex justify-content-between flex-column">
                      <p className="m-0">Estado Actividad : {data.estado}</p>
                      {data.caja_estados.length > 0 ? (
                        "si"
                      ) : (
                        <div
                          className="bgfondo d-flex  justify-content-center p-2 align-items-center w-100"
                          style={{ minHeight: "80px", maxHeight: "80px" }}
                        >
                          <div className="bgfondo2 w-100 h-100 d-flex justify-content-center align-items-center">
                            No hay ninguna Actividad en esta caja
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="card-footer w-100 d-flex justify-content-between flex-row">
                      <small className="text-muted">
                        Creado por{" "}
                        {data.usuarios
                          ? data.usuarios.personas.length > 0
                            ? data.usuarios.personas[0].nombres
                            : null
                          : null}
                      </small>
                      <small className="text-muted">
                        <i className="fas fa-calendar mx-1"></i>
                        {moment(data.create_at).format("LLLL")}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{ opacity: "0.3" }}
              className=" d-flex flex-column justify-content-center align-items-center"
            >
              <i
                className="fa-solid fa-computer"
                style={{ fontSize: "100px" }}
              ></i>
              <h5>No hay cajas registradas</h5>
            </div>
          )}
        </div>
      </div>

      <ModalDefault
        bandera={"confirmacion"}
        modal={modalActivaDesactivar}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActionsaActivarDesactivar}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se{" "}
            {selectData
              ? selectData.activo
                ? "Desactivará"
                : "Activará"
              : null}{" "}
            la caja {selectData ? selectData.nombre : null}
          </label>
          <hr className="w-100" />

          <div className="d-flex justify-content-center align-items-center">
            {loadActivarDesactivar ? (
              <button
                className="bg-btn-vet w-100 bordesombreado2 bordecompleto"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                {selectData
                  ? selectData.activo
                    ? "Desactivando..."
                    : "Activando..."
                  : null}
              </button>
            ) : (
              <div>
                <button
                  type=""
                  className="bg-btn-vet-danger mx-1"
                  onClick={() => setModalActivarDesactivar(false)}
                >
                  Cancelar
                </button>
                <button
                  type=""
                  className="bg-btn-vet mx-1"
                  onClick={() => {
                    if (selectData) {
                      ActivarDesactivarCaja(
                        !selectData.activo,
                        selectData.caja_id
                      );
                    } else {
                      toast.error("No existe el indice de la caja");
                    }
                  }}
                >
                  Confirmar
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalEliminar}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActionsEliminar}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="negrita " style={{ textAlign: "center" }}>
            Se eliminará la caja {selectData ? selectData.nombre : null}
          </label>
          <label
            className="negrita"
            style={{ color: "red", textAlign: "center" }}
          >
            Importante! : Esta acción no es reversible
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            {loadeliminar ? (
              <button
                className="bg-btn-vet w-100 bordesombreado2 bordecompleto"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Eliminando...
              </button>
            ) : (
              <div>
                <button
                  type=""
                  className="bg-btn-vet-danger mx-1"
                  onClick={() => setModalEliminar(false)}
                >
                  Cancelar
                </button>
                <button
                  type=""
                  className="bg-btn-vet mx-1"
                  onClick={() => {
                    if (selectData) {
                      EliminarCaja(selectData.caja_id);
                    } else {
                      toast.error("No existe el indice de la caja");
                    }
                  }}
                >
                  Confirmar
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default TablaCategorias;
