

import React, {useContext,useState, useEffect} from 'react';

import Login from './Layouts/Login/login';
import Splash from './Layouts/Splash/splash'

import  AuthContext from './Context/auth/authContext';
import { useHotkeys } from 'react-hotkeys-hook';
import moment from "moment"

function App() {
var locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

  let storageUser =localStorage.getItem("integralaccesscli")


  const AuthenticationContext = useContext(AuthContext)
  const {
   limpiarSesiondataFn,
  } = AuthenticationContext


 /* useHotkeys('ctrl+u', (e) => {
    e.preventDefault()
    teclasActionFn(e)z
  })
*/
  useEffect((e) => {
   console.log(locale)
  
    return()=>{
      
    }

  }, [])

  return (
    <>
    
    {!storageUser?<Login></Login>:<Splash></Splash>}
   </>
  
  );
}

export default App;
