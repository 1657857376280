import React, { useContext, useEffect, useState } from "react";

import Axios from "../../../config/Axios";
import { Link } from "react-router-dom";
import axios2 from "axios";
import { CarouselItem, CarouselCaption } from "reactstrap";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";

import AuthContext from "../../../Context/auth/authContext";
import { Carousel } from "react-responsive-carousel";
import toastr from "toastr";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const items = [];
const PublicarData = ({ veterinaria_id, activeTab }) => {
  moment.locale("es");

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [modal, setModal] = useState(false);
  const [dataPubli, setDataPubli] = useState({});
  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);
  const { sessiondatacli } = authContext;
  const { usuario_id } = sessiondatacli[0].persona[0];

  const [stateLoadInfo, setStateLoadInfo] = useState({
    publicaciones: [],
    currentPage: 1,
    todosPerPage: 1,
  });

  const obtenerPublicacionesVteter = async (veterinaria_id) => {
    try {
      const result = await Axios.get(
        `/PublicacionVeterianriaData/${veterinaria_id}/${usuario_id}`
      );
     /*  if (result.data.result.length > 0) {
        console.log(result.data.result);
        const aux = result.data.result; */
        //console.log(aux[0].urlFotoMascota)
/*         result.data.result.map(async (data, index) => {
 */          /* const firmaFotoUsuario = await Axios.post("/downloadStorageFile", {
            file: data.datausuario[0].foto,
          });
          const firmaFotoVeterinaria = await Axios.post(
            "/downloadStorageFile",
            {
              file: data.dataveterinaria[0].logo,
            }
          ); */
          

         /*  fetch(data.datausuario[0].urlFoto)
            .then((response) => response.blob())
            .then((response) => {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onloadend = () =>
                (aux[index].datausuario[0].urlFotoUsuario = reader.result);
            });

          fetch(data.dataveterinaria[0].urlFoto)
            .then((response) => response.blob())
            .then((response) => {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onloadend = () =>
                (aux[index].dataveterinaria[0].urlFotoVeterinaria =
                  reader.result);
            });

          */

          /* if (data.archivos !== null) {
            data.archivos.map(async (data, index) => {
              const firmaArchivo = await Axios.post("/downloadStorageFile", {
                file: data.archivo,
              });
              data.urlFoto = firmaArchivo.data.url;
              
            });
          } */
      /*   });
       setTimeout(() => {
          setStateLoadInfo({
            ...stateLoadInfo,
            publicaciones: aux,
          });
        }, 1000);
      } else { */
        setStateLoadInfo({
          ...stateLoadInfo,
          publicaciones: result.data.result,
        });
     
    } catch (e) {
      console.log(e);
    }
  };

  const toDataURL = (url) =>{
     fetch(url)
            .then((response) => response.blob())
            .then((response) => {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onloadend = () =>{return reader.result}
                })
      }

  const CargarImagenes = ({ imagen }) => {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        className="w-40"
        src={
          imagen == null
            ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
            : imagen
        }
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          border: `1px solid white`,
          borderRadius: "35px",
        }}
      ></img>
    );
  };

  const CargarImagenesPublic = ({ imagen }) => {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        className="w-40"
        src={
          imagen == null
            ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
            : imagen
        }
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          border: `none`,
        }}
      ></img>
    );
  };

  useEffect(() => {
    obtenerPublicacionesVteter(veterinaria_id);
  }, []);

  const EliminarPublicacion = async (e, data) => {
    e.preventDefault();
    setLoading(true);

    const eliminar = await Axios.post("/EliminarPublicacion", {
      files: data.archivos,
      pm_id: data.pm_id,
    });
    if (eliminar.data.sms === "ok") {
      toastr.success(`${eliminar.data.mensaje}`, "Notificación", {
        timeOut: 3000,
      });

      obtenerPublicacionesVteter(veterinaria_id);
      setModal(false);
      setLoading(false);
    } else {
      toastr.error(`${eliminar.data.mensaje}`, "Notificación", {
        timeOut: 3000,
      });
    }
  };

  return (
    <>
      {
        stateLoadInfo.publicaciones.length > 0
          ? stateLoadInfo.publicaciones.map((data, index) => {
              return (
                <>
                  {data.datamascota !== null ? (
                    <div
                      className="card bg-white my-4 bordecompleto"
                      data-aos="fade-up"
                    >
                      <div className="card-title mb-0 px-4 pt-4 d-flex justify-content-between">
                        <dvi>
                          <div className="w-100" key={index}>
                            <Link to="#" className=" w-100">
                              <div className=" p-0 mx-1 d-flex justify-content-between align-items-center  ">
                                <div
                                  className=""
                                  style={{ width: "50px", height: "50px" }}
                                >
                                  <CargarImagenes
                                    imagen={
                                      //data.datausuario[0].urlFotoUsuario
                                      data.datamascota[0].urlFoto
                                    }
                                  ></CargarImagenes>
                                </div>
                                <div className="mx-2">
                                  <div className="font-weight-bold">
                                    {data.datamascota[0].nombres}
                                  </div>
                                  <div
                                    className="text-muted"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {" "}
                                    Publicado por{" "}
                                    {data.datausuario[0].nombres.toLowerCase()}
                                  </div>
                                  <div
                                    className="text-muted"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {" "}
                                    {moment(data.fpublicacion).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </dvi>
                      </div>
                      <div className="card-body pb-0">
                        <TextareaAutosize
                          style={{
                            width: "100%",
                            textDecoration: "none",
                            border: "none",
                            background: "none",
                          }}
                          value={data.descripcion}
                        />{" "}
                        {data.archivos !== null ? (
                          data.archivos.length > 0 ? (
                            <div
                              className="row d-flex justify-content-center"
                              style={{
                                overflowX: "auto",
                                flexWrap: "initial",
                              }}
                            >
                              <Carousel
                                className="w-100"
                                showArrows={true}
                                swipeable={true}
                                showThumbs={false}
                                emulateTouch={true}
                                autoPlay={false}
                              >
                                {/* <div>
                              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmRJA8VpG1f5dnAj5d1O_D-Q9coIt4kwnOdR0LQkaXBONlctj0z-eAMSe_YAztu7BF8-Q&usqp=CAU" />
                              <p className="legend">Legend 1</p>
                            </div> */}
                                {data.archivos.map((d, i) => {
                                  return (
                                    <div
                                      key={i}
                                      /*  className={
                                  data.archivos.length === 0
                                    ? null
                                    : data.archivos.length === 1
                                    ? "col-md-12 p-0 floatizquierda"
                                    : data.archivos.length > 1
                                    ? "col-md-12 p-0 floatizquierda"
                                    : null
                                } */
                                    >
                                      {d.type === "video" ? (
                                        <video
                                          controls
                                          style={{
                                            textAlign: "center",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          loop
                                          muted
                                          autoPlay
                                          src={d.urlFoto}
                                          preload={"auto"}
                                          //type={d.type}
                                          //className={classes.video}
                                          //ref={ref => this.headerVideo}
                                          //onLoadEnd={() => this.setState({isLoading: false})}
                                        ></video>
                                      ) : (
                                        <CargarImagenesPublic
                                          imagen={d.urlFoto}
                                        ></CargarImagenesPublic>
                                      )}
                                    </div>
                                  );
                                })}
                              </Carousel>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                      <div class="d-flex justify-content-between py-2 align-items-center ">
                        <div>
                          <button
                            onClick={
                              (e) => {} //createLike(e, usuario_id, data.pm_id, true)
                            }
                            className=" px-1 py-1"
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              background: "white",
                            }}
                          >
                            <i
                              style={{
                                fontSize: "20px",
                                color: data.like !== null ? "blue" : "grey",
                              }}
                              className="fas fa-paw ml-1 mx-2"
                            ></i>
                            {data.totallikes[0].totallikes}{" "}
                          </button>
                        </div>
                        <div>
                          {" "}
                          <Link
                            to={"/publicacion/" + data.code_pm}
                            className="px-1"
                          >
                            {data.totalcomentarios[0].totalcomentario}{" "}
                            comentarios
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : data.dataveterinaria !== null ? (
                    <div
                      className="card bg-white my-4 bordecompleto"
                      data-aos="fade-up"
                    >
                      <div className="card-title mb-0 px-4 pt-4 d-flex justify-content-between">
                        <Link
                          to={
                            "/veterinariaperfil/" +
                            data.dataveterinaria[0].codigo
                          }
                          className=" w-100"
                        >
                          <div className=" p-0 mx-1 d-flex justify-content-start align-items-center">
                            <div
                              className=""
                              style={{ width: "50px", height: "50px" }}
                            >
                              <CargarImagenes
                                imagen={
                                  data.dataveterinaria[0].urlFoto
                                }
                              ></CargarImagenes>
                            </div>
                            <div className="mx-2">
                              <div className="font-weight-bold">
                                {data.dataveterinaria[0].nombre}
                              </div>

                              <div
                                className="text-muted"
                                style={{ fontSize: "12px" }}
                              >
                                {" "}
                                {moment(data.fpublicacion).fromNow()}
                              </div>
                            </div>
                          </div>
                        </Link>
                        <button
                          className="bg-btn-vet-danger d-flex justify-content-center align-items-center"
                          style={{
                            width: "25px",
                            height: "25px",
                          }}
                          onClick={(e) => {
                            setModal(true);
                            setDataPubli(data);
                          }}
                        >
                          x
                        </button>

                        {/* <div>x</div> */}
                      </div>
                      <div className="card-body m-0 p-0">
                        <div className="mx-3">
                          {data.descripcion ? (
                            <TextareaAutosize
                              className="textareapublicar bg-white "
                              value={data.descripcion}
                            />
                          ) : null}
                        </div>
                        {data.archivos !== null ? (
                          data.archivos.length > 0 ? (
                            <div
                              className="row d-flex justify-content-center"
                              style={{
                                overflowX: "auto",
                                flexWrap: "initial",
                              }}
                            >
                              <Carousel
                                className="w-100 p-0 m-0"
                                showArrows={true}
                                swipeable={true}
                                showThumbs={false}
                                emulateTouch={true}
                                autoPlay={false}
                              >
                                {/* <div>
                              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmRJA8VpG1f5dnAj5d1O_D-Q9coIt4kwnOdR0LQkaXBONlctj0z-eAMSe_YAztu7BF8-Q&usqp=CAU" />
                              <p className="legend">Legend 1</p>
                            </div> */}
                                {data.archivos.map((d, i) => {
                                  return (
                                    <div
                                      key={i}
                                      /*  className={
                                  data.archivos.length === 0
                                    ? null
                                    : data.archivos.length === 1
                                    ? "col-md-12 p-0 floatizquierda"
                                    : data.archivos.length > 1
                                    ? "col-md-12 p-0 floatizquierda"
                                    : null
                                } */
                                    >
                                      {" "}
                                      {d.type === "video" ? (
                                        <video
                                          controls
                                          style={{
                                            textAlign: "center",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          loop
                                          muted
                                          autoPlay
                                          src={d.urlFoto}
                                          preload={"auto"}
                                          //type={d.type}
                                          //className={classes.video}
                                          //ref={ref => this.headerVideo}
                                          //onLoadEnd={() => this.setState({isLoading: false})}
                                        ></video>
                                      ) : (
                                        <CargarImagenesPublic
                                          imagen={d.urlFoto}
                                        ></CargarImagenesPublic>
                                      )}
                                    </div>
                                  );
                                })}
                              </Carousel>
                            </div>
                          ) : null
                        ) : null}
                        <div class="d-flex justify-content-between py-2 align-items-center ">
                          <div>
                            <button
                              onClick={
                                (e) => {}
                                // createLike(e, usuario_id, data.pm_id, true)
                              }
                              className=" px-1 py-1"
                              style={{
                                borderRadius: "20px",
                                border: "none",
                                background: "white",
                              }}
                            >
                              <i
                                style={{
                                  fontSize: "20px",
                                  color: data.like !== null ? "blue" : "grey",
                                }}
                                className="fas fa-paw ml-1 mx-2"
                              ></i>
                              {data.totallikes[0].totallikes}
                            </button>
                          </div>
                          <div>
                            {" "}
                            <Link
                              to={"/publicacion/" + data.code_pm}
                              className="px-1"
                            >
                              {data.totalcomentarios[0].totalcomentario}{" "}
                              comentarios
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })
          : null /* (
        <h5 className="w-100 p-12 text-center">Sin publicaciones</h5>
      ) */
      }
      {/*   <hr className="my-1 w-100 borderbottom"></hr>
      <div>
        <button
        className="d-flex justify-content-center p-5 flex-column align-items-center w-100"
        style={{background:"none",border:"none"}}
          onClick={(e) => {
            setStatelimit({
              ...statelimit,
              limit: parseInt(statelimit.limit) + parseInt(statelimit.limit),
            });
          }}
        >
          <label>Mostrar más</label>
          <i className="fas fa-arrow-circle-down"></i>
        </button>
      </div> */}
      <br />
      <br />
      {/* MODAL INFO VETERINARIA*/}
      <Modal
        size="sm"
        centered
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
        style={{ maxWidth: "40%", padding: "20px" }}
      >
        <ModalHeader
          toggle={() => {
            setModal(false);
          }}
        >
          Confirmación
        </ModalHeader>
        <ModalBody>
          <div className=" d-flex flex-column justify-content-center align-items-center">
            {loading ? (
              <label for="">Eliminando...</label>
            ) : (
              <>
                {" "}
                <label>Se eliminará la publicación.</label>
                <hr className="w-50" />
                <button
                  type=""
                  className="bg-btn-vet-danger w-50"
                  onClick={(e) => EliminarPublicacion(e, dataPubli)}
                >
                  Eliminar
                </button>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
      {/* FIN MODAL PREGUNTA ELIMINACION PUBLIAR*/}
    </>
  );
};

export default PublicarData;
