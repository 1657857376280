import React, { useEffect, useState } from 'react';

import Axios from "../../../config/Axios"
import Swal from 'sweetalert2'




const EditarPacientePersona = ({ data }) => {

    const { mascota_id, 
        nombreMascota,
        persona_id,
        nombres,
        apellidos,
        correo,
        provincia,
        ciudad, } = data

    const [state, setState] = useState({
        todos: [],
        search: "",
        persona_id: persona_id,
        nombres: nombres,
        apellidos: apellidos,
        correo: correo,
        provincia: provincia,
        ciudad: ciudad,
        mascota_id: mascota_id,
        nombreMascota: nombreMascota

    })

    const onchange = (e) => {
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase()
        })
    }

    const BuscarUsers = async (e) => {
        if (e.target.value === "") {
            /*setState({
                ...state,
                persona_id: "",
                nombres: "",
                apellidos: "",
                correo: "",
                provincia: "",
                ciudad: ""
            })*/
        } else if (e.key === 'Enter') {
            try {
                const respuesta = await Axios.get('/filtarPersona/' + state.search)
                console.log(respuesta.data)
                if (respuesta.data.sms === "ok") {
                    setState({
                        ...state,
                        persona_id: respuesta.data.result[0].persona_id,
                        nombres: respuesta.data.result[0].nombres,
                        apellidos: respuesta.data.result[0].apellidos,
                        correo: respuesta.data.result[0].correo,
                        ciudad: respuesta.data.result[0].ciudad,
                        provincia: respuesta.data.result[0].provincia
                    })

                } else {
                    setState({
                        ...state,
                        persona_id: "",
                        nombres: "",
                        apellidos: "",
                        correo: "",
                        provincia: "",
                        ciudad: ""
                    })
                }
            } catch (e) {

            }

        }

    }



    useEffect(() => {

    }, [])





    const editarPacientePersona = async (e) => {
        e.preventDefault()
        if (state.persona_id === null || state.persona_id === "") {
            alert("La persona es obligatoria")
        } else if (state.mascota_id === null || state.mascota_id === undefined || state.mascota_id === "" ) {
            alert("La mascota es oblgatoria")
        } else {
            try {
                const respuesta = await Axios.post("/editarMascotaPersona", state);
                if (respuesta.data.sms === "ok") {
                    Swal.fire(
                        'Aviso!',
                        `${respuesta.data.mensaje}!`,
                        'success'
                    )

                } else {
                    Swal.fire(
                        'Aviso!',
                        `${respuesta.data.mensaje}!`,
                        'error'
                    )
                }
            } catch (e) { console.log(e) }
        }
    }

 
    useEffect(() => {

    }, [])

    return (
        <>
            {/* Main content */}
            <section className="">
                <div className="">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-12">
                            {/* general form elements */}
                            <div className="card card-primary elevation-2">

                                {/* /.card-header */}
                                {/* form start */}
                                <form role="form">
                                    <div className="card-body" >
                                        <div className="col-md-12">
                                            <div className="row mb-9" style={{ borderBottom: "1px solid #d8d8d8" }}>
                                                <p style={{ fontSize: "16px" }}> Buscar dueño para: <b>{state.nombreMascota}</b> </p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Filtrar cliente por número de cédula:</label><br></br>
                                                        <input type="text" className="form-control" placeholder="Filtre la cédula del cliente y precione enter" aria-label="codigobuscar" aria-describedby="addon-wrapping" onChange={onchange} onKeyUp={(e) => BuscarUsers(e)} id="search" name="search" value={state.search} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Nombres* :</label><br></br>
                                                        <input type="text" className="form-control" disabled onChange={onchange} value={state.nombres} id="nombres" name="nombres" placeholder="Nombres " />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Apellidos* :</label><br></br>
                                                        <input type="text" className="form-control" disabled onChange={onchange} value={state.apellidos} id="apellidos" name="apellidos" placeholder="Apellidos" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Correo* :</label><br></br>
                                                        <input type="text" className="form-control" disabled onChange={onchange} value={state.correo} id="correo" name="correo" placeholder="Correo " />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Provincia* :</label><br></br>
                                                        <input type="text" className="form-control" disabled onChange={onchange} value={state.provincia} id="provincia" name="provincia" placeholder="Provincia " />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Ciudad* :</label><br></br>
                                                        <input type="text" className="form-control" disabled onChange={onchange} value={state.ciudad} id="ciudad" name="ciudad" placeholder="Ciudad " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary" style={{ float: "right" }} onClick={(e) => editarPacientePersona(e)}>Guardar Dueño</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/*/.col (left) */}
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}

        </>
    )
}

export default EditarPacientePersona