import Axios from "../../config/AxiosContable";

export const listarVentasReporte = async (empresa_id,data)=> {
  try {
    const res = await Axios.post("/getFacturasXreportes/" + empresa_id, data);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const listarTodosDocumentosXEmpresa = async (empresa_id, data) => {
  try {
    const res = await Axios.post(
      "/POSTListarTodosDocumentosEmpresa/" + empresa_id,
      data
    );
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const listarDocumentosXEmpresa = async (empresa_id,data) => {
  try {
    const res = await Axios.post(
      "/getAllDocumentosEmpresa/" + empresa_id,{
        data}
      
    );
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const listarVentasTotalesReporte = async (empresa_id) => {
  try {
    const res = await Axios.get(
      "/getDocumentosTotalesXempresa/" + empresa_id
    );
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const listarVentasTotalesFacturasReporte = async (empresa_id) => {
  try {
    const res = await Axios.get("/getFacturasTotalesXempresa/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const listarRecibosTotalesFacturasReporte = async (empresa_id) => {
  try {
    const res = await Axios.get("/getRecibosTotalesXempresa/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
export const listarLiquidacionesTotalesFacturasReporte = async (empresa_id) => {
  try {
    const res = await Axios.get("/getLiquidacionesTotalesXempresa/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
