import React, { useEffect, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Publicar from "./publicar";
import AuthContext from "../../../Context/auth/authContext";
import Publicaciones from "./publicaciones";
import Axios from "../../../config/Axios";
import moment from "moment";

const Inicio = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli, index } = AuthenticationContext;

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const { usuario_id, nombres, correo, color, foto } =
    sessiondatacli[0].persona[0];
  const { logo, atencion, veterinaria_id, expire_access, create_at } =
    sessiondatacli[0].veterinaria[0];

  const [stateModal, setStateModal] = useState({
    modalPublicacion: false,
  });
  const cerrarModalPublicacion = () => {
    setStateModal({
      ...stateModal,
      modalPublicacion: false,
    });
  };

  useEffect(() => {
  }, []);

  const CargarImagenes = ({ imagen }) => {
    return (
      <img
        className="w-40"
        src={logo == null ? "/admin/img/imgfondo.jpg" : imagen}
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          border: `1px solid ${color}`,
          borderRadius: "30px",
        }}
      ></img>
    );
  };

  const startOnlineVet = async (e, online) => {
    e.preventDefault();
    try {
      const result = await Axios.get(`/vetOnline/${online}/${veterinaria_id}`);
      if (result.data.sms === "ok") {
        //window.location="/"
      }
    } catch (e) {
      console.log(e);
    }
  };

  const obtenerdiasdecaducidad = (expire_access) => {
    const hoy = moment();
    const expire = moment(expire_access);
    return `${expire.diff(hoy, "days")} dias`;
  };

  const obtenersiesperiododeprueba = (create_at) => {
    const hoy = moment();
    const expire = moment(create_at);
    return expire.diff(hoy, "months");
  };

  return (
    <>
      <div className="col-md-12 bgfondo2 ">
        <div className="row d-flex justify-content-center ">
          {/* <div className="col-md-1 "></div> */}
          <div className="col-md-5">
            <div
              className="card bgfondo my-4 "
              style={{ margin: "auto", maxWidth: "900px" }}
            >
              <div className="card-body bordecompleto  bordecircular">
                <div className="navi navi-spacer-x-0 w-100 d-flex justify-content-center align-items-center">
                  <div className="navi-link p-0 m-0 d-flex align-items-center w-100 ">
                    <div className="mx-2  ">
                      <div
                        className=""
                        style={{ borderRadius: "30px", width: "50px" }}
                      >
                        <CargarImagenes
                          imagen={localStorage.getItem("ImgVetUrlAuthcli")}
                        ></CargarImagenes>
                      </div>
                    </div>
                    {/* </Link> */}
                    <button
                      className="navi-text w-100 ml-2 bgfondo2 p-3 "
                      style={{ borderRadius: "15px", border: "none" }}
                      onClick={() => {
                        setStateModal({
                          ...stateModal,
                          modalPublicacion: true,
                        });
                      }}
                    >
                      {/*                           <div className="font-weight-bold">vvv</div>
                       */}{" "}
                      <div className="text-muted">
                        PUBLICA ALGO{" "}
                        {sessiondatacli[index].veterinaria[0].nombre}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Publicaciones
              veterinaria_id={
                sessiondatacli[index].veterinaria[0].veterinaria_id
              }
            ></Publicaciones>
          </div>
          {!isMobile ? (
            <div className="col-md-4">
              <div class="card  bordecircular bordecompleto  my-4">
                <div class="card-body d-flex flex-column">
                  <label for="">Nota:</label>

                  <label for="">
                    Los usuarios que sigan la veterinaria en la aplicación de
                    usuario recibiran notificaciones de las publicaciones
                    realizadas.
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          {/*           <div className="col-md-1"></div>
           */}{" "}
        </div>
      </div>

      {/* MODAL PUBLICAR*/}
      <Modal
        isOpen={stateModal.modalPublicacion}
        toggle={() => {
          setStateModal({
            ...stateModal,
            modalPublicacion: false,
          });
        }}
        style={{ maxWidth: "50%", padding: "55px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              modalPublicacion: false,
            });
          }}
          // style={{ background: `${color}` }}
        >
          Publicación
        </ModalHeader>
        <ModalBody>
          {" "}
          <Publicar cerrarModal={cerrarModalPublicacion}></Publicar>
        </ModalBody>
      </Modal>
      {/* FIN MODAL PUBLICARs*/}
    </>
  );
};

export default Inicio;
