import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import {
  getAllRolesVets,
  eliminarRol,
  createRolUsuario,
  getUsuariosPorRoles,
  deleteUsuariosPorRoles,
} from "../../../api/roles";
import {
  cargarModulosPorRoles,
  cargarTodosLosModulosActivos,
  createModuleRolAccess,
} from "../../../api/modulos";
import CreateRol from "./createrol";
import DefaultModal from "../../modalDefault";
import DataTableDefault from "../../ReactDataTable";
import { BuscarUsers } from "../../../api/usuarios";

/* import AuthContext from "../../Context/auth/authContext";
 */
const Roles = ({ veterinaria_id }) => {
  const listadomodulosref = useRef(null);
  /*   const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];
 */

  const [modalCreateRol, setModalCreateRol] = useState(false);
  const [modalCreateRolUsuario, setModalCreateRolUsuario] = useState(false);
  const [modalAsignateModule, setModalAsignateModule] = useState(false);

  const [modulos, setModulosAllAccess] = useState([]);
  const [modulosPersonalizados, setModulosPersonalizados] = useState([]);

  const [rol_id, setRol_id] = useState(null);
  const [totalallaccess, setTotalallaccess] = useState([]);
  const [modulosActivos, setModulosActivos] = useState([]);
  const [modulosPorAgregar, setModulosPorAgregar] = useState([]);
  const [modulosSelect, setModulosSelect] = useState("");

  const [roles, setRoles] = useState([]);
  const [loadRoles, setLoadRoles] = useState(false);
  const [loadingGuardarModulos, setLoadingGuardarModulos] = useState(false);
  const columns = [
    {
      name: "Rol",
      center: true,
      selector: (row) => row.rol,
    },
    {
      name: "Todos los accesos",
      center: true,
      selector: (row) => (row.allaccess ? "si" : "no"),
    },
    {
      name: "Estado",
      center: true,
      selector: (row) => (row.estado ? "Activo" : "Inactivo"),
    },

    {
      name: "Opciones",
      center: true,
      cell: (row) => opciones(row),
      wrap: true,
    },
  ];

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={(e) => {
              e.preventDefault();
              cargarModulosPorRolesFn(row);
              getUsuariosPorRolesFn(row); 
              window.scrollTo({
                top: listadomodulosref.current.offsetTop,
                behavior: "smooth",
              });
            }}
          >
            ver módulos
          </button>
        </div>
        <div>
          {totalallaccess.length > 1 && row.allaccess === true ? (
            <button
              className="btn btn-danger mx-1"
              onClick={() => {
                eliminarRolFn(row.rol_id, !row.estado, row.allaccess);
              }}
            >
              Eliminar
            </button>
          ) : row.allaccess === false ? (
            <button
              className="btn btn-danger mx-1"
              onClick={() => {
                eliminarRolFn(row.rol_id, !row.estado);
              }}
            >
              Eliminar
            </button>
          ) : null}
        </div>
      </>
    );
  };

  const cargarModulosPorRolesFn = async (row) => {
    setRol_id(row);
    const res = await cargarModulosPorRoles(row.rol_id);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      if (row.allaccess === true) {
        setModulosPersonalizados([]);

        setModulosAllAccess(res[0].data);
      } else {
        setModulosAllAccess([]);

        setModulosPersonalizados(res[0].data);
      }
    } else if (res[0].sms === "err") {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      setModulosAllAccess([]);
    }
  };

  const eliminarRolFn = async (rol_id, activo, allaccess) => {
    const res = await eliminarRol(rol_id, activo, veterinaria_id, allaccess);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      init();
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const init = async () => {
    const res = await getAllRolesVets(veterinaria_id);
    if (res[0].sms === "ok") {
      const totalallaccess = res[0].data.filter((data) => {
        if (data.allaccess === true) return data;
      });
      setTotalallaccess(totalallaccess);
      setRoles(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`);
    }
  };

  const cargarModulosActivosFn = async () => {
    const res = await cargarTodosLosModulosActivos(veterinaria_id);
    if (res[0].sms === "ok") {
      console.log("modulos ====");
      console.log(res[0].data);
      setModulosActivos(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`);
    }
  };

  useEffect(() => {
    init();
    cargarModulosActivosFn();
  }, []);

  const closeAction = async (modal, bandera) => {
    setModalCreateRol(modal);
  };
   const closeActionRolUsuario = async (modal, bandera) => {
     setModalCreateRolUsuario(modal);
     setUsuario(null);
    setSearch("")
   };

  const closeActionAsignarModule = async (modal, bandera) => {
    cargarModulosPorRolesFn(rol_id);
    setModalAsignateModule(modal);
    limpiarEstadoAsignarModulosRol();

  };

  const rolcreadoFn = () => {
    setModalCreateRol(false);
    init();
  };

  const selectModuloRef = useRef(null);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const onchangeModulo = (e) => {
    e.preventDefault();
    setModulosSelect(e.target.value);
  };
  const handleDrag = (e) => {
    e.preventDefault();
    let _modulos = [...modulosPorAgregar];
    const draggeItemContento = _modulos.splice(dragItem.current, 1)[0];
    _modulos.splice(dragOverItem.current, 0, draggeItemContento);
    dragItem.current = null;
    dragOverItem.current = null;
    _modulos.map((data, index) => {
      data.orden = index + 1;
    });
    setModulosPorAgregar(_modulos);
  };

  const llenarArrayPorAgregar = () => {
    let aux = [...modulosPorAgregar];
    if (modulosSelect !== "") {
      let res = aux.filter(
        (elemento) => elemento.modulo_veterinaria_id === parseInt(modulosSelect)
      );
      if (res.length > 0) {
        alert("El modulo ya se encuentra en la selección");
      } else {
        let obj = {
          modulo_veterinaria_rol_access_id: "",
          orden: modulosPorAgregar.length + 1,
          modulo_veterinaria_id: modulosSelect,
          rol_id: rol_id.rol_id,
          modulos_veterinarias: {
            modulos: {
              modulo:
                selectModuloRef.current.options[
                  selectModuloRef.current.selectedIndex
                ].text,
            },
          },
        };

        aux.push(obj);
      }
    }
    let eliminar = 0;

    aux.forEach((elemento, index) => {
      if (elemento.eliminar === true) {
        eliminar = eliminar + 1;
      } else {
        elemento.orden = index + 1 - eliminar;
      }
    });
    eliminar = 0;
    aux.sort((a, b) => {
      return a.orden - b.orden;
    });
    setModulosPorAgregar(aux);
  };

  const quitarItemPoAgregar = async (e, index2) => {
    e.preventDefault();
    let todo = [...modulosPorAgregar];
    //let res = await todo.filter((elemento, index) => index === index2);
    let eliminar = 0;

    todo.forEach((elemento, index) => {
      if (index === index2 || elemento.eliminar === true) {
        elemento.eliminar = true;
        elemento.orden = 0;
        eliminar = eliminar + 1;
      } else {
        elemento.orden = index + 1 - eliminar;
      }
    });
    eliminar = 0;
    todo.sort((a, b) => {
      return a.orden - b.orden;
    });
    setModulosPorAgregar(todo);
  };

  const createModuleRolAccessFn = async () => {
    setLoadingGuardarModulos(true);
    modulosPorAgregar.forEach(async (element) => {
      const res = await createModuleRolAccess(element);
      if (res[0].sms === "ok") {
        toast.success(`${res[0].mensaje}`, {
          position: "bottom-left",
        });
      } else {
        toast.error(`${res[0].mensaje}`, {
          position: "bottom-left",
        });
      }
    });
    setLoadingGuardarModulos(false);
  };

  const limpiarEstadoAsignarModulosRol = () => {
    setModulosPorAgregar([]);
    setModulosSelect("");
  };

  
  const [usuario, setUsuario] = useState(null);
  const [search, setSearch] = useState("");
    const [usuariosPorRoles, setUsuariosPorRoles] = useState([]); //("");


 const onchange = async(e, valor) => {
    e.preventDefault();
 if (e.target.name === "search") {
   setSearch(e.target.value);
   return;
 }
}
  const BuscarUsersFn = async (e) => {
    if (e.target.value === "") {
    } else if (e.key === "Enter") {
      const respuesta = await BuscarUsers(e.target.value);
      if (respuesta[0].sms === "ok") {
        setUsuario(respuesta[0].result[0]);
      } else {
        setUsuario(null);
      }
    }
  };

  const gardarRolusuario = async () => {
    let obj = {
      usuario_id: usuario.usuario_id,
      rol_id: rol_id.rol_id,
      panel: 2,
    };
    const res = await createRolUsuario(obj);
    if(res[0].sms==="ok"){
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      setUsuario(null)
      setModalCreateRolUsuario(false)
      getUsuariosPorRolesFn(rol_id);
    }else{
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

    const getUsuariosPorRolesFn = async (row) => {
      const res = await getUsuariosPorRoles(row.rol_id);
      if (res[0].sms === "ok") {
       setUsuariosPorRoles(res[0].data)
      } else {
        toast.error(`${res[0].mensaje}`, {
          position: "bottom-left",
        });
      }
    };

    const deleteUsuariosPorRolesFn = async (usuario_rol_id) => {
      const res = await deleteUsuariosPorRoles(usuario_rol_id);
      if (res[0].sms === "ok") {
        toast.success(`${res[0].mensaje}`, {
          position: "bottom-left",
        });
        getUsuariosPorRolesFn(rol_id)
      } else {
        toast.error(`${res[0].mensaje}`, {
          position: "bottom-left",
        });
      }
    };


  return (
    <div
      className="col-md-12 m-0 p-0
     d-flex justify-content-start flex-column align-items-center"
    >
      <div className=" w-100  d-flex  justify-content-start align-items-center ">
        <label
          for=""
          className="titulosubmenusize"
          style={{ textAlign: "start" }}
        >
          Roles de empresa
        </label>
        <button
          type=""
          className="mx-4 bg-btn-vet"
          onClick={(e) => setModalCreateRol(true)}
        >
          Crear nuevo rol
        </button>
      </div>
      <div className="row w-100 mt-3">
        <hr className="w-100" />

        {loadRoles ? (
          <div>
            {" "}
            <label>Cargando ...</label>
          </div>
        ) : roles.length > 0 ? (
          <DataTableDefault data={roles} columns={columns} title={""} />
        ) : (
          <div>
            <label>No existen roles asignados</label>
          </div>
        )}
      </div>

      {/** ===================================================================== */}

      <div className="my-5"></div>
      {rol_id ? (
        <div className=" w-100  d-flex  justify-content-start align-items-center ">
          <label
            for=""
            className="titulosubmenusize"
            style={{ textAlign: "start" }}
          >
            Módulos asignados para el rol {rol_id.rol}
          </label>
          {rol_id.allaccess === true ? null : (
            <button
              type=""
              className="mx-4 bg-btn-vet"
              onClick={(e) => {
                setModalAsignateModule(true);
                setModulosPorAgregar(modulosPersonalizados);
              }}
            >
              Agregar/Eliminar módulo al rol {rol_id.rol}
            </button>
          )}
        </div>
      ) : null}
      <hr className="w-100" />

      <div className="row w-100" ref={listadomodulosref}>
        {rol_id === null ? (
          <div>
            <label>Seleccione un rol para ver los módulos asignados</label>
          </div>
        ) : rol_id.allaccess === true ? (
          modulos.length > 0 ? (
            <div className="col-md-12 d-flex justify-content-center align-items-center flex-column w-100">
              <div className="d-flex justify-content-start align-items-start flex-column w-100">
                <label for="" className="w-100 my-4">
                  Listado de módulos para el rol ({rol_id.rol})
                </label>
              </div>

              <div className="d-flex justify-content-start align-items-start flex-row flex-wrap w-100">
                {modulos.map((data, index) => (
                  <div className="d-flex justify-content-between align-items-center bgfondo2 m-1 p-2 bordecircular">
                    <label style={{ textAlign: "left" }}>
                      <label style={{}}>({data.orden}) </label>
                      Módulo de {data.modulos.modulo}
                    </label>
                    {rol_id.allaccess === true ? null : (
                      <button
                        type=""
                        className="bg-btn-sm bg-btn-vet-danger mx-5"
                      >
                        Eliminar acceso
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <label>No se asignaron módulos a este rol</label>
            </div>
          )
        ) : modulosPersonalizados.length > 0 ? (
          <div className="col-md-12 d-flex justify-content-center align-items-center flex-column w-100">
            <div className="d-flex justify-content-start align-items-start flex-column w-100">
              <label for="" className="w-100 my-4">
                Listado de módulos para el rol ({rol_id.rol})
              </label>
            </div>

            <div className="d-flex justify-content-start align-items-start flex-row flex-wrap w-100">
              {modulosPersonalizados.map((data, index) => (
                <div className="d-flex justify-content-between align-items-center bgfondo2 m-1 p-2 bordecircular">
                  <label style={{ textAlign: "left" }}>
                    <label style={{}}>({data.orden}) </label> Módulo de{" "}
                    {data.modulos_veterinarias.modulos.modulo}
                  </label>
                  {/* {rol_id.allaccess === true ? null : (
                    <button
                      type=""
                      className="bg-btn-sm bg-btn-vet-danger mx-2"
                    >
                      Eliminar acceso
                    </button>
                  )} */}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <label>No se asignaron módulos a este rol</label>
          </div>
        )}
      </div>

      {/** ===================================================================== */}
      <div className="my-5"></div>
      {rol_id ? (
        <div className=" w-100  d-flex  justify-content-start align-items-center ">
          <label
            for=""
            className="titulosubmenusize"
            style={{ textAlign: "start" }}
          >
            Usuarios asignados para el rol de {rol_id.rol}
          </label>
          { (
            <button
              type=""
              className="mx-4 bg-btn-vet"
              onClick={(e) => setModalCreateRolUsuario(true)}
            >
              Agregar usuario al rol {rol_id.rol}
            </button>
          )}
        </div>
      ) : null}
      <hr className="w-100" />

      <div className="row w-100" ref={null}>
        {rol_id === null ? (
          <div>
            <label>Seleccione un rol para ver los usuarios asignados</label>
          </div>
        ) : usuariosPorRoles.length > 0 ? (
          <div className="col-md-12 d-flex justify-content-center align-items-center flex-column w-100">
            <div className="d-flex justify-content-start align-items-start flex-column w-100">
              <label for="" className="w-100 my-4">
                Listado de usuario para el rol de ({rol_id.rol})
              </label>
            </div>

            <div className="d-flex justify-content-start align-items-start flex-row flex-wrap w-100">
              {usuariosPorRoles.map((data, index) => (
                <div className="d-flex justify-content-between align-items-center bgfondo2 m-2 p-2 bordecircular">
                  <label style={{ textAlign: "left" }} className="mx-2">
                    {data.usuarios.personas[0].nombres}{" "}
                    {data.usuarios.personas[0].apellidos}
                  </label>
                  <button
                    type=""
                    className="bg-btn-sm bg-btn-vet-danger mx-5"
                    onClick={() => deleteUsuariosPorRolesFn(data.usuariorol_id)}
                  >
                    Eliminar acceso
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <label>No se asignaron usuarios a este rol</label>
          </div>
        )}
      </div>

      <DefaultModal
        modal={modalCreateRol}
        titulo="Crear rol"
        closeAction={closeAction}
        size={"lg"}
      >
        <CreateRol
          veterinaria_id={veterinaria_id}
          rolcreadoFn={rolcreadoFn}
        ></CreateRol>
      </DefaultModal>

      <DefaultModal
        modal={modalCreateRolUsuario}
        titulo={rol_id ? `Asignar usuario al rol ${rol_id.rol}` : null}
        closeAction={closeActionRolUsuario}
        size={"md"}
      >
        {usuario ? (
          <div className="col-md-12 d-flex justify-content-center align-items-center my-4">
            <label for="">
              {usuario.nombres} {usuario.apellidos}
            </label>
          </div>
        ) : (
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                Filtrar cliente por número de cédula:
              </label>
              <br></br>
              <input
                type="search"
                className="form-control"
                placeholder="Filtre la cédula del cliente y precione enter"
                aria-label="codigobuscar"
                aria-describedby="addon-wrapping"
                onChange={onchange}
                onKeyUp={(e) => BuscarUsersFn(e)}
                id="search"
                name="search"
                value={search}
              />
              <span className="sizeInfoInput">
                Filtre el usuario al que se le asignará el rol
              </span>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <button
            className="bg-btn-vet w-100"
            onClick={() => gardarRolusuario()}
          >
            Guardar
          </button>
        </div>
      </DefaultModal>

      <DefaultModal
        modal={modalAsignateModule}
        titulo={rol_id ? `Asignar módulo al rol ${rol_id.rol}` : null}
        closeAction={closeActionAsignarModule}
        size={"lg"}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          {modulosActivos.length > 0 ? (
            <div className="col-md-12">
              <div className="row">
                <div className="form-group col-md-8 d-flex justify-content-center align-items-start flex-column">
                  <label>Módulo :</label>
                  <select
                    className="form-control select2bs4"
                    id="modulosSelect"
                    name="modulosSelect"
                    style={{ width: "100%" }}
                    onChange={onchangeModulo}
                    value={modulosSelect}
                    ref={selectModuloRef}
                  >
                    <option value="">Seleccione ...</option>
                    {modulosActivos.map((data, index) => {
                      return (
                        <option value={data.modulo_veterinaria_id} key={index}>
                          {data.modulos.modulo}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => llenarArrayPorAgregar()}
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center flex-column">
              Aun no tenemos modulos creados
            </div>
          )}

          {modulosPorAgregar.length > 0 ? (
            <div className="row">
              <div>Listado de módulos que se agregarán</div>
              {modulosPorAgregar.map((data, index) => {
                return (
                  <div
                    draggable
                    className="grabbable p-2 my-2  d-flex justify-content-between align-items-center"
                    style={{
                      border: "1px solid #115400",
                      background: data.eliminar === true ? "red" : "#115400",
                      color: "white",
                    }}
                    value={data.modulo_id}
                    key={index}
                    onDragStart={(e) => (dragItem.current = index)}
                    onDragEnter={(e) => (dragOverItem.current = index)}
                    onDragEnd={(e) => {
                      handleDrag(e);
                    }}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="d-flex justify-content-center align-items-center flex-row">
                      <div
                        className="d-flex justify-content-between p-2"
                        style={{
                          width: "auto",
                          background: "green",
                          textAlign: "center",
                          border: "1px solid grey",
                        }}
                      >
                        #{data.orden}
                      </div>{" "}
                      <label for="" className="mx-3">
                        {data.modulos_veterinarias.modulos.modulo}{" "}
                      </label>
                    </div>
                    <button
                      type=""
                      className="bg-btn-vet-danger bg-btn-sm ml-5"
                      onClick={(e) => quitarItemPoAgregar(e, index)}
                    >
                      Quitar
                    </button>
                  </div>
                );
              })}
            </div>
          ) : null}
          <hr />
          <div className="row w-100">
            <button
              className="bg-btn-vet w-100 "
              onClick={() => createModuleRolAccessFn()}
            >
              Guarda módulos
            </button>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default Roles;
