import React, { useState } from "react";
import PublicacionesContext from "./PublicacionesContext";
import Axios from "../../config/Axios";
const PublicacionesState = (props) => {
  const initialState = {
    Publicaciones: [],
    Publicacion: [],
  };

  const [state, setState] = useState(initialState);

  const getPublicacionesFn = async (usuario_id) => {
    try {
      const result = await Axios.get(`/PublicacionSiguiendoData/${usuario_id}`);
      if (result.data.result.length > 0) {
        const aux = result.data.result;
        //console.log(aux[0].urlFotoMascota)
        result.data.result.map(async (data, index) => {
          const firmaFotoUsuario = await Axios.post("/downloadStorageFile", {
            file: data.datausuario[0].foto,
          });
          toDataURL(firmaFotoUsuario.data.url).then((dataUrl) => {
            //console.log("Here is Base64 Url", dataUrl);
            //var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
            //console.log("Here is JavaScript File Object", fileData);
            aux[index].datausuario[0].urlFotoUsuario = dataUrl;
          });
          if (data.dataveterinaria !== null) {
            const firmaFotoVeterinaria = await Axios.post(
              "/downloadStorageFile",
              {
                file: data.dataveterinaria[0].logo,
              }
            );
            toDataURL(firmaFotoVeterinaria.data.url).then((dataUrl) => {
              //console.log("Here is Base64 Url", dataUrl);
              //var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
              //console.log("Here is JavaScript File Object", fileData);
              aux[index].dataveterinaria[0].urlFotoVeterinaria = dataUrl;
            });
          }

          if (data.datamascota !== null) {
            const firmaFotoMascota = await Axios.post("/downloadStorageFile", {
              file: data.datamascota[0].foto,
            });
            aux[index].datamascota[0].urlFotoMascota =
              firmaFotoMascota.data.url;
          }

          if (data.archivos !== null) {
            data.archivos.map(async (data, index) => {
              const firmaArchivo = await Axios.post("/downloadStorageFile", {
                file: data.archivo,
              });
              data.urlFoto = firmaArchivo.data.url;
            });
          }
        });
        const timer = setTimeout(() => {
          setState({
            ...state,
            Publicaciones: aux,
          });
        }, 2000);
      } else {
        setState({
          ...state,
          Publicaciones: result.data.result,
        });
      }
    } catch (e) {}
  };

  const getPublicacionCodeFn = async (code_pm, usuario_id) => {
    try {
      const result = await Axios.get(
        `/PublicacionCodeData/${code_pm}/${usuario_id}`
      );
      if (result.data.result.length > 0) {
        const aux = result.data.result;
        //console.log(aux[0].urlFotoMascota)
        result.data.result.map(async (data, index) => {
          const firmaFotoUsuario = await Axios.post("/downloadStorageFile", {
            file: data.datausuario[0].foto,
          });
          toDataURL(firmaFotoUsuario.data.url).then((dataUrl) => {
            //console.log("Here is Base64 Url", dataUrl);
            //var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
            //console.log("Here is JavaScript File Object", fileData);
            aux[index].datausuario[0].urlFotoUsuario = dataUrl;
          });

          if (data.dataveterinaria !== null) {
            const firmaFotoVeterinaria = await Axios.post(
              "/downloadStorageFile",
              {
                file: data.dataveterinaria[0].logo,
              }
            );
            toDataURL(firmaFotoVeterinaria.data.url).then((dataUrl) => {
              //console.log("Here is Base64 Url", dataUrl);
              //var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
              //console.log("Here is JavaScript File Object", fileData);
              aux[index].dataveterinaria[0].urlFotoVeterinaria = dataUrl;
            });
            /*  aux[index].dataveterinaria[0].urlFotoVeterinaria =
              firmaFotoVeterinaria.data.url; */
          }

          if (data.datamascota !== null) {
            const firmaFotoMascota = await Axios.post("/downloadStorageFile", {
              file: data.datamascota[0].foto,
            });
            aux[index].datamascota[0].urlFotoMascota =
              firmaFotoMascota.data.url;
          }

          if (data.archivos !== null) {
            data.archivos.map(async (data, index) => {
              const firmaArchivo = await Axios.post("/downloadStorageFile", {
                file: data.archivo,
              });
              data.urlFoto = firmaArchivo.data.url;
            });
          }
        });
        const timer = setTimeout(() => {
          setState({
            ...state,
            Publicacion: aux,
          });
        }, 2000);
      } else {
        setState({
          ...state,
          Publicacion: result.data.result,
        });
      }
    } catch (e) {}
  };

  const actualizartotalcomentarioFn = async () => {
    try {
      const result = await Axios.get(
        `/totalComentario/${state.Publicacion[0].pm_id}`
      );
      if (result.data.result.length > 0) {
        let aux = state.Publicacion;
        aux.map((data) => {
          data.totalcomentarios[0].totalcomentario =
            result.data.result[0].totalcomentario;
        });
        console.log(aux);
        console.log(result.data.result[0].totalcomentario);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            // reader.readAsDataURL(blob)
          })
      );

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <PublicacionesContext.Provider
      value={{
        publicacionesSiguiendo: state.Publicaciones,
        publicacion: state.Publicacion,
        getPublicacionesFn,
        getPublicacionCodeFn,
        actualizartotalcomentarioFn,
      }}
    >
      {props.children}
    </PublicacionesContext.Provider>
  );
};

export default PublicacionesState;
