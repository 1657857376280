import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";
import ModalDefault from "../../../modalDefault";
import ModalDefaultPreload from "../../../modalpreload";
import VisualizarPDFPanel from "../visualizadorPDF";

import moment from "moment";
import { filtrarTablaDocumentos } from "./filterarray";
import { DataTable } from "mantine-datatable";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import Axios from "../../../../config/Axios";
import {
  listarDocumentosXEmpresa,
  listarTodosDocumentosXEmpresa,
} from "../../../../api/contable/reportes";
import AuthContext from "../../../../Context/auth/authContext";
import {
  crearFirmarXmlVentaApi,
  anularventaApi,
  listarDocumentosDelDiaxUsuario,
} from "../../../../api/contable/ventas";
import {
  verificarAutorizacionsriApi,
  sendmailclienteDocumento,
  getpdfacturaApi,
} from "../../../../api/contable/facturacion";
import DetalleVenta from "./detalledocumentos";
import ExportToExcel from "./excel";

const TablaLiquidaciones = (props) => {

  const authContext = useContext(AuthContext);
  const { sessiondatacli, index, ambientedocumentos } = authContext;
  const { logo } = sessiondatacli[index].veterinaria[0];
  const { usuario_id } = sessiondatacli[index].persona[0];
  const [ima, setima] = useState("");
  const [selectData, setselectData] = useState(null);
  const [dataDocumentos, setDataDocumentos] = useState([]);
  const [load, setLoad] = useState(false);
  const [modalconfirmacion, setModalConfirmacion] = useState(false);
  const [modalloadpdf, setModalloadpdf] = useState(false);

  const [modaldetallesfactura, setModalDetallesFactura] = useState(false);
  const [textmodal, settextmodal] = useState("Procesando...");

  const [modalautorizarfactura, setModalAutorizacionFactura] = useState(false);
  const [imprimir, setImprimir] = useState(0);
  const [modalcorreos, setModalcorreos] = useState(false);

  const [stateverificacionsri, setStateVerificacionSri] = useState(
    "Esperando respuesta ..."
  );

  const [reporte, setReporte] = useState({
    f_inicio: moment().format("YYYY-MM-DD"),
    f_final: moment().format("YYYY-MM-DD"),
    estado_sri: "AUTORIZADO",
    tipo_documento: "03",
    ambiente: ambientedocumentos,
    aplicarfiltro: false,
    aplicarfiltroxusuario: false,
    todosxusuario: false,
    usuario_id: props.usuario_id ? props.usuario_id : "",
  });
  const [search, setSearch] = useState("");
  const [display, setDisplay] = useState("none");

  const [modalsenmail, setModalsenmail] = useState(false);
  const [mails, setmails] = useState([]);
  const [extracorreo, setExtracorreo] = useState("");
  const extrachange = (e) => {
    setExtracorreo(e.target.value);
  };

  const [fetching, setfetching] = useState(false);

  const PAGE_SIZE = props.ocultar ? 20 : 6;
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [records, setRecords] = useState([]);
  const [datarespaldo, setdatarespaldo] = useState([]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [page]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [data]);

  const [doc, setdoc] = useState("");

  const eliminarCategoriaFn = async (categoria_id) => {
    const res = await null;
    if (res[0].sms === "ok") {
      getDocmentosEmpresaXfiltor();
      setModalConfirmacion(false);
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      selectData(null);
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const verificarAutorizacionSriFn = async (row) => {
    setModalAutorizacionFactura(true);
    const res = await verificarAutorizacionsriApi({
      claveacceso: row.clave_acceso,
      ambiente: row.ambiente,
      venta_cabecera_id: row.venta_cabecera_id,
    });
    if (res[0].sms === "ok") {
      setStateVerificacionSri(res[0].mensaje);
      getDocmentosEmpresaXfiltor();
    } else {
      setStateVerificacionSri(res[0].mensaje);
    }
  };

  const editarfactura = (row) => {
    let update = {
      venta_cabecera_id: row.venta_cabecera_id,
      editar: "SI",
      x_key: row.x_key,
      x_password: row.x_password,
      anulado: row.anulado,
      facturado: row.facturado,
      identificacion_cliente: row.identificacion_cliente,
      tipo_emision: row.tipo_emision,
      codigo_identificacion: row.codigo_identificacion,
      codigo_documento: row.codigo_documento,
      codigo_forma_pago: row.codigo_forma_pago,
      codigo_forma_pago_sri: row.codigo_forma_pago_sri,
      fecha_venta: row.fecha_venta,
      n_establecimiento: row.n_establecimiento,
      n_punto_venta: row.n_punto_venta,
      n_factura: row.n_factura,
      subtotal_iva: row.subtotal_iva,
      subtotal_siniva: row.subtotal_siniva,
      subtotal_no_objeto_iva: row.subtotal_no_objeto_iva,
      subtotal_exento_iva: row.subtotal_exento_iva,
      subtotal_sin_impuestos: row.subtotal_sin_impuestos,
      total_descuento: row.total_descuento,
      ice: row.ice,
      iva: row.iva,
      total_devolucion_iva: row.total_descuento,
      irbpnr: row.irbpnr,
      propina: row.propina,
      valor_total: row.valor_total,
      empresa_id: row.veterinaria_id,
      activo: row.activo,
      ambiente: row.ambiente,
      ventas_detalle: row.ventas_detalle,
      emisor: JSON.parse(row.emisor),
      moneda: row.moneda,
      comprador: JSON.parse(row.comprador),
      numero: row.numero,
      docsustento: JSON.parse(row.docsustento),
      info_adicional: JSON.parse(row.info_adicional),
      traslado: JSON.parse(row.traslado),
      pagos: [
        {
          medio: JSON.parse(row.pagos)[0].medio,
          total: JSON.parse(row.pagos)[0].total,
          propiedades: {
            numero: JSON.parse(row.pagos)[0].propiedades.numero,
            banco: JSON.parse(row.pagos)[0].propiedades.banco,
          },
          notas: "Pago de factura",
        },
      ],
    };
    localStorage.setItem("factura", JSON.stringify(update));
    window.location = "/facturacion/inicio";
  };

  const crearyenviarFn = async (row) => {
    const resfirmadoxml = await crearFirmarXmlVentaApi(
      row.veterinaria_id,
      row.venta_cabecera_id
    );
    toast.error(JSON.stringify(resfirmadoxml[0].mensaje), {
      position: "bottom-left",
    });
    getDocmentosEmpresaXfiltor();
  };

  const anularventaFn = async (row) => {
    const resanular = await anularventaApi(row.venta_cabecera_id);
    if (resanular[0].sms === "ok") {
      toast.success(`${resanular[0].mensaje}`, {
        position: "bottom-left",
        theme: "colored",
      });
    } else {
      toast.error(`${resanular[0].mensaje}`, {
        position: "bottom-left",
        theme: "colored",
      });
    }
    recargar();
  };

  const sendMailFn = async (row, e) => {
    e.preventDefault();
    let aux = row;
    aux.correos_enviados = [JSON.parse(aux.comprador).correo];
    const logores = await toDataURL(logo);
    const sndmail = await sendmailclienteDocumento(aux, logores);
    if (sndmail[0].sms === "ok") {
      setModalsenmail(false);
      toast.success(`${sndmail[0].mensaje}`, {
        position: "bottom-left",
      });
    } else {
      setModalsenmail(false);
      toast.error(`${sndmail[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };
  const sendMailFn2 = async (e) => {
    let aux = selectData;
    if (mails.length > 0) {
      aux.correos_enviados = mails;
    } else {
      toast.error("No se ingresó ningún correo");
      return;
    }
    setModalsenmail(true);

    const logores = await toDataURL(logo);
    const sndmail = await sendmailclienteDocumento(aux, logores);
    if (sndmail[0].sms === "ok") {
      setModalsenmail(false);
      toast.success(`${sndmail[0].mensaje}`, {
        position: "bottom-left",
      });
      closeActionCorreos(false);
    } else {
      setModalsenmail(false);
      toast.error(`${sndmail[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const generarPdffn = async (data, bandera) => {
    setModalloadpdf(true);
    settextmodal(`Preparando documento para ${bandera}`);
    console.log(data);
    const logores = await toDataURL(logo);
    const res = await getpdfacturaApi(data, logores);
    console.log(res);
    if (res.sms === "ok") {
      const linkSource = `data:application/pdf;base64,${res.data}`;
      /* var binary = atob(res.data.replace(/\s/g, ""));
         var len = binary.length;
         var buffer = new ArrayBuffer(len);
         var view = new Uint8Array(buffer);
         for (var i = 0; i < len; i++) {
           view[i] = binary.charCodeAt(i);
         }

         // create the blob object with content-type "application/pdf"
         var blob = new Blob([view], { type: "application/pdf" });
         var url = URL.createObjectURL(blob); */

      if (bandera === "visualizar") {
        setdoc(linkSource);
        setselectData(data);
        setModalloadpdf(false);
        setModalDetallesFactura(true);
      }

      if (bandera === "descargar") {
        const a = document.createElement("a");
        a.href = linkSource;
        a.download = `${data.clave_acceso}`;
        a.click();
        setModalloadpdf(false);
      }

      if (bandera === "imprimir") {
        setModalloadpdf(false);
        var iframe = document.createElement("iframe");
        var binary = atob(res.data.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        // create the blob object with content-type "application/pdf"
        var blob = new Blob([view], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        iframe.style.display = "none";
        iframe.src = url;

        document.body.appendChild(iframe);
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }

      settextmodal("");
    } else {
      setModalloadpdf(false);
      toast.error(`${res.mensaje}`);
    }
  };

  const opcionesx = (row) => {
    return (
      <UncontrolledDropdown
        className="me-2 contextMenu w-100"
        direction="start"
      >
        <DropdownToggle caret color="primary">
          Acciones
        </DropdownToggle>
        <DropdownMenu className="ddmenu" style={{ position: "a" }}>
          <DropdownItem header>
            {row.estado_sri === "RECIBIDA" ? (
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO"
                    ? true
                    : row.estado_factura !== 1
                    ? true
                    : false
                }
                className="btn   w-100 p-0 m-0"
                onClick={(e) => {
                  /*               setselectData(row);
                   */
                  verificarAutorizacionSriFn(row);
                }}
              >
                Verificar
              </button>
            ) : row.estado_sri === "DEVUELTA" ? (
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO"
                    ? true
                    : row.estado_factura !== 1
                    ? true
                    : row.codigo_documento === ""
                    ? false
                    : false
                }
                className="btn  w-100 p-0 m-0"
                style={{ width: "100px" }}
                onClick={(e) => {
                  /*               setselectData(row);
                   */
                  crearyenviarFn(row);
                }}
              >
                Autorizar
              </button>
            ) : row.estado_sri === "NO AUTORIZADO " ? (
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO"
                    ? true
                    : row.estado_factura !== 1
                    ? true
                    : row.codigo_documento === ""
                    ? false
                    : false
                }
                className="btn  w-100 p-0 m-0"
                style={{ width: "100px" }}
                onClick={(e) => {
                  /*               setselectData(row);
                   */
                  crearyenviarFn(row);
                }}
              >
                Autorizar
              </button>
            ) : row.estado_sri === "BORRADOR" && row.codigo_documento !== "" ? (
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO"
                    ? true
                    : row.estado_factura !== 1
                    ? true
                    : row.codigo_documento === ""
                    ? false
                    : false
                }
                className="btn  w-100 p-0 m-0"
                style={{ width: "100px" }}
                onClick={(e) => {
                  /*               setselectData(row);
                   */
                  crearyenviarFn(row);
                }}
              >
                Autorizar
              </button>
            ) : null}
          </DropdownItem>
          <DropdownItem divider />

          <DropdownItem>
            {" "}
            {
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO" &&
                  JSON.parse(row.comprador).codigo_identificacion !== "07"
                    ? false
                    : row.codigo_documento === ""
                    ? false
                    : true
                }
                className="btn  w-100 p-0 m-0"
                onClick={(e) => {
                  setModalsenmail(true);
                  sendMailFn(row, e);
                }}
              >
                Enviar correo cliente
              </button>
            }
          </DropdownItem>
          <DropdownItem divider />

          <DropdownItem>
            <button
              disabled={
                row.estado_sri === "AUTORIZADO" &&
                JSON.parse(row.comprador).codigo_identificacion !== "07"
                  ? false
                  : row.codigo_documento === ""
                  ? false
                  : true
              }
              className="btn  w-100 p-0 m-0"
              onClick={(e) => {
                setModalcorreos(true);

                setselectData(row, e);
              }}
            >
              Enviar correos extra
            </button>
          </DropdownItem>

          <DropdownItem>
            <button
              className="btn  w-100 p-0 m-0"
              onClick={(e) => {
                //setModalloadpdf(true);
                generarPdffn(row, "descargar");
              }}
            >
              Descargar PDF
            </button>
          </DropdownItem>

          <DropdownItem divider />

          <DropdownItem>
            {" "}
            <button
              className="btn  my-1 w-100 p-0 m-0"
              onClick={async (e) => {
                e.preventDefault();
                /* const logores = await toDataURL(logo);
                setima(logores);
                setselectData(row);
                setImprimir(imprimir + 1); */
                generarPdffn(row, "imprimir");
              }}
            >
              Imprimir
            </button>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            {" "}
            {row.estado_sri !== "AUTORIZADO" ? (
              <button
                className="btn   w-100 p-0 m-0"
                onClick={(e) => {
                  console.log(e);
                  editarfactura(row);
                }}
              >
                Editar
              </button>
            ) : null}
          </DropdownItem>
          <DropdownItem>
            <button
              className="btn   w-100 p-0 m-0"
              onClick={(e) => {
                generarPdffn(row, "visualizar");
              }}
            >
              detalle
            </button>
          </DropdownItem>
          <DropdownItem>
            {row.estado_sri !== "ANULADO" ? (
              <button
                className="btn  w-100 p-0 m-0"
                onClick={(e) => {
                  console.log(e);
                  anularventaFn(row);
                }}
              >
                Anular
              </button>
            ) : null}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const closeAction2 = (data) => {
    setModalsenmail(data);
  };

  const closeAction3 = (data) => {
    setModalloadpdf(data);
  };

  /* useEffect(() => {
        getDocmentosEmpresaXfiltor();
  }, []); */

  const recargarinicio = () => {
    let auxreport = reporte;
    auxreport.aplicarfiltroxusuario = false;
    auxreport.aplicarfiltro = false;
    auxreport.estado_sri = "AUTORIZADO";
    auxreport.todosxusuario = props.ocultar ? true : false;
    auxreport.f_inicio = moment().format("YYYY-MM-DD");
    auxreport.f_final = moment().format("YYYY-MM-DD");
    setReporte(auxreport)
    setSearch("");
    getDocmentosEmpresaXfiltor();
  };

  const getdocumentosEmpresadeldia = async () => {
    setLoad(true);
    const res = await listarDocumentosDelDiaxUsuario(
      props.empresa_id,
      usuario_id,
      1,
      moment().format("YYYY-MM-DD"),
      "06"
    );
    if (res[0].sms === "ok") {
      //setDataDocumentos(res[0].data);
      setdata(res[0].data);
      setRecords(res[0].data.slice(0, PAGE_SIZE));
      setdatarespaldo(data);
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
    setLoad(false);
  };

  const getDocmentosEmpresaXfiltor = async () => {
    let auxreport = reporte;
    auxreport.aplicarfiltro = false;
    const res = await listarTodosDocumentosXEmpresa(
      props.empresa_id,
      auxreport
    );
    if (res[0].sms === "ok") {
      //setDataDocumentos(res[0].data);
      setdata(res[0].data);
      setRecords(res[0].data.slice(0, PAGE_SIZE));
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const getAllDocmentosEmpresa = async () => {
    setLoad(true);
    const res = await listarDocumentosXEmpresa(props.empresa_id, reporte);
    if (res[0].sms === "ok") {
      //setDataDocumentos(res[0].data);
      setdata(res[0].data);
      setRecords(res[0].data.slice(0, PAGE_SIZE));
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
    setLoad(false);
  };

  const modalActions = (modal, bandera) => {
    setModalConfirmacion(modal);
  };

  const closeAction = (data) => {
    setModalDetallesFactura(data);
    setselectData(null);
    setdoc("");
  };

  const closeActionCorreos = (data) => {
    setModalcorreos(data);
    setselectData(null);
    setmails([]);
  };
  const agregarcorreo = async (e) => {
    e.preventDefault();

    if (extracorreo === "") return;

    const resfilter = await mails.filter((data) => {
      if (data === extracorreo) {
        return data;
      } else {
        return null;
      }
    });

    if (resfilter.length > 0) {
      toast.error(`${extracorreo} ya se encuentra en la lista`);
      return;
    }

    var aux = [...mails];
    aux.push(extracorreo);
    setExtracorreo("");
    setmails(aux);
  };

  const onchange = (e) => {
    if (e.target.name === "search") {
      setSearch(e.target.value);
    } else {
      setReporte({
        ...reporte,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onkeyup = (e) => {
    if (e.key === "Enter") {
      filtrar(search);
    }
    if (e.target.value === "") {
      getDocmentosEmpresaXfiltor();
    }
  };

  const filtrar = async (search) => {
    const res = await filtrarTablaDocumentos(data, search);
    setRecords(res.slice(0, PAGE_SIZE));
  };

  useEffect(() => {
    if (search === "") {
      setdata(datarespaldo);
      setRecords(datarespaldo.slice(0, PAGE_SIZE));
    }
    if (data.length === 0) {
      setdata(datarespaldo);
      setRecords(datarespaldo.slice(0, PAGE_SIZE));
    }
  }, [search]);

  const eliminarcorreo = async (dta) => {
    const resfilter = await mails.filter((data) => {
      if (data !== dta) {
        return data;
      } else {
        return null;
      }
    });

    setmails(resfilter);
  };

  const toDataURL = async () => {
    const firmaFotoVet = await Axios.post("/downloadStorageFile", {
      file: logo,
    });

    return firmaFotoVet.data.url;

    /* const response = await fetch(firmaFotoVet.data.url);
      const file = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        console.log(firmaFotoVet.data.url);
        console.log(reader.result);
        await setima(reader.result);
      }; */
  };

  useEffect(() => {
    recargar();
  }, []);

 const recargar = () => {
   if (props.ocultar === true) {
     recargarinicio();
   } else {
     getDocmentosEmpresaXfiltor();
   }
 };

  const getDocmentosEmpresaAplicarFiltro = async () => {
    let auxreport = reporte;
    auxreport.aplicarfiltroxusuario = props.ocultar ? true : false;
    auxreport.aplicarfiltro = props.ocultar ? false : true;
    const res = await listarTodosDocumentosXEmpresa(
      props.empresa_id,
      auxreport
    );
    if (res[0].sms === "ok") {
      //setDataDocumentos(res[0].data);
      setdata(res[0].data);
      setRecords(res[0].data.slice(0, PAGE_SIZE));
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };
  return (
    <>
      <div className="w-100 ">
        <div className="row ">
          <div className="col-md-12 p-0 d-flex justify-content-between align-items-center">
            <div className="w-50 ">
              <label className="negrita py-1">
                Buscar por nombres/identificación
              </label>
              <div className={"d-flex justify-content-between"}>
                {" "}
                <input
                  className="inputtext2 bordecompleto bordecompleto bordesombreado"
                  id="search"
                  name="search"
                  value={search}
                  placeholder="Ingrese un valor y presione enter"
                  type="search"
                  onChange={onchange}
                  onKeyUp={onkeyup}
                />
              </div>
            </div>
            <div className="w-50 d-flex justify-content-end align-items-end align-self-end">
              <button
                type=""
                className=" h-100 bg-btn-vet bordecompleto bordesombreado"
                onClick={recargarinicio}
              >
                Recargar
              </button>
              <button
                type=""
                className="w-50 h-100 bg-btn-vet bordecompleto bordesombreado mx-2"
                onClick={() => {
                  setDisplay("block");
                }}
              >
                Busqueda avanzada
              </button>
              <ExportToExcel
                finalDataDetail={data}
                state={reporte}
              ></ExportToExcel>
            </div>
          </div>
        </div>
        {display === "block" ? <hr /> : null}
        <div
          className="w-100 bgfondo bordecompleto py-2 mb-3 bordesombreado"
          style={{ display: display }}
        >
          <div className="row">
            <div className="col-md-3 ">
              <div className="form-group">
                <label>AMBIENTE* :</label>
                <select
                  className=" inputselect bordesombreado bordecompleto"
                  id="ambiente"
                  name="ambiente"
                  onChange={(e) => onchange(e)}
                  value={reporte.ambiente}
                >
                  <option value="">Seleccione un ambiente...</option>
                  <option value={1}>PRUEBAS</option>
                  <option value={2}>PRODUCCION</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>ESTADO* :</label>
                <select
                  className=" inputselect bordesombreado bordecompleto"
                  id="estado_sri"
                  name="estado_sri"
                  onChange={(e) => onchange(e)}
                  value={reporte.estado_sri}
                >
                  <option value={"TODOS"}>TODOS</option>
                  <option value={"AUTORIZADO"}>AUTORIZADO</option>
                  <option value={"NO AUTORIZADO"}>NO AUTORIZADO</option>
                  <option value={"RECIBIDA"}>RECIBIDA</option>
                  <option value={"DEVUELTA"}>DEVUELTA</option>
                  <option value={"ANULADO"}>ANULADO</option>
                  <option value={"BORRADOR"}>BORRADOR</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>FECHA INICIAL* :</label>
                <input
                  onChange={(e) => onchange(e)}
                  type="date"
                  name="f_inicio"
                  value={reporte.f_inicio}
                  className="inputtext bordecompleto bordesombreado"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>FECHA FINAL* :</label>
                <input
                  onChange={(e) => onchange(e)}
                  type="date"
                  name="f_final"
                  value={reporte.f_final}
                  className="inputtext bordecompleto bordesombreado"
                />
              </div>
            </div>
            <hr />
            <div className="col-md-12 d-flex justify-content-center ">
              <div className=" w-50 d-flex justify-content-center flex-row py-2">
                <button
                  type=""
                  className=" h-100 bg-btn-vet-danger bordecompleto bordesombreado"
                  onClick={(e) => {
                    setDisplay("none");
                    recargarinicio();
                  }}
                >
                  Cerrar
                </button>
                <button
                  onClick={getDocmentosEmpresaAplicarFiltro}
                  type=""
                  className="bg-btn-vet w-50  bordecompleto bordesombreado mx-1 "
                >
                  Aplicar filtro
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <DataTable
        borderRadius="md"
        /* striped */
        /*  verticalAlignment="top" */
        className={"bordecompleto bgfondo borderradiusnone"}
        highlightOnHover
        height={378}
        noRecordsText={`NO SE ENCONTRARON LIQUIDACIONES ${
          reporte.aplicarfiltro
            ? ` EN EL PERIODO ( ${reporte.f_inicio} - ${reporte.f_final} )`
            : ""
        } `}
        records={records}
        columns={[
          {
            accessor: "numero",
            title: "Nº LIQUIDACIÓN",
            textAlignment: "center",
            render: ({ numero }) => numero,
          },
          {
            accessor: "comprador",
            title: "PROVEEDOR",
            textAlignment: "center",
            render: ({ comprador }) => JSON.parse(comprador).razon_social,
          },
          {
            accessor: "comprador",
            title: "C.I/RUC",
            textAlignment: "center",
            render: ({ comprador }) => JSON.parse(comprador).identificacion,
          },
          {
            accessor: "ambiente",
            title: "AMBIENTE",
            textAlignment: "center",
            render: ({ ambiente }) =>
              ambiente === 1 ? "PRUEBA" : "PRODUCCION",
          },
          {
            accessor: "estado_sri",
            title: "ESTADO_SRI",
            textAlignment: "center",
            render: ({ estado_sri }) => estado_sri,
          },

          {
            accessor: "pagos",
            title: "FORMA PAGO",
            textAlignment: "center",
            render: ({ pagos }) => JSON.parse(pagos)[0].medio,
          },
          {
            accessor: "pagos",
            title: "DETALLE PAGO",
            textAlignment: "center",
            render: ({ pagos }) => JSON.parse(pagos)[0].notas,
          },
          {
            accessor: "fecha_venta",
            title: "FECHA VENTA",
            textAlignment: "center",
            render: ({ fecha_venta }) =>
              moment(fecha_venta).format("YYYY-MM-DD"),
          },
          {
            accessor: "acciones",
            title: "ACCIONES",
            textAlignment: "center",
            render: (record) => opcionesx(record),
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        withColumnBorders
        fetching={fetching}
        /*  rowContextMenu={{
          items: (row) => [
            {
              key: "autorizar",
              hidden:
                row.estado_sri === "RECIBIDA" || row.codigo_documento === "",
              icon: <i className="fas fa-check"></i>,
              title: `Autorizar documento ${row.numero}`,
              onClick: (e) => {
                crearyenviarFn(row);
              },
            },
            {
              key: "verificar",
              hidden:
                row.estado_sri === "DEVUELTA" ||
                row.estado_sri === "NO AUTORIZADO0" ||
                row.estado_sri === "BORRADOR" ||
                row.codigo_documento === "",
              icon: <i className="fas fa-trash"></i>,
              title: `Verificar documento ${row.numero}`,
              onClick: (e) => {
                verificarAutorizacionSriFn(row);
              },
            },
            { key: "divider1", divider: true },

            {
              key: "correo",
              color: "blue",
              hidden:
                (row.estado_sri !== "BORRADOR" &&
                  row.codigo_documento !== "") ||
                (row.estado_sri !== "AUTORIZADO" &&
                  row.codigo_documento !== ""),
              icon: <i className="fas fa-envelope"></i>,
              title: `Enviar ${getTituloPorDocumento().toLowerCase()} a ${
                JSON.parse(row.comprador).correo
              }`,
              onClick: (e) => {
                setModalsenmail(true);
                sendMailFn(row, e);
              },
            },
            {
              key: "correos",
              color: "blue",
              hidden:
                (row.estado_sri !== "BORRADOR" &&
                  row.codigo_documento !== "") ||
                (row.estado_sri !== "AUTORIZADO" &&
                  row.codigo_documento !== ""),
              icon: <i className="fas fa-envelope"></i>,
              title: `Enviar correos extras`,
              onClick: (e) => {
                setModalcorreos(true);

                setselectData(row, e);
              },
            },
            { key: "divider2", divider: true },
            {
              key: "Detalle",
              icon: <i className="fas fa-info"></i>,
              title: `Detalle  ${getTituloPorDocumento().toLowerCase()} nº ${
                row.numero
              }`,
              onClick: (e) => {
                generarPdffn(row, "visualizar");
              },
            },
            {
              key: "dwpdf",
              icon: <i className="fas fa-download"></i>,
              title: `Desgargar PDF ${getTituloPorDocumento().toLowerCase()} nº ${
                row.numero
              }`,
              onClick: (e) => {
                generarPdffn(row, "descargar");
              },
            },

            { key: "divider3", divider: true, color: "red" },

            {
              key: "anular",
              color: "red",
              icon: <i className="fas fa-ban"></i>,
              title: `Anular ${getTituloPorDocumento().toLowerCase()} nº ${
                row.numero
              } `,
              onClick: (e) => {
                anularventaFn(row);
              },
            },
            // ...
          ],
        }} */
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalconfirmacion}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActions}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará la categoría{" "}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => setModalConfirmacion(false)}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectData !== null) {
                  eliminarCategoriaFn(selectData.categoria_id);
                } else {
                  toast.error("No existe el indice de la categoría");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        modal={modaldetallesfactura}
        size={"lg"}
        titulo="Detalles factura"
        closeAction={closeAction}
      >
        <DetalleVenta data={selectData}></DetalleVenta>
        <div className="viewer">
          {doc === "" ? null : (
            <VisualizarPDFPanel doc={doc}>
            </VisualizarPDFPanel>
          )}
        </div>
      </ModalDefault>
      <ModalDefault
        modal={modalautorizarfactura}
        size={"md"}
        titulo="Confirmación"
        closeAction={closeAction}
      >
        <div className="col-m-12">
          <div className="row" style={{ flexWrap: "wrap" }}>
            {stateverificacionsri}
          </div>
          {/*  <hr />
          <div className="row ">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <button
                type=""
                className="bg-btn-vet-danger  mx-1"
                onClick={() => setModalAutorizacionFactura(false)}
              >
                Cancelar
              </button>
              <button
                type=""
                className="bg-btn-vet mx-1"
                onClick={() => autorizarsri()}
              >
                Enviar
              </button>
            </div>
          </div> */}
        </div>
      </ModalDefault>
      <ModalDefaultPreload
        modal={modalsenmail}
        maxWidth={"35%"}
        closeAction={closeAction2}
      >
        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
          {/*  <Spinnericon className="svgpreloadfactura" /> */}
          <img
            style={{ width: "280px" }}
            src="https://i.pinimg.com/originals/af/10/b0/af10b0661568f8aa2f98a43f7298224e.gif"
            alt=""
          />
          <div>Enviando correo</div>
          <div>...</div>
        </div>
      </ModalDefaultPreload>
      <ModalDefaultPreload
        modal={modalloadpdf}
        maxWidth={"35%"}
        bg="#fcfefc"
        closeAction={closeAction3}
      >
        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
          {/*  <Spinnericon className="svgpreloadfactura" /> */}
          <img
            style={{ width: "50px", height: "50px" }}
            src="https://media.tenor.com/wuuWICoS8kMAAAAC/smartparcel-down-arrows.gif"
            alt=""
            className="my-2"
          />
          <div>{textmodal}</div>
          <div>...</div>
        </div>
      </ModalDefaultPreload>
      <ModalDefault
        modal={modalcorreos}
        size={"md"}
        titulo="Ingrese correos adicionales"
        closeAction={closeActionCorreos}
      >
        <div className="col-md-12">
          <div className="row" style={{ flexWrap: "wrap" }}>
            <input
              type="extracorreo"
              name="extracorreo"
              value={extracorreo}
              onChange={extrachange}
              className="inputtext"
              placeholder="Digite un correo electrónico válido"
            />
            <button
              type=""
              className="bg-btn-vet my-3"
              onClick={(e) => agregarcorreo(e)}
            >
              Agregar
            </button>
          </div>
          <hr />
          <div className="row negrita titulosize">
            Listado de correos agregados
          </div>
          <hr />
          <div className="row">
            {mails.length > 0 ? (
              <table className="w-100">
                <tbody>
                  {mails.map((data) => {
                    return (
                      <tr className="bordecompleto  p-2">
                        <td className="titulosSize negrita">
                          <label for="" className="px-2">
                            {data}
                          </label>
                        </td>
                        <td style={{ width: "100px" }}>
                          <button
                            type=""
                            className="bg-btn-vet-danger btn-sm my-1"
                            onClick={(e) => eliminarcorreo(data)}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colspan="2">
                      <button
                        type=""
                        className="bg-btn-vet my-3 w-100"
                        onClick={(e) => sendMailFn2(e)}
                      >
                        Enviar ({mails.length}) correos
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <label for="" style={{ textAlign: "center" }}>
                No se agregó ningún correo extra
              </label>
            )}
          </div>
        </div>
      </ModalDefault>
    </>
  );
};

export default TablaLiquidaciones;
