import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";

const ConfigurablesPagos = ({
  state,
  pagos,
  onchangeprincipal,
  tiposDocumentos,
  tiposPagos,
  tiposImpuestos,
  tiposPagossri,
  pagoselect,
}) => {
  const onchange = (e) => {
    onchangeprincipal(e);
  };


  /* useEffect(() => {
  }, [pagoselect]); */

  return (
    <div className="row my-0 p-0 bgfondo w-100 ">
      <div className="w-100 d-flex flex-column py-1">
        {pagoselect.medio === "cheque" ||
        pagoselect.medio === "debito_cuenta_bancaria" ||
        pagoselect.medio === "otros" ||
        pagoselect.medio === "tranferencia" ? (
          <div
            className="col-md-12  d-flex flex-column justify-content-around bordecompleto bgfondo2 p-2 "
            style={{ display: "none" }}
          >
            <div className=" w-100">
              {" "}
              <label
                for=""
                className=" negrita d-flex flex-column justify-content-start textfloatleft"
              >
                N° cnta. beneficiario (Opcional) :{" "}
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className=" inputtextsmall bordecompleto"
                  onChange={onchange}
                  value={pagoselect ? pagoselect.propiedades.numero : ""}
                  id="numero"
                  name="numero"
                  placeholder="N° bancario (Opcional)"
                />
              </div>
            </div>
            
            <div className=" w-100">
              {" "}
              <label
                for=""
                className=" negrita d-flex flex-column justify-content-start textfloatleft"
              >
                Banco (Opcional):
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className=" inputtextsmall bordecompleto"
                  onChange={onchange}
                  value={pagoselect ? pagoselect.propiedades.banco : ""}
                  id="banco"
                  name="banco"
                  placeholder="banco (Opcional)"
                />
              </div>
            </div>
          </div>
        ) : null}

        {state.codigo_documento === "06" ||
        state.codigo_documento === "07" ? null : (
          <div className="col-md-12 m-0 px-0 py-2" style={{ display: "block" }}>
            <div className=" d-flex justify-content-start align-items-center flex-column">
              <label
                for=""
                className=" negrita d-flex justify-content-start align-items-center "
              >
                Monto Pendiente de pago :{" "}
              </label>
              <div className="col-md-12 d-flex flex-row justify-content-center">
                <h3>$</h3>
                <input
                  type="text"
                  className=" inputtext bordecompleto negrita"
                  onChange={onchange}
                  value={pagoselect ? pagoselect.total : 0}
                  style={{
                    width: "30%",
                    height: "25px",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                  id="total"
                  name="total"
                  placeholder="valor"
                />
              </div>
            </div>
          </div>
        )}

       {/*  {state.codigo_documento === "06" ||
        state.codigo_documento === "07" ? null : (
          <div className="col-md-12 m-0 p-0" style={{ display: "block" }}>
            <div className=" d-flex justify-content-start align-items-center flex-column">
              <label
                for=""
                className=" negrita d-flex justify-content-start align-items-center "
              >
                Detalle del pago :{" "}
              </label>
              <TextareaAutosize
                rows="2"
                className="textareapublicar bordecompleto"
                onChange={onchange}
                value={pagoselect ? pagoselect.notas : ""}
                id="notas"
                name="notas"
                placeholder={"Detalle del pago"}
                style={{
                  height: "70px!important",
                  width: "100%",
                  border: "none",
                }}
              />{" "}
             
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ConfigurablesPagos;
