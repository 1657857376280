import React, { useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Usuarios from "../Components/veterinariaLayout/Usuarios/listarUsuarios";
import Clinica from "../Components/veterinariaLayout/dashboard/dashboard";



import Seguridad from "../Components/veterinariaLayout/seguridad/index";

import ConsultaMedica from "../Components/veterinariaLayout/consultaMedica/consultamedica";

import Inicio from "../Components/veterinariaLayout/registroMedicoPaciente/index";
import InicioDashboard from "../Components/veterinariaLayout/inicio/inicio";
import PublicacionesInicio from "../Components/veterinariaLayout/publicaciones/inicio";

import Publicacion from "../Components/extras/publicacion";
import Contable from "../pages/Contable";
import Afiliados from "../pages/Afiliaciones";

import Facturacion from "../pages/Facturacion";
import PreloadRutas from "../Components/preloadRutas";
import authContext from "../Context/auth/authContext";
const RutasVet = () => {
  const navigate = useNavigate();
  const AuthContext = useContext(authContext);
  const { modulos } = AuthContext;
  const getModulo = ({ route, code }) => {
    return <Route key={code} exact path={route} element={getcomponent(code)} />;
  };

  const getcomponent = (code) => {
    if (code === "clinica") return <Clinica />;
    if (code === "inicio") return <InicioDashboard />;
    if (code === "facturacion") return <Facturacion />;
    if (code === "contable") return <Contable />;
    if (code === "afiliados") return <Afiliados />;
    if (code === "seguridad") return <Seguridad />;
    if (code === "publicaciones") return <PublicacionesInicio />;
  };

  return (
    <Routes>
      {modulos.length > 0 ? (
        modulos.map((data) => getModulo(data.modulos))
      ) : (
        <Route exact path="/" element={<PreloadRutas />} />
      )}

      <Route exact path="/usuarios/:idusuarios" element={<Usuarios />} />
      <Route exact path="/consulta" element={<ConsultaMedica />} />
      <Route exact path="/registromedico/:mascota_id" element={<Inicio />} />
      <Route exact path="/publicacion/:code_pm" element={<Publicacion />} />
      {/*         <Route exact path="/tienda" element={IndexTienda} />
        <Route exact path="/items" element={Items} />
        <Route exact path="/inventario" element={Inventario} /> */}

      <Route
        render={function () {
          navigate("/");
        }}
      />
    </Routes>
  );
};

export default RutasVet;
