import React, { useEffect, useState, useContext, useRef } from "react";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";

import { editarBancoApi, guardarBancosApi } from "../../../api/contable/bancos";
import AuthContext from "../../../Context/auth/authContext";
import TablaSocios from "./tabla";
import { toast } from "react-toastify";
import ModalDefault from "../../modalDefault";
import CrearEditarAfiliado from "./crearEditar";
import AfiliadosConfiguraciones from "../servicios/index";
import CategoriasAfiliados from "../categorias/index";

const Socios = (props) => {
  const bancoref = useRef(null);
  const paneleditarcrearRef = useRef(null);
  const tablacategoriasRef = useRef(null);
  const [actualizarLista, setActualizarLista] = useState(false);

  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [state, setState] = useState({
    banco: "",
    empresa_id: veterinaria_id,
  });
  const [statereset, setStateReset] = useState({
    banco: "",
    empresa_id: veterinaria_id,
  });
  const [editState, setEditState] = useState({
    campoedit: "",
    nuevovalor: "",
    banco_id: "",
  });

  const [Toggle, settoggle] = useState("1");

  const [respaldoEditField, setRespaldoEditField] = useState("");
  const [modalCrearEditar, setModalCrearEditarr] = useState(false);
  const [modalServiciosConfig, setModalServiciosConfig] = useState(false);

  const [editar, setEditar] = useState(false);

  const [buttonState, setButtonState] = useState(false);
  const [editDateSelect, setEditDataSelect] = useState(false);

  const [load, setLoad] = useState(false);
  const [selectData, setselectData] = useState(null);
  const onchange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (editar) ed(e.target.name, e.target.value);
  };

  const guardarFn = async () => {
    setLoad(true);
    const res = await guardarBancosApi(state);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, { position: "bottom-left" });
      setActualizarLista(!actualizarLista);
      setState(statereset);
    } else {
      alert(`${res[0].mensaje}`);
    }
    setLoad(false);
  };

  const habilitarEdicion = (edit, data) => {
    setEditState({
      ...editState,
      campoedit: edit,
      banco_id: data.banco_id,
    });
    setRespaldoEditField(state[edit]);
    bancoref.current.focus();
  };
  const cancelarEdicion = (e, campoeditar) => {
    e.preventDefault();
    setState(statereset);
    setEditar(false);
    setEditState({
      ...editState,
      campoedit: "",
      nuevovalor: "",
      banco_id: "",
    });
    setRespaldoEditField("");
    document.getElementById(campoeditar).focus();
  };

  const editarFn = async (e) => {
    e.preventDefault();
    setButtonState(true);
    if (editState.campoedit === "") {
      toast.error("El campo no puede ir vacio", { position: "bottom-right" });
      return;
    }
    const res = await editarBancoApi(editState);
    console.log(res[0]);
    if (res[0].sms === "ok") {
      cancelarEdicion(e, editState.campoedit);
      window.scrollTo(0, tablacategoriasRef.current);
      setButtonState(false);
      setActualizarLista(!actualizarLista);
    } else if (res[0].ssm === "err") {
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
      });
      setButtonState(false);
    }
  };

  const editbtn = (campoeditar) => {
    if (editState.campoedit === campoeditar) {
      document.getElementById(campoeditar).disabled = false;
    }

    return editar ? (
      <div className="w-100 ">
        {editState.campoedit !== campoeditar ? (
          <button
            className=" bg-btn-vet w-100 bordecompleto bordesombreado"
            onClick={(e) => habilitarEdicion(e, campoeditar)}
          >
            editar
          </button>
        ) : editState.campoedit === campoeditar ? (
          <div className="d-flex flex-row justify-content-between">
            <button
              className="bg-btn-vet-danger mx-2 bordesombreado bordecompleto"
              onClick={(e) => {
                e.preventDefault();
                cancelarEdicion(e, campoeditar);
              }}
            >
              x
            </button>
            <button
              className=" bg-btn-vet  w-100 bordecompleto bordesombreado"
              disabled={editState.nuevovalor === "" ? true : false}
              onClick={editarFn}
            >
              Guardar cambios
            </button>
          </div>
        ) : null}
      </div>
    ) : null;
  };
  const ed = (name, valor) => {
    if (editState.campoedit === name) {
      setEditState({
        ...editState,
        nuevovalor: valor,
      });
    }
  };

  const editField = (data) => {
    setState({
      ...state,
      banco: data.banco,
    });
  };

  const ejecutaropciones = (data, editar) => {
    if (editar === true) {
      setEditar(true);
      editField(data);
      habilitarEdicion("banco", data);
      window.scrollTo(0, paneleditarcrearRef.current);
    }
  };

  const modalActions = () => {
    setEditDataSelect(null);
    setModalCrearEditarr(false);
  };

  const activarModalCrearEditar = () => {
    setModalCrearEditarr(true);
  };
  const crearrModalActualizar = () => {
    setEditDataSelect(null);
    setModalCrearEditarr(false);
    setActualizarLista(!actualizarLista);
  };

  const fnModalEdit = (dataedit) => {
    setModalCrearEditarr(true);
    setEditDataSelect(dataedit);
  };

  const toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  return (
    <div className="col-md-12 m-0 p-0 ">
      <div className="row  d-flex flex-column ">
        <div
          className="d-flex justify-content-start bgfondo align-items-center"
          style={{ height: "60px" }}
        >
          <h5 className="titulosubmenusize">PANEL AFILIADOS || SOCIOS </h5>
        </div>
        <div ref={paneleditarcrearRef} className="my-3">
          <div className="">
            <button
              type=""
              className="bg-btn-vet bordesombreado bordecompleto mx-1 "
              onClick={activarModalCrearEditar}
            >
              <i className="fas fa-plus"></i> Nueva afiliación
            </button>
            <button
              type=""
              className="bg-btn-vet bordesombreado bordecompleto mx-1"
              onClick={() => setModalServiciosConfig(true)}
            >
              <i className="fas fa-cog"></i> Servicios
            </button>
          </div>
        </div>
        <div ref={tablacategoriasRef}>
          <TablaSocios
            veterinaria_id={veterinaria_id}
            ejecutarOpciones={ejecutaropciones}
            actualizarLista={actualizarLista}
            ActivarModalEdit={fnModalEdit}
          ></TablaSocios>
        </div>
      </div>
      <ModalDefault
        bandera={"crearModalEditar"}
        modal={modalCrearEditar}
        maxWidth={"55%"}
        classnameheader=""
         
        titulo={`Crear/Editar Afiliado`}
        closeAction={modalActions}
      >
        <div className="col-md-12">
          <div className="negrita w-100 center p-2">
            Información necesaria para la suscripción del socio/afiliado a la
            sucursal.
          </div>
          <p></p>
        </div>
        <hr />
        <CrearEditarAfiliado
          selectData={editDateSelect}
          editar={true}
          crearrModalActualizar={crearrModalActualizar}
        />
      </ModalDefault>
      <ModalDefault
        bandera={"configServicios"}
        modal={modalServiciosConfig}
        maxWidth={"55%"}
        classnameheader=""
         
        titulo={`Afiliados/Servicios configuraciones`}
        closeAction={() => {
          setModalServiciosConfig(false);
        }}
      >
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={Toggle === "1" ? "active" : ""}
                onClick={() => {
                  settoggle("1");
                }}
              >
                Servicios socios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={Toggle === "2" ? "active" : ""}
                onClick={() => {
                  settoggle("2");
                }}
              >
                Categorias socios
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={Toggle}>
            <TabPane tabId="1">
              <AfiliadosConfiguraciones />
            </TabPane>
            <TabPane tabId="2">
              <CategoriasAfiliados />
            </TabPane>
          </TabContent>
        </div>
      </ModalDefault>
    </div>
  );
};

export default Socios;
