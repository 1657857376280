import { Worker, Viewer, ScrollMode } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const VisualizarPdf = ({doc}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //pdfjs-dist

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Viewer fileUrl={doc} plugins={[defaultLayoutPluginInstance]}></Viewer>
    </Worker>
  );
};

export default VisualizarPdf
