import Axios from "../../config/AxiosContable";

export const crearCategoriaServiciosApi = async (data) => {
  try {
    const result = await Axios.post("/POSTCrearCategoriaAfiliado", data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const editarCategoriaServiciosApi = async (data) => {
  try {
    const result = await Axios.post("/POSTEditarCategoriaAfiliado", data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const eliminarCategoriaServiciosApi = async (data) => {
  try {
    const result = await Axios.post("/POSTEliminarCategoriaAfiliado", data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const getAllCategoriasServiciosApi = async (empresa_id) => {
  try {
    const result = await Axios.get(`/GETAllCategoriasAfiliados/${empresa_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const getCategoriaServiciosApi = async (id) => {
  try {
    const result = await Axios.get(`/GETCategoriaAfiliado/${id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};
