import Axios from "../../config/AxiosContable";

export const guardarBancosPagosApi = async (state) => {
  try {
    const res = await Axios.post("/createBancoPagos", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getBancosPagosEmpresaApi = async (empresa_id) => {
  try {
    const res = await Axios.get("/getBancosPagosEmpresa/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const editarBancoPagosApi = async (state) => {
  try {
    const res = await Axios.post("/editBancoPagos", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const eliminarBancosPagosEmpresaApi = async (banco_id) => {
  try {
    const res = await Axios.get("/deleteBancoPagos/" + banco_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
