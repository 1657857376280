import React, { useState, useEffect } from "react";
import ModalDefault from "../../modalDefault";
import {
  getMedidasEmpresaApi,
  eliminarMedidasEmpresaApi,
} from "../../../api/contable/medidas";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
/* import { ReactComponent as EllipsisMenu } from "../../../icons/ellipsis-solid.svg";
 */import moment from "moment";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
const TablaMedidas = (props) => {
  const [selectData, setselectData] = useState(null);
  const [dataMedidas, setDataMedidas] = useState([]);
  const [load, setLoad] = useState(false);

  const [loadLista, setLoadLista] = useState(false);
  const [modalconfirmacionMedidas, setModalConfirmacionMedidas] =
    useState(false);

  const [fetching, setfetching] = useState(false);

  const PAGE_SIZE = 7;
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [page]);

   useEffect(() => {
     const from = (page - 1) * PAGE_SIZE;
     const to = from + PAGE_SIZE;
     setRecords(data.slice(from, to));
   }, [data]);

  const accionescategoria = (data) => {
    props.ejecutarOpciones(data, true);
  };

  const eliminarMedidaFn = async (medida_id) => {
    const res = await eliminarMedidasEmpresaApi(medida_id);
    if (res[0].sms === "ok") {
      getCategoriasEmpresa();
      setModalConfirmacionMedidas(false);
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      selectData(null);
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const editarfn=()=>{

  }

  const opcionesx = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="start">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              accionescategoria(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> editar
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              setselectData(row);
              setModalConfirmacionMedidas(true);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };



  useEffect(() => {
    initComponent();
  }, [props.actualizarLista]);
  const getCategoriasEmpresa = async () => {
    setfetching(true);
    const res = await getMedidasEmpresaApi(props.veterinaria_id);
    if (res[0].sms === "ok") {
      toast.info(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      setdata(res[0].data);
      setRecords(res[0].data.slice(0, PAGE_SIZE));
    } else {
      toast.error(`${res[0].mensaje}`);
    }
    setfetching(false);
  };

  const initComponent = async () => {
    getCategoriasEmpresa();
  };

  const modalActions = (modal, bandera) => {
    setModalConfirmacionMedidas(modal);
  };

  return (
    <div className=" w-100 ">
      <DataTable
        borderRadius="md"
        /* striped */
        /*  verticalAlignment="top" */
        className="bordecompleto bgfondo"
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        columns={[
          {
            accessor: "medida",
            title: "Medida",
            textAlignment: "left",
            render: ({ medidas }) => medidas,
          },
          {
            accessor: "create_at",
            title: "Fecha de cración",
            textAlignment: "center",
            render: ({ create_at }) => moment(create_at).format("MMM D YYYY"),
          },
          {
            accessor: "estado",
            textAlignment: "center",
            render: ({ activo }) => (activo ? "ACTIVO" : "INACTIVO"),
          },
          {
            accessor: "acciones",
            title: "Acciones",
            textAlignment: "center",
            render: (record) => opcionesx(record),
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        withColumnBorders
        fetching={fetching}
         rowContextMenu={{
          items: (record) => [
            // ...

            {
              key: "edit",
              color: "blue",
              icon: <i className="fas fa-edit"></i>,
              title: `Editar medida ${record.medidas}`,
              onClick: () => {
                accionescategoria(record);
              },
            },
            {
              key: "delete",
              color: "red",
              icon: <i className="fas fa-trash"></i>,
              title: `Eliminar medida ${record.medidas}`,
              onClick: (e) => {
                setselectData(record);
                setModalConfirmacionMedidas(true);
              },
            },

            // ...
          ],
        }}
      />

      <ModalDefault
        bandera={"confirmacion"}
        modal={modalconfirmacionMedidas}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActions}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará la medida {selectData ? selectData.medidas : null}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => setModalConfirmacionMedidas(false)}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectData !== null) {
                  eliminarMedidaFn(selectData.medida_id);
                } else {
                  toast.error("No existe el indice de la categoría");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      {/*  <ModalDefault bandera={"otracosa"} modal={false} size={"sm"} icontitulo={<AdminIcon/>} titulo={"Titulo modal"} closeAction={modalActions}>
                modal de editar usuario
            </ModalDefault> */}
    </div>
  );
};

export default TablaMedidas;
