import Axios from "../config/Axios";

export const cargarModulos= async (rol_id) => {
  try {
    const result = await Axios.get(
      `/getModulosEmpresa/${rol_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const cargarModulosPorRoles = async (rol_id) => {
  try {
    const result = await Axios.get(`/getListaModulosPorRol/${rol_id}`);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const cargarTodosLosModulosActivos = async (veterinaria_id) => {
  try {
    const result = await Axios.get(`/getModulosEmpresaCentral/${veterinaria_id}`);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};


//FUNCION PARA  ASIGNAR  LOS MODULOS SEGUN LOS ROLES QUE NO SEAN ALL ACCESS
export const createModuleRolAccess = async (state) => {

  try {
    const respuesta = await Axios.post(
      "/createModulosRolAccess",state
    );
    console.log(respuesta.data)
    if (respuesta.data.sms === "ok") {
      return [respuesta.data];
    } else {
    }
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};
