import Axios from "../../config/AxiosContable";

export const ApiCreatePresignedUrl = async (key) => {
  try {
    const result = await Axios.post(`/POSTcreatePresignedUrl`, {
      key,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};
