import React, { useState, useEffect } from "react";
import ModalDefault from "../../modalDefault";

import { GetAfiliadosEmpresaApi } from "../../../api/afiliaciones_socios";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import ServiciosContratados from "../serviciosContratados/index";
import Pagos from "../pagos";

const TablaBancos = (props) => {
  const [selectData, setselectData] = useState(null);
  const [load, setLoad] = useState(false);
  const [modalconfirmacion, setModalConfirmacion] = useState(false);
  const [fetching, setfetching] = useState(false);

  const PAGE_SIZE = 9;
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [records, setRecords] = useState([]);

  const [modalActividad, setModalActividad] = useState(false);
  const [Toggle, settoggle] = useState("1");
  const [afiliado, setAfiliado] = useState(null);

  /*
  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    //setRecords(data.slice(from, to));
    setRecords(data);
  }, [page]);
  */

  /*
  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    //setRecords(data.slice(from, to));
    setRecords(data);
  }, [data]);
  */

  const accionescategoria = (data) => {
    props.ActivarModalEdit(data);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    const filter = data.filter(
      (item) =>
        item.nombres.toLowerCase().includes(value) ||
        item.apellidos.toLowerCase().includes(value) ||
        item.cedula.toLowerCase().includes(value)
    );
    setRecords(filter);
    //setPage(1);
  };

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={(e) => {
              accionescategoria(e, row);
            }}
          >
            editar
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger mx-1"
            onClick={() => {
              setselectData(row);
              setModalConfirmacion(true);
            }}
          >
            Eliminar
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    initComponent();
  }, [props.actualizarLista]);

  const getRecordsEmpresa = async () => {
    setLoad(true);
    const res = await GetAfiliadosEmpresaApi(props.veterinaria_id);

    if (res.sms === "ok") {
      toast.info(`${res.mensaje}`, {
        position: "bottom-center",
      });
      setdata(res.data);
      //setRecords(res.data.slice(0, PAGE_SIZE));
      setRecords(res.data);
    } else {
      toast.error(`${res.mensaje}`);
    }
    setLoad(false);
  };

  const initComponent = async () => {
    getRecordsEmpresa();
  };

  const modalActions = (modal, bandera) => {
    setModalConfirmacion(modal);
  };

  const accionesactividad = (data) => {
    setAfiliado(data);
    setModalActividad(true);
  };

  const opcionesx = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="end">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              accionesactividad(row);
            }}
          >
            <i className="fas fa-history mx-2"></i> Actividad
          </DropdownItem>
          {/*
          <DropdownItem
            onClick={(e) => {
              accionescategoria(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> editar
          </DropdownItem>
          */}
          <DropdownItem
            onClick={() => {
              setselectData(row);
              setModalConfirmacion(true);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <input
          type="text"
          className="form-control w-25 my-2"
          placeholder="Buscar por cédula, nombre o apellido"
          onChange={handleSearchChange}
        />
      </div>

      <DataTable
        borderRadius="md"
        /* striped */
        /*  verticalAlignment="top" */
        className="bordecompleto bgfondo"
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        columns={[
          {
            accessor: "acciones",
            title: "Acciones",
            textAlignment: "center",
            render: (record) => opcionesx(record),
          },
          {
            accessor: "cedula",
            title: "Cédula",
            textAlignment: "center",
            render: ({ cedula }) => cedula,
          },
          {
            accessor: "nombres",
            title: "Nombres",
            textAlignment: "center",
            render: ({ nombres, apellidos }) => `${apellidos} ${nombres}`,
          },
          {
            accessor: "afiliacion",
            title: "Afiliación",
            textAlignment: "center",
            render: ({ afiliados_estados }) =>
              afiliados_estados.length > 0
                ? afiliados_estados[0].estado_afiliado
                : "PENDIENTE",
          },
          {
            accessor: "afiliados_estados",
            title: "Estado pagos",
            textAlignment: "center",
            render: ({ afiliados_estados }) =>
              afiliados_estados[0]?.pagos?.length > 0
                ? "Si hay registros"
                : "No hay registros",
          },
          {
            accessor: "fecha_afiliacion",
            title: "Fecha de afiliación",
            textAlignment: "center",
            render: ({ afiliados_estados }) =>
              afiliados_estados.length > 0
                ? moment(afiliados_estados[0].fecha_afiliacion).format(
                    "MM/DD/YYYY"
                  )
                : "No afiliado",
          },
          {
            accessor: "numero_afiliacion",
            title: "N. Afiliado",
            textAlignment: "center",
            render: ({ afiliados_estados }) =>
              afiliados_estados.length > 0
                ? afiliados_estados[0].numero_afiliacion
                  ? afiliados_estados[0].numero_afiliacion
                  : "No afiliado"
                : "No afiliado",
          },
          /*
          {
            accessor: "servicios_contratados",
            title: "Servicios contratados",
            textAlignment: "center",
            render: ({ afiliados_servicios_asignaciones }) =>
              afiliados_servicios_asignaciones.length > 0
                ? afiliados_servicios_asignaciones.map((data) => (
                    <p>
                      {data.afiliados_servicios?.alias} ({data.forma_pago})
                    </p>
                  ))
                : "NO DEFINIDO",
          },
          
          {
            accessor: "estado_debito_bancario",
            title: "Débito bancario",
            textAlignment: "center",
            render: ({ afiliados_servicios_asignaciones }) =>
              afiliados_servicios_asignaciones.length > 0
                ? afiliados_servicios_asignaciones.map((data) => (
                    <p>({data.estado_debito_bancario ?? "NO DEFINIDO"})</p>
                  ))
                : "NO DEFINIDO",
          },
          {
            accessor: "auth_debito_bancario",
            title: "Auth_Débito_Bancario",
            textAlignment: "center",
            render: ({ afiliados_servicios_asignaciones }) =>
              afiliados_servicios_asignaciones.length > 0
                ? afiliados_servicios_asignaciones.map((data) => (
                    <p>({data.auth_debito_bancario ?? "N/A"})</p>
                  ))
                : "NO DEFINIDO",
          },
          */
          {
            accessor: "detalle",
            title: "Nota",
            textAlignment: "center",
            render: ({ afiliados_estados }) =>
              afiliados_estados.length > 0 ? (
                <div>{afiliados_estados[0].detalle}</div>
              ) : (
                "NO DEFINIDO"
              ),
          },
          {
            accessor: "aceeso_usuario",
            title: "Acceso usuario",
            textAlignment: "center",
            render: ({ usuarios }) =>
              usuarios ? (usuarios.length > 0 ? "SI" : "NO") : "NO",
          },

          /* {
            accessor: "fecha",
            title: "Fecha de registro",
            textAlignment: "center",
            render: ({ afiliados_estados }) =>
              moment(afiliados_estados[0].fecha).format("MM/DD/YYYY"),
          },*/
        ]}
        totalRecords={data.length}
        //recordsPerPage={PAGE_SIZE}
        //page={page}
        //onPageChange={(p) => setPage(p)}
        withColumnBorders
        fetching={fetching}
        /**
         * rowContextMenu={{
          items: (record) => [
            // ...
            {
              key: "edit",
              color: "blue",
              icon: <i className="fas fa-edit"></i>,
              title: `Editar medida ${record.categoria}`,
              onClick: () => {
                accionescategoria(record);
              },
            },
            {
              key: "delete",
              color: "red",
              icon: <i className="fas fa-trash"></i>,
              title: `Eliminar medida ${record.categoria}`,
              onClick: (e) => {
                setselectData(record);
                setModalConfirmacion(true);
              },
            },

            // ...
          ],
        }}
         */
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalconfirmacion}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActions}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará el registro seleccionado, ¿Desea continuar?
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => setModalConfirmacion(false)}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectData !== null) {
                  toast.success("Esta función aún no está habilitada");
                } else {
                  toast.error("No existe el indice del banco");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"actividad"}
        modal={modalActividad}
        maxWidth={"70%"}
        titulo={`Actividad`}
        closeAction={() => {
          setModalActividad(false);
          setAfiliado(null);
        }}
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={Toggle === "1" ? "active" : ""}
              onClick={() => {
                settoggle("1");
              }}
            >
              Servicios contratados
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={Toggle === "2" ? "active" : ""}
              onClick={() => {
                settoggle("2");
              }}
            >
              Pagos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={Toggle}>
          <TabPane tabId="1">
            <ServiciosContratados
              afiliado={afiliado}
              setModalActividad={setModalActividad}
              setAfiliado={setAfiliado}
            />
          </TabPane>
          <TabPane tabId="2">
            <Pagos
              afiliado={afiliado}
              setModalActividad={setModalActividad}
              setAfiliado={setAfiliado}
            />
          </TabPane>
        </TabContent>
      </ModalDefault>
    </>
  );
};

export default TablaBancos;
