import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { crearCLientesFacturacion } from "../../api/clientesFacturacion";
const ClienteFactura = ({ cliente, setcliente }) => {
  const [state, setState] = useState({
    cedula: "",
    direccion: "",
    telefono: "",
    n_remision: "",
    correo: "",
    tipo_identificacion_id: "",
    ruc: "",
    placa: "",
    pasaporte: "",
    idexterior: "",
    razonsocial: "",
  });
  const [loading, setLoading] = useState(false);
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const crearClientefactura = async () => {
    setLoading(true)
    if (
      state.cedula === "" &&
      state.placa === "" &&
      state.ruc === "" &&
      state.idexterior === ""
    ) {
      toast.error(`Se necesita un campo de identificación `,{
        theme:"colored",
        position:"bottom-left"
      });
      return 
    }     
    
      const res = await crearCLientesFacturacion(state);
      if (res[0].sms === "ok") {
        setcliente(res[0].data);
        toast.success(res[0].mensaje);
      } else {
        toast.error(res[0].mensaje);
      }

      setLoading(false)
    
  };

  useEffect(() => {
    initprops();
  }, []);

  const initprops = () => {
    setState({
      ...state,
      cedula: cliente.cedula,
      direccion: "",
      telefono: "",
      n_remision: "",
      correo: "",
      tipo_identificacion_id: "",
      ruc: cliente.ruc,
      placa: cliente.placa,
      pasaporte: cliente.pasaporte,
      idexterior: cliente.idexterior,
    });
  };

  return (
    <div className="row bg-white">
      <div className="col-md-12">
        <div className="form-group row">
          <div className="col-md-12 bgfondo  px-0">
            <div className="row px-2 negrita">
              INGRESE LAS IDENTIFICACIONES ASOCIADAS AL CLIENTE
            </div>
            <div className="row px-2 py-2">
              <hr />
            </div>
            <div className="row">
              <div className="col-md-6 px-0">
                <div className="form-group row">
                  <div className="col-md-12 d-flex d-flex justify-content-start align-items-center">
                    {" "}
                    <label className="py-2" htmlFor="exampleInputEmail1">
                      Cédula* :
                    </label>
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="inputtext bordecompleto "
                      onChange={onchange}
                      value={state.cedula}
                      id="cedula"
                      name="cedula"
                      placeholder="Ingrese los cedula/ruc "
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-0">
                <div className="form-group row">
                  <div className="col-md-12 d-flex d-flex justify-content-start align-items-center">
                    {" "}
                    <label className="py-2" htmlFor="exampleInputEmail1">
                      Ruc* :
                    </label>
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="inputtext bordecompleto "
                      onChange={onchange}
                      value={state.ruc}
                      id="ruc"
                      name="ruc"
                      placeholder="Ingrese el  ruc "
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className="py-2" htmlFor="exampleInputEmail1">
                  Placa* :
                </label>
                <input
                  type="text"
                  className="inputtext bordecompleto "
                  onChange={onchange}
                  value={state.placa}
                  id="placa"
                  name="placa"
                  placeholder="Ingrese la placa"
                />
              </div>
              <div className="col-md-6">
                <label className="py-2" htmlFor="exampleInputEmail1">
                  Identificación del exterior * :
                </label>
                <input
                  type="text"
                  className="inputtext bordecompleto "
                  onChange={onchange}
                  value={state.idexterior}
                  id="idexterior"
                  name="idexterior"
                  placeholder="Ingrese la identificación del exterior "
                />
              </div>
              <div className="col-md-12 mt-3">
                <div className="row px-2 negrita">INFORMACIÓN GENERAL</div>
                <div className="row px-2 ">
                  <hr />
                </div>
              </div>
              <div className="col-md-12">
                <label className="py-2" htmlFor="exampleInputEmail1">
                  Razon social:
                </label>
                <input
                  type="text"
                  className="inputtext bordecompleto "
                  onChange={onchange}
                  value={state.razonsocial}
                  id="razonsocial"
                  name="razonsocial"
                  placeholder="Ingrese la razon social "
                />
              </div>

              <div className="col-md-6">
                <label className="py-2" htmlFor="exampleInputEmail1">
                  Teléfono :
                </label>
                <input
                  type="text"
                  className="inputtext bordecompleto "
                  onChange={onchange}
                  value={state.telefono}
                  id="telefono"
                  name="telefono"
                  placeholder="Ingrese los telefóno "
                />
              </div>

              <div className="col-md-6">
                <label className="py-2" htmlFor="exampleInputEmail1">
                  Correo :
                </label>
                <input
                  type="text"
                  className="inputtext bordecompleto  "
                  onChange={onchange}
                  value={state.correo}
                  id="correo"
                  name="correo"
                  placeholder="Ingrese los correo "
                />
              </div>
              <div className="col-md-12">
                <label className="py-2" htmlFor="exampleInputEmail1">
                  Dirección :
                </label>
                <input
                  type="text"
                  className="bordecompleto inputtext"
                  onChange={onchange}
                  value={state.direccion}
                  id="direccion"
                  name="direccion"
                  placeholder="Ingrese los direccion "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 my-2">
        <button
          type=""
          disabled={loading}
          className="bg-btn-vet w-100 bordecompleto bordesombreado"
          onClick={crearClientefactura}
        >
          {" "}
          Guardar
        </button>
      </div>
    </div>
  );
};

export default ClienteFactura;
