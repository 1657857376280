import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import moment from "moment";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import ModalDefault from "../../modalDefault";
import {
  actualizarEstadoPagoAfiliadoApi,
  getPagosAfiliadoEmpresaApi,
} from "../../../api/afiliaciones_socios/pagos";

const TablaPagos = (props) => {
  const PAGE_SIZES = 5; // Define los tamaños de página disponibles
  const [page, setPage] = useState(1); // Estado para el página actual
  const [data, setData] = useState([]); // Estado para los datos totales
  const [records, setRecords] = useState([]); // Estado para los registros que se muestran actualmente

  const [selectedPago, setSelectedPago] = useState(null);
  const [modalCofirmacion, setModalConfirmacion] = useState(false);

  const [modalCambiarEstado, setModalCambiarEstado] = useState(false);
  const [estado, setEstado] = useState("");

  const [deuda, setDeuda] = useState(0);

  useEffect(() => {
    const getRecordsEmpresa = async () => {
      const res = await getPagosAfiliadoEmpresaApi({
        empresa_id: props.empresa,
        afiliado_id: props.afiliado,
      });
      if (res.sms === "ok") {
        setData(res.data);
      } else {
        toast.error(res.mensaje);
      }
    };

    getRecordsEmpresa();
  }, [props.actualizarLista]);

  useEffect(() => {
    const deuda = data.reduce((acc, item) => {
      if (
        item.estado_pago === "PENDIENTE" ||
        item.estado_pago === "NO PAGADO"
      ) {
        acc += parseFloat(item.valor);
      }
      return acc;
    }, 0);
    setDeuda(deuda);
  }, [data]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZES;
    const to = from + PAGE_SIZES;
    setRecords(data.slice(from, to));
  }, [page, data]);

  const handleEditEstado = (pago) => {
    setSelectedPago(pago);
    setEstado(pago.estado_pago);
    setModalCambiarEstado(true);
  };

  const handleDelete = (pago) => {
    setSelectedPago(pago);
    setModalConfirmacion(true);
  };

  const eliminarPagoFn = async (pago_id) => {
    try {
      const respuesta = { sms: "ok", data: [] }; //await eliminarPagoServiciosApi({pago_id});
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        const d = data.filter((cat) => cat.pago_id !== pago_id);
        setData(d);
        setSelectedPago(null);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar el petición");
    } finally {
      setModalConfirmacion(false);
    }
  };

  const editarEstadoPago = async (pago_id, estado) => {
    try {
      const respuesta = await actualizarEstadoPagoAfiliadoApi({
        pago_id: pago_id,
        estado_pago: estado,
      });
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        const d = data.map((cat) => {
          if (cat.pago_id === pago_id) {
            cat.estado_pago = estado;
          }
          return cat;
        });
        setData(d);
        setSelectedPago(null);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar el petición");
    } finally {
      setModalCambiarEstado(false);
      setEstado("");
    }
  };

  const acciones = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="end">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleEditEstado(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> Cambiar estado
          </DropdownItem>
          
          {/*
          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleDelete(row);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
          */}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      {deuda === 0 ? (
        <div className="d-flex justify-content-end align-items-center mb-2">
          <i className="fa fa-check-circle text-success me-2" />
          <span className="badge bg-success">Paz y salvo</span>
        </div>
      ) : (
        <div className="d-flex justify-content-end align-items-center mb-2">
          <i className="fa fa-exclamation-triangle text-danger me-2" />
          <span className="badge bg-warning text-dark">
            En mora por: ${deuda}
          </span>
        </div>
      )}

      <DataTable
        borderRadius="md"
        className="bordecompleto bgfondo"
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        columns={[
          {
            accessor: "pago_id",
            title: " ",
            render: (row) => (
              <div className="d-flex justify-content-center">
                {acciones(row)}
              </div>
            ),
          },
          {
            accessor: "orden_pago",
            title: "Orden de pago",
            textAlignment: "left",
          },

          {
            accessor: "descripcion_producto",
            title: "Descripcion",
            textAlignment: "left",
          },
          {
            accessor: "fecha_pago",
            title: "Fecha de pago",
            textAlignment: "left",
            render: ({ fecha_pago }) => moment(fecha_pago).format("MM/DD/YYYY"),
          },
          {
            accessor: "estado_pago",
            title: "Estado",
            textAlignment: "left",
            //Color a la celda del estad PENDIENTE: Naranja ,PAGADO: verdad,NO PAGADO: rojo, ANULADO: rojo
            render: ({ estado_pago }) => {
              let color = "";
              if (estado_pago === "PENDIENTE") {
                color = "bg-warning";
              } else if (estado_pago === "PAGADO") {
                color = "bg-success";
              } else if (estado_pago === "NO PAGADO") {
                color = "bg-danger";
              } else if (estado_pago === "ANULADO") {
                color = "bg-danger";
              }
              return (
                <div className={`text-white ${color} px-2 py-1`}>
                  {estado_pago}
                </div>
              );
            },
          },
          {
            accessor: "file_pago_comprobante",
            title: "Comprobante",
            textAlignment: "center",
            render: ({ file_pago_comprobante }) =>
              !file_pago_comprobante ? (
                <i className="fas fa-upload"></i>
              ) : (
                file_pago_comprobante
              ),
          },
          {
            accessor: "forma_pago",
            title: "Forma de pago",
            textAlignment: "left",
          },
          {
            accessor: "code_pago",
            title: "Codigo",
            textAlignment: "left",
          },
          {
            accessor: "valor",
            title: "Valor",
            textAlignment: "left",
            render: ({ valor }) => `$${valor}`,
          },
          {
            accessor: "condonado",
            title: "Condonado",
            textAlignment: "left",
            render: ({ condonado }) => `${condonado ? "SI" : "NO"}`,
          },
          {
            accessor: "fecha",
            title: "Fecha de registro",
            textAlignment: "left",
            render: ({ create_at }) => moment(create_at).format("MM/DD/YYYY"),
          },
          {
            accessor: "fecha_mininma_pago",
            title: "Fecha minima de pago",
            textAlignment: "left",
            render: ({ fecha_mininma_pago }) =>
              moment(fecha_mininma_pago).format("MM/DD/YYYY"),
          },
          {
            accessor: "fecha_maxima_pago",
            title: "Fecha maxima de pago",
            textAlignment: "left",
            render: ({ fecha_maxima_pago }) =>
              moment(fecha_maxima_pago).format("MM/DD/YYYY"),
          },
          {
            accessor: "detalle_pago",
            title: "Detalle",
            textAlignment: "left",
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={PAGE_SIZES}
        page={page}
        onPageChange={setPage}
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalCofirmacion}
        size={"sm"}
        titulo={`Confirmación`}
        closeAction={() => {
          setModalConfirmacion(false);
        }}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará el pago {selectedPago?.pago}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => {
                setModalConfirmacion(false);
                setSelectedPago(null);
              }}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectedPago !== null) {
                  eliminarPagoFn(selectedPago.pago_id);
                } else {
                  toast.error("No existe el indice de el pago.");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>

      <ModalDefault
        bandera={"confirmacion"}
        modal={modalCambiarEstado}
        size={"sm"}
        titulo={`Cambiar estado`}
        closeAction={() => {
          setModalCambiarEstado(false);
        }}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center mb-2">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Seleccione el estado del pago
          </label>
          <select
            className="form-select"
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
          >
            <option value="PENDIENTE">Pendiente</option>
            <option value="PAGADO">Pagado</option>
            <option value="NO PAGADO">No pagado</option>
            <option value="ANULADO">Anulado</option>
          </select>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <button
            type=""
            className="bg-btn-vet-danger mx-1"
            onClick={() => {
              setModalCambiarEstado(false);
              setEstado("");
            }}
          >
            Cancelar
          </button>
          <button
            type=""
            className="bg-btn-vet mx-1"
            onClick={() => {
              if (selectedPago !== null) {
                editarEstadoPago(selectedPago.pago_id, estado);
              } else {
                toast.error("No existe el indice de el pago.");
              }
            }}
          >
            Confirmar
          </button>
        </div>
      </ModalDefault>
    </>
  );
};

export default TablaPagos;
