import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import perrito from "../../../assets/perrito.gif";
import Axios from "../../../config/Axios";
import toastr from "toastr";
import {
  soloPermitirTextoConEspacio,
  soloPermitirNumeros,
  soloPermitirTextoMayusculaConEspacio,
  soloPermitirTextoConEspacioCaracteresBasicos,
} from "../../../config/validaciones";
import  TextareaAutosize from "react-textarea-autosize";
//import Swal from 'sweetalert2'
import {
  getPaisesFn,
  getProvinciasFn,
  getCiudadesFn,
} from "../../../api/ubicaciones";

const CrearUsuarios = (props) => {
  const [ubicaciones, setUbicaciones] = useState({
    paises: [],
    provincias: [],
    ciudades: [],
  });
  const { paises, ciudades, provincias } = ubicaciones;

  const [state, setState] = useState({
    cedula: "",
    nombres: "",
    apellidos: "",
    telefono: "",
    correo: "",
    direccion: "",
    provincia_id: 0,
    ciudad: "",
    genero: "",
    usuario_id: "",
    pais_id: 0,
    pais: "",
  });

  const [statefire, setStatefire] = useState({
    mensaje: "Iniciando registro ...",
    info: "info",
    sms: "",
  });

  const [stateModal, setStateModal] = useState({
    configModal: false,
  });

  const onchange = async (e) => {
    e.preventDefault();

    if (e.target.name === "nombres") {
      //inicio if principal campo nombres ====================================================
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            state.nombres[
              state.nombres.length === 0
                ? state.nombres.length
                : state.nombres.length - 1
            ] !== " "
          ) {
            setState({
              ...state,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //inicio elseif principal campo apellidos ====================================================
    } else if (e.target.name === "apellidos") {
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            state.apellidos[
              state.apellidos.length === 0
                ? state.apellidos.length
                : state.apellidos.length - 1
            ] !== " "
          ) {
            setState({
              ...state,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "direccion") {
      if (
        e.target.value[
          e.target.value.length === 0
            ? e.target.value.length
            : e.target.value.length - 1
        ] === " "
      ) {
        if (
          state.direccion[
            state.direccion.length === 0
              ? state.direccion.length
              : state.direccion.length - 1
          ] !== " "
        ) {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } else {
        setState({
          ...state,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "ciudad") {
      if (
        e.target.value[
          e.target.value.length === 0
            ? e.target.value.length
            : e.target.value.length - 1
        ] === " "
      ) {
        if (
          state.ciudad[
            state.ciudad.length === 0
              ? state.ciudad.length
              : state.ciudad.length - 1
          ] !== " "
        ) {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } else {
        setState({
          ...state,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "cedula") {
      console.log(e.target.value.length);
      if (soloPermitirNumeros.test(e.target.value)) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "telefono") {
      if (soloPermitirNumeros.test(e.target.value)) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "pais_id") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
      const provincias = await getProvinciasFn(e.target.value);
      setUbicaciones({
        ...ubicaciones,
        provincias: provincias[0].data,
      });
    } else if (e.target.name === "provincia_id") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
      const ciudades = await getCiudadesFn(e.target.value);
      setUbicaciones({
        ...ubicaciones,
        ciudades: ciudades[0].data,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const crearUsuarios = async (e) => {
    e.preventDefault();
    if (state.nombre === "" || state.nombre === null) {
      alert("los nombres son oblgatorios");
    } else if (state.apellidos === "" || state.apellidos === null) {
      alert("los apellidos son obligatorios");
    } else if (state.telefono === "" || state.telefono === null) {
      alert("se debe ingresa un telefono correcto (10 caracteres)");
    } else if (
      state.correo === "" ||
      /.+@.+/.test(state.correo) === false ||
      state.correo === null
    ) {
      alert("se debe ingresar un correo válido");
    } else if (!navigator.onLine) {
      alert("El explorador no tiene acceso a la red");
    } else {
      try {
        setStateModal({
          ...stateModal,
          configModal: true,
        });
        const respuesta = await Axios.post("/createUserAccount", state);
        if (respuesta.data.sms === "ok") {
          setStatefire({
            ...statefire,
            mensaje: respuesta.data.mensaje,
            sms: respuesta.data.sms,
          });

          setState({
            ...state,
            cedula: null,
            nombres: null,
            apellidos: null,
            telefono: null,
            correo: null,
            direccion: null,
            provincia_id: null,
            ciudad: null,
            genero: null,
            usuario_id: null,
          });
        } else {
          setStatefire({
            ...statefire,
            mensaje: respuesta.data.mensaje,
            sms: respuesta.data.sms,
          });
        }
      } catch (e) {
        setStatefire({
          ...statefire,
          mensaje: e,
        });
      }
    }
  };


  const cerrarModalPreload = () => {
    setStateModal({
      ...stateModal,
      configModal: false,
    });
    setStatefire({
      ...statefire,
      mensaje: "Iniciando registro ...",
    });
  };


  useEffect(() => {
    inizializar()
    return ()=>{
      
    }
  },[]);

  const inizializar=async()=>{
    const paises = await getPaisesFn();
    setUbicaciones({
      ...ubicaciones,
      paises: paises[0].data,
    });
  }


  
  return (
    <>
      {/* Main content */}
      <section className="">
        <div className="">
          <div className="row">
            {/* left column */}
            <div className="col-md-12">
              {/* general form elements */}
              <div className="">
                {/* /.card-header */}
                {/* form start */}
                <form role="form">
                  <div className="card-body">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                htmlFor="exampleInputEmail1"
                              >
                                C.I.* :
                              </label>
                            </div>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="inputtext form-control"
                                onChange={onchange}
                                value={state.cedula}
                                id="cedula"
                                name="cedula"
                                placeholder="Ingrese un número identificación"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              {" "}
                              <label
                                className=""
                                htmlFor="exampleInputEmail1"
                              >
                                Nombres* :
                              </label>
                            </div>
                            <div className="col-md-10">
                              {" "}
                              <input
                                type="text"
                                className="inputtext form-control"
                                onChange={onchange}
                                value={state.nombres}
                                id="nombres"
                                name="nombres"
                                placeholder="Ingrese los nombres "
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              {" "}
                              <label
                                className=""
                                htmlFor="exampleInputEmail1"
                              >
                                Apellidos* :
                              </label>
                            </div>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="inputtext form-control"
                                onChange={onchange}
                                value={state.apellidos}
                                id="apellidos"
                                name="apellidos"
                                placeholder="Ingrese los apellidos "
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              {" "}
                              <label
                                className=""
                                htmlFor="exampleInputEmail1"
                              >
                                Teléfono* :
                              </label>
                            </div>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="inputtext form-control"
                                onChange={onchange}
                                value={state.telefono}
                                id="telefono"
                                name="telefono"
                                placeholder="Ingrese teléfono "
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              {" "}
                              <label
                                className=""
                                htmlFor="exampleInputEmail1"
                              >
                                Correo* :
                              </label>
                            </div>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="inputtext form-control"
                                onChange={onchange}
                                value={state.correo}
                                id="correo"
                                name="correo"
                                placeholder="Ingrese el correo"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              {" "}
                              <label
                                className=""
                                htmlFor="exampleInputEmail1"
                              >
                                Genero* :
                              </label>
                            </div>
                            <div className="col-md-10">
                              <select
                                className="inputselect2 borde-tarjeta"
                                id="genero"
                                name="genero"
                                style={{ width: "100%" }}
                                onChange={onchange}
                                value={state.genero}
                              >
                                <option value="">Seleccione ...</option>

                                <option value="1">MASCULINO</option>
                                <option value="2">FEMENINO</option>
                                <option value="3">OTRO</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              {" "}
                              <label
                                className=""
                                htmlFor="exampleInputEmail1"
                              >
                                dirección* :
                              </label>
                            </div>
                            <div className="col-md-10">
                              <TextareaAutosize
                                rows="2"
                                className="textareapublicar"
                                onChange={(e) => onchange(e)}
                                value={state.direccion}
                                id="direccion"
                                name="direccion"
                                placeholder={"Observación"}
                                style={{
                                  height: "70px!important",
                                  width: "100%",
                                  border: "none",
                                }}
                              />{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                              {" "}
                              <label>Pais* :</label>
                            </div>
                            <div className="col-md-10">
                              {" "}
                              <select
                                className="inputselect2 borde-tarjeta"
                                id="pais_id"
                                name="pais_id"
                                style={{ width: "100%" }}
                                onChange={onchange}
                                value={state.pais_id}
                              >
                                <option value="0">Seleccione ...</option>
                                {paises.map((data, index) => (
                                  <option value={data.pais_id} key={index}>
                                    {data.pais}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2 d-flex justify-content-start align-items-center">
                              {" "}
                              <label>Provincia* :</label>
                            </div>
                            <div className="col-md-10">
                              <select
                                className="inputselect2 borde-tarjeta"
                                id="provincia_id"
                                name="provincia_id"
                                style={{ width: "100%" }}
                                onChange={onchange}
                                //onClick={cargarCiudades}
                                value={state.provincia_id}
                              >
                                <option value="0">Seleccione ...</option>
                                {provincias.map((data, index) => (
                                  <option value={data.provincia_id} key={index}>
                                    {data.provincia}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-md-2  d-flex justify-content-start align-items-center">
                              {" "}
                              <label>Ciudad* :</label>
                            </div>
                            <div className="col-md-10">
                              {" "}
                              <input
                                type="text"
                                className="inputselect2 borde-tarjeta"
                                onChange={onchange}
                                value={state.ciudad}
                                id="ciudad"
                                name="ciudad"
                                placeholder="Digite la ciudad"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row ">
                        <button
                          type="button"
                          className="btn btn-primary p-3 "
                          style={{ float: "right" }}
                          onClick={(e) => crearUsuarios(e)}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/*/.col (left) */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}

      {/* MODAL PRELOAD USUARIO */}
      <Modal
        isOpen={stateModal.configModal}
        className="mt-100"
        toggle={() => {}}
        style={{ maxWidth: "50%", maxHeight: "200PX", padding: "20px" }}
      >
        <ModalHeader
          className="cabeceraNotify"
          toggle={cerrarModalPreload}
          style={{ background: "white" }}
        >
          Notificación{" "}
        </ModalHeader>
        <ModalBody style={{ padding: "0.75rem" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2 notifygif">
                <img
                  src={perrito}
                  style={{
                    width: "100%",
                    height: "80px",
                    borderRadius: "200px",
                  }}
                ></img>
              </div>
              <div className="col-md-10">
                <h4 style={{ textAlign: "center", width: "100%" }}>
                  {statefire.mensaje}
                </h4>
                {statefire.sms !== "ok" ? null : (
                  <div>
                    <h4 style={{ textAlign: "center", width: "100%" }}>
                      Enviamos a tu correo la clave generica de acceso{" "}
                    </h4>
                    <p style={{ textAlign: "center", width: "100%" }}>
                      Recuerda al iniciar cambiar tu clave !
                    </p>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CrearUsuarios;
