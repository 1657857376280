import React, { useContext, useEffect, useState } from "react";
import AdminVeter from "../../Layouts/Veterinaria/app";
import AuthContext from "../../Context/auth/authContext";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import GatitoJugando from "../../assets/logopreload.png"
import GatitoPeresoso from "../../assets/gatoperesoso.gif";
import Nologo from "../../assets/nologo.webp"

import { decrypt, encrypt } from "../../utils/aes";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
const Splash = (props) => {
  //let panel = localStorage.getItem("integraldata")?JSON.parse(localStorage.getItem("integraldata")):0
  var res = decrypt(localStorage.getItem("integralaccesscli"));
  let { usuario_id, token } = JSON.parse(res);
 const textColor = "#fc4718";
  const AuthenticationContext = useContext(AuthContext);
  const {
    authenticationDataFn,
    tieneAcceso,
    color,
    sessiondatacli,
    cargarModulosVetFn,
  } = AuthenticationContext;

  const [stateModal, setStateModal] = useState({
    configModal: false,
  });

  const [state,setState]=useState([])

  useEffect(() => {
    //getProvinciasFn()
      authenticationDataFn(usuario_id, token);
  
  }, []);

  useEffect(()=>{
 setTimeout(() => {
     setState(sessiondatacli)
}, 3000);
   
  },[])

 

  const seleccionarVet = async (e, data) => {
    e.preventDefault();
    console.log(data);
    const veterdata = data.veterinaria[0];
    if (veterdata.estado === 1) {
      const encryptdata = encrypt(veterdata.veterinaria_id);
      localStorage.setItem("selectVet", encryptdata);
      window.location="/"
    } else {
      alert("La veterinaria se encuentra desactivada");
    }
  };

  const obtenerdiasdecaducidad = (expire_access) => {
    if(expire_access){
      const hoy = moment();
      const expire = moment(expire_access);
      return expire.diff(hoy, "days");
    }else{
            return undefined;

    }
  };

  const obtenersiesperiododeprueba = (create_at) => {
    const hoy = moment();
    const expire = moment(create_at);
    return expire.diff(hoy, "months");
  };

  return (
    <>
      {sessiondatacli.length === 0 ? (
        <div
          className={"d-flex justify-content-center azulmarino"}
          style={{
            height: "100vh",
            width: "100%",
            transition:
              "left 10s ease, right 10s ease, bottom 10s ease, top 10s ease",
            display: sessiondatacli.length > 0 ? "none" : "block",
            position: "absolute",
            //backgroundImage: 'url(https://cdn.wallpapersafari.com/78/36/XjB4g5.jpg)',
            //backgroundSize:"cover",
            //backgroundRepeat: "no-repeat"
          }}
        >
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img
              style={{
                textAlign: "center",
                width: "200px",
                height: "200px",
                borderRadius: "150px",
                border: "5px solid white",
              }}
              src={tieneAcceso === 0 ? GatitoPeresoso : GatitoJugando}
            ></img>

            <br />
            <br />

            {tieneAcceso === 0 ? (
              <>
                <h1 className="color-plomo">! Ups ... ¡</h1>
                <h2 className="color-plomo">
                  Lo sentimos , no tiene ningún acceso válido
                </h2>
                <button
                  className="brn btn-success"
                  onClick={() => {
                    localStorage.clear();
                    window.location = "/";
                  }}
                >
                  Sacame de aquí
                </button>
              </>
            ) : tieneAcceso === 3 ? (
              <>
                <div className="col-md-12">
                  <h3 className="color-plomo">! UPSS !</h3>
                  <h5 className="color-plomo">
                    Tu acceso a esta sucursal fue revocado !{" "}
                  </h5>
                  <h5 className="color-plomo">
                    Si no conoces la razón de la suspención comunicate con el
                    soporte de la plataforma
                  </h5>
                </div>
                <div className="col-md-12 p-5">
                  {/*  <button
                  className="btn btn-success"
                  onClick={() =>
                    setStateModal({ ...stateModal, configModal: true })
                  }
                >
                  Crear
                </button> */}
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      localStorage.clear();
                      window.location = "/";
                    }}
                  >
                    Sacame de aquí
                  </button>
                </div>
              </>
            ) : (
              <>
                <h3 className="color-plomo">
                  ! Porfavor no salgas de la pantalla !
                </h3>
                <h3 className="color-plomo">
                  Estamos verificando los datos de sesión...
                </h3>
              </>
            )}
          </div>
        </div>
      ) : sessiondatacli.length > 0 && !localStorage.getItem("selectVet") ? (
        <div className="h-100 w-100 bgfondo2">
          <div
            className=" d-flex flex-column justify-content-center align-items-center "
            style={{ height: "100vh" }}
          >
            <div
              className="d-flex justify-content-center flex-column align-items-center"
              style={{ height: "20%" }}
            >
              <h3 style={{ textAlign: "center" }} className="color-naranja">
                SELECCIONA UN ESTABLECIMIENTO PARA INGRESAR
              </h3>
              <p style={{ textAlign: "center" }} className="color-azulmarino">
                <label for="" className="negrita mx-2">
                  USUARIO :{" "}
                </label>
                {sessiondatacli[0].persona[0].nombres}{" "}
                {sessiondatacli[0].persona[0].apellidos}
              </p>
              <button
                className="bg-btn-vet-login bordecompleto w-100 p-3 my-3  naranja bordesombreado "
                style={{width:"200px!important"}}
                onClick={() => {
                  localStorage.clear();
                  window.location = "/";
                }}
              >
                <i className="fas fa-arrow-left"></i> Cerrar sesión de usuario
              </button>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center w-100"
              style={{ height: "70%", maxHeight: "70%" }}
            >
              <div
                style={{
                  width: "65%",
                  maxHeight: "100%",
                  height: "auto",
                  overflowY: "scroll",
                }}
                className=" d-flex flex-column bordecircular px-3"
              >
                {sessiondatacli.map((data, index) => {
                  return (
                    <div
                      className="w-100 h-100 my-1 bordesombreado azulmarino color-plomo bordecircular cursorselect"
                      key={index}
                    >
                      <div
                        className="bordecompleto cursor bordecircular d-flex justify-content-center w-100 "
                        onClick={(e) => seleccionarVet(e, data)}
                      >
                        <div>
                          {/* {obtenersiesperiododeprueba(
                          data.veterinaria[0].create_at
                        ) > 0
                          ? "Plan de Pago"
                          : "Periodo de prueba"}
                        <br />
                        {obtenerdiasdecaducidad(
                          data.veterinaria[0].expire_access
                        ) >= 0
                          ? `Faltan ${obtenerdiasdecaducidad(
                              data.veterinaria[0].expire_access
                            )} días para caducar el ${
                              obtenersiesperiododeprueba(
                                data.veterinaria[0].create_at
                              ) > 0
                                ? "Plan de Pago"
                                : "Periodo de prueba"
                            }`
                          : obtenerdiasdecaducidad(
                              data.veterinaria[0].expire_access
                            ) === undefined
                          ? "No se a definido una fecha"
                          : `Caducado hace ${
                              obtenerdiasdecaducidad(
                                data.veterinaria[0].expire_access
                              ) * -1
                            }dias`}
                        <br />
                        F/Caducidad :
                        {data.veterinaria[0].expire_access === "" ||
                        data.veterinaria[0].expire_access === null
                          ? "No definida"
                          : moment(data.veterinaria[0].expire_access).format(
                              "YYYY-MM-DD"
                            )}
                        <br /> */}
                          <div className="">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                              <div
                                className=" p-2"
                                style={{ textAlign: "center" }}
                              >
                                {data.veterinaria[0].nombre_comercial}
                              </div>
                              <div>{data.veterinaria[0].codigo}</div>
                              <div>
                                {data.veterinaria[0].sucursal === "" ||
                                data.veterinaria[0].sucursal === null
                                  ? "MATRIZ"
                                  : "SUCURSAL"}
                              </div>
                              <div>{data.veterinaria[0].type}</div>
                              <div>({data.veterinaria[0].direccion})</div>
                              {`Estado:  ${
                                data.veterinaria[0].estado === 1
                                  ? "Activa"
                                  : "Desactivada"
                              }`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        //solo accede al panel cuando sea el panel asignado y el rd que significa reload que s eusa solo al inicio de session ya no exista

        <Router>
          <AdminVeter></AdminVeter>
        </Router>
      )}

      {/* MODAL PRELOAD REGISTRO DE VETERINARIAS */}
      <Modal
        isOpen={stateModal.configModal}
        className="mt-100"
        toggle={() => {}}
        style={{ maxWidth: "50%", maxHeight: "200PX", padding: "20px" }}
      >
        <ModalHeader
          className="cabeceraNotify"
          toggle={() => setStateModal({ ...stateModal, configModal: false })}
          style={{ background: color }}
        >
          CREAR VETERINARIA
        </ModalHeader>
        <ModalBody style={{ padding: "0.75rem" }}>
          {/* <CrearVeterinaria></CrearVeterinaria> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Splash;
