import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../../Context/auth/authContext";
import MenuConfiguracion from "./menu";
import Servicios from "../servicios/servicio"
import Tags from "./tags"
//import { url, urlLogoImagen, urlPortadaImagen } from "../../../../config/urls";
//MONCA

const Servicio = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli ,index} = AuthenticationContext;
  
  const {
    nombre,
    direccion,
    latitud,
    longitud,
    atencion,
    logo,
    ruc,
    portada,
    provincia_id,
    telefono = "",
    ciudad,
    codigo,
    veterinaria_id,
    pais_id
  } = sessiondatacli[index].veterinaria[0];

  
 


  return (
    <>
      <Servicios></Servicios>
      <br />
      <br />
      <div className=" px-3 w-100 d-flex justify-content-start align-items-center">
        <label for="" className="titulosubmenusize">
          Tags de busqueda
        </label>
      </div>
      <hr  className="mx-3"/>
      <Tags veterinaria_id={veterinaria_id}></Tags>
    </>
  );
};

export default Servicio;
