import Axios from "../config/Axios"

export const startOnlineVet = async (online,veterinaria_id) => {
    try {
      const result = await Axios.get(`/vetOnline/${online}/${veterinaria_id}`);
      return result
    } catch (e) {
      console.log(e);
      return [{
          sms:"err",
          data:"",
          mensaje:"Ocurrio un problemas"
      }]
    }
  };

  export const getPlanes = async (veterinaria_id) => {
    try {
      const result = await Axios.get(`/getPlanEmpresa/${veterinaria_id}`);
      return result;
    } catch (e) {
      console.log(e);
      return [
        {
          sms: "err",
          data: [],
          mensaje: "Ocurrio un problemas",
        },
      ];
    }
  };