import React, { useState, useEffect } from "react";
import {
  listarVentasTotalesReporte,
  listarVentasTotalesFacturasReporte,
  listarRecibosTotalesFacturasReporte,
  listarLiquidacionesTotalesFacturasReporte,
} from "../../../api/contable/reportes";
import { toast } from "react-toastify";
const DashBoardInicio = (props) => {
  const [documentos, setDocumentos] = useState([]);
  const [facturas, setFacturas] = useState([]);
  const [recibos, setRecibos] = useState([]);
  const [liquidaciones, setLiquidaciones] = useState([]);

  const getVentasEmpresa = async () => {
    const res = await listarVentasTotalesReporte(props.empresa_id);
    if (res[0].sms === "ok") {
      setDocumentos(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const getFacturasVentasEmpresa = async () => {
    const res = await listarVentasTotalesFacturasReporte(props.empresa_id);
    if (res[0].sms === "ok") {
      setFacturas(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };
  const getRecibosVentasEmpresa = async () => {
    const res = await listarRecibosTotalesFacturasReporte(props.empresa_id);
    if (res[0].sms === "ok") {
      setRecibos(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };
  const getLiquidacionesVentasEmpresa = async () => {
    const res = await listarLiquidacionesTotalesFacturasReporte(
      props.empresa_id
    );
    if (res[0].sms === "ok") {
      setLiquidaciones(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    getVentasEmpresa();
    getFacturasVentasEmpresa();
    getLiquidacionesVentasEmpresa();
    getRecibosVentasEmpresa();
  }, []);

  return (
    <div className="row p-2 d-flex justify-content-center">
      <div className="col-md-12">
        <label for="" className="titulosSize">DOCUMENTOS</label>
        <hr/>
      </div>
      <div className="col-md-3  ">
        <div className="row">
          <div
            className="col-md-12 borde-tarjeta bgfondo  center"
            style={{ minHeight: "180px" }}
          >
            <div className="row d-flex justify-content-center align-items-center">
              <label
                className="center my-2 titulosSize negrita "
                style={{ textAlign: "center" }}
              >
                FACTURAS
              </label>
              <hr />
            </div>
            {facturas.length > 0
              ? facturas.map((data) => {
                  return (
                    <div className="row my-1">
                      {data.estado_sri} ({data._count})
                      
                    </div>
                  );
                })
              : null}
             
          </div>
        </div>
      </div>
      <div className="col-md-3  ">
        <div className="row">
          <div
            className="col-md-12 borde-tarjeta bgfondo  center"
            style={{ minHeight: "180px" }}
          >
            <div className="row d-flex justify-content-center align-items-center">
              <label
                className="center my-2 titulosSize negrita "
                style={{ textAlign: "center" }}
              >
                RECIBOS
              </label>
              <hr />
            </div>
            {recibos.length > 0
              ? recibos.map((data) => {
                  return <div className="row my-1">VENTAS ({data._count})</div>;
                })
              : null}
          </div>
        </div>
      </div>
      <div className="col-md-3  ">
        <div className="row">
          <div
            className="col-md-12 borde-tarjeta bgfondo  center"
            style={{ minHeight: "180px" }}
          >
            <div className="row d-flex justify-content-center align-items-center">
              <label
                className="center my-2 titulosSize negrita "
                style={{ textAlign: "center" }}
              >
                LIQUIDACIÓN DE COMPRA
              </label>
              <hr />
            </div>
            {liquidaciones.length > 0
              ? liquidaciones.map((data) => {
                  return (
                    <div className="row my-1">
                      {data.estado_sri} ({data._count})
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardInicio;
