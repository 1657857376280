import React, { useState, useEffect } from "react";
import DataTableDefault from "../../Components/ReactDataTable";
import SearchItem from "./searchItem";
import Select from "react-select";

import Axios from "../../config/AxiosContable";

import { ReactComponent as MinusIcon } from "../../icons/minus.svg";
const ItemsFacturaEditar = ({
  items,
  veterinaria_id,
  actionItems,
  deletesitems,
  actiondeletes,
  datafactura,
}) => {
  const [modalRegistrarItem, setModalRegistrarItem] = useState(false);
  const [dataitems, setdataitems] = useState([]);
  const [data, setdata] = useState([]);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");

  const [omit, setomit] = useState(false);
  /*   const [tipodescuento, setTipoDescuento] = useState("dolar");
   */ const columns = [
    {
      name: "-",
      center: true,
      cell: (row, index) => opciones(row, index),
      width: "30px",
    },
    {
      name: "Código",
      center: true,
      selector: (row) => row.codigo_producto,
      width: !omit ? "180px" : "",
    },
    {
      name: "Descripción",
      center: true,
      selector: (row) => row.descripcion_producto,
      wrap: true,
      width: !omit ? "250px" : "",
    },
    {
      name: "Cantidad",
      center: true,
      width: "100px",
      cell: (row, index) => (
        <input
          className="inputtextsmall bordecompleto "
          type="number"
          name={"cantidad-" + index}
          value={row.cantidad}
          onChange={(e) => onchangecantidad(e, index)}
        />
      ),
    },
    {
      name: "$ pvp",
      center: true,
      width: "100px",
      omit: omit,
      cell: (row, index) => (
        <input
          className="inputtextsmall bordecompleto"
          type="number"
          name={"precio_venta-" + index}
          value={row.precio_venta}
          onChange={(e) => onchange2precio(e, index)}
        />
      ),
    },
    {
      name: "Desc.",
      center: true,
      width: "200px",
      omit: omit,

      cell: (row, index) => (
        <div className="d-flex flex-row justify-content-center align-items-center bgfondo m-2 px-2 borderadiusinput bordecompleto">
          <input
            className="inputtextsmall w-50"
            type="number"
            name={"descuento-" + index}
            value={row.descuento}
            onChange={(e) => onchangedescuento(e, index)}
          />
          <div className="">
            <button
              onClick={(e) => onchangetipodescuento(index, "dolar")}
              className={`btn-tabla-factura bordesuperiorizquierdo bordeinferiorizquierdo bordecompleto ${
                row.tipo_descuento === "dolar" ? "active-descuento" : ""
              }`}
            >
              $
            </button>
            <button
              onClick={(e) => onchangetipodescuento(index, "porcentaje")}
              className={`btn-tabla-factura bordesuperiorderecho bordeinferiorderecho bordecompleto ${
                row.tipo_descuento === "porcentaje" ? "active-descuento" : ""
              }`}
            >
              %
            </button>
          </div>
        </div>
      ),
    },

    {
      name: "Desc.T.",
      center: true,
      selector: (row) => row.descuento,
      width: "80px",
      omit: omit,
    },
    {
      name: "(% iva)",
      center: true,
      omit: omit,

      selector: (row) => row.iva_tarifa,
      width: "80px",
    },
    {
      name: "iva.T.",
      center: true,
      selector: (row) => row.iva_valor,
      width: "80px",
      omit: omit,
    },
    {
      name: "Total",
      center: true,
      omit: omit,

      selector: (row) => row.subtotal,
      width: "80px",
    },
    /*  {
      name: "(ice)",
      center: true,
      selector: (row) => row.rice_code_porcent,
      width: "80px",
    }, */
    /* {
      name: "Subtotal",
      center: true,
      selector: (row) => row.subtotal,
      width: "100px",
    }, */
    /* {
      name: "iva",
      center: true,
      selector: (row) => row.iva_valor,
      width: "100px",
    }, */
  ];

  useEffect(() => {
    if (datafactura.codigo_documento === "06") {
      setomit(true);
    } else {
      setomit(false);
    }
  }, [datafactura.codigo_documento]);

  const eliminaritem = async (index) => {
    const dataaux = [...items];
    let auxdeletes = "";
    console.log("***********************");
    console.log(auxdeletes);

    if (dataaux[0].ventas_detalle_id !== "") {
      const resremove = await dataaux.filter((element, ind) => ind === index);
      console.log(resremove);
      auxdeletes = resremove[0].ventas_detalle_id;
    }
    const res = await dataaux.filter((element, ind) => ind !== index);
    console.log(auxdeletes);
    actionItems(res);
    actiondeletes(auxdeletes);
  };

  const opciones = (row, index) => {
    return (
      <>
        <button
          type=""
          style={{
            width: "20px",
            height: "20px",
            background: "red",
            border: "1px solid red",
          }}
          className=" d-flex ustify-content-center align-items-center bordecircular"
          onClick={() => eliminaritem(index)}
        >
          <MinusIcon fill="white" className="w-100 negrita" />
        </button>
      </>
    );
  };

  useEffect(() => {
    init();
  }, []);

  /* const cargartablaconmenu=()=>{
        var res = document.getElementsByClassName(``);
   element.classList.remove("gelpCx");
      element.classList.s("gelpCx");

  } */

  /*  useEffect(() => {
    cargardata();
  }, [items]);

  const cargardata = () => {
    setState(items);
  }; */

  /*   useEffect(() => {
    if (ipts.length > 0) {
      ipts.forEach((n) => {
        setIdsearch(`${n.getAttribute("id")}`);
        var res = document.getElementById(`${n.getAttribute("id")}`);
        res.type = "search";
       
      });
    }
  }, [ipts]); */

  const closeAction = (modal, x) => {
    setModalRegistrarItem(modal);
  };

  const respuesta = async (value) => {
    let data2 = await dataitems.filter((op) => op.codigo_producto === value);
    let aux = [...items];
    const count2 = await count(items, data2[0].codigo_producto);
    let cantidad = count2.length + 1;
    //const impuestos = await calcularImpuestos(data2, cantidad, 0);
    let obj = {
      venta_cabecera_id: data2[0].venta_cabecera_id
        ? data2[0].venta_cabecera_id
        : "",
      ventas_detalle_id: data2[0].ventas_detalle_id
        ? data2[0].ventas_detalle_id
        : "",
      producto_id: data2[0].producto_id,
      codigo_producto: data2[0].codigo_producto,
      codigo_auxiliar: data2[0].codigo_barras,
      descuento: 0,
      descuento_total: 0,
      tipo_descuento: "dolar",
      cantidad: cantidad,
      precio_venta: data2[0].precio_venta,
      iva_code: data2[0].iva_code,
      rice_code: data2[0].rice_code,
      rice_tarifa: data2[0].rice_tarifa,
      iva_tarifa: data2[0].iva_tarifa,
      irbpnr_tarifa: data2[0].irbpnr_tarifa,
      iva_valor: parseFloat(
        (parseInt(count2.length) + 1) *
          parseFloat(data2[0].precio_venta) *
          (parseInt(data2[0].iva_tarifa) / 100)
      ).toFixed(2),

      irbpnr_code: data2[0].irbpnr_code,
      iva_code_porcent: data2[0].iva_code_porcent,
      rice_code_porcent: data2[0].rice_code_porcent,
      irbpnr_code_porcent: data2[0].irbpnr_code_porcent,
      subtotal: parseFloat(
        (parseInt(count2.length) + 1) * parseFloat(data2[0].precio_venta)
      ).toFixed(2),
      descripcion_producto: data2[0].descripcion_producto,
      create_at: new Date(),
      activo:
        data2[0].activo === null ||
        data2[0].activo === true ||
        data2[0].activo === undefined
          ? true
          : false,
      //impuestos: impuestos,
    };
    if (count2.length > 0) {
      aux.map((elemento) => {
        if (elemento.codigo_producto === data2[0].codigo_producto) {
          elemento.cantidad = parseFloat(elemento.cantidad) + 1;
          elemento.subtotal = parseFloat(
            parseFloat(elemento.cantidad) * parseFloat(elemento.precio_venta) -
              elemento.descuento_total
          ).toFixed(2);
          elemento.iva_valor = parseFloat(
            (parseFloat(elemento.cantidad) * parseFloat(elemento.precio_venta) -
              elemento.descuento_total) *
              (parseInt(elemento.iva_tarifa) / 100)
          ).toFixed(2);
          //elemento.impuestos = [];
          //elemento.impuestos = calcularImpuestos(data2, elemento.cantidad, 0);
        }
      });

      actionItems(aux);
    } else {
      aux.push(obj);
      actionItems(aux);
    }
  };

  const onchange = async (e) => {
    e.preventDefault();
    let aux = [...items];
    setSearch(e.target.value);
    const res = await filtrar(items, e.target.value);
    console.log(res);
    if (res.length > 0) {
      let cantidad = parseInt(res[0].cantidad) + 1;
      //const impuestos = await calcularImpuestos(res, cantidad, 0);
      let obj = {
        venta_cabecera_id: res[0].venta_cabecera_id
          ? res[0].venta_cabecera_id
          : "",
        ventas_detalle_id: res[0].ventas_detalle_id
          ? res[0].ventas_detalle_id
          : "",
        producto_id: res[0].producto_id,
        codigo_producto: res[0].codigo_producto,
        codigo_auxiliar: res[0].codigo_barras,
        descuento: 0,
        descuento_total: 0,
        tipo_descuento: "dolar",
        cantidad: cantidad,
        precio_venta: res[0].precio_venta,
        iva_code: res[0].iva_code,
        rice_code: res[0].rice_code,
        irbpnr_code: res[0].irbpnr_code,
        iva_code_porcent: res[0].iva_code_porcent,
        rice_code_porcent: res[0].rice_code_porcent,
        irbpnr_code_porcent: res[0].irbpnr_code_porcent,
        rice_tarifa: res[0].rice_tarifa,
        iva_tarifa: res[0].iva_tarifa,
        irbpnr_tarifa: res[0].irbpnr_tarifa,
        iva_valor: parseFloat(
          cantidad *
            parseFloat(res[0].precio_venta) *
            (parseInt(res[0].iva_tarifa) / 100)
        ).toFixed(2),
        subtotal: parseFloat(
          (parseInt(cantidad) + 1) * parseFloat(res[0].precio_venta)
        ).toFixed(2),
        descripcion_producto: res[0].descripcion_producto,
        create_at: new Date(),
        activo:
          res[0].activo === null ||
          res[0].activo === true ||
          res[0].activo === undefined
            ? true
            : false,
        //impuestos: impuestos,
      };
      if (cantidad > 0) {
        aux.map(async (elemento) => {
          if (elemento.codigo_producto === res[0].codigo_producto) {
            elemento.cantidad = parseFloat(elemento.cantidad) + 1;
            elemento.subtotal = parseFloat(
              parseFloat(elemento.cantidad) *
                parseFloat(elemento.precio_venta) -
                elemento.descuento_total
            ).toFixed(2);
            elemento.iva_valor = parseFloat(
              (parseFloat(elemento.cantidad) *
                parseFloat(elemento.precio_venta) -
                elemento.descuento_total) *
                (parseInt(elemento.iva_tarifa) / 100)
            ).toFixed(2);
            //elemento.impuestos = [];
            //elemento.impuestos = calcularImpuestos(res, elemento.cantidad, 0);
          }
        });
        console.log("======");
        console.log(aux);
        console.log("======");

        actionItems(aux);
        setSearch("");
      } else {
        aux.push(obj);
        actionItems(aux);
        setSearch("");
      }
    } else {
      const resdata = await filtrar(data, e.target.value);
      //const impuestos = await calcularImpuestos(resdata, 1, 0);
      let obj = {
        venta_cabecera_id: resdata[0].venta_cabecera_id
          ? resdata[0].venta_cabecera_id
          : "",
        ventas_detalle_id: resdata[0].ventas_detalle_id
          ? resdata[0].ventas_detalle_id
          : "",
        producto_id: resdata[0].producto_id,

        codigo_producto: resdata[0].codigo_producto,
        codigo_auxiliar: resdata[0].codigo_barras,
        descuento: 0,
        descuento_total: 0,
        tipo_descuento: "dolar",
        cantidad: 1,
        precio_venta: resdata[0].precio_venta,
        iva_code: resdata[0].iva_code,
        rice_code: resdata[0].rice_code,
        irbpnr_code: resdata[0].irbpnr_code,
        iva_code_porcent: resdata[0].iva_code_porcent,
        rice_code_porcent: resdata[0].rice_code_porcent,
        irbpnr_code_porcent: resdata[0].irbpnr_code_porcent,
        subtotal: 1 * parseFloat(resdata[0].precio_venta),
        descripcion_producto: resdata[0].descripcion_producto,
        create_at: new Date(),
        activo:
          resdata[0].activo === null ||
          resdata[0].activo === true ||
          resdata[0].activo === undefined
            ? true
            : false,
        rice_tarifa: resdata[0].rice_tarifa,
        iva_tarifa: resdata[0].iva_tarifa,
        irbpnr_tarifa: resdata[0].irbpnr_tarifa,
        iva_valor: parseFloat(
          1 *
            parseFloat(resdata[0].precio_venta) *
            (resdata[0].iva_tarifa / 100)
        ).toFixed(2),
        //impuestos: impuestos,
      };
      aux.push(obj);
      actionItems(aux);
      setSearch("");
    }
  };

  const onchangecantidad = async (e, index) => {
    e.preventDefault();
    if (e.target.value === "") {
      const dataaux = [...items];
      dataaux[index].cantidad = 1;
      dataaux[index].subtotal = parseFloat(
        parseInt(e.target.value) * parseFloat(dataaux[index].precio_venta)
      ).toFixed(2);
      actionItems(dataaux);
    } else {
      const dataaux = [...items];
      dataaux[index].cantidad = e.target.value;
      dataaux[index].subtotal = parseFloat(
        parseInt(e.target.value) * parseFloat(dataaux[index].precio_venta)
      ).toFixed(2);

      /* dataaux[index].impuestos = [];
      dataaux[index].impuestos = calcularImpuestos(
        dataaux,
        parseInt(e.target.value),
        index
      ); */
      const resdata = await obtenerdescuentototal(
        dataaux[index].tipo_descuento,
        dataaux[index].descuento,
        dataaux[index]
      );
      dataaux[index].descuento_total = parseFloat(resdata).toFixed(2);
      dataaux[index].subtotal = parseFloat(
        dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
          resdata
      ).toFixed(2);
      dataaux[index].iva_valor = parseFloat(
        (dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
          resdata) *
          (dataaux[index].iva_tarifa / 100)
      ).toFixed(2);
      actionItems(dataaux);
    }
  };

  const onchange2precio = async (e, index) => {
    e.preventDefault();
    const dataaux = [...items];
    dataaux[index].precio_venta = e.target.value;
    dataaux[index].subtotal = parseFloat(
      parseFloat(e.target.value) * parseInt(dataaux[index].cantidad)
    ).toFixed(2);
    /* dataaux[index].impuestos = [];
    dataaux[index].impuestos = calcularImpuestos(
      dataaux,
      dataaux[index].cantidad,
      index
    ); */

    const resdata = await obtenerdescuentototal(
      dataaux[index].tipo_descuento,
      dataaux[index].descuento,
      dataaux[index]
    );
    dataaux[index].descuento_total = parseFloat(resdata).toFixed(2);
    dataaux[index].subtotal = parseFloat(
      dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
        resdata
    ).toFixed(2);
    dataaux[index].iva_valor = parseFloat(
      (dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
        resdata) *
        (dataaux[index].iva_tarifa / 100)
    ).toFixed(2);
    actionItems(dataaux);
  };

  const onchangedescuento = async (e, index) => {
    e.preventDefault();
    const dataaux = [...items];
    dataaux[index].descuento = parseFloat(e.target.value);
    const resdata = await obtenerdescuentototal(
      dataaux[index].tipo_descuento,
      parseFloat(e.target.value),
      dataaux[index]
    );
    dataaux[index].descuento_total = parseFloat(resdata).toFixed(2);
    dataaux[index].subtotal = parseFloat(
      dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
        resdata
    ).toFixed(2);
    dataaux[index].iva_valor = parseFloat(
      (dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
        resdata) *
        (dataaux[index].iva_tarifa / 100)
    ).toFixed(2);
    actionItems(dataaux);
  };

  const onchangetipodescuento = async (index, tipo) => {
    const dataaux = [...items];
    dataaux[index].tipo_descuento = tipo;
    const resdata = await obtenerdescuentototal(
      tipo,
      dataaux[index].descuento,
      dataaux[index]
    );
    dataaux[index].descuento_total = parseFloat(resdata).toFixed(2);
    dataaux[index].subtotal = parseFloat(
      dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
        resdata
    ).toFixed(2);
    dataaux[index].iva_valor = parseFloat(
      (dataaux[index].cantidad * parseFloat(dataaux[index].precio_venta) -
        resdata) *
        (dataaux[index].iva_tarifa / 100)
    ).toFixed(2);
    actionItems(dataaux);
  };

  const obtenerdescuentototal = (tipo_descuento, value, dataaux) => {
    if (tipo_descuento === "porcentaje") {
      return (
        (dataaux.cantidad * dataaux.precio_venta * parseFloat(value)) / 100
      );
    } else {
      return parseFloat(value);
    }
  };

  const filtrar = (array, dato) => {
    var listado = [];
    listado = array;
    var resutadobusqueda = listado.filter((elemento) => {
      if (
        elemento.codigo_producto.toString() === dato.toString() ||
        elemento.descripcion_producto
          .toString()
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "") === dato.toString().toUpperCase()
      ) {
        return elemento;
      }
    });
    return resutadobusqueda;
  };

  const count = (array, dato) => {
    var listado = [];
    listado = array;
    var resutadobusqueda = listado.filter((elemento) => {
      if (elemento.codigo_producto.toString() === dato.toString()) {
        return elemento;
      }
    });
    return resutadobusqueda;
  };

  const init = async () => {
    try {
      const result = await Axios.get(`/alldatageneral/${veterinaria_id}`);
      if (result.data.data.length > 0) {
        // const aux = result.data.data;
        //console.log(aux[0].urlFotoMascota)
        const x1 = await result.data.data.map((item, index) => {
          return {
            value: item.codigo_producto,
            label: `${item.descripcion_producto}`,
          };
        });
        /* result.data.data.map(async (data, index) => {
      
          aux[index].description = data.codigo_producto;
          aux[index].value = data.descripcion_producto;
           
        }); */

        setTimeout(() => {
          setdata(x1);
          setdataitems(result.data.data);
        }, 2000);
      } else {
        setdata(result.data.result);
      }
      //var ob = document.getElementById(":r1:");
      /*  ob.classList.add("inputtextsmall"); */
      //ob.type = "search";
    } catch (e) {
      console.log(e);
    }
  };

  /*   document.addEventListener("click", function () {
      var input = document.getElementById(`${idsearc}`);
      input.value = "";
  }); */

  const conditionalRowStyles = [
    {
      when: (row) => row.activo === true || row.activo === null,
      style: {
        backgroundColor: "blue",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.activo === false,
      style: {
        backgroundColor: "red",
        //display:"none",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    // You can also pass a callback to style for additional customization
    /*  {
    when: row => row.calories < 400,
    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
  }, */
  ];

  return (
    <div className=" row h-100 ">
      <div className="col-md-12 bgfondo p-0  bordecompleto bordesombreado2">
        <div className="row azulmarino">
          <label style={{color:"white",fontSize:"15PX"}} className="negrita " >ITEMS</label>
          {/* <div>
            <button type=""></button>
          </div> */}
        </div>
        <div className="row   ">
          <div className="col-md-6 ">
            <div className="d-flex justify-content-start  align-items-start flex-column  ">
              <label className="negrita">Filtrar por código</label>
              <input
                type="search"
                className="bordecompleto bordesombrado w-100"
                style={{
                  height: "38px",
                  borderRadius: "5px",
                }}
                placeholder="Ingrese el código"
                name="search"
                value={search}
                onChange={onchange}
              />{" "}
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="d-flex justify-content-start  align-items-start flex-column "
              style={{ position: "relative", zIndex: "100" }}
            >
              <label className="negrita">Filtrar por nombres</label>
              <Select
                name="codigo_producto"
                value={data.filter((option) => option.value === search2)}
                options={data}
                className="basic-multi-select w-100 "
                classNamePrefix="select"
                placeholder="Seleccione una item de la lista"
                closeMenuOnSelect={true}
                onChange={(e) => {
                  respuesta(e.value);
                  setSearch2(e.value);
                }}
              />
              {/* <SearchItem data={data} respuesta={respuesta}></SearchItem> */}
            </div>
          </div>
          {/*  <div className="col-md-3">
            <button
              type=""
              className="bg-btn-vet w-100 h-100"
              onClick={() => setModalRegistrarItem(true)}
            >
              Busqueda avanzada
            </button>
          </div>{" "} */}
        </div>
        <div className="my-1 h-100">
          <DataTableDefault
            data={items.length > 0 ? items : []}
            columns={columns}
            height="230px"
            title={""}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>

      {/* <ModalDefault
        modal={modalRegistrarItem}
        size={"lg"}
        titulo="AGREGAR ITEMS "
        closeAction={closeAction}
      >
        <SearchItem
          respuesta={respuesta}
          veterinaria_id={veterinaria_id}
        ></SearchItem>
      </ModalDefault> */}
    </div>
  );
};

export default ItemsFacturaEditar;
