import React, { useState } from "react";
import Axios from "../../config/Axios";
import TokenAuth from "../../config/Token";
import { toast } from "react-toastify";
import { encrypt, decrypt } from "../../utils/aes";
import { cargarModulos } from "../../api/modulos";
import authContext from "./authContext";
//import Swal from 'sweetalert2'

const AuthState = (props) => {
  //var data = [{id: 1}, {id: 2}]

  // Encrypt
  //var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

  // Decrypt
  //var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
  //var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  //console.log(decryptedData); // [{id: 1}, {id: 2}]

  //STATE INICIAL

  const initialState = {
    ambientedocumentos:2,
    tieneAcceso: 2,
    color: "rgba(192, 185, 185, 0.474)",
    textColor: "black",
    sessiondatacli: [],
    index: 0,
    teclas: "",
    atencionVeterinaria: false,
    atencionLaboratorio: true,
    laboratorios: [],
    modulos: [],
    modulos2: [
      {
        modulo_id: 1,
        modulo: "Clinica",
        link: "/clinica/citas",
        route: "/clinica/:panel",
        icon: "fa fas-manu",
        activo: true,
        estado: true,
      },
      {
        modulo_id: 1,
        modulo: "Home",
        link: "/",
        route: "/",
        icon: "fa fas-manu",
        activo: true,
        estado: true,
      },
      {
        modulo_id: 1,
        modulo: "Seguridad",
        link: "/seguridad/inicio",
        route: "/seguridad/:panel",
        icon: "fa fas-manu",
        activo: true,
        estado: true,
      },
    ],
    menubtn: [
      {
        icon: "fas fa-home",
        color: false,
        btnactive: false,
        titulo: "Home",
        link: "/",
        route: "",
      },
      {
        icon: "fas fa-briefcase-medical",
        color: false,
        btnactive: false,
        titulo: "Clínica",
        link: "/clinica",
        route: "",
      },
      /* {
        icon: "fas fa-store",
        color: false,
        btnactive: false,
        titulo: "Tienda",
        link: "/tienda",
        route:""
      }, */
      {
        icon: "fas fa-cog",
        color: false,
        btnactive: false,
        titulo: "Configuraciones",
        link: "/configuraciones",
        route: "",
      },
    ],
    menubtnSinLab: [],
    menubtnDefault: [
      {
        icon: "fas fa-briefcase-medical",
        btnactive: false,
        titulo: "Clínica",
        link: "/",
        route: "/",
      },
      ,
      /* {
        icon: "fas fa-microscope",
        color: false,
        btnactive: false,
        titulo: "Laboratorio",
        link: "/laboratorio",
        route:""
      }, */
      {
        icon: "fas fa-cog",
        btnactive: false,
        titulo: "Configuraciones",
        link: "/configuraciones",
        route: "/configuraciones",
      },
    ],
    indiceanterior: "",
    /* paneles:[
      {"panel_id":1,"nombre":"ADMINISTRACIÓN GENERAL"},
      {"panel_id":2,"nombre":"ADMINISTRACIÓN X VETERINARIA"},
    ]*/
  };

  // PROVEDOR
  const [state, setState] = useState(initialState);
  const toastId = React.useRef(null);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  React.useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);

  //INITIALIZACION FUNCTION SERIES
  ////////////////////////////////////////////////////////
  const authenticationfn = async (datos) => {
    try {
      const respuesta = await Axios.post("/login", datos);
      if (respuesta.data.sms === "ok") {
        let data = {
          usuario_id: respuesta.data.usuario_id,
          token: respuesta.data.token,
        };
        var encryptData = encrypt(JSON.stringify(data));

        //STATE INICIAL
        localStorage.setItem("integralaccesscli", encryptData);
        return true
      } else {
        toast.error(`${respuesta.data.mensaje}`, {
          position:"bottom-left",
          autoClose: 5000,
          icon: "🚀",
          theme: "colored",
        });
        return false
      }
    } catch (e) {
      toast.error(`${e}`, {
        position: "bottom-left",
        autoClose: 5000,
        icon: "🚀",
        theme: "colored",
      });
      return false
    }
  };

  const authenticationDataFn = async (usuario, tokendata) => {
    TokenAuth(tokendata);
    var tokenfirebase = undefined;
    try {
      const respuesta = await Axios.get(
        "/dataUserLogin/" + usuario + "/" + tokenfirebase
      );
      if (respuesta.data.sms === "ok") {
        let aux = [];
        let acceso = 0;
        const selectVet = localStorage.getItem("selectVet")
          ? decrypt(localStorage.getItem("selectVet"))
          : null;
        console.log(respuesta.data.roles);
        if (respuesta.data.roles.length > 0) {
          let auxroles =respuesta.data.roles
          const vetconfoto = await auxroles.map(
            async (vets) => {
              if(vets.roles.veterinarias){
                const firmaFotoVetaux = await Axios.post(
                  "/downloadStorageFile",
                  {
                    file: vets.roles.veterinarias.logo,
                  }
                );

                vets.roles.veterinarias.urlFotoVet = firmaFotoVetaux.data.url;
              }
            }
          );

          console.log(vetconfoto);

          auxroles.map(async (roles) => {
            console.log("*********")
            console.log(roles)
            if (
              roles.panel === 2 &&
              roles.estado === 1 &&
              roles.roles.veterinarias !== null
            ) {
              console.log(roles.roles);

              
              if (selectVet) {
                if (
                  parseInt(selectVet) ===
                  roles.roles.veterinarias.veterinaria_id
                ) {
                  aux.push({
                    persona: respuesta.data.result[0].personas,
                    veterinaria: [roles.roles.veterinarias],
                    rol: roles.roles,
                  });
                }

                const firmaFoto = await Axios.post("/downloadStorageFile", {
                  file: aux.length>0?aux[0].persona.length>0?aux[0].persona[0].foto:null:null,
                });
                localStorage.setItem("ImgUrlAuthcli", firmaFoto.data.url);
                

               // localStorage.setItem("ImgUrlAuthcli", firmaFoto.data.url);
               // localStorage.setItem("ImgVetUrlAuthcli", firmaFotoVet.data.url);

                /* toDataURL().then((dataUrl) => {
                  //console.log("Here is Base64 Url", dataUrl);
                  var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
                  //console.log("Here is JavaScript File Object", fileData);
                }); */

                /*  toDataURL(firmaFotoVet.data.url).then((dataUrl) => {
                  console.log("Here is Base64 Url", dataUrl);
                  alert(dataUrl)
                  //var fileData = dataURLtoFile(dataUrl, "imageName.jpg");
                  //console.log("Here is JavaScript File Object", fileData);
                }); */
              } else {
                aux.push({
                  persona: respuesta.data.result[0].personas,
                  veterinaria: [roles.roles.veterinarias],
                  rol: roles.roles,
                  usuariorol_id: roles.roles.usuariorol_id,
                });
              }

              acceso = 2;
            } else {
              acceso = 0;
            }
          });
        }

        /* if(respuesta.data.roles.length > 0){
            const firmaFoto = await Axios.post("/downloadStorageFile", {
              file: aux[0].persona[0].foto,
            });
            const firmaFotoVet = await Axios.post("/downloadStorageFile", {
              file: aux[0].veterinaria[0].logo,
            });
            localStorage.setItem("ImgUrlAuthcli", firmaFoto.data.url);
            localStorage.setItem("ImgVetUrlAuthcli", firmaFotoVet.data.url);
          }
 */

        setState({
          ...state,
          tieneAcceso: acceso,
          sessiondatacli: aux,
        });

        //await toDataURL();
      } else {
        localStorage.clear();
        window.location = "/";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toDataURL = async (url) => {
    const response = await fetch(localStorage.getItem("ImgVetUrlAuthcli"));
    const file = await response.blob();
    console.log(file);
    /*       var blob = new Blob([file], { type: "image/png" });
     */ const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      localStorage.setItem("ImgVetbase64", reader.result);
    };

    //      resolve(reader.readAsDataURL(blob));
  };
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  /*const UsuarioRegistratfn = async (datos) => {
    try {
      const respuesta = await Axios.post("/createUser", datos);

      if (respuesta.data.sms === "ok") {
        localStorage.setItem("correo", respuesta.data.email);
        localStorage.setItem("usuario_id", respuesta.data.usuario_id);
        localStorage.setItem("estado", respuesta.data.estado);
        localStorage.setItem("token", respuesta.data.token);
        window.location = "/";
      } else {
        alert(`${respuesta.data.sms}`);
      }
    } catch (e) {}
  };

  const UsuarioEditfn = async (datos) => {
    try {
      const respuesta = await Axios.post("/editUser", datos);

      if (respuesta.data.sms === "ok") {
        localStorage.setItem("nombres", respuesta.data.nombres);
        localStorage.setItem("apellidos", respuesta.data.apellidos);
        localStorage.setItem("estado", respuesta.data.estado);
        localStorage.setItem("direccion", respuesta.data.direccion);
        window.location = "/";
      } else {
        alert(`${respuesta.data.sms}`);
      }
    } catch (e) {}
  };*/
  const limpiarSesiondataFn = (e) => {
    setState({
      ...state,
      tieneAcceso: 2,
      sessiondata: [],
    });
  };

  const teclasActionFn = (e) => {
    e.preventDefault();
    setState({
      ...state,
      teclas: "sdf",
    });
  };

  const actionbtnmenuFn = (indice) => {
    if (indice !== -1) {
      if (state.indiceanterior !== "") {
        state.menubtn[state.indiceanterior].btnactive = false;
        state.menubtn[state.indiceanterior].color = false;
      }

      state.menubtn[indice].btnactive = true;
      state.menubtn[indice].color = true;

      setState({
        ...state,
        menubtn: state.menubtn,
        indiceanterior: indice,
      });
    } else {
      alert("xxx");
      state.menubtn[state.indiceanterior].btnactive = false;
      state.menubtn[state.indiceanterior].color = false;

      setState({
        ...state,
        menubtn: state.menubtn,
        indiceanterior: indice,
      });
    }
  };

  const getLaboratorioDataFn = async (veterinaria_id) => {
    /* try {
      const result = await Axios.get(
        `/dataLaboratorioXveterinariaActual/${veterinaria_id}`
      );
      if (result.data.sms === "ok") {
        setState({
          ...state,
          laboratorios: result.data.result,
        });
      }
    } catch (e) {
      console.log(e);
    } */
  };

  const cargarModulosVetFn = async (usuario_id) => {
    //const veterinariaActual = decrypt(localStorage.getItem("selectVet"));

    const res = await cargarModulos(state.sessiondatacli[0].rol.rol_id);
    console.log(res.data);
    if (res.data.length > 0) {
      const resmodule = await res.data.sort((a, b) => {
        return a.orden - b.orden;
      });
      setState({
        ...state,
        modulos: resmodule,
      });
      console.log(state.modulos);
    }
  };

  return (
    <authContext.Provider
      value={{
        tieneAcceso: state.tieneAcceso,
        // paneles:state.paneles,
        ambientedocumentos:state.ambientedocumentos,
        sessiondatacli: state.sessiondatacli,
        color: state.color,
        modulos: state.modulos,
        textColor: state.textColor,
        teclas: state.teclas,
        menubtn: state.menubtn,
        atencionLaboratorio: state.atencionLaboratorio,
        atencionVeterinaria: state.atencionVeterinaria,
        menubtnSinLab: state.menubtnSinLab,
        laboratorios: state.laboratorios,
        index: state.index,
        getLaboratorioDataFn,
        authenticationfn,
        authenticationDataFn,
        limpiarSesiondataFn,
        teclasActionFn,
        actionbtnmenuFn,
        cargarModulosVetFn,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};
export default AuthState;
