import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/auth/authContext";
import ReactTooltip from "react-tooltip";
import Mapa from "../../Components/veterinariaLayout/mapa/index";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import NavNuevo from "../../Components/nav/navar";
import {Button,Offcanvas,OffcanvasBody,OffcanvasHeader} from "reactstrap"
import Rutas from "../../Rutas/veterinarias";
const Admin = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  /*let { veterinaria, nombres,  rol, correo } = JSON.parse(
    localStorage.getItem("integraldatacli")
  );*/

  const AuthenticationContext = useContext(AuthContext);
  const {
    sessiondatacli, 
    cargarModulosVetFn
  } = AuthenticationContext;
  const { veterinaria_id, atencion, latitud, longitud, nombre_comercial,regimen,sucursal } =
    sessiondatacli[0].veterinaria[0];
    const {
     nombres,
     apellidos
    } = sessiondatacli[0].persona[0];

  const [state, setState] = useState({
    kt_aside: "",
    action: "",
  });

  const [stateAtencion, setStateAtencion] = useState({
    atencion: atencion,
  });

  const [notifi, setNotifi] = useState({
    notifi: false,
    permiso: false,
  });
  const [canvas,setCanvas]=useState(false)

  const [stateModal, setStateModal] = useState({
    mapaModal: false,
    modal: localStorage.getItem("bienvenida")?false:false,
  });

  const [stateConfiguraciones, setStateConfiguraciones] = useState({
    especialidades: [],
    ok: false,
  });

  useEffect(() => {
    //document.body.style.display = "none";
    setState({ ...state, kt_aside: "" });
    setState({ ...state, action: "" });
    document.title = "IV";
  }, []);

   useEffect(() => {
     //getProvinciasFn()
     const timer = setTimeout(() => {
       cargarModulosVetFn();
     }, 1000);

     return () => clearTimeout(timer);
   }, []);

   const canvasfn=()=>{
    setCanvas(!canvas)
   }
   const canvasleavefn = () => {
     setCanvas(false);
   };

   const nombrefn=()=>{
    return <label for="" className="negrita ">{nombre_comercial}</label>;
   }
  return (
    <div className=" bgfondo" style={{ height: "100vh" }}>
      <ReactTooltip />

      <div
        className="header w-100 bgfondo bordesombreado2"
        style={{ height: "10%", position: "fixed", zIndex: "100" }}
      >
        <div className=" d-flex  flex-row w-100 align-items-center h-100 justify-content-between px-3">
          <div style={{ background: "none" }} className=" w-auto ">
            <h3>
              {nombrefn()} -{" "}
              {sucursal === "" || sucursal === null ? "MATRIZ" : "SUCURSAL"} -
            </h3>
          </div>
          {/* <div className="w-30 d-flex justify-content-end movil">
            <NavNuevo tipo={0}></NavNuevo>
          </div> */}
          <div
            className=" d-flex justify-content-end  align-items-center"
            style={{ float: "left" }}
          >
            <label className=" mx-1 ">
              <label for="" className="movil">
                {nombres} {apellidos}
              </label>
              <img
                src={localStorage.getItem("ImgUrlAuthcli")}
                alt=""
                width={50}
                height={50}
                style={{ borderRadius: "25px" }}
                className="mx-2 imanav bordecompleto"
              />
            </label>
            <button
              className="bordecompleto negrita py-3 px-4 bordecircular color-plomo azulmarino"
              onClick={function noRefCheck() {
                canvasfn();
              }}
            >
              <label for="" className="movil" style={{ fontSize: "16px" }}>
                Menú
              </label>{" "}
              <i className="fas fa-bars iconmenu p-0 m-0"></i>
            </button>

            <Offcanvas
              scrollable
              className="bgfondo bordetarjeta bordesombreado"
              direction="end"
              toggle={function noRefCheck() {
                canvasfn();
              }}
              isOpen={canvas}
              onMouseLeave={() => canvasleavefn()}
            >
              <OffcanvasHeader
                toggle={function noRefCheck() {
                  canvasfn();
                }}
              >
                OPCIONES DEL SISTEMA
              </OffcanvasHeader>
              <OffcanvasBody className="p-0">
                <div className="w-100 d-flex justify-content-center flex-column align-items-center azulmarino p-3">
                  <div
                    style={{
                      borderRadius: "100px",
                      width: "150px",
                      height: "150px",
                    }}
                    className=" bordecompleto d-flex justify-content-center bgfondo my-1"
                  >
                    <img
                      src={localStorage.getItem("ImgUrlAuthcli")}
                      alt=""
                      style={{
                        borderRadius: "100px",
                        width: "150px",
                        height: "150px",
                      }}
                    />
                  </div>
                  <div className="">
                    <button
                      className="bordecompleto bg-btn-vet bordesombreado "
                      onClick={() => {
                        localStorage.removeItem("selectVet");
                        window.location = "/";
                      }}
                    >
                      cerrar sesión establecimiento
                    </button>
                    <button
                      className="bordecompleto bg-btn-vet-danger bordesombreado mx-1"
                      onClick={() => {
                        localStorage.clear();
                        window.location = "/";
                      }}
                    >
                      Salir
                    </button>
                  </div>
                </div>
                <div className="w-100">
                  <NavNuevo tipo={1} canvas={canvasfn}></NavNuevo>
                </div>
              </OffcanvasBody>
            </Offcanvas>
          </div>
        </div>
      </div>
      <div className="" id="" style={{ height: "10%" }}></div>

      <div className="w-100" id="" style={{ height: "90%" }}>
        {notifi.notifi ? (
          <div
            class="alert alert-danger d-flex justify-content-center fixed  "
            role="alert"
          >
            Necesitamos que actives la ubicación para relizar configuraciones
            importantes !
          </div>
        ) : null}
        {/*  <div className="col-md-3">
            
          </div> */}
        <Rutas></Rutas>
      </div>

      {/* MODAL MAPA*/}
      <Modal isOpen={stateModal.mapaModal} toggle={() => {}} centered size="lg">
        <ModalHeader
          /*toggle={() => {
            setStateModal({
              ...stateModal,
              mapaModal: false,
            });
          }}*/
          style={{ background: "white" }}
          className="border"
        >
          <i className="fas fa-ambulance" style={{ fontSize: "20px" }}></i>{" "}
          Agrega la ubicación de la veterinaria
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <Mapa
              veterinaria_id={veterinaria_id}
              permiso={setNotifi.permiso}
            ></Mapa>
          </div>
        </ModalBody>
      </Modal>
      {/* FIN MODAL MAPA*/}

      {/* MODAL inicio */}
      <Modal
        isOpen={stateModal.modal}
        backdrop={false}
        keyboard={false}
        toggle={() => {}}
        //modalTransition={{ timeout: 700 }}
        //backdropTransition={{ timeout: 1300 }}
        style={{ maxWidth: "50%", maxHeight: "200PX", padding: "20px" }}
      >
        <ModalBody style={{ padding: "0.75rem" }}>
          <div className="d-flex flex-column align-self-center">
            <img
              src="/img/bienvenida.jpg "
              style={{ width: "100%", height: "350px" }}
            ></img>
          </div>
          <div
            className="d-flex flex-column justify-content-cnter align-items-center align-self-center"
            style={{ textAlign: "center" }}
          >
            <div className="p-2">
              <span style={{ float: "center" }}>
                BIENVENIDO {nombre_comercial}
              </span>
            </div>
            <div className="p-2">
              <span>TE INFORMAREMOS CUANDO EXITAN ACCIONES QUE REALIZAR</span>
            </div>

            {latitud === null && longitud === null ? (
              <div className="p-1">
                <span>
                  EMPIEZA POR REALIZAR ALGUNAS CONFIGURACIONES IMPORANTES
                </span>
              </div>
            ) : null}
            {latitud === null && longitud === null ? (
              <a
                className="bg-btn-vet"
                onClick={() => {
                  localStorage.setItem("bienvenida", true);
                }}
                href="/configuraciones/inicio"
              >
                ¡ Empezar !
              </a>
            ) : (
              <a
                className="btn"
                style={{ background: "blue", color: "white" }}
                onClick={() => {
                  localStorage.setItem("bienvenida", true);
                }}
                href="/"
              >
                ¡ De acuerdo !
              </a>
            )}

            {/*   {latitud === null && longitud === null ? (
              <button
                className="btn"
                style={{ background: "blue", color: "white" }}
                onClick={() => {
                  setStateModal({
                    ...stateModal,
                    mapaModal:
                      latitud === null && longitud === null ? true : false,
                    modal: false,
                  });
                }}
              >
                ¡ De acuerdo !
              </button>
            ) : null} */}
            <br />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={stateModal.modalEspecialidades}
        backdrop={false}
        keyboard={false}
        //modalTransition={{ timeout: 700 }}
        // backdropTransition={{ timeout: 1300 }}
        style={{ maxWidth: "50%", maxHeight: "200PX", padding: "20px" }}
      >
        {stateConfiguraciones.ok ? null : (
          <ModalHeader className="borde">Servicios</ModalHeader>
        )}
        <ModalBody style={{ padding: "0.75rem" }}>
          <>
            <div className="d-flex flex-column align-self-center justify-content-center align-items-center">
              <img width="20%" src="/admin/img/rolling.gif"></img>
            </div>
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Admin;
