import React, { useEffect, useState, useContext } from "react";
import Axios from "../../../../config/Axios";
import axios from "axios";

import AuthContext from "../../../../Context/auth/authContext";
import Swal from "sweetalert2";
import "./style.css";
import { getPaisesFn, getProvinciasFn } from "../../../../api/ubicaciones";
import { toast } from "react-toastify";
import {
  uploadLogoVet,
  firmarFoto,
  uploadFirmaVet,
} from "../../../../api/files";
import InputFile from "../../../inputFile";
import { url_facturacion_electronica_api } from "../../../../config/urls";
const ConfiguracionesVeter = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli, index } = AuthenticationContext;
  const nofoto = null;
  const {
    nombre,
    direccion,
    latitud,
    longitud,
    atencion,
    logo,
    ruc,
    portada,
    provincia_id,
    telefono,
    regimen,
    ciudad,
    codigo,
    veterinaria_id,
    pais_id,
    establecimiento_codigo,
    obligado_contabilidad,
    contribuyente_especial,
    establecimiento_punto_emision,
    api_key,
    firma_certificado_clave,
    init_number_venta,
    init_number_recibos,
    init_number_liquidacion,
    init_number_guia_remision,
    init_number_retencion,
    init_number_nota_credito,
    init_number_nota_debito,
    entidad,
    sucursal,
    calificacion_artesanal,
    nombre_comercial,
    agente_retencion,
  } = sessiondatacli[0].veterinaria[0];

  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);

  const [stateModal, setStateModal] = useState({
    nuevoUsuarioModal: false,
  });

  const [stateVeterinaria, setstateVeterinaria] = useState({
    nombre_comercial: nombre_comercial,
    nombre: nombre,
    direccion: direccion,
    latitud: latitud,
    longitud: longitud,
    regimen: regimen,
    atencion: atencion,
    logoanterior: logo,
    ruc: ruc,
    portadaanterior: portada,
    codigo: codigo,
    provincia_id: provincia_id,
    pais_id: pais_id,
    ciudad: ciudad,
    telefono: telefono,
    veterinaria_id: veterinaria_id,
    logo: "",
    portada: "",
    obligado_contabilidad: obligado_contabilidad,
    contribuyente_especial: contribuyente_especial,
    establecimiento_punto_emision: establecimiento_punto_emision,
    establecimiento_codigo: establecimiento_codigo,
    api_key: api_key,
    firma_certificado_clave: firma_certificado_clave,
    init_number_venta: init_number_venta,
    entidad: entidad,
    sucursal: sucursal,
    calificacion_artesanal: calificacion_artesanal,
    init_number_recibos:init_number_recibos,
    init_number_liquidacion:init_number_liquidacion,
    init_number_guia_remision:init_number_guia_remision,
    init_number_retencion:init_number_retencion,
    init_number_nota_credito:init_number_nota_credito,
    init_number_nota_debito:init_number_nota_debito,
    agente_retencion:agente_retencion
  });

  const [disablebtnFile, setDisableBtnFile] = useState(false);
  const [disablebtnFileFirma, setDisableBtnFileFirma] = useState(false);

  const [fotoanterior, setFotoanterior] = useState(null);
  const [image, setimager] = useState(null);
  const [imageFirma, setimageFirma] = useState(null);
  const [pass, setpass] = useState("password");

  const [imagelink, setImageLink] = useState(null);
  const [ima, setIma] = useState(null);
  const [ShowButtonsImageFirma, setShowBottonImageFirma] = useState(false);

  const [ShowButtonsImage, setShowBottonImage] = useState(false);
  const getFiles2 = (e) => {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setShowBottonImageFirma(true);
      setimageFirma(selectedFile);
    }
  };
  const getFiles = (files) => {
    if (files.length > 0) {
      alert("si")
      var readerBase64 = new FileReader();
      const selectedFile = files[0];
      readerBase64.readAsDataURL(selectedFile);
      readerBase64.onloadend = (e) => {
        var base64 = e.target.result;
        setIma(base64);
        setShowBottonImage(true);
        setimager(selectedFile);
      };
    }
  };
  const uploadEditFotoFn = async (e) => {
    e.preventDefault();
    setDisableBtnFile(true);
    if (!image) {
      setDisableBtnFile(false);

      toast.error("Debe ingresar una nueva foto", {
        theme: "colored",
      });
    } else {
      const res = await uploadLogoVet(image, veterinaria_id, fotoanterior);
      switch (res[0].sms) {
        case "ok":
          toast.success(res[0].mensaje, {
            theme: "colored",
          });
          setShowBottonImage(false);
          setDisableBtnFile(false);

          break;
        default:
          setDisableBtnFile(false);

          toast.error(res[0].mensaje, {
            theme: "colored",
          });

          break;
      }
    }
  };

  const uploadFirmaFotoFn = async (e) => {
    e.preventDefault();
    setDisableBtnFileFirma(true);
    if (!imageFirma) {
      setDisableBtnFileFirma(false);

      toast.error("Debe ingresar una firma", {
        theme: "colored",
      });
    } else {
      const res = await uploadFirmaVet(imageFirma, veterinaria_id, api_key);
      switch (res[0].sms) {
        case "ok":
          await savefirmavps(res[0].data);

          toast.success(res[0].mensaje, {
            theme: "colored",
          });
          setShowBottonImageFirma(false);
          setDisableBtnFileFirma(false);
          setimageFirma(null);
          window.location.reload()

          break;
        default:
          setDisableBtnFileFirma(false);

          toast.error(res[0].mensaje, {
            theme: "colored",
          });

          break;
      }
    }
  };

  const recargarfirma = async (firma) => {
    alert(firma);
    const firmaFotoVet = await Axios.post("/downloadStorageFile", {
      file: firma,
    });
    const response = await fetch(firmaFotoVet.data.url);
    const file = await response.blob();
    const xx = new File([file], `${firma}`, { type: "" });
    console.log(xx);
    await savefirmavpsload(xx);
  };

  const savefirmavps = async (data) => {
    /*  fetch(data.link).then(res=>res.blob).then(async (blob)=>{
  }) */
    const namepathstr = data.key;
    const formdata = new FormData();
    formdata.append("p12", imageFirma, namepathstr);
    console.log(formdata);
    const result = await axios.post(
      `${url_facturacion_electronica_api}cargar_p12?password=${firma_certificado_clave}`,
      formdata
    );
    console.log(result.data);

    return result.data;
  };

  const savefirmavpsload = async (data) => {
    /*  fetch(data.link).then(res=>res.blob).then(async (blob)=>{
  }) */
    var n = data.name.lastIndexOf("/");
    const namepathstr = data.name.substring(n + 1) + ".p12";
    const formdata = new FormData();
    formdata.append("p12", imageFirma, namepathstr);
    console.log(formdata);
    const result = await axios.post(
      `${url_facturacion_electronica_api}cargar_p12?password=${firma_certificado_clave}`,
      formdata
    );
    console.log(result.data);

    return result.data;
  };

  const onchangePerson = async (e) => {
    e.preventDefault();

    if (e.target.name === "pais_id") {
      setstateVeterinaria({
        ...stateVeterinaria,
        [e.target.name]: e.target.value,
      });
      const provincias = await getProvinciasFn(e.target.value);
      setProvincias(provincias[0].data);
    } else if (e.target.name === "obligado_contabilidad") {
      setstateVeterinaria({
        ...stateVeterinaria,
        [e.target.name]:
          e.target.value === "true"
            ? true
            : e.target.value === "false"
            ? false
            : "",
      });
    } else {
      setstateVeterinaria({
        ...stateVeterinaria,
        [e.target.name]: e.target.value,
      });
    }
  };

  //FUNCION PARA EDITAR LOS DATOS DE LA VETERINARIA

  const editarInfoVeterinaria = async () => {
    if (stateVeterinaria.nombre === null) {
      alert("El nombre es obligatorio");
    } else if (stateVeterinaria.ruc === "") {
      alert("El RUC es obligatorio");
    } else if (stateVeterinaria.direccion === "") {
      alert("Debe ingresar una direccion");
    } else if (
      stateVeterinaria.provincia_id === null ||
      stateVeterinaria.provincia_id === ""
    ) {
      alert("Debe seleccionar la provincia");
    } else if (stateVeterinaria.ciudad === "") {
      alert("Se debe ingresar la ciudad");
    } else {
      try {
        const result = await Axios.post(
          `/editarInfoVeterinaria`,
          stateVeterinaria
        );
        if (result.data.sms === "ok") {
          Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
          window.location.reload()
        } else {
          Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    iniciar();
  }, []);

  useEffect(() => {
    iniciarPaises();
  }, []);

  useEffect(() => {
    fotofn();
  
  }, []);

  const fotofn = async () => {
    setFotoanterior(logo);
    const firma = logo ? await firmarFoto(logo) : null;
    setImageLink(firma ? firma[0].data : null);
  };

  const iniciarPaises = async () => {
    const paises = await getPaisesFn();
    setPaises(paises[0].data);
  };

  const iniciar = async () => {
    if (pais_id != null || pais_id != "") {
      const provincias = await getProvinciasFn(pais_id);
      setProvincias(provincias[0].data);
    }
  };

  return (
    <>
      <div className="col-md-12 h-100 ">
        <div className="card-body row">
          <div className="col-md-3">
            <h5 className="text-dark"> Logo </h5>
            <hr />
            <div className="row">
              <div
                className="col-md-12 p-0 m-0"
                style={{ position: "relative", height: "200px" }}
              >
                <InputFile
                  getFiles={getFiles}
                  absolute="absolute"
                  opacidad="opacoUpload"
                  accept={"image/*"}
                />
                <img
                  src={ima ? ima : fotoanterior ? imagelink : nofoto}
                  alt=""
                  className="h-100 w-100 borde-tarjeta p-0"
                />
              </div>
              {ShowButtonsImage ? (
                <div className="d-flex flex-column justify-content-center my-2">
                  <button
                    className="btn btn-primary w-100 my-1"
                    onClick={(e) => {
                      uploadEditFotoFn(e);
                    }}
                    disabled={disablebtnFile}
                  >
                    Guardar foto
                  </button>
                  <button
                    className="btn btn-danger w-100"
                    onClick={() => {
                      setShowBottonImage(false);
                      setIma(false);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-9">
            <h5 className="text-dark"> Perfil de empresa</h5>
            <hr />
            <div className="row">
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Razon social* :</label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.nombre}
                    id="nombre"
                    name="nombre"
                    placeholder="Ingrese la razon social"
                  />
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Nombre comercial* :
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.nombre_comercial}
                    id="nombre_comercial"
                    name="nombre_comercial"
                    placeholder="Ingrese el Nombre comercial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">RUC* :</label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.ruc}
                    id="ruc"
                    name="ruc"
                    placeholder="Ingrese el RUC "
                  />
                </div>
              </div>

              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Telefono* :</label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.telefono}
                    id="telefono"
                    name="telefono"
                    placeholder="Ingrese teléfono "
                  />
                </div>
              </div>
              <div className="col-sm col-md-6">
                <div className="form-group">
                  <label>Pais* :</label>
                  <select
                    className="inputselect2 bordecompleto "
                    id="pais_id"
                    name="pais_id"
                    style={{ width: "100%" }}
                    onChange={onchangePerson}
                    value={stateVeterinaria.pais_id}
                  >
                    <option value="0">Seleccione ...</option>
                    {paises.map((data, index) => (
                      <option value={data.pais_id} key={index}>
                        {data.pais}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm col-md-6">
                <div className="form-group">
                  <label>Provincia* :</label>
                  <select
                    className="inputselect2 bordecompleto "
                    id="provincia_id"
                    name="provincia_id"
                    style={{ width: "100%" }}
                    onChange={(e) => onchangePerson(e)}
                    value={stateVeterinaria.provincia_id}
                  >
                    <option value="0">Seleccione ...</option>
                    {provincias.map((data, index) => (
                      <option value={data.provincia_id} key={index}>
                        {data.provincia}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm col-md-16">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Ciudad* :</label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.ciudad}
                    id="ciudad"
                    name="ciudad"
                    placeholder="Ingrese la ciudad "
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Establecimiento dirección* :
                  </label>
                  <textarea
                    rows="2"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.direccion}
                    id="direccion"
                    name="direccion"
                    placeholder="Ingrese la dirección"
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Contribuyente especial :
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.contribuyente_especial}
                    id="contribuyente_especial"
                    name="contribuyente_especial"
                    placeholder="Contribuyente especial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label>Está obligado a llevar contabilidad ? :</label>
                  <select
                    className=" inputselect2 bordecompleto"
                    id="obligado_contabilidad"
                    name="obligado_contabilidad"
                    style={{ width: "100%" }}
                    onChange={(e) => onchangePerson(e)}
                    value={stateVeterinaria.obligado_contabilidad}
                  >
                    <option value="">Seleccione ...</option>
                    <option value={true}>SI</option>
                    <option value={false}>NO</option>
                  </select>
                </div>
              </div>

              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label>Régimen</label>
                  <select
                    className=" inputselect bordecompleto"
                    id="regimen"
                    name="regimen"
                    style={{ width: "100%" }}
                    onChange={(e) => onchangePerson(e)}
                    value={stateVeterinaria.regimen}
                  >
                    <option value="">NO DEFINIDO</option>
                    <option value="RIMPE">Contribuyente Régimen RIMPE</option>
                    <option value="POPULAR">
                      Régimen RIMPE - Contribuyente negocio popular
                    </option>
                    <option value="GENERAL">Régimen general</option>
                  </select>
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de resolución de agente de retención:
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.agente_retencion}
                    id="agente_retencion"
                    name="agente_retencion"
                    placeholder="Número de resolución de agente de retención"
                  />
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Calificación artesanal:
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.calificacion_artesanal}
                    id="calificacion_artesanal"
                    name="calificacion_artesanal"
                    placeholder="Calificación artesanal"
                  />
                </div>
              </div>

              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Establecimiento punto de emisión :
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.establecimiento_punto_emision}
                    id="establecimiento_punto_emision"
                    name="establecimiento_punto_emision"
                    placeholder="Establecimieno punto de emisión"
                  />
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Establecimiento código :
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.establecimiento_codigo}
                    id="establecimiento_codigo"
                    name="establecimiento_codigo"
                    placeholder="Ingrese el código del establecimiento"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12 ">
                <div className="w-100 d-flex jsutify-content-start">
                  <label for="" className="titulosubmenusize">
                    Certificado .p12
                  </label>
                </div>

                <div className=" w-100 d-flex flex-column justify-content-center align-items-center bgfondo2 p-2 mb-2">
                  {imageFirma ? (
                    <div
                      className=" w-100 d-flex flex-column justify-content-center align-items-center px-5"
                      style={{ width: "200px" }}
                    >
                      <h6 className="p-1">{imageFirma.name}</h6>
                      <div className="d-flex flex-row justify-content-around my-2 w-100">
                        <button
                          className="bg-btn-vet-danger mx-1 w-50 bordesombreado bordecompleto"
                          onClick={() => {
                            setimageFirma(null);
                          }}
                        >
                          Cancelar
                        </button>
                        <button
                          className="bg-btn-vet bordesombreado bordecompleto mx-1 w-50"
                          onClick={(e) => {
                            uploadFirmaFotoFn(e);
                          }}
                          disabled={disablebtnFileFirma}
                        >
                          Guardar firma
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        onChange={getFiles2}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Api Key :</label>
                  <input
                    disabled
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.api_key}
                    id="api_key"
                    name="api_key"
                    placeholder="Api key"
                  />
                </div>
              </div>
              <div className="col-sm col-md-12 ">
                <label htmlFor="exampleInputEmail1">
                  Clave certificado de la Firma electrónicas :
                </label>
                <div className="d-flex flex-row  form-group">
                  <input
                    type={pass}
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.firma_certificado_clave}
                    id="firma_certificado_clave"
                    name="firma_certificado_clave"
                    placeholder="Firma certificado clave"
                    autoComplete="new-password"
                  />
                  <button
                    type=""
                    className="w-auto bordecompleto my- bg-white"
                    onClick={() => {
                      if (pass === "text") {
                        setpass("password");
                      } else {
                        setpass("text");
                      }
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </button>
                </div>
              </div>
              <div className="col-sm col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Entidad certificadora
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.entidad}
                    id="entidad"
                    name="entidad"
                    placeholder="Entidad certificadora"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <label for="" className="negrita">
                  Configuración de números de documentos iniciales
                </label>
                <label for="" className="color-azulmarino">
                  El sistema tomará el número inicial configurado en este panel
                  e iniciara el proceso de creación del número de documento al
                  emitir un documento electrónico nuevo, si existen documentos
                  previamente emitidos el sistema continuará la sucesión siempre
                  que la configuración inicial sea menor al número de documento
                  emitido, caso contrario si el número inicial configurado es
                  mayor al número del último documento emito el sistema generará
                  el número de documento apartir del número configurado.
                </label>
                <hr />
              </div>
              <div className="col-sm col-md-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de Recibo inicial
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.init_number_recibos}
                    id="init_number_recibos"
                    name="init_number_recibos"
                    placeholder="Número de recibo inicial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de factura inicial
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.init_number_venta}
                    id="init_number_venta"
                    name="init_number_venta"
                    placeholder="Número de factura inicial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de liquidación inicial
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.init_number_liquidacion}
                    id="init_number_liquidacion"
                    name="init_number_liquidacion"
                    placeholder="Número de liquidación inicial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de guía de remisión inicial
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.init_number_guia_remision}
                    id="init_number_guia_remision"
                    name="init_number_guia_remision"
                    placeholder="Número de guía inicial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de retención inicial
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.init_number_retencion}
                    id="init_number_retencion"
                    name="init_number_retencion"
                    placeholder="Número de retencón inicial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de nota de debito inicial
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.init_number_nota_debito}
                    id="init_number_nota_debito"
                    name="init_number_nota_debito"
                    placeholder="Número de nota de débito inicial"
                  />
                </div>
              </div>
              <div className="col-sm col-md-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Número de nota de crédito inicial
                  </label>
                  <input
                    type="text"
                    className="inputtext2 bordecompleto"
                    onChange={onchangePerson}
                    value={stateVeterinaria.init_number_nota_credito}
                    id="init_number_nota_credito"
                    name="init_number_nota_credito"
                    placeholder="Número de nota de crédito inicial"
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="row">
              <button
                className="bg-btn-vet bordesombreado bordecompleto"
                onClick={editarInfoVeterinaria}
              >
                <i className="fa fa-save"></i>Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfiguracionesVeter;
