import React, { useEffect, useState, useContext } from "react";

import VentasFactura from "../Components/facturacion/index";

import { useParams } from "react-router-dom";

const Facturacion = () => {
  const { panel } = useParams();

  const [stateContenedor, setContenedor] = useState(null);

  useEffect(() => {
    if (panel === "inicio") {
      setContenedor(<VentasFactura />);
    }
    return () => {};
  }, [panel]);
  return (
    <div className="row">
     
      <div
        className="col-md-12 text-center bgfondo2 p-0 m-0"
        style={{ marginLeft: "25%!important" }}
      >
        {stateContenedor}
      </div>{" "}
    </div>
  );
};

export default Facturacion;
