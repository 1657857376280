import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../../Context/auth/authContext";
import { Table } from "reactstrap";
import {toast} from "react-toastify";
import Axios from "../../../config/Axios";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const Servicios = () => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, color, textColor, index } = authContext;

  const { nombre, veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [state, setState] = useState({
    todos: [],
    currentPage: 1,
    todosPerPage: 20,
    servicio: {},
  });

  const { todos, currentPage, todosPerPage } = state;

  const [stateServicio, setStateServicio] = useState({
    servicio_id: null,
    veterinaria_id: veterinaria_id,
    servicio: null,
    costo: null,
  });

  const [stateModal, setStateModal] = useState({
    mapaModal: false,
    modal: false,
    serviciodata: {},
  });

  const onchange = (e) => {
    setStateServicio({
      ...stateServicio,
      [e.target.name]: e.target.value,
    });
  };

  const deleteServicio = async (e, servicio_id) => {
    e.preventDefault();
    try {
      const result = await Axios.get(`/deleteServicio/` + servicio_id);
      if (result.data.sms === "ok") {
        toast.success(`${result.data.mensaje}`);
      } else {
        toast.error(`${result.data.mensaje}`);
      }
    } catch (e) {}
  };

  const CrearServicio = async (e) => {
    e.preventDefault();

    if (stateServicio.servicio === "" || stateServicio.servicio === null) {
      alert("Ingrese el nombre del servico");
    } else if (stateServicio.costo === "" || stateServicio.costo === null) {
      alert("Ingrese el costo del servicio");
    } else {
      try {
        const result = await Axios.post("/createServicio", stateServicio);

        if (result.data.sms === "ok") {
          toast.success(`${result.data.mensaje}`);
          setStateServicio({
            ...stateServicio,
            servicio: "",
            costo: "",
          });

          inicializarTodosLosServicios();
        } else {
          toast.error(`${result.data.mensaje}`);
        }
      } catch (e) {}
    }
  };

  const EditarServicio = async (e) => {
    e.preventDefault();

    if (stateServicio.servicio === "" || stateServicio.servicio === null) {
      alert("Ingrese el nombre del servico");
    } else if (stateServicio.costo === "" || stateServicio.costo === null) {
      alert("Ingrese el costo del servicio");
    } else {
      try {
        const result = await Axios.post("/editServicio", stateServicio);

        if (result.data.sms === "ok") {
          toast.success(`${result.data.mensaje}`);
          setStateModal({
            ...stateModal,
            modal: false,
            serviciodata: {},
          });
          setStateServicio({
            ...stateServicio,
            servicio: "",
            costo: "",
            servicio_id: "",
          });

          inicializarTodosLosServicios();
        } else {
          toast.error(`${result.data.mensaje}`);
        }
      } catch (e) {}
    }
  };

  const inicializarTodosLosServicios = async () => {
    try {
      const respuesta = await Axios.get(`/getServicios/` + veterinaria_id);
      if (respuesta.data.sms === "ok") {
        setState({
          ...state,
          todos: respuesta.data.result,
          currentPage: 1,
        });
      } else {
      }
    } catch (e) {}
  };

  // Logic for displaying current todos
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

  const renderTodos = currentTodos.map((todo, index) => {
    return (
      <tr key={index}>
        <td>{todo.servicio}</td>
        <td>{todo.costo}</td>
        <td>
          <button
            onClick={() => {
              setStateModal({
                ...stateModal,
                modal: true,
                serviciodata: todo,
              });

              setStateServicio({
                ...stateServicio,
                servicio: todo.servicio,
                costo: todo.costo,
                servicio_id: todo.servicio_id,
              });
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
          <button onClick={(e) => deleteServicio(e, todo.servicio_id)}>
            <i className="fas fa-trash"></i>
          </button>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    inicializarTodosLosServicios();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-start my-2 px-3 ">
        <label className="titulosubmenusize">Servicios</label>
      </div>
      <div className="row d-flex justify-content-center align-items-center  ">
        <div className="col-md-7 ">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Detalle el servicio* :</label>
            <br></br>
            <input
              type="text"
              className="form-control inputtext2"
              onChange={onchange}
              value={stateServicio.servicio}
              id="servicio"
              name="servicio"
              placeholder="Ingrese el servicio que ofrece"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Costo* :</label>
            <br></br>
            <input
              type="text"
              className="form-control inputtext2"
              onChange={onchange}
              value={stateServicio.costo}
              id="costo"
              name="costo"
              placeholder="Ingrese el costo del servicio"
            />
          </div>
        </div>
        <div className="col-md-2 d-flex justify-content-center align-items-center">
          <button onClick={CrearServicio} className="bg-btn-vet">
            Guardar
          </button>
        </div>
      </div>
      <div className="px-3 d-flex justify-content-start flex-column align-items-start">
        Listado de servicios
        <Table>
          <thead
            className=""
            style={{ background: color, textAlign: "center" }}
          >
            <tr>
              <th style={{ color: textColor }}>Detalle del servicio</th>
              <th style={{ color: textColor }}>Costo</th>
              <th style={{ color: textColor }}>OPCIONES</th>
            </tr>
          </thead>
          <tbody className="bg-white" style={{ padding: "12px", textAlign: "center" }}>
            {renderTodos}
          </tbody>
        </Table>
      </div>

      <Modal
        isOpen={stateModal.modal}
        backdrop={false}
        keyboard={false}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        style={{ maxWidth: "70%", maxHeight: "200PX", padding: "20px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              modal: false,
              serviciodata: {},
            });
            setStateServicio({
              ...stateServicio,
              servicio: null,
              costo: null,
              servicio_id: null,
            });
          }}
          className="borde"
        >
          Modificar Servicio
        </ModalHeader>
        <ModalBody style={{ padding: "0.75rem" }}>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Detalle el servicio* :
                </label>
                <br></br>
                <input
                  type="text"
                  className="form-control inputtext2"
                  onChange={onchange}
                  value={stateServicio.servicio}
                  id="servicio"
                  name="servicio"
                  placeholder="Detalle el servicio"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Costo* :</label>
                <br></br>
                <input
                  type="text"
                  className="form-control inputtext2"
                  onChange={onchange}
                  value={stateServicio.costo}
                  id="costo"
                  name="costo"
                  placeholder="Costo del servicio"
                />
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-center align-items-center">
              <button onClick={EditarServicio} className="bg-btn-vet">
                guardar
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Servicios;
