import Axios from "../../config/AxiosContable";

export const guardarVentaApi = async (state) => {
  try {
    const res = await Axios.post("/createFacturas", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const editarVentaApi = async (state) => {
  try {
    const res = await Axios.post("/editarFacturas", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const crearFirmarXmlVentaApi = async (empresa_id,venta_cabecera_id) => {
  try {
    const res = await Axios.get("/crearyfirmarxml/"+empresa_id+"/"+venta_cabecera_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const anularventaApi = async (venta_cabecera_id) => {
  try {
    const res = await Axios.get("/anularventa/"+venta_cabecera_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};


export const listarVentaApi = async (empresa_id) => {
  try {
    const res = await Axios.get("/getFacturas/"+empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const listarDocumentosDelDiaxUsuario = async (
  empresa_id,
  usuario_id,
  ambiente,
  fecha_venta,
  codigo_documento
) => {
  try {
    const res = await Axios.post(
      "/getDocumentosDelDiaxUsuario/" + empresa_id + "/" + usuario_id,
      {
        ambiente: ambiente,
        fecha_venta: fecha_venta,
        codigo_documento
      }
    );
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const listarVentaApicode = async (code) => {
  try {
    const res = await Axios.get(
      "/getFacturascode/" + code
    );
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};