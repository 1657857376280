import Axios from "../../config/AxiosContable";

export const crearAfiliadoSocioApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTCrearAfiliado`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const verificarAfiliadoSocioApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTVerificarAfiliado`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: "",
      mensaje: "Ocurrio un problemas",
    };
  }
};

export const crearEditarAfiliadoSocioApi = async (data) => {
  try {
    const result = await Axios.get(`/POSTEditarAfiliado`, data);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const GetAfiliadosEmpresaApi = async (veterinaria_id) => {
  try {
    const result = await Axios.get(`/GETAfiliadosEmpresa/${veterinaria_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};
