import React, { useState, useEffect } from "react";
import ModalDefault from "../modalDefault";
import RegistrarCliente from "./cliente";
import { actualizarCLientesFacturacion } from "../../api/clientesFacturacion";
import { getCLientesFacturacion } from "../../api/clientesFacturacion/index";
import { toast } from "react-toastify";
const CabeceraFactura = ({
  state,
  onchangeprincipal,
  tiposIdentificacion,
  cargartransportista,
}) => {
  const [modalRegistrarCliente, setModalRegistrarCliente] = useState(false);
  const [cliente, setCliente] = useState({
    correo: "",
    identificacion: "",
    tipo_identificacion: "",
    razon_social: "",
    direccion: "",
    telefono: "",
    cliente_facturacion_id: "",
  });

  const onchange = (e) => {
    onchangeprincipal(e);
  };

  const onchangeciente = (e) => {
    setCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };

  const keyevent = (e) => {
    if (e.key === "Enter") {
      filtrarclientefactura();
    }
  };

  useEffect(() => {
    setCliente({
      identificacion: state.traslado.ructransportista,
      tipo_identificacion: state.traslado.tipo_identificacion_transportista,
      razon_social: state.traslado.razonsocialtransportista,
      telefono: state.traslado.telefonotransportista,
      correo: state.traslado.correotransportista,
    });
  }, [state]);

  const closeAction = (modal, x) => {
    setModalRegistrarCliente(modal);
  };

  const filtrarclientefactura = async () => {
    if (state.codigo_identificacion_transportista === "") {
      toast.error(`Seleccione el tipo de identificación !`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    if (state.identificacion_transportista === "") {
      toast.error(`Ingrese la idetificación !`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (
      state.identificacion_transportista.length !== 13 &&
      state.codigo_identificacion_transportista === "04"
    ) {
      toast.error(`El ruc debe contener 13 digitos!`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (
      state.identificacion_transportista.length !== 10 &&
      state.codigo_identificacion_transportista === "05"
    ) {
      toast.error(`La cédula debe contener 10 digitos!`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    const res = await getCLientesFacturacion(
      state.identificacion_transportista,
      state.codigo_identificacion_transportista
    );
    if (res[0].sms === "ok") {
      if (res[0].data === null) {
        setCliente({
          ruc:
            state.codigo_identificacion === "04"
              ? state.identificacion_cliente
              : "",
          cedula:
            state.codigo_identificacion === "05"
              ? state.identificacion_cliente
              : "",
          pasaporte:
            state.codigo_identificacion === "06"
              ? state.identificacion_cliente
              : "",
          idexterior:
            state.codigo_identificacion === "08"
              ? state.identificacion_cliente
              : "",
          placa:
            state.codigo_identificacion === "09"
              ? state.identificacion_cliente
              : "",
        });
        setModalRegistrarCliente(true);
      } else {
        console.log(res[0].data);
        cargartransportista(res[0].data);
      }
    } else {
    }
  };

  const updateClientefactura = async () => {
    if (state.identificacion_cliente === "") {
      alert("Es necesario un campo de identificacion");
    } else {
      const res = await actualizarCLientesFacturacion(cliente);
      if (res[0].sms === "ok") {
        setCliente(res[0].data);
        toast.success(res[0].mensaje);
        filtrarclientefactura();
      } else {
        toast.error(res[0].mensaje);
      }
    }
  };

  const retornocliente = (data) => {
    setCliente(data);
    setModalRegistrarCliente(false);
  };

  return (
    <div className="col-md-12 p-2 ">
      <div className="row d-flex justify-content-start align-items-start">
        <div className="negrita" style={{textAlign:"left"}}>TRANSPORTE Y TRASLADO</div>
        <hr/>
      </div>
      <div className="row d-flex justify-content-start align-items-center ">
        <div className="col-md-6 bordecompleto bgfondo2 bordesombreado">
          <div className="row">
            <div className="col-md-12 py-0 my-0">
              <div className="row">
                <label for="" className="negrita">
                  Transporte
                </label>
              </div>
            </div>
            <div className="col-md-6" style={{ display: "block" }}>
              <div className="form-group">
                <label>Tipo de identificación* :</label>
                <select
                  className="inputselectsmall bordecompleto bordecombreado "
                  disabled={state.codigo_documento === "07" ? true : false}
                  id="codigo_identificacion_transportista"
                  name="codigo_identificacion_transportista"
                  style={{ width: "100%" }}
                  onChange={(e) => onchange(e)}
                  value={state.codigo_identificacion_transportista}
                >
                  <option value="">Tipo de identificación...</option>
                  {tiposIdentificacion.map((data, index) => (
                    <option value={data.codigo} key={index}>
                      {data.tipo}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="row w-100 d-flex flex-column justify-content-center align-items-center p-0 m-0">
                <div className="col-md-12 col-sm-12 p-0 m-0 ">
                  {state.codigo_identificacion_transportista !== "" ? (
                    state.codigo_identificacion_transportista !== "07" ? (
                      <div className="form-group ">
                        {" "}
                        <label className="" htmlFor="exampleInputEmail1">
                          Identificación transportista:
                        </label>
                        <div className="col-md-12  d-flex justify-content-between justify-content-center align-items-center">
                          <input
                            type="text"
                            disabled={
                              state.codigo_documento === "07" ? true : false
                            }
                            className=" inputtextsmall bordecompleto "
                            onChange={onchange}
                            value={state.identificacion_transportista}
                            onKeyUp={keyevent}
                            id="identificacion_transportista"
                            name="identificacion_transportista"
                            placeholder="Digite el número de identificación "
                          />
                          <button
                            disabled={
                              state.codigo_documento === "07" ? false : false
                            }
                            className="bordecompleto borderedondeado mx-1 d-flex justify-content-center align-items-center "
                            style={{ height: "28px", width: "28px" }}
                            onClick={filtrarclientefactura}
                          >
                            <i
                              className="fas fa-search"
                              style={{ fontSize: "10px" }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group row ">
                        <div className="col-md-12 d-flex d-flex justify-content-center align-items-center">
                          {" "}
                          <label className="" htmlFor="exampleInputEmail1">
                            Consumidor final
                          </label>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="form-group row">
                      <div className="col-md-12 d-flex d-flex justify-content-center align-items-center">
                        {" "}
                        <label className="" htmlFor="exampleInputEmail1">
                          Seleccione el tipo de identificacion para continuar
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex flex-row justify-content-start">
              <div className="form-group w-100">
                {" "}
                <label for="" className=" negrita">
                  Razon Social transportista :{" "}
                </label>
                <div className="col-md-12 d-flex justify-content-between ">
                  <input
                    readOnly={
                      state.codigo_identificacion === "07" ? true : false
                    }
                    type="text"
                    className=" inputtextsmall bordecompleto "
                    onChange={onchange}
                    value={state.traslado.razonsocialtransportista}
                    id="razonsocialtransportista"
                    name="razonsocialtransportista"
                    placeholder="Razon social del transportista"
                    disabled={state.codigo_documento === "07" ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-row justify-content-start">
              <div className="form-group w-100">
                {" "}
                <label for="" className=" negrita">
                  Teléfono transportista :{" "}
                </label>
                <div className="col-md-12">
                  <input
                    readOnly={
                      state.codigo_identificacion === "07" ? true : false
                    }
                    type="text"
                    className=" inputtextsmall bordecompleto"
                    onChange={onchange}
                    value={state.traslado.telefonotransportista}
                    id="telefonotransportista"
                    name="telefonotransportista"
                    placeholder="Teléfono"
                    disabled={state.codigo_documento === "07" ? true : false}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6  d-flex flex-row justify-content-start">
              <div className="form-group w-100">
                {" "}
                <label for="" className=" negrita">
                  Correo transportista :{" "}
                </label>
                <div className="col-md-12">
                  <input
                    readOnly={
                      state.codigo_identificacion === "07" ? true : false
                    }
                    type="text"
                    className=" inputtextsmall bordecompleto"
                    onChange={onchange}
                    value={state.traslado.correotransportista}
                    id="correotransportista"
                    name="correotransportista"
                    placeholder="Correo"
                    disabled={state.codigo_documento === "07" ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 bordecompleto bgfondo2 bordesombreado ">
          <div className="row">
            <div className="col-md-12 py-0 my-0">
              <div className="row">
                <label for="" className="negrita">
                  Traslado
                </label>
              </div>
            </div>
            <div className="col-md-6 m-0 p-0">
              <div className="row">
                <div className="col-md-12  d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      Fecha inicio de transporte :{" "}
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="date"
                        className=" inputtextsmall bordecompleto"
                        onChange={onchange}
                        value={state.traslado.fechainitransporte}
                        id="fechainitransporte"
                        name="fechainitransporte"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 m-0 p-0">
              <div className="row">
                <div className="col-md-12  d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      Fecha final de transporte :{" "}
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="date"
                        className=" inputtextsmall bordecompleto"
                        onChange={onchange}
                        value={state.traslado.fechafintransporte}
                        id="fechafintransporte"
                        name="fechafintransporte"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 m-0 p-0">
              <div className="row">
                <div className="col-md-12  d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      Placa :{" "}
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="text"
                        className=" inputtextsmall bordecompleto"
                        onChange={onchange}
                        value={state.traslado.placa}
                        id="placa"
                        name="placa"
                        placeholder="Ingrese el número de placa"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 m-0 p-0">
              <div className="row">
                <div className="col-md-12  d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      Dirección de partida :{" "}
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="text"
                        className=" inputtextsmall bordecompleto"
                        onChange={onchange}
                        value={state.traslado.dirpartida}
                        id="dirpartida"
                        name="dirpartida"
                        placeholder="Dirección de partida"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  <div className="col-md-12">
        {state.identificacion_cliente === "" ? (
          "Para modificar los datos del comprador se debe definir previamente una identifcación válida"
        ) : cliente.razon_social === state.comprador.razon_social &&
          cliente.telefono === state.comprador.telefono &&
          cliente.direccion === state.comprador.direccion &&
          cliente.n_remision === state.comprador.n_remision &&
          cliente.correo === state.comprador.correo &&
          cliente.razon_social === state.comprador.razon_social ? null : (
          <button className="bg-btn-vet  w-100" onClick={updateClientefactura}>
            Guardar cambios de la identificación {state.identificacion_cliente}
          </button>
        )}
      </div> */}
        {/* <div className="col-md-12">
        <div>
          <label for="" className="negrita">
            Razon social:{" "}
            <label for="" className="color-azulmarino">
              {cliente.razon_social}
            </label>
          </label>
        </div>
        <div>
          <label for="" className="negrita">
            Correo:{" "}
            <label for="" className="color-azulmarino">
              {cliente.correo}
            </label>
          </label>
        </div>
        <div>
          <label for="" className="negrita">
            Teléfono:{" "}
            <label for="" className="color-azulmarino">
              {cliente.telefono}
            </label>
          </label>
        </div>
        <div>
          <label for="" className="negrita">
            Dirección:{" "}
            <label for="" className="color-azulmarino">
              {cliente.direccion}
            </label>
          </label>
        </div>
      </div> */}
        <ModalDefault
          modal={modalRegistrarCliente}
          size={"lg"}
          titulo="REGISTRO DE NUEVOS CLIENTES"
          closeAction={closeAction}
        >
          <RegistrarCliente
            cliente={cliente}
            setcliente={retornocliente}
          ></RegistrarCliente>
        </ModalDefault>
      </div>
    </div>
  );
};

export default CabeceraFactura;
