import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import moment from "moment";
import {
  getAllCategoriasServiciosApi,
  eliminarCategoriaServiciosApi,
} from "../../../api/afiliaciones_socios/categorias";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import ModalDefault from "../../modalDefault";

const TablaCategorias = (props) => {
  const PAGE_SIZES = 5; // Define los tamaños de página disponibles
  const [page, setPage] = useState(1); // Estado para la página actual
  const [data, setData] = useState([]); // Estado para los datos totales
  const [records, setRecords] = useState([]); // Estado para los registros que se muestran actualmente

  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [modalCofirmacion, setModalConfirmacion] = useState(false);

  useEffect(() => {
    const getRecordsEmpresa = async () => {
      const res = await getAllCategoriasServiciosApi(props.empresa);
      if (res.sms === "ok") {
        setData(res.data);
      } else {
        toast.error(res.mensaje);
      }
    };

    getRecordsEmpresa();
  }, [props.actualizarLista]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZES;
    const to = from + PAGE_SIZES;
    setRecords(data.slice(from, to));
  }, [page, data]);

  const handleEdit = (categoria) => {
    props.setCategoria(categoria);
    props.setEditar(true);
  };

  const handleDelete = (categoria) => {
    setSelectedCategoria(categoria);
    setModalConfirmacion(true);
  };

  const eliminarCategoriaFn = async (afiliado_categoria_id) => {
    try {
      const respuesta = await eliminarCategoriaServiciosApi({
        afiliado_categoria_id,
      });
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        const d = data.filter(
          (cat) => cat.afiliado_categoria_id !== afiliado_categoria_id
        );
        setData(d);
        setSelectedCategoria(null);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar la petición");
    } finally {
      setModalConfirmacion(false);
    }
  };

  const acciones = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="end">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleEdit(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> editar
          </DropdownItem>

          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleDelete(row);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      <DataTable
        borderRadius="md"
        className="bordecompleto bgfondo"
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        columns={[
          {
            accessor: "afiliado_categoria_id",
            title: " ",
            render: (row) => (
              <div className="d-flex justify-content-center">
                {acciones(row)}
              </div>
            ),
          },
          {
            accessor: "categoria",
            title: "Categoria",
            textAlignment: "left",
          },
          {
            accessor: "descripcion",
            title: "Descripcion",
            textAlignment: "left",
          },
          {
            accessor: "activo",
            title: "Estado",
            textAlignment: "left",
            render: ({ activo }) => (activo ? "ACTIVO" : "INACTIVO"),
          },
          {
            accessor: "fecha",
            title: "Fecha de registro",
            textAlignment: "left",
            render: ({ create_at }) => moment(create_at).format("MM/DD/YYYY"),
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={PAGE_SIZES}
        page={page}
        onPageChange={setPage}
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalCofirmacion}
        size={"sm"}
        titulo={`Confirmación`}
        closeAction={() => {
          setModalConfirmacion(false);
        }}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará la categoria {selectedCategoria?.categoria}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => {
                setModalConfirmacion(false);
                setSelectedCategoria(null);
              }}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectedCategoria !== null) {
                  eliminarCategoriaFn(selectedCategoria.afiliado_categoria_id);
                } else {
                  toast.error("No existe el indice de la categoria.");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
    </>
  );
};

export default TablaCategorias;
