import React, { useState, useContext, useEffect } from "react";
import {useParams } from "react-router-dom";
import PanelCrearUsuario from "../Usuarios/crearUsuarios";
import PanelCrearMascota from "../pacientes/crearPacientes";
import ConsultaMedica from "../consultaMedica/consultamedica";
import EditarPaciente from "../pacientes/editarPaciente";
import Citas from "./citas";
import InsertarPacientePersona from "../pacientes/insertarPacientePersona";
import EditarPacientePersona from "../pacientes/editarPacientePersona";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";
import { ReactComponent as MedicListIcon } from "../../../icons/mediclist.svg";
import { ReactComponent as PetIcon } from "../../../icons/pet.svg";
import {
  Modal,
  ModalHeader,
  ModalBody,

} from "reactstrap";
import AuthContext from "../../../Context/auth/authContext";
import ReactTooltip from "react-tooltip";

const Dashboard = (props) => {

  const { panel } = useParams;

  const AuthenticationContext = useContext(AuthContext);
  const {actionbtnmenuFn } =
    AuthenticationContext;

  const [stateContenedor, setContenedor] = useState(null);
  const [stateModal, setStateModal] = useState({
    nuevoUsuarioModal: false,
    nuevoPacienteModal: false,
    nuevoPecientePersonaModal: false,
    editarPecientePersonaModal: false,
    userdata: {},
    configModal: false,
  });

  const [opciones, setOpciones] = useState([
    /* {
      titulo: "Citas medicas",
      mouseleave: "blue",
      mouseenter: "white",
      fill: "blue",
      icon: "home",
      class: "primary",
    }, */
    {
      titulo: "Registrar usuario ",
      mouseleave: "blue",
      mouseenter: "white",
      fill: "blue",
      icon: "adduser",
      class: "primary",
    },
    {
      titulo: "Registrar mascota ",
      mouseleave: "blue",
      mouseenter: "white",
      fill: "blue",
      icon: "registromascota",
      class: "primary",
    },
  ]);

  const mouseActionButon = (index) => {
    let opcionesCopia = [...opciones];
    opcionesCopia[index].fill = opciones[index].mouseenter;
    setOpciones(opcionesCopia);
  };

  const mouseActionButon2 = (index) => {
    let opcionesCopia = [...opciones];
    opcionesCopia[index].fill = opciones[index].mouseleave;
    setOpciones(opcionesCopia);
  };

/*   const icons = (boton) => {
    switch (boton.icon) {
      case "home":
        return (
          <MedicListIcon
            fill={boton.fill}
            width={25}
            height={25}
            className="mx-2"
          />
        );
        break;
      case "registromascota":
        return (
          <PetIcon fill={boton.fill} width={25} height={25} className="mx-2" />
        );
        break;
      case "adduser":
        return (
          <AddIcon fill={boton.fill} width={25} height={25} className="mx-2" />
        );
        break;
    }
  };

  const ejecutarBoton = (e, boton) => {
    e.preventDefault();
    switch (boton.icon) {
      case "adduser":
        //setModalRegistro(true)
        navigate("/clinica/registrousuario");

        break;
      case "home":
        //setModalRegistro(true)
        navigate("/clinica/citas");

        break;
      case "registromascota":
        //setModalRegistro(true)
        navigate("/clinica/registromascota");
        break;
    }
  }; */

  useEffect(() => {
    switch (panel) {
      case "registrousuario":
        setContenedor(<PanelCrearUsuario />);
        break;
      case "registromascota":
        setContenedor(<PanelCrearMascota />);
        break;
      default:
        setContenedor(<Citas />);
        break;
    }
  }, [panel]);

  useEffect(() => {
    actionbtnmenuFn(0);
  }, []);

  const actualizarListaDespuesDeingresarMascota = async (
    dato_id,
    dato_nombre
  ) => {
    console.log(dato_nombre);
    setStateModal({
      ...stateModal,
      nuevoPacienteModal: false,
      nuevoPecientePersonaModal: true,
      userdata: {
        mascota_id: dato_id,
        nombreMascota: dato_nombre,
      },
    });
  };

  const cerrarModalConfiguraciones = (e) => {
    e.preventDefault();
    setStateModal({
      ...stateModal,
      configModal: false,
    });
  };

  return (
    <>
      <ReactTooltip></ReactTooltip>
      <div className="row  bgfondo2 h-100">
        <div className="col-md-3 movilmenu fijo h-100 mb-3 bordehorizontal p-0 m-0">
          <div className="row p-0 m-0 ">
            <div className="col-md-12 m-0 px-0 ">
              <ConsultaMedica></ConsultaMedica>
            </div>
          </div>
          {/*end::Card Body*/}
          {/*end::Card*/}
        </div>
        <div
          className="col-md-9 fijopanelleft bgfondo2 h-100 "
          style={{ overflow: "auto" }}
        >
          <div className="row ">
            <div className="col-md-12 bgfondo bordecircular  py-3">
              <div className="row m-0 -100 d-flex justify-content-between ">
                <h5 className="centrar negrita">Agenda veterinaria </h5>
                {/*  <div style={{ textAlign: "center" }}>
              {opciones.map((boton, indx) => (
                <Button
                  outline
                  className={" mx-2"}
                  style={{ borderRadius: "15px" }}
                  color={boton.class}
                  onMouseEnter={() => mouseActionButon(indx)}
                  onMouseLeave={() => mouseActionButon2(indx)}
                  onClick={(e) => ejecutarBoton(e, boton)}
                >
                  {icons(boton)}
                  {boton.titulo}
                </Button>
              ))}
            </div> */}
              </div>
              <div className="row my-1">{stateContenedor}</div>
            </div>
          </div>
        </div>
      </div>

      {/* =======================================================================================================================================
         PANELES MODALES 
      ===========================================================================================================================================**/}

      {/* MODAL REGISTRO DE MASCOTAS*/}
      <Modal
        centered={true}
        isOpen={stateModal.nuevoPacienteModal}
        toggle={() => {}}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              nuevoPacienteModal: false,
            });
          }}
          className="borde"
        >
          REGISTRO DE MASCOTAS
        </ModalHeader>
        <ModalBody>
          <PanelCrearMascota
            actualizaLista={actualizarListaDespuesDeingresarMascota}
          ></PanelCrearMascota>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL CONFIGURACIONES MASCOTA */}
      <Modal centered={true} isOpen={stateModal.configModal} toggle={() => {}}>
        <ModalHeader
          toggle={cerrarModalConfiguraciones}
          style={{ background: "white" }}
        >
          EDITAR INFORMACIÓN DE : {stateModal.userdata.nombres}{" "}
          {stateModal.userdata.apellidos}{" "}
        </ModalHeader>
        <ModalBody>
          <EditarPaciente data={stateModal.userdata}></EditarPaciente>
        </ModalBody>
      </Modal>
      {/* FIN  MODAL CONFIGURACIONES MASCOTA */}

      {/* MODAL REGISTRO DE USUARIOS*/}
      <Modal
        centered={true}
        isOpen={stateModal.nuevoUsuarioModal}
        toggle={() => {}}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              nuevoUsuarioModal: false,
            });
          }}
          className="borde"
        >
          REGISTRO DE USUARIO
        </ModalHeader>
        <ModalBody>
          <PanelCrearUsuario></PanelCrearUsuario>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL REGISTRO DE MASCOTAS*/}
      <Modal
        centered={true}
        isOpen={stateModal.nuevoPecientePersonaModal}
        toggle={() => {}}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              nuevoPecientePersonaModal: false,
            });
          }}
          className="borde"
        >
          INSERTAR DUEÑO
        </ModalHeader>
        <ModalBody>
          <InsertarPacientePersona
            data={stateModal.userdata}
          ></InsertarPacientePersona>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL REGISTRO DE MASCOTAS*/}
      <Modal
        centered={true}
        isOpen={stateModal.editarPecientePersonaModal}
        toggle={() => {}}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              editarPecientePersonaModal: false,
            });
          }}
          className="borde"
        >
          INSERTAR DUEÑO
        </ModalHeader>
        <ModalBody>
          <EditarPacientePersona
            data={stateModal.userdata}
          ></EditarPacientePersona>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}
    </>
  );
};

export default Dashboard;
