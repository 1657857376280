import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import InputFile from "../../../inputFile";
import Axios from "../../../../config/Axios";
import AuthContext from "../../../../Context/auth/authContext";
import Swal from "sweetalert2";
import "./style.css";
import {
  soloPermitirTextoConEspacio,
  soloPermitirNumeros,
  soloPermitirTextoMayusculaConEspacio,
  soloPermitirTextoConEspacioCaracteresBasicos,
} from "../../../../config/validaciones";
import {
  getCiudadesFn,
  getProvinciasFn,
  getPaisesFn,
} from "../../../../api/ubicaciones";

import { toast } from "react-toastify";
import { uploadUpdateperfil, firmarFoto } from "../../../../api/files";

const ConfiguracionesUsuario = () => {
  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);

  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli } = AuthenticationContext;

  const {
    persona_id,
    cedula,
    nombres,
    apellidos,
    direccion,
    correo,
    genero,
    c_postal,
    usuario_id,
    pais_id,
    provincia_id,
    ciudad,
    foto,
    telefono,
    fecha_regis,
  } = sessiondatacli[0].persona[0];

  const nofoto =
    "https://w7.pngwing.com/pngs/629/15/png-transparent-brookville-grace-brethren-silhouette-graphy-avatar-animals-heroes-logo.png";

  const urlImgSession = localStorage.getItem("ImgUrlAuthcli");

  const [stateModal, setStateModal] = useState({
    nuevoPassword: false,
  });

  const [state, setState] = useState({
    modalinfo: false,
    verBotonEditarAcceso: "none",
    btnInfpFile: "Cambiar de archivo",
    reader: "",
    todosPersona: {},
  });

  const [stateUsuarioPassword, setStateUsuarioPassword] = useState({
    antiguopassword: "",
    nuevopassword: "",
    usuario_id: usuario_id,
  });

  const [stateUsuario, setstateUsuario] = useState({
    persona_id: persona_id,
    cedula: cedula,
    nombres: nombres,
    apellidos: apellidos,
    direccion: direccion,
    correo: correo,
    genero: genero,
    c_postal: c_postal,
    usuario_id: usuario_id,
    pais_id: pais_id,
    provincia_id: provincia_id,
    ciudad: ciudad,
    fotoanterior: foto,
    telefono: "0" + telefono,
    fecha_regis: fecha_regis,
    foto: "",
  });

  const [disablebtnSave, setDisableBtnSave] = useState(false);
  const [disablebtnFile, setDisableBtnFile] = useState(false);

  const [fotoanterior, setFotoanterior] = useState(null);
  const [image, setimager] = useState(null);
  const [imagelink, setImageLink] = useState(null);
  const [ima, setIma] = useState(null);
  const [ShowButtonsImage, setShowBottonImage] = useState(false);
  const getFiles = (files) => {
    if (files.length > 0) {
      var readerBase64 = new FileReader();
      const selectedFile = files[0];

      readerBase64.readAsDataURL(selectedFile);
      readerBase64.onloadend = (e) => {
        var base64 = e.target.result;
        setIma(base64);
        setShowBottonImage(true);
        setimager(selectedFile);
      };
    }
  };
  const uploadEditFotoFn = async (e) => {
    e.preventDefault();
    setDisableBtnFile(true);
    if (!image) {
      setDisableBtnFile(false);

      toast.error("Debe ingresar una nueva foto", {
        theme: "colored",
      });
    } else {
      const res = await uploadUpdateperfil(
        image,
        persona_id,
        stateUsuario.fotoanterior
      );
      switch (res[0].sms) {
        case "ok":
          toast.success(res[0].mensaje, {
            theme: "colored",
          });
          setShowBottonImage(false);
          setDisableBtnFile(false);

          break;
        default:
          setDisableBtnFile(false);

          toast.error(res[0].mensaje, {
            theme: "colored",
          });

          break;
      }
    }
  };

  const onchangePassword = (e, valor) => {
    e.preventDefault();

    setStateUsuarioPassword({
      ...stateUsuarioPassword,
      [e.target.name]: e.target.value,
    });
  };

  const onchangePerson = (e, valor) => {
    e.preventDefault();

    if (e.target.name === "nombres") {
      //inicio if principal campo nombres ====================================================
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            stateUsuario.nombres[
              stateUsuario.nombres.length === 0
                ? stateUsuario.nombres.length
                : stateUsuario.nombres.length - 1
            ] !== " "
          ) {
            setstateUsuario({
              ...stateUsuario,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setstateUsuario({
            ...stateUsuario,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //inicio elseif principal campo apellidos ====================================================
    } else if (e.target.name === "apellidos") {
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            stateUsuario.apellidos[
              stateUsuario.apellidos.length === 0
                ? stateUsuario.apellidos.length
                : stateUsuario.apellidos.length - 1
            ] !== " "
          ) {
            setstateUsuario({
              ...stateUsuario,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setstateUsuario({
            ...stateUsuario,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "direccion") {
      if (
        e.target.value[
          e.target.value.length === 0
            ? e.target.value.length
            : e.target.value.length - 1
        ] === " "
      ) {
        if (
          stateUsuario.direccion[
            stateUsuario.direccion.length === 0
              ? stateUsuario.direccion.length
              : stateUsuario.direccion.length - 1
          ] !== " "
        ) {
          setstateUsuario({
            ...stateUsuario,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } else {
        setstateUsuario({
          ...stateUsuario,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "cedula") {
      console.log(e.target.value.length);
      if (soloPermitirNumeros.test(e.target.value)) {
        setstateUsuario({
          ...stateUsuario,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "telefono") {
      if (soloPermitirNumeros.test(e.target.value)) {
        setstateUsuario({
          ...stateUsuario,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "ciudad") {
      //inicio if principal campo nombres ====================================================
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            stateUsuario.ciudad[
              stateUsuario.ciudad.length === 0
                ? stateUsuario.ciudad.length
                : stateUsuario.ciudad.length - 1
            ] !== " "
          ) {
            setstateUsuario({
              ...stateUsuario,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setstateUsuario({
            ...stateUsuario,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //inicio elseif principal campo apellidos ====================================================
    } else {
      setstateUsuario({
        ...stateUsuario,
        [e.target.name]: e.target.value,
      });
    }

    if (valor === 1) {
      getProvinciasFn(e.target.value);
    }
  };

  useEffect(() => {
    //  console.log(URL.createObjectURL(ima))
    /*     const url = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBKEGmmEQ4WlpXIfdqhhaFbJER2pXMLOFU3A&usqp=CAU'
        const fileName = 'myFile.jpg'

        fetch(url)
            .then(async response => {
                const contentType = response.headers.get('content-type')
                const blob = await response.blob()
                const file = new File([blob], fileName, { contentType })

              console.log(file)
                setstateUsuario({
                    ...stateUsuario,
                    foto: file,
                })

                setState({
                    ...setState,
                    btnInfpFile: "Cambiar de archivo",
                    reader: URL.createObjectURL(file)

                })
            })*/

    //getCiudadesFn(provincia_id)
    initial();
  }, []);

  useEffect(() => {
    fotofn();
  }, []);

  const fotofn = async () => {
    setFotoanterior(foto);
    const firma = foto ? await firmarFoto(foto) : null;
    setImageLink(firma ? firma[0].data : null);
  };

  const initial = async () => {
    const respais = await getPaisesFn(pais_id);
    setPaises(respais[0].data);
    if (pais_id) {
      const resprovincia = await getProvinciasFn(pais_id);
      setProvincias(resprovincia[0].data);
    }
  };

  //FUNCION PARA EDITAR LOS DATOS DE LA VETERINARIA

  const editarinforPersona = async () => {
    if (stateUsuario.nombres === "") {
      alert("los nombres son oblgatorios");
    } else if (stateUsuario.apellidos === "") {
      alert("los apellidos son obligatorios");
    } else if (
      stateUsuario.telefono === "" ||
      stateUsuario.telefono.length !== 10
    ) {
      alert("se debe ingresa un telefono correcto (10 caracteres)");
    } else if (
      stateUsuario.correo === "" ||
      /.+@.+/.test(stateUsuario.correo) === false
    ) {
      alert("se debe ingresar un correo válido");
    } else if (stateUsuario.direccion === "") {
      alert("se debe ingresar una direccion");
    } else if (
      stateUsuario.provincia_id === null ||
      stateUsuario.provincia_id === ""
    ) {
      alert("Debe seleccionar la provincia");
    } else if (stateUsuario.ciudad === null || stateUsuario.ciudad === "") {
      alert("Se debe ingresar la ciudad");
    } else if (stateUsuario.genero === "") {
      alert("se debe seleccionar un genero");
    } else if (!navigator.onLine) {
      alert("El explorador no tiene acceso a la red");
    } else {
      try {
        const result = await Axios.post(`/editarPerson`, stateUsuario);
        if (result.data.sms === "ok") {
          Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
          //recarga para actualizar los datos del sesiondata
          window.location.reload();
        } else {
          Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const editarPasswordUsuario = async () => {
    console.log("asdas");
    if (stateUsuarioPassword.antiguopassword === "") {
      alert("Ingrese la antigua contraseña");
    } else if (stateUsuarioPassword.nuevopassword === "") {
      alert("Ingrese la nueva contraseña");
    } else {
      try {
        const result = await Axios.post(
          `/editUserAccount`,
          stateUsuarioPassword
        );
        if (result.data.sms === "ok") {
          Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
          //recarga para actualizar los datos del sesiondata
          window.location.reload();
        } else {
          Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div>
      <div className="">
        <div className="card-body row">
          <div className="col-md-3">
            <h5 className="text-dark"> Foto </h5>
            <hr />
            <div className="row">
              <div
                className="col-md-12 p-0 m-0"
                style={{ position: "relative", height: "200px" }}
              >
                <InputFile
                  getFiles={getFiles}
                  absolute="absolute"
                  opacidad="opacoUpload"
                  accept={"image/*"}
                />
                <img
                  src={ima ? ima : fotoanterior ? imagelink : nofoto}
                  alt=""
                  className="h-100 w-100 borde-tarjeta p-0"
                />
              </div>
              {ShowButtonsImage ? (
                <div className="d-flex flex-column justify-content-center my-2">
                  <button
                    className="bg-btn-vet w-100 my-1"
                    onClick={(e) => {
                      uploadEditFotoFn(e);
                    }}
                    disabled={disablebtnFile}
                  >
                    Guardar foto
                  </button>
                  <button
                    className="bg-btn-vet-danger w-100"
                    onClick={() => {
                      setShowBottonImage(false);
                      setIma(false);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              ) : null}
            </div>
            <hr className="my-3"></hr>
            <div className="row ">
              <div className="form-grou row d-flex justify-content-betweenp  w-100">
                <button
                  className="bg-btn-vet w-100"
                  onClick={(e) =>
                    setStateModal({ ...stateModal, nuevoPassword: true })
                  }
                >
                  <i className="fa fa-key"></i>Actualizar contraseña
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <h5 className="text-dark"> INFORMACIÓN DE USUARIO </h5>
            <hr />
            <div className="row ">
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Cédula* :
                    </label>
                  </div>
                  <div className="col-md-9">
                    {" "}
                    <input
                      type="text"
                      disabled
                      className="inputtext2"
                      onChange={onchangePerson}
                      value={stateUsuario.cedula}
                      id="cedula"
                      name="cedula"
                      placeholder="Ingrese la cedula"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Nombres* :
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="inputtext2"
                      onChange={onchangePerson}
                      value={stateUsuario.nombres}
                      id="nombres"
                      name="nombres"
                      placeholder="Ingrese los nombres"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Apellidos* :
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="inputtext2"
                      onChange={onchangePerson}
                      value={stateUsuario.apellidos}
                      id="apellidos"
                      name="apellidos"
                      placeholder="Ingrese los apellidos"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Genero* :
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="inputtext2 select2bs4"
                      id="genero"
                      name="genero"
                      style={{ width: "100%" }}
                      onChange={onchangePerson}
                      value={
                        stateUsuario.genero === null ? 0 : stateUsuario.genero
                      }
                    >
                      <option value="0">Seleccione ...</option>
                      <option value="M">MASCULINO</option>
                      <option value="F">FENENINO</option>
                      <option value="O">OTRO</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Correo* :
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      disabled
                      type="text"
                      className="inputtext2"
                      onChange={onchangePerson}
                      value={stateUsuario.correo}
                      id="correo"
                      name="correo"
                      placeholder="Ingrese el correo "
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Teléfono :
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="inputtext2"
                      onChange={onchangePerson}
                      value={stateUsuario.telefono}
                      id="telefono"
                      name="telefono"
                      placeholder="Ingrese el teléfono "
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="col-md-4">
                <div className="form-group row d-flex justify-content-between">
                  <label>Pais* :</label>
                  <select
                    className="inputtext2 select2bs4"
                    id="pais_id"
                    name="pais_id"
                    style={{ width: "100%" }}
                    onChange={(e) => onchangePerson(e, 1)}
                    value={stateUsuario.pais_id}
                  >
                    <option value="0">Seleccione ...</option>
                    {paises.map((data, index) => (
                      <option value={data.pais_id} key={index}>
                        {data.pais}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group row d-flex justify-content-between">
                  <label>Provincia* :</label>
                  <select
                    className="inputtext2 select2bs4"
                    id="provincia_id"
                    name="provincia_id"
                    style={{ width: "100%" }}
                    onChange={(e) => onchangePerson(e, 2)}
                    value={stateUsuario.provincia_id}
                  >
                    <option value="0">Seleccione ...</option>
                    {provincias.map((data, index) => (
                      <option value={data.provincia_id} key={index}>
                        {data.provincia}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group row d-flex justify-content-between">
                  <label clas htmlFor="ciudad">
                    Ciudad* :
                  </label>
                  <input
                    type="text"
                    className="inputtext2"
                    onChange={onchangePerson}
                    value={stateUsuario.ciudad}
                    id="ciudad"
                    name="ciudad"
                    placeholder="Ingrese la ciudad"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Código Postal :
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="inputtext2"
                      onChange={onchangePerson}
                      value={stateUsuario.c_postal}
                      id="c_postal"
                      name="c_postal"
                      placeholder="Ingrese el código postal "
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group row d-flex justify-content-between">
                  <div className="col-md-3">
                    <label clas htmlFor="exampleInputEmail1">
                      Dirección* :
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="inputtext2"
                      onChange={onchangePerson}
                      value={stateUsuario.direccion}
                      id="direccion"
                      name="direccion"
                      placeholder="Ingrese la dirección"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-5">
              <button
                className="bg-btn-vet w-100"
                onClick={editarinforPersona}
                disabled={disablebtnSave}
              >
                <i className="fa fa-save"></i>Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL CAMBIAR CONTRASEÑA*/}
      <Modal
        isOpen={stateModal.nuevoPassword}
        toggle={() => {
          setStateModal({
            ...stateModal,
            nuevoPassword: false,
          });
        }}
        style={{ maxWidth: "50%", padding: "20px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              nuevoPassword: false,
            });
          }}
          style={{ background: "white" }}
        >
          ACTUALIZAR CONTRASEÑA
        </ModalHeader>
        <ModalBody>
          <div className="col-md-12">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-md-3">
                <label clas htmlFor="exampleInputEmail1">
                  Contraseña Anterior* :
                </label>
              </div>
              <input
                type="password"
                className="inputtext2"
                onChange={onchangePassword}
                value={stateUsuarioPassword.antiguopassword}
                id="antiguopassword"
                name="antiguopassword"
                placeholder="Ingrese la antigua contraseña "
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-md-3">
                <label clas htmlFor="exampleInputEmail1">
                  Nueva Contraseña* :
                </label>
              </div>
              <input
                type="password"
                className="inputtext2"
                onChange={onchangePassword}
                value={stateUsuarioPassword.nuevopassword}
                id="nuevopassword"
                name="nuevopassword"
                placeholder="Ingrese la nueva contraseña "
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="bg-btn-vet"
            style={{ float: "right" }}
            onClick={editarPasswordUsuario}
          >
            <i className="fa fa-save"></i>Actualizar contraseña
          </button>
          <Button
            color="secondary"
            onClick={(e) => {
              setStateModal({
                ...stateModal,
                nuevoPassword: false,
              });
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      {/* FIN MODAL REGISTRO DE VETERINARIA*/}
    </div>
  );
};

export default ConfiguracionesUsuario;
