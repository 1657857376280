import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExportToExcel =({finalDataDetail,state }) => {
  const fileType = "xlsx";
  const exportToCSV = async() => {   
    console.log(finalDataDetail)
        const extra = await finalDataDetail.map(data=>{
            return {
              N_DOCUMENTO: data.numero,
              DOCUMENTO:"RECIBO",
              FECHA_VENTA: data.fecha_venta,
              CLIENTE: JSON.parse(data.comprador).razon_social,
              IDENTIFICACION_CLIENTE: data.identificacion_cliente,
              MEDIO_PAGO:JSON.parse(data.pagos)[0].medio,
              NOTA_PAGO:JSON.parse(data.pagos)[0].notas,
/*               DESCUENTO_TOTAL: data.total_descuento,
 */             /*  SUBTOTAL_IVA: data.subtotal_iva,
              SUBTOTAL_SIN_IVA: data.subtotal_siniva,
              SUBTOTAL_NO_OBJETO_IVA: data.subtotal_no_objeto_iva,
              SUBTOTAL_EXENTO_IVA:data.subtotal_exento_iva,
              IVA:data.subtotal_exento_iva, */
              TOTAL: data.valor_total,
            };
        })
    
        const product1 = XLSX.utils.json_to_sheet(extra);
        const wb = {Sheets:{product:product1}, SheetNames:["product"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"array"});
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, "reporte.xlsx")
  };

  return (
    <button
      onClick={exportToCSV}
      className="bg-btn-vet bordecompleto bordesombreado"
    >
      Exportar a Excel
    </button>
  );
};


export default ExportToExcel