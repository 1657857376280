import React from 'react'
const StilysedInput =(props)=>{

    return (
        <div className="form-outline">
            <label className="form-label" htmlFor={props.statePropertieName}>{props.labelText}:</label>
            <input
            type={props.inputType}
            id={props.statePropertieName}
            name={props.statePropertieName}
            className="form-control"
            value={props.stateManager[props.statePropertieName]}
            onChange={props.onChange}>
            </input>
      </div>
    )
}

export default StilysedInput