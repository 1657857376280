import React, { useState, useEffect } from "react";
import ModalDefault from "../../modalDefault";

import {
  getBancosPagosEmpresaApi,
  eliminarBancosPagosEmpresaApi,
} from "../../../api/contable/bancos_pagos";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
const TablaBancosPagos = (props) => {
  const [selectData, setselectData] = useState(null);
  const [dataCategorias, setDataCategoria] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadLista, setLoadLista] = useState(false);
  const [modalconfirmacion, setModalConfirmacion] = useState(false);
  const [fetching, setfetching] = useState(false);

  const columns = [
    {
      name: "Categoria",
      center: true,
      selector: (row) => row.categoria,
    },

    {
      name: "Opciones",
      center: true,
      cell: (row) => opciones(row),
    },
  ];

  const PAGE_SIZE = 7;
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [page]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [data]);

  const accionescategoria = (data) => {
    props.ejecutarOpciones(data,true);
  };

  const eliminarCategoriaFn = async (banco_pago_id) => {
    alert(banco_pago_id)
    const res = await eliminarBancosPagosEmpresaApi(banco_pago_id);
    if (res[0].sms === "ok") {
      getCategoriasEmpresa();
      setModalConfirmacion(false);
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
        theme:"colored"
      });
      selectData(null);
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
        theme:"colored"
      });
    }
  };

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={(e) => {
              accionescategoria(row);
            }}
          >
            editar
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger mx-1"
            onClick={() => {
              setselectData(row);
              setModalConfirmacion(true);
            }}
          >
            Eliminar
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    initComponent();
  }, [props.actualizarLista]);
  const getCategoriasEmpresa = async () => {
    setLoad(true);
    const res = await getBancosPagosEmpresaApi(props.veterinaria_id);
    if (res[0].sms === "ok") {
      toast.info(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      setdata(res[0].data);
      setRecords(res[0].data.slice(0, PAGE_SIZE));
    } else {
      toast.error(`${res[0].mensaje}`);
    }
    setLoad(false);
  };

  const initComponent = async () => {
    getCategoriasEmpresa();
  };

  const modalActions = (modal, bandera) => {
    setModalConfirmacion(modal);
  };

  const opcionesx = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="start">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              e.preventDefault()
              accionescategoria(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> editar
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              setselectData(row);
              setModalConfirmacion(true);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      <DataTable
        borderRadius="md"
        /* striped */
        /*  verticalAlignment="top" */
        className="bordecompleto bgfondo"
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        columns={[
          {
            accessor: "banco",
            title: "Nombre del banco",
            textAlignment: "left",
            render: ({ bancos }) => `${bancos.banco} (${bancos.activo?"Activo":"inactivo"})`,
          },
          {
            accessor: "numero",
            title: "Cuenta",
            textAlignment: "center",
            render: ({ numero }) => numero,
          },
          {
            accessor: "defecto",
            title: "Defecto",
            textAlignment: "center",
            render: ({ defecto }) => defecto===0?"NO":"SI",
          },
          {
            accessor: "create_at",
            title: "Fecha de cración",
            textAlignment: "center",
            render: ({ create_at }) => moment(create_at).format("MMM D YYYY"),
          },
          {
            accessor: "estado",
            textAlignment: "center",
            render: ({ activo }) => (activo ? "ACTIVO" : "INACTIVO"),
          },
          {
            accessor: "acciones",
            title: "Acciones",
            textAlignment: "center",
            render: (record) => opcionesx(record),
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        withColumnBorders
        fetching={fetching}
        rowContextMenu={{
          items: (record) => [
            // ...

            {
              key: "edit",
              color: "blue",
              icon: <i className="fas fa-edit"></i>,
              title: `Editar cuenta ${record.numero}`,
              onClick: () => {
                accionescategoria(record);
              },
            },
            {
              key: "delete",
              color: "red",
              icon: <i className="fas fa-trash"></i>,
              title: `Eliminar cuenta ${record.numero}`,
              onClick: (e) => {
                setselectData(record);
                setModalConfirmacion(true);
              },
            },

            // ...
          ],
        }}
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalconfirmacion}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActions}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará la cuenta{" "}
            {selectData ? ` ${selectData.numero} del banco ${selectData.bancos.banco}` : null}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => setModalConfirmacion(false)}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectData !== null) {
                  eliminarCategoriaFn(selectData.banco_pago_id);
                } else {
                  toast.error("No existe el indice de la cuenta bancaria , actualize el explorador",{
                    theme:"colored",
                    position:"bottom-left"
                  });
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      {/*  <ModalDefault bandera={"otracosa"} modal={false} size={"sm"} icontitulo={<AdminIcon/>} titulo={"Titulo modal"} closeAction={modalActions}>
                modal de editar usuario
            </ModalDefault> */}
    </>
  );
};

export default TablaBancosPagos;
