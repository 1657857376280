import CryptoJS  from "crypto-js";

export const encrypt =(data)=>{
    var ciphertext = CryptoJS.AES.encrypt(data.toString(), `${process.env.SECRETAES}`).toString();
    return ciphertext

}

export const decrypt =(data)=>{
    var bytes  = CryptoJS.AES.decrypt(data, `${process.env.SECRETAES}`);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData

}



