import React, { useEffect, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import AuthContext from "../../../Context/auth/authContext";
import moment from "moment";
import { getPlanes } from "../../../api/veterinarias";
import { toast } from "react-toastify";

const Plan = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli } = AuthenticationContext;

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const { usuario_id, nombres, correo, color, foto } =
    sessiondatacli[0].persona[0];
  const { logo, atencion, veterinaria_id, nombre_comercial, type } =
    sessiondatacli[0].veterinaria[0];

  const [plan, setPlan] = useState([]);

  useEffect(() => {
    getPlanefn();
  }, []);

  const getPlanefn = async () => {
    const resplanes = await getPlanes(veterinaria_id);
    console.log(resplanes);
    if (resplanes.data.sms === "ok") {
      setPlan(resplanes.data.data);
    } else {
      toast.error(resplanes.data.mensaje);
    }
  };

  return (
    <>
      {plan.length > 0
        ? plan.map((data) => {
            return (
              <div className="row p-2">
                <div className="col-md-12 d-flex justify-content-between p-2">
                  <label for="" className="bordecompleto bordecircular p-2">
                    {data.planes.plan} {data.planes.tipo} (
                    {data.planes.documentos}) DOCUMENTOS
                  </label>
                  <label for="" className="bordecompleto bordecircular p-2">
                    EXPIRA :{" "}
                    {moment(data.fecha_expiracion).format("YYYY-MM-DD")}
                  </label>
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};

export default Plan;
