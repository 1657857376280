import React, { useState, useEffect, useContext } from "react";
//import InputFile2 from "../../inputfile2";
import ModalDefault from "../../modalDefault";
import { useDropzone } from "react-dropzone";
import XMLParser from "react-xml-parser";
import xml2js from "xml-js";

import InputFile from "../../inputFile";
import TablaFiles from "./tablaFiles";
import {
  getCategoriasEmpresaApi,
  eliminarCategoriasEmpresaApi,
} from "../../../api/contable/categorias";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import AuthContext from "../../../Context/auth/authContext";

import moment from "moment";
const TablaComprobantesComprasXML = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];
  const [selectData, setselectData] = useState(null);
  const [dataArchivos, setDataArchivos] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadLista, setLoadLista] = useState(false);
  const [modalconfirmacion, setModalConfirmacion] = useState(false);
  const [fetching, setfetching] = useState(false);
  const [actualizarLista, setActualizarLista] = useState(false);

  const columns = [
    {
      name: "Categoria",
      center: true,
      selector: (row) => row.categoria,
    },

    {
      name: "Opciones",
      center: true,
      cell: (row) => opciones(row),
    },
  ];

  const PAGE_SIZE = 7;
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [page]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [data]);

  const accionescategoria = (data) => {
    props.ejecutarOpciones(data, true);
  };

  const eliminarCategoriaFn = async (categoria_id) => {
    const res = await eliminarCategoriasEmpresaApi(categoria_id);
    if (res[0].sms === "ok") {
      getCategoriasEmpresa();
      setModalConfirmacion(false);
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      selectData(null);
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={(e) => {
              accionescategoria(e, row);
            }}
          >
            editar
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger mx-1"
            onClick={() => {
              setselectData(row);
              setModalConfirmacion(true);
            }}
          >
            Eliminar
          </button>
        </div>
      </>
    );
  };

  const getCategoriasEmpresa = async () => {
    setLoad(true);
    const res = await getCategoriasEmpresaApi(props.veterinaria_id);
    if (res[0].sms === "ok") {
      toast.info(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      setdata(res[0].data);
      setRecords(res[0].data.slice(0, PAGE_SIZE));
    } else {
      toast.error(`${res[0].mensaje}`);
    }
    setLoad(false);
  };

  const getFiles2 = async (files) => {
    if (files.length > 0) {
      const auxdata = [...dataArchivos];
              let indexdata = 0;

      files.forEach(async (file, index) => {
        var n = file.path.lastIndexOf(".");
        var subtype = file.path.substring(n + 1);
        if (subtype === "xml") {
          const reader = new FileReader();
          reader.addEventListener(
            "loadend",
            async () => {
              // this will then display a text file
              const xml = await new XMLParser().parseFromString(
                `${reader.result}`
              );
              const x = xml.children
                .filter((child) => child.name === "infoTributaria")[0]
                .children.filter(
                  (child) => child.name === "claveAcceso"
                )[0].value;
                console.log(x)
              xml.indexrecords= `${x}`
             
              auxdata.push(xml);
            },
            false
          );
          //reader.addEventListener("progress", (result) => console.log(result));

          if (file) {
            reader.readAsText(file);
          }
          //var xml = await new XMLParser().parseFromString(`${xmlText}`); //
          //var result = xml2js.xml2json(, { compact: true, spaces: 4 });
          //console.log(xml)
        }
      });
      
      
       setTimeout(()=>setDataArchivos(auxdata),2000);

      //      const aux = await res.filter((data) => data !== null);

      /* const res = await uploadDocuments(files);
      console.log("********");
      console.log(res);
      setDataArchivos(res); */
    }
  };

  const ejecutaropciones = (data, editar) => {
    if (editar === true) {
    }
  };
  return (
    <>
      <div className="col-md-12 m-0 p-0 ">
        <div className="row  d-flex flex-column ">
          <div
            className="d-flex justify-content-start bgfondo align-items-center"
            style={{ height: "60px" }}
          >
            <h5 className="titulosubmenusize">PANEL COMPRAS XML</h5>
          </div>

          <div className="py-5 d-flex justify-content-center align-items-center h-100  ">
            <InputFile
              getFiles={getFiles2}
              absolute=""
              opacidad=""
              accept={{ "text/xml": [".xml"] }}
            />
          </div>
          <div>
            <TablaFiles
              veterinaria_id={veterinaria_id}
              ejecutarOpciones={ejecutaropciones}
              actualizarLista={actualizarLista}
              dataArchivos={dataArchivos}
            ></TablaFiles>
          </div>
        </div>
      </div>
    </>
  );
};

export default TablaComprobantesComprasXML;
