import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Perfil from "./perfil";
import Atp from "./atp/atp";
import Vacunas from "./vacumas/vacunas";
import FichasMedicas from "./HistoriasMedicas/index";

const Index = (props) => {
  const { mascota_id} = useParams();



  useEffect(() => {
  }, []);

  return (
    <>
      <div className="row h-100  bgfondo2">
        <div className="col-md-4 py-2 ">
          <Perfil mascota={atob(mascota_id)}></Perfil>
        </div>
        <div className="col-md-8 h-100 m-0 p-0  ">
          <div className="row  contentidopanel h-auto">
            <div className="col-md-6 my-2">
              <Atp mascota={atob(mascota_id)}></Atp>
            </div>
            <div className="col-md-6 my-2">
              <Vacunas mascota={atob(mascota_id)}></Vacunas>
            </div>
            <div className="col-md-12">
              <FichasMedicas mascota={atob(mascota_id)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
