import Axios from "../../config/AxiosContable";

export const crearPagoAfiliadoApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTCrearPagoAfiliado`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const actualizarEstadoPagoAfiliadoApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTActualizarEstado`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const getPagosAfiliadoEmpresaApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTGetPagosAfiliadoEmpresa`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};
