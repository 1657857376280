import React, { useState, useContext } from "react";
import AuthContext from "../../../Context/auth/authContext";
import TablaCategorias from "./tabla";
import CrearEditarCategoriaServicio from "./crearEditar";

const Categorias = () => {
  const { sessiondatacli, index } = useContext(AuthContext);
  const veterinariaId = sessiondatacli[index].veterinaria[0].veterinaria_id;

  const [actualizarLista, setActualizarLista] = useState(false);
  const [categoria, setCategoria] = useState({
    categoria: "",
    descripcion: "",
    afiliado_categoria_id: "",
    empresa_id: veterinariaId,
  });
  const [editar, setEditar] = useState(false);

  const resetCategoria = () => {
    setCategoria({
      categoria: "",
      descripcion: "",
      afiliado_categoria_id: "",
      empresa_id: veterinariaId,
    });
  };

  const toggleActualizarLista = () => setActualizarLista(!actualizarLista);

  return (
    <div className="col-md-12 m-0 p-0 ">
      <div className="row  d-flex flex-column ">
        <CrearEditarCategoriaServicio
          editar={editar}
          actualizarLista={toggleActualizarLista}
          categoria={categoria}
          setCategoria={setCategoria}
          resetCategoria={resetCategoria}
          setEditar={setEditar}
        />
        <div>
          <TablaCategorias
            veterinaria_id={veterinariaId}
            actualizarLista={actualizarLista}
            setEditar={setEditar}
            setCategoria={setCategoria}
            empresa={veterinariaId}
          />
        </div>
      </div>
    </div>
  );
};

export default Categorias;
