import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { cargarTodosLosModulosActivos } from "../../api/modulos";
import DataTableDefault from "../ReactDataTable";
import AuthContext from "../../Context/auth/authContext";
import { DataTable } from "mantine-datatable";
import moment from "moment";

const Modulos = () => {
    const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [modulos, setRoles] = useState([]);
  const columns = [
    {
      name: "Rol",
      center: true,
      selector: (row) => row.modulos.modulo,
    },
    {
      name: "Estado",
      center: true,
      selector: (row) => row.activo?"Activo":"Inactivo",
    },

   /*  {
      name: "Opciones",
      center: true,
      cell: (row) => opciones(row),
    }, */
  ];
const PAGE_SIZE = 10;
const [page, setPage] = useState(1);
const [data, setdata] = useState([]);
const [records, setRecords] = useState([]);

useEffect(() => {
  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;
  setRecords(data.slice(from, to));
}, [page]);

  const init = async () => {
    const res = await cargarTodosLosModulosActivos(veterinaria_id);
    if (res[0].sms === "ok") {
      setRoles(res[0].data);
            setRecords(res[0].data.slice(0, PAGE_SIZE));

    } else {
      toast.error(`${res[0].mensaje}`);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div
      className="col-md-12 m-0 p-0
     d-flex justify-content-start flex-column align-items-center"
    >
      <div className="row w-100 pb-3 d-flex flex-column align-items-start justify-content-start">
        <label
          for=""
          className="titulosubmenusize"
          style={{ textAlign: "start" }}
        >
          Módulos de empresa
        </label>
        <label for="" style={{ color: "blue", textAlign: "start" }}>
          Los modulos listado son los que se encuentran actualmente activos en
          la empresa y se muestran en la cabecera del sistema !{" "}
        </label>
      </div>
      <div className="bg-white w-100">
        <DataTable
          withBorder
          highlightOnHover
          minHeight={150}
          noRecordsText="No se encontraron datos"
          height="auto"
          records={modulos}
          columns={[
            { accessor: "modulo",title:"Módulo", textAlignment: "left" ,render: ({ modulos }) =>modulos.modulo},
            {
              accessor: "create_at",
              title:"Fecha de cración",
              textAlignment: "center",
              //width: 120,
              render: ({ create_at }) => moment(create_at).format("MMM D YYYY"),
            },
            {
              accessor: "estado",
              textAlignment: "center",
              //width: 120,
              render: ({ activo }) => (activo ? "ACTIVO" : "INACTIVO"),
            },
           /*  {
              accessor: "acciones",
              title: "Acciones",
              textAlignment: "center",
              render: (record) => (
                <div>
                  <button
                    type=""
                    onClick={() => {
                      
                    }}
                  >
                    Editar
                  </button>
                  <button
                    type=""
                    onClick={() => {
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              ),
            }, */
          ]}
          totalRecords={modulos.length}
          recordsPerPage={PAGE_SIZE}
          page={page}
          onPageChange={(p) => setPage(p)}
          withColumnBorders
          rowContextMenu={{
            items: (record) => [
              // ...
              {
                key: "delete",
                color: "red",

                title: `Delete company ${record.name}`,
                onClick: () => {
                  alert(`${record.bien_id}`);
                },
              },
              {
                key: "edit",
                color: "red",

                title: `Delete company ${record.name}`,
                onClick: () => {
                  alert(`${record.bien_id}`);
                },
              },

              // ...
            ],
          }}
        />
      </div>
    </div>
  );
};

export default Modulos;
