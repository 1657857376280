import React, { useContext, useEffect, useState, useRef } from "react";
import Axios from "../../../config/Axios";
import Swal from "sweetalert2";
import PacienteContext from "../../../Context/pacientes/pacientesContext";
import AuthContext from "../../../Context/auth/authContext";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { toast } from "react-toastify";
import TextareaAutosize from "react-textarea-autosize";

const Publicar = (props) => {
  const toastId = useRef(null);
  const PacientesContext = useContext(PacienteContext);
  const { mismascotas } = PacientesContext;

  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const [state, setState] = useState({
    mascota_id: 0,
    descripcion: "",
    usuario_id: sessiondatacli[index].persona[0].usuario_id,
    veterinaria_id: sessiondatacli[index].veterinaria[0].veterinaria_id,
  });

  const [img, setCom] = useState({
    imagenes: [],
    todosArchivos: [],
    reader: "",
  });

  const [stateActual, setStateActual] = useState({
    nombreMascota: "",
  });

  const [statepanel, setStatepanel] = useState({
    dispaly: "none",
  });

  const onchange = (e, valor) => {
    e.preventDefault();
    if (valor === 1) {
      let index = e.target.selectedIndex;
      /* console.log(e.target.options[index].text) */
      setStateActual({
        ...stateActual,
        nombreMascota: e.target.options[index].text,
      });
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const Input = () => {
    return (
      <label
        style={{
          textAlign: "center",
          backgroundColor: "none",
          color: "#fff",
          cursor: "pointer",
          padding: 5,
          borderRadius: 3,
          margin: "0px",
        }}
      >
        <input
          style={{ display: "none" }}
          type="file"
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              setStatepanel({
                ...statepanel,
                dispaly: "block",
              });
              let aux = [];
              if (chosenFiles && chosenFiles.length > 0) {
                aux = img.imagenes;
                for (let x = 0; x < chosenFiles.length; x++) {
                  let reader = URL.createObjectURL(chosenFiles[x]);
                  let tipodearchivo = chosenFiles[x].type.substring(
                    0,
                    chosenFiles[x].type.indexOf("/")
                  );
                  console.log(tipodearchivo);
                  let todo = {
                    imagen: chosenFiles[x],
                    reader: reader,
                    type: tipodearchivo,
                  };
                  aux.push(todo);
                }
              } else {
                aux = [];
              }

              setCom({
                ...img,
                imagenes: aux,
              });
              //console.log(aux.length)
              aux = [];
            });
          }}
          accept="image/jpeg,image/gif,image/png,video/*"
        />
        <i
          className="fas fa-image"
          style={{ fontSize: "30px", color: "blue" }}
        ></i>
      </label>
    );
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        console.log("archivos" + chosenFiles[0].name);
        setCom({
          ...img,
          imagenes: chosenFiles,
        });
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const OnSubtmit = async (e) => {
    e.preventDefault();
    try {
      if (state.mascota_id === "" || state.mascota_id === null) {
        toast.error("Selecciona una de tus mascotas",{
          theme:"colored"
        })
      } else if (img.imagenes.length == 0) {
        alert("Debes abjuntar almenos una imagen");
      } else {
        var cont = 0;
        img.imagenes.map((data, index) => {
          console.log(data.type);
          switch (data.type) {
            case "image":
              return (cont = cont + 0);
            case "video":
              return (cont = cont + 0);
            default:
              return cont++;
          }
        });
        if (cont === 0) {
          const formdata = new FormData();
          img.imagenes.forEach((element) => {
            formdata.append("file", element.imagen);
          });
          formdata.append("descripcion", state.descripcion);
          formdata.append("usuario_id", state.usuario_id);
          formdata.append("mascota_id", state.mascota_id);
          formdata.append("veterinaria_id", state.veterinaria_id);

          const result = await Axios.post(
            "/createPublicacionMascota",
            formdata,
            {
              onUploadProgress: (p) => {
                const progress = p.loaded / p.total;
                const percento = Math.round((100 * p.loaded) / p.total);
                if (toastId.current === null) {
                  toastId.current = toast(`Procesando... (${percento}%)`, {
                    type: toast.TYPE.INFO,
                    theme: "colored",
                    autoClose: progress * 1000,
                    progress,
                  });
                } else {
                  toast.update(toastId.current, {
                    type: toast.TYPE.INFO,
                    render: `Procesando... (${percento}%)`,
                    theme: "colored",
                    autoClose: progress * 1000,
                  });
                }
              },
            }
          );
          toast.dismiss()
          if (result.data.sms === "ok") {
            toast.success(`${result.data.mensaje}`)
            setCom({
              ...img,
              imagenes: [],
            });
            setState({
              ...state,
              mascota_id: "",
              descripcion: "",
              pp_id: "",
            });

            props.cerrarModal();
          } else {
            toast.error(`${result.data.mensaje}`)
          }
        } else {
          toast.success( `Detectamos formatos no permitidos , revisa  los archivos que estas intentando subir !`,{
            autoClose:5000
          }
          )

          
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeItemFromArr = (e, indice) => {
    // var i = img.imagenes.indexOf(item);
    e.preventDefault();
    if (indice !== -1) {
      img.imagenes.splice(indice, 1);
      setCom({
        ...img,
        imagenes: img.imagenes,
      });
    }

    if (img.imagenes.length === 0) {
      setStatepanel({
        ...statepanel,
        dispaly: "none",
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <section className="">
        <>
          <form className="my-0">
            <div className="col-md-12 mb-5">
              <div className="row">
                <>
                  <div className="col-md-12">
                    <div className="form-group">
                      <TextareaAutosize
                        rows="2"
                        className="textareapublicar"
                        onChange={(e) => onchange(e)}
                        value={state.descripcion}
                        id="descripcion"
                        name="descripcion"
                        placeholder={
                          "publica en el muro de " +
                          sessiondatacli[index].veterinaria[0].nombre
                        }
                        style={{
                          height: "70px!important",
                          width: "100%",
                          border: "none",
                        }}
                      />{" "}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className="row p-5 "
                      style={{
                        textAlign: "center",
                        maxHeight: "250px",
                        overflowY: "scroll",
                        display: statepanel.dispaly,
                      }}
                    >
                      {img.imagenes.map((data, indice) => (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 floatizquierda">
                          {data.type === "video" ? (
                            <video
                              style={{ width: "100%", height: "200px" }}
                              controls
                              poster="/imagen-preview.png"
                            >
                              <source src={data.reader} />
                            </video>
                          ) : (
                            <img
                              className="img-responsive"
                              style={{ width: "100%", height: "200px" }}
                              src={data.reader}
                              alt
                            />
                          )}
                          <div
                            className=""
                            style={{
                              position: "relative",
                              top: "-215px",
                              right: "-70px",
                            }}
                          >
                            <button
                              style={{
                                background: "red",
                                padding: "5px 9px",
                                borderRadius: "20px",
                              }}
                              onClick={(e) => removeItemFromArr(e, indice)}
                            >
                              <i
                                className="fas fa-trash"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className="row mx-12 d-flex justify-content-center align-items-center"
                      style={{ border: "1px solid #f0f2f5" }}
                    >
                      <div>
                        <h5 style={{ textAlign: "center" }}>
                          Agrega un archivo{" "}
                        </h5>
                      </div>
                      <Input></Input>
                    </div>
                  </div>
                </>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-success"
              style={{
                float: "right",
                cursor:
                  state.descripcion !== "" && state.mascota_id !== ""
                    ? ""
                    : "no-drop",
              }}
              onClick={(e) => OnSubtmit(e)}
            >
              Publicar
            </button>
          </form>
        </>
      </section>
    </>
  );
};

export default Publicar;
