import React, { useState, useEffect, useContext } from "react";
import { Form, FormGroup, Input, Label, Button, Col } from "reactstrap";
import {
  crearAfiliadoSocioApi,
  crearEditarAfiliadoSocioApi,
  verificarAfiliadoSocioApi,
} from "../../../api/afiliaciones_socios";

import { getTipoPago } from "../../../api/contable/configuraciones";
import { GetAfiliadosServiciosEmpresaApi } from "../../../api/afiliaciones_socios/servicios";
import { toast } from "react-toastify";
import AuthContext from "../../../Context/auth/authContext";
import TextAreaAutosize from "react-textarea-autosize";
import { getAllCategoriasServiciosApi } from "../../../api/afiliaciones_socios/categorias";
const CrearEditarAfiliado = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;
  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];
  const [editar, setEditar] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [esServicio, setesServicio] = useState(false);
  const [formasPagos, setFormasPagos] = useState([]);
  const [isRecordExist, setisRecordExist] = useState({
    authorize: false,
    mensaje:
      "Por favor ingrese la identificación del afiliado/socio a verificar. Presione enter en el campo de identificación",
  });
  const [loadingSaveRecord, setLoadingSaveRecord] = useState(false);
  const [afiliadosServicios, setAfiliadosServicios] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const [state, setState] = useState({
    cedula: "",
    ruc: "",
    tipo_identificacion: "",
    identificacion: "",
    nombres: "",
    apellidos: "",
    direccion: "",
    telefono: "",
    correo: "",
    numero_afiliacion: "",
    fecha_afiliacion: "",
    afiliado_id: "",
    empresa_id: veterinaria_id,
    forma_pago: "",
    cta_bancaria: "",
    cta_institucion: "",
    aplica_categoria: true,
    afiliado_categoria_id: "",
    servicio_afiliado_config_id: "",
    valor: "",
    detalle_pago: "",
    codigo_producto: "",
    descripcion_producto: "",
  });
  const [editState, setEditState] = useState({
    campoedit: "",
    nuevovalor: "",
    id: "",
  });

  const [editStatereset, setEditStatereset] = useState({
    campoedit: "",
    nuevovalor: "",
    id: "",
  });

  const [respaldoEditField, setRespaldoEditField] = useState("");
  const habilitarEdicion = (e, edit) => {
    e.preventDefault();
    if (editState.campoedit === "") {
      setEditState({
        ...editState,
        campoedit: edit,
        id: props.selectData.producto_id,
      });
      setRespaldoEditField(state[edit]);
    } else {
      alert(
        "otro campo se encuentra en edicion , por favor guarde o cancele los cambios para continuar "
      );
    }
  };
  const cancelarEdicion = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [editState.campoedit]: respaldoEditField,
    });
    setEditState(editStatereset);
    setesServicio(false);
    setRespaldoEditField("");
  };

  const editarFn = async (e) => {
    e.preventDefault();
    setButtonState(true);
    const res = await crearEditarAfiliadoSocioApi(editState);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, {
        theme: "colored",
      });
      setButtonState(false);
      setEditState(editStatereset);
    } else {
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
      });
      setButtonState(false);
    }
  };

  const onchange = async (e) => {
    const update = {
      ...state,
      [e.target.name]: e.target.value,
    };

    if (e.target.name === "servicio_afiliado_config_id") {
      const serv = servicios.find(
        (data) => data.servicio_afiliado_config_id === parseInt(e.target.value)
      );
      update.detalle_pago = serv.detalle_pago;
      update.valor = serv.valor;
      update.codigo_producto = serv.productos.codigo_producto;
      update.descripcion_producto = serv.productos.descripcion_producto;
    }

    setState((prevState) => ({
      ...prevState,
      ...update,
    }));
  };

  const guardarRow = (e) => {
    e.preventDefault();
    if (state.identificacion === "") {
      toast.error(`Debe agregar el número de identificacion`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    if (state.nombres === "") {
      toast.error(`Debe agregar los nombres completos`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    if (state.apellidos === "") {
      toast.error(`Debe agregar los apellidos completos`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    /*
    if (state.telefono === "") {
      toast.error(`Debe registrar un número de teléfono`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (state.fecha_afiliacion === "") {
      toast.error(`Debe ingresar la fecha de afiliación`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    */

    /*  if (state.forma_pago === "") {
      toast.error(`Debe ingresar la forma de pago.`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    } */
    if (state.servicio_afiliado_config_id === "") {
      toast.error(
        `El servicio de afiliacion es obligatorio , se debe crear un servicio de tipo REGISTRO`,
        {
          theme: "colored",
          position: "bottom-left",
        }
      );
      return;
    }
    saveRecord();
  };

  const saveRecord = async () => {
    setLoadingSaveRecord(true);
    const respuesta = await crearAfiliadoSocioApi(state);
    if (respuesta.sms === "ok") {
      toast.success(
        respuesta.mensaje,
        { position: "bottom-left" },
        {
          theme: "colored",
          position: "bottom-left",
        }
      );
      cerrarModalActualizarTabla();
    } else {
      toast.error(
        respuesta.mensaje,
        { position: "bottom-left" },
        {
          theme: "colored",
          position: "bottom-left",
        }
      );
    }
    setLoadingSaveRecord(false);
  };

  const cerrarModalActualizarTabla = () => {
    props.crearrModalActualizar();
  };

  useEffect(() => {
    getAfiliadosServiciosFn();
    initTipoPago();
    if (props.selectData) {
      setEditar(true);
      initEditar();
    }
  }, []);

  const initTipoPago = async () => {
    const res = await getTipoPago();
    //console.log(res);
    if (res[0].sms === "ok") {
      setFormasPagos(res[0].data);
    } else {
      setFormasPagos([]);
    }
  };

  const verificarAfiliadofN = async () => {
    try {
      const res = await verificarAfiliadoSocioApi(state);
      //console.log(res);
      const { sms, mensaje } = res;
      if (sms === "ok") {
        setisRecordExist({
          ...isRecordExist,
          authorize: true,
        });
      } else {
        setisRecordExist({
          ...isRecordExist,
          authorize: false,
          mensaje: mensaje,
        });
      }
    } catch (error) {
      console.error("Error en verificarAfiliadofN:", error);
    }
  };

  const initEditar = () => {
    setisRecordExist({
      ...isRecordExist,
      authorize: true,
    });
    console.log(props.selectData);
    setState({
      ...state,
      cedula: props.selectData.cedula,
      ruc: props.selectData.ruc,
      nombres: props.selectData.nombres,
      apellidos: props.selectData.apellidos,
      direccion: props.selectData.direccion,
      telefono: props.selectData.telefono,
      correo: props.selectData.correo,
      numero_afiliacion: props.selectData.numero_afiliacion,
      fecha_afiliacion: props.selectData.fecha_afiliacion,
      afiliado_id: props.selectData.afiliado_id,
      tipo_identificacion: props.selectData.tipo_identificacion,
      identificacion: props.selectData.identificacion,
      afiliado_categoria_id: props.selectData.afiliado_categoria_id,
      servicio_afiliado_config_id: props.selectData.servicio_afiliado_config_id,
      valor: props.selectData.valor,
    });

    /* setEditState({
      ...editState,
      id: props.selectData.afiliado_id,veterinaria_id
    }); */
  };

  const getAfiliadosServiciosFn = async () => {
    const respuesta = await GetAfiliadosServiciosEmpresaApi(veterinaria_id);
    const servicios = respuesta?.data.filter(
      (data) => data.tipo_servicio === "REGISTRO_SOCIOS"
    );
    /* const cat = [];
    servicios.forEach((data) => {
      if (
        !cat.some(
          (item) => item.afiliado_categoria_id === data.afiliado_categoria_id
        )
      ) {
        cat.push(data.afiliado_categoria);
      }
    });

    setCategorias(cat); */
    setAfiliadosServicios(servicios);

    if (servicios.length > 0) {
      setState({
        ...state,
        servicio_afiliado_config_id: servicios[0].servicio_afiliado_config_id,
      });
    } else {
      setState({
        ...state,
        servicio_afiliado_config_id: "",
      });
    }
  };

  const onKeyDonwVerificarIdentificacionAfiliado = (e) => {
    if (state.tipo_identificacion === "") {
      toast.error(`Debe seleccionar un tipo de identificación`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    if (e.keyCode === 13) {
      e.preventDefault();
      verificarAfiliadofN();
    }
  };

  const validaDisabledInput = () => {
    return editar === true || isRecordExist.authorize ? false : true;
  };

  useEffect(() => {
    setServicios(
      afiliadosServicios.filter(
        (data) =>
          data.afiliado_categoria_id === parseInt(state.afiliado_categoria_id)
      )
    );
  }, [state.afiliado_categoria_id]);

  return (
    <>
      {afiliadosServicios.length > 0 ? (
        <Form inline className="w-100">
          <div className="row">
            <label for="">Registro de afiliación: $</label>(
            {afiliadosServicios[0].valor}{" "}
          </div>
          <div className="row bgfondo2 py-3 bordecircular">
            <div className="col-md-6">
              <div className="">
                <label className="negrita">Tipo de identificación :</label>
                <select
                  className=" inputselect bordecompleto "
                  id="tipo_identificacion"
                  name="tipo_identificacion"
                  style={{ width: "100%" }}
                  onChange={(e) => onchange(e)}
                  value={state.tipo_identificacion}
                  required={true}
                  disabled={validaDisabledInput}
                >
                  <option value={""} key={index}>
                    Seleccione ...
                  </option>
                  <option value={"05"} key={index}>
                    Cédula
                  </option>
                  {/*  <option value={"04"} key={index}>
              Ruc
            </option> */}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label>Identificación</label>
                <div className={"d-flex justify-content-between"}>
                  {" "}
                  <input
                    className="inputtext bordecompleto"
                    disabled={validaDisabledInput}
                    id="identificacion"
                    name="identificacion"
                    value={state.identificacion}
                    placeholder="Ingrese el valor de la identificación"
                    type="text"
                    onChange={onchange}
                    onKeyDown={(e) =>
                      onKeyDonwVerificarIdentificacionAfiliado(e)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {!isRecordExist.authorize ? (
            <div className="d-flex row justify-content-center align-items-center">
              <div
                className="col-md-6 my-3 w-100 p-2"
                style={{ background: "red" }}
              >
                <h5
                  for=""
                  style={{ opacity: "1", textAlign: "center", color: "white" }}
                >
                  {isRecordExist.mensaje}
                </h5>
              </div>
            </div>
          ) : (
            <div className="row my-2">
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Nombres completos:</label>
                  <div className={"d-flex justify-content-between"}>
                    {" "}
                    <input
                      className="inputtext bordecompleto"
                      disabled={validaDisabledInput}
                      id="nombres"
                      name="nombres"
                      value={state.nombres}
                      placeholder="Ingrese los nombres"
                      type="text"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Apellidos completos:</label>
                  <div className={"d-flex justify-content-between"}>
                    {" "}
                    <input
                      className="inputtext bordecompleto"
                      disabled={validaDisabledInput}
                      id="apellidos"
                      name="apellidos"
                      value={state.apellidos}
                      placeholder="Ingrese los apellidos"
                      type="text"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Teléfono movil:</label>
                  <div className={"d-flex justify-content-between"}>
                    {" "}
                    <input
                      className="inputtext bordecompleto"
                      disabled={validaDisabledInput}
                      id="telefono"
                      name="telefono"
                      value={state.telefono}
                      placeholder="Ingrese el teléfono"
                      type="text"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Correo:</label>
                  <div className={"d-flex justify-content-between"}>
                    {" "}
                    <input
                      className="inputtext bordecompleto"
                      disabled={validaDisabledInput}
                      id="correo"
                      name="correo"
                      value={state.correo}
                      placeholder="Ingrese el correo"
                      type="text"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  <label>Dirección:</label>
                  <div className={"d-flex justify-content-between"}>
                    {" "}
                    <TextAreaAutosize
                      className="inputtext bordecompleto"
                      disabled={validaDisabledInput}
                      id="direccion"
                      name="direccion"
                      value={state.direccion}
                      placeholder="Ingrese la dirección"
                      type="date"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
              <div className="bgfondo2 m-0 p-0 my-2 row py-3 bordecircular ">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Fecha de afiliación:</label>
                    <div className={"d-flex justify-content-between"}>
                      {" "}
                      <input
                        className="inputtext bordecompleto"
                        disabled={validaDisabledInput}
                        id="fecha_afiliacion"
                        name="fecha_afiliacion"
                        value={state.fecha_afiliacion}
                        placeholder="Ingrese la fecha afiliación"
                        type="date"
                        onChange={onchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Número de afiliación:</label>
                    <div className={"d-flex justify-content-between"}>
                      {" "}
                      <input
                        className="inputtext bordecompleto"
                        disabled={validaDisabledInput}
                        id="numero_afiliacion"
                        name="numero_afiliacion"
                        value={state.numero_afiliacion}
                        placeholder="Ingrese el número de afiliación"
                        type="text"
                        onChange={onchange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-start flex-column">
                {/*
            <FormGroup
              switch
              className="d-flex justify-content-start align-items-center"
            >
              <Input
                style={{ width: "50px", height: "20px" }}
                id="aplica_categoria"
                name="aplica_categoria"
                type="switch"
                checked={state.aplica_categoria}
                onClick={() => {
                  setState({
                    ...state,
                    aplica_categoria: !state.aplica_categoria,
                  });
                }}
              />
              <Label className="mx-2" check>
                Aplicar categoria de socios
              </Label>
            </FormGroup>
            */}
                {/*   <div className="col-md-12 d-flex flex-column">
              <label className="negrita">
                Seleccione una categoria de socios.
              </label>
              <div className="form-group d-flex justify-content-center">
                <select
                  select
                  className=" inputselect2 bordecompleto"
                  id="afiliado_categoria_id"
                  name="afiliado_categoria_id"
                  style={{ width: "100%" }}
                  onChange={onchange}
                  value={state.afiliado_categoria_id}
                >
                  <option value={""}>Seleccione</option>
                  {categorias.length > 0 &&
                    categorias.map((data, index) => {
                      return (
                        <option value={data.afiliado_categoria_id} key={index}>
                          {data.categoria}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div> */}
              </div>
              {/*  <div className="col-md-12">
            <div className=" my-2">
              <label className="negrita">
                Seleccione el servicio a asignar *:
              </label>
              <select
                className=" inputselect bordecompleto "
                id="servicio_afiliado_config_id"
                name="servicio_afiliado_config_id"
                style={{ width: "100%" }}
                onChange={(e) => onchange(e)}
                value={state.servicio_afiliado_config_id}
              >
                <option value={""}>
                  {state.afiliado_categoria_id === ""
                    ? "Seleccione una categoria..."
                    : servicios.length === 0
                    ? "No hay servicios disponibles"
                    : "Seleccione un servicio..."}
                </option>
                {servicios.length > 0
                  ? servicios.map((data, index) => {
                      return (
                        <option
                          value={data.servicio_afiliado_config_id}
                          key={index}
                        >
                          {data.detalle_pago} - ${data.valor}{" "}
                          {data.periodo_pagos
                            ? ` ${data.periodo_pagos.periodo}`
                            : null}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div> */}
              {/*  <div className="col-md-12">
            <div className="">
              <label className="negrita">Forma de pago :</label>
              <select
                className=" inputselect bordecompleto "
                id="forma_pago"
                name="forma_pago"
                style={{ width: "100%" }}
                onChange={(e) => onchange(e)}
                value={state.forma_pago}
              >
                <option value={""} key={index}>
                  Seleccione ...
                </option>
                {formasPagos.length > 0
                  ? formasPagos.map((data, index) => {
                      const x = [
                        "efectivo",
                        "transferencia",
                        "deposito_cuenta_bancaria",
                      ];

                      return x.includes(data.codigo_forma_pago) ? (
                        <option value={data.codigo_forma_pago} key={index}>
                          {data.forma_pago}
                        </option>
                      ) : null;
                    })
                  : null}
              </select>
            </div>
          </div> */}
              {/*   {state.forma_pago === "debito_cuenta_bancaria" ? (
            <>
              <div className="my-2 color-azulmarino">
                Al seleccionar el pago por débito bancario es necesario ingresar
                el número de cuenta bancaria y el nombre de la institución
                bancaria en donde se realizará el debito.
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Institución bancaria del usuario:</label>
                  <div className={"d-flex justify-content-between"}>
                    {" "}
                    <input
                      className="inputtext bordecompleto"
                      disabled={validaDisabledInput}
                      id="cta_institucion"
                      name="cta_institucion"
                      value={state.cta_institucion}
                      placeholder="Ingrese el nombre de la institución bancaria"
                      type="text"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>N. cuenta bancaria del usuario:</label>
                  <div className={"d-flex justify-content-between"}>
                    {" "}
                    <input
                      className="inputtext bordecompleto"
                      disabled={validaDisabledInput}
                      id="cta_bancaria"
                      name="cta_bancaria"
                      value={state.cta_bancaria}
                      placeholder="Ingrese el número de cuenta bancaria"
                      type="text"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null} */}
              {!props.selectData ? (
                <div className="d-flex justify-content-center flex-column align-items-center my-3">
                  {loadingSaveRecord ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <button
                      className="w-50 bg-btn-vet-opciones bordesombreado "
                      disabled={loadingSaveRecord ? true : false}
                      onClick={(e) => {
                        guardarRow(e);
                      }}
                    >
                      Guardar nuevo afiliado
                    </button>
                  )}
                </div>
              ) : null}{" "}
            </div>
          )}
        </Form>
      ) : (
        <div className="flex-column d-flex justify-content-between py-5 align-items-center w-100">
          <i
            class="fa-solid fa-triangle-exclamation "
            style={{ fontSize: "100px" }}
          ></i>
          <h5>No se ah configurado el servicio de registro para los socios</h5>
        </div>
      )}
    </>
  );
};

export default CrearEditarAfiliado;
