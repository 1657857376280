import { toast } from "react-toastify";
export const notifyInfo = (text,posicion) =>
    toast.info(text,{
      position: posicion,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });


export const notifyErr = (text,posicion) =>
    toast.error(text,{
      position: posicion,
      autoClose: 6000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });