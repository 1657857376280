import React, { useState } from "react";
import { Form } from "reactstrap";
import { toast } from "react-toastify";
import {
  crearCategoriaServiciosApi,
  editarCategoriaServiciosApi,
} from "../../../api/afiliaciones_socios/categorias";

const CrearEditarCategoriaServicio = ({
  editar,
  categoria,
  setCategoria,
  actualizarLista,
  resetCategoria,
  setEditar,
}) => {
  const [loading, setLoading] = useState(false);

  const guardarCategoria = async (e) => {
    e.preventDefault();

    if (!categoria.categoria || !categoria.descripcion) {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    setLoading(true);
    try {
      const apiFunc = editar
        ? editarCategoriaServiciosApi
        : crearCategoriaServiciosApi;
      const respuesta = await apiFunc(categoria);
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        actualizarLista();
        resetCategoria();
        setEditar(false);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar la petición");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form inline className="row">
      <div className="col-md-4">
        <div className="form-group">
          <label className="negrita">Categoria</label>
          <input
            className="inputtext bordecompleto mx-1"
            id="categoria"
            name="categoria"
            value={categoria.categoria}
            onChange={(e) => {
              setCategoria({
                ...categoria,
                [e.target.name]: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="col-md-8">
        <div className="form-group">
          <label className="negrita">Descripcion</label>
          <textarea
            className="inputtext bordecompleto mx-1"
            id="descripcion"
            name="descripcion"
            value={categoria.descripcion}
            onChange={(e) => {
              setCategoria({
                ...categoria,
                [e.target.name]: e.target.value,
              });
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center flex-row align-items-center my-3">
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        ) : (
          <>
            <button
              className="w-50 bg-btn-vet-opciones bordesombreado mx-1"
              disabled={loading}
              onClick={guardarCategoria}
            >
              {editar ? "Editar Categoría" : "Crear Categoría"}
            </button>
            {editar && (
              <button
                className="w-5 bg-btn-vet-opciones bordesombreado"
                onClick={() => {
                  resetCategoria();
                  setEditar(false);
                }}
              >
                X
              </button>
            )}
          </>
        )}
      </div>
    </Form>
  );
};

export default CrearEditarCategoriaServicio;
