import Axios from "../../config/AxiosContable";
import AxiosMails from "../../config/AxiosMails";


export const autorizarFacturaApi = async (state) => {
  try {
    const res = await Axios.post("/autorizarfactura/", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};


export const verificarAutorizacionsriApi = async (state) => {
  try {
    const res = await Axios.post("/verificarAutorizacionsri/", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};

export const sendmailclienteDocumento = async (state,ima) => {
  try {

/*      const formdata = new FormData();
     formdata.append("documento", state);
     formdata.append("logobase64", localStorage.getItem("ImgVetbuffer")); */
    const res = await Axios.post("/sendmail/", {
      documento: state,
      logobase64:ima,
    });
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};




export const getpdfacturaApi = async (state,ima) => {
  try {
    const result = await AxiosMails.post(`/getfacturapdf`, {
      documento: state,
      logobase64: ima,
    });
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
