import React, { useEffect, useState, useContext } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import Axios from '../../../config/Axios'
import AuthContext from '../../../Context/auth/authContext'
import './style.css'
import PanelCrearUsuario from './crearUsuarios';
import ConfiguracionesUser from './configuraciones';
import '../../../App.css'

//MONCA

const ListarUsuario = (props) => {

    const AuthenticationContext = useContext(AuthContext)
    const {
        departamento
    } = AuthenticationContext


    const [state, setState] = useState({
        todos: [],
        currentPage: 1,
        todosPerPage: 5,
        search: "",
    })

    const [stateModal, setStateModal] = useState({
        nuevoUsuarioModal: false,
        UsuarioModal: false,
        userdata: {},
        configModal: false,
        id_persona: ""
    })




    const { todos, currentPage, todosPerPage } = state;


    const abrirConfiguraciones = (dato, e) => {
        e.preventDefault()


        setStateModal({
            ...stateModal,
            configModal: true,
            id_persona: dato.id_persona,
            userdata: dato
        })
    }

    const handleClick = (event) => {
        setState({
            ...state,
            currentPage: Number(event.target.id)
        });
    }

    const onchange = (e) => {
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase()
        })
    }

    const verDatos = (data, e) => {
        e.preventDefault()
        setStateModal({
            ...stateModal,
            UsuarioModal: true,
            userdata: data
        })

    }

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((todo, index) => {
        return (
            <>
            {
               // todo.rol_id===2 && todo.panel===2?
                <tr key={index}>
                <td>
                    
                    <button className="btn btn-warning" onClick={(e) => abrirConfiguraciones(todo, e)}><li className="fas fa-user-cog"></li></button>
                    {/*<button onClick={(e) => { verDatos(todo, e) }}>ver datos</button>*/}

                </td>
                <td scope="row">{todo.cedula}</td>
                <td>{todo.nombres} {todo.apellidos}</td>
                <td>{todo.telefono}</td>
                <td>{todo.ciudad}</td>
                </tr>

               // :null
            }
            </>
            

        )
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <li
                key={number}
                id={number}
                onClick={handleClick}
                style={{ border: "1px solid", padding: "5px" }}
            >
                {number}
            </li>




        );
    });


    useEffect(() => {
        //allpersonsData()
    }, [])


    //FUNCION PARA CARGAR LOS DATOS DE LOS TERRENOS

    const allpersonsData = async () => {
        try {
            const result = await Axios.get(`/alldataPersons`)
            setState({
                ...state,
                todos: result.data.resultado

            })
        } catch (e) {
            console.log(e)
        }
    }


    const BuscarUsers = async (e) => {
        if (e.target.value === "") {
            //allpersonsData()
            setState({
                ...state,
                todos: [],
                currentPage: 1
            })
        } else if (e.key === 'Enter') {
            try {
                const respuesta = await Axios.get('/filtrarPorCoincidencias/' + state.search)
                console.log(respuesta.data)
                if (respuesta.data.code === "success") {
                    setState({
                        ...state,
                        todos: respuesta.data.result,
                        currentPage: 1
                    })
                } else {

                }
            } catch (e) {

            }

        }

    }




    const actualizarListaDespuesDeEditarUser = async (dato) => {

        try {
            const respuesta = await Axios.get('/filtrarPorCoincidencias/' + dato)
            console.log(respuesta.data)
            if (respuesta.data.code === "success") {
                setState({
                    ...state,
                    todos: respuesta.data.result,
                    currentPage: 1,
                    search: ""
                })
            } else {

            }
        } catch (e) {

        }



    }

    const cerrarModalConfiguraciones = (e) => {
        e.preventDefault()
        setStateModal({
            ...stateModal,
            configModal: false
        })

        setState({
            ...state,
            infoUsuario: {}

        })
    }

    return (

        <div>
            <div className="row mb-9 negrita" style={{ borderBottom: "1px solid #d8d8d8" }}>
                <p style={{ fontSize: "16px" }}> PANEL DE USUARIOS </p>
            </div>
        
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Filtrar usuarios:</label><br></br>
                            <input type="text" className="form-control" placeholder="Filtre las iniciales de cédula o nombre y precione enter" aria-label="codigobuscar" aria-describedby="addon-wrapping" onChange={onchange} onKeyUp={(e) => BuscarUsers(e)} id="search" name="search" value={state.search} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <label htmlFor="exampleInputEmail1" style={{ textAlign: "center", width: "100%" }}>Acciones:</label><br></br>
                        <div className="">
                            <button type="button" className="btn btn-success" style={{ float: "right" }} onClick={(e) => setStateModal({ ...stateModal, nuevoUsuarioModal: true })}>Nuevo usuario</button>
                        </div>

                    </div>

                </div>
         




            <div className="row ">
                <div className="col-md-12">
                    {/* TABLA DE USUARIOS */}
                    <div className="card borde">
                        <div className="card-body " style={{ padding: "0px" }}>
                            <div className="row " style={{ padding: "12px" }} className="overflow-auto">
                                <Table >
                                    <thead className="bg-integralvet" style={{ textAlign: "center" }}>
                                        <tr>
                                            <th>PACIENTES</th>
                                            <th>CEDULA</th>
                                            <th>NOMBRES</th>
                                            <th>TELEFONO</th>
                                            <th>CIUDAD</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ padding: "12px", textAlign: "center" }}  >
                                        {renderTodos}
                                    </tbody>

                                </Table>
                                {
                                    state.todos.length > 0 ?
                                        null :
                                        <div style={{ width: "100%", padding: "12px", textAlign: "center" }}>
                                            <label style={{ width: "100%", padding: "12px", textAlign: "center" }}>FILTRE UN USUARIO PARA OBTENER LA INFORMACION</label>
                                        </div>
                                }
                            </div>
                        </div>
                        <ul id="page-numbers">
                            {renderPageNumbers}
                        </ul>
                    </div>
                    {/* FIN TABLA DE USAURIOS*/}
                </div>
            </div>






            {/* MODAL REGISTRO DE USUARIOS*/}
            <Modal isOpen={stateModal.nuevoUsuarioModal} toggle={() => { }} style={{ maxWidth: "70%", padding: "0px" }}   >
                <ModalHeader toggle={() => {
                    setStateModal({
                        ...stateModal,
                        nuevoUsuarioModal: false
                    })
                }
                }
                    className="borde">
                    REGISTRO DE USUARIO
                </ModalHeader>
                <ModalBody>
                    <PanelCrearUsuario></PanelCrearUsuario>
                </ModalBody>

            </Modal>
            {/* FIN MODAL REGISTRO DE USUARIOS*/}







            {/* MODAL CONFIGURACIONES USUARIO */}
            <Modal isOpen={stateModal.configModal} toggle={() => { }} style={{ maxWidth: "90%" }}>
                <ModalHeader toggle={cerrarModalConfiguraciones}
                    style={{ background: "white" }}>CONFIGURACIONES DE USUARIO : {stateModal.userdata.nombres} {stateModal.userdata.apellidos} </ModalHeader>
                <ModalBody>
                    <ConfiguracionesUser data={stateModal.userdata} actualizaLista={actualizarListaDespuesDeEditarUser}></ConfiguracionesUser>
                </ModalBody>
            </Modal>
            {/* FIN  MODAL CONFIGURACIONES USUARIO */}







            {/* MODAL */}
            <Modal isOpen={stateModal.UsuarioModal} toggle={() => {
                setStateModal({
                    ...stateModal,
                    UsuarioModal: false
                })
            }}
                style={{ maxWidth: "100%", height: "600px", padding: "20px" }}
            >
                <ModalHeader toggle={() => {
                    setStateModal({
                        ...stateModal,
                        UsuarioModal: false
                    })
                }
                }
                    style={{ background: "white" }}>DATOS DEL CLIENTE</ModalHeader>
                <ModalBody>
                </ModalBody>
                {/*<ModalFooter></ModalFooter>*/}
            </Modal>{/* FIN MODAL REGISTRO DE USUARIOS*/}

        </div>



    )
}

export default ListarUsuario