import Axios from "../config/Axios";
export const getAllTags = async() => {

    try {
    const result = await Axios.get(
      `/getAllTags`
    );
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};


export const getAllTagsEmpresa = async (empresa_id) => {
  try {
    const result = await Axios.get(`/getAllTagsEmpresa/${empresa_id}`);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};


export const eliminarTagsEmpresa = async (tag_id) => {
  try {
    const result = await Axios.get(`/eliminarTagEmpresa/${tag_id}`);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};

export const asignarTagsEmpresas = async (state) => {
  try {
    const result = await Axios.post(`/asignateTagEmpresa`, state);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};
