import React, { useRef, useContext, useState, useEffect } from "react";
import Barcode from "react-jsbarcode";
import moment from "moment";
import ReactToPrint from "react-to-print";
import Axios from "../../../config/Axios";
import "./imprimir.css";
import { getBase64Image } from "../../helpers";
const pageStyle80 = `
  @page {
    size: 80mm auto!important;
    margin:0;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    @page {
    size: 80mm 140mm;
    margin:0;
    background:red;
  }
    .pagebreak {
      page-break-before: always;
    }
  }
`;

const pageStyleA4 = `
  @page {
    size: 80mm auto!important;
    margin:0;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    @page {
    size: 210mm 297mm;
    margin:0;
    background:red;
  }
    .pagebreak {
      page-break-before: always;
    }
  }
`;
export default function PrintComponent(props) {
  let componentRef = useRef();
  /* const authContext = useContext(AuthContext);
  const { sessiondataadm } = authContext; */
  const [dataprint, setDataPrint] = useState(props.data);
  const [modalconfirmacion, setModalConfirmacion] = useState(false);
  useEffect(() => {
    if (props.data) {
      document.getElementById("printbtn").click();
    }
  }, [props.imprimir]);

  /*    useEffect(()=>{         
    if(dataprint){
      document.getElementById("printbtn").click();
    }
  },[dataprint]) */

  const init = async () => {
    /* const res = await createTiket(props.data);
     if (res[0].sms === "ok") {
      setDataPrint(res[0].data)
                document.getElementById("printbtn").click();

     } else {
       toast.error(res[0].mensaje);
     } */
    /*  const res = await printstate(props.data)
    if(res[0].sms==="ok"){
        await setDataPrint(res[0].data);
        await props.updatedatesend(res[0].data)

    }else{
      alert("No se logro completar la impresión")
    } */
  };

  return (
    <>
      <div className="">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          onAfterPrint={() => {
            /*  despues(); */
          }}
          pageStyle={pageStyleA4}
          trigger={() => (
            <button
              className="w-100 bg-btn-vet-green p-3"
              id="printbtn"
              style={{ color: "white", display: "none" }}
            >
              <label for="" style={{ color: "white", display: "none" }}>
                Seleccionar impresora
              </label>
            </button>
          )}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }} media="print">
          <ComponentToPrint
            ref={(el) => (componentRef = el)}
            data={props.data}
            datasession={null}
            ima={props.ima}
          />
        </div>
      </div>
    </>
  );
}

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = { checked: false };
  }

  componentDidMount() {
    console.log("=========================");
  }

  handleCheckboxOnChange = () =>
    this.setState({ checked: !this.state.checked });

  regimen = (v1) => {
    return v1 === "RIMPE" || v1 === "rimpe"
      ? "Contribuyente Régimen RIMPE"
      : v1 === "POPULAR"
      ? "Régimen RIMPE - Contribuyente negocio popular"
      : v1 === "GENERAL"
      ? "Régimen general"
      : "";
  };
  tipodocumento = (v1) => {
    return v1 === "01"
      ? "FACTURA"
      : v1 === "03"
      ? "LIQUIDACIÓN DE COMPRA"
      : v1 === "04"
      ? "NOTA DE CRÉDITO"
      : v1 === "05"
      ? "NOTA DE DÉBITO"
      : v1 === "06"
      ? "GUIA DE REMISIÓN"
      : v1 === "07"
      ? "COMPROBANTE DE RETENCIÓN"
      : "RECIBO";
  };

  ifartesanal = (v1) => {
    return v1 === "" || v1 === null
      ? ""
      : `
         Calificación artesanal : ${v1}  
         `;
  };

  tipopago = (v1) => {
    return v1 === "efectivo"
      ? "Efectivo"
      : v1 === "chueque"
      ? "Cheque"
      : v1 === "debito_cuenta_bancaria"
      ? "Débito de cuenta bancaria"
      : v1 === "transferencia"
      ? "Transferencia bancaria"
      : v1 === "deposito_cuenta_bancaria"
      ? "Depósito en cuenta(Corriente / Ahorros)"
      : v1 === "tarjeta_debito"
      ? "Tarjeta de débito"
      : v1 === "tarjeta_prepago"
      ? "Tarjeta prepago"
      : v1 === "tarjeta_credito"
      ? "Tarjeta de crédito"
      : v1 === "otros"
      ? "Otros"
      : v1 === "endoso_titulos"
      ? "Endoso de títulos"
      : v1 === "01"
      ? "Sin utilizacion del sistema financiero"
      : v1 === "15"
      ? "Compensación de deudas"
      : v1 === "16"
      ? "Tarjeta de débito"
      : v1 === "17"
      ? "Dinero electrónico"
      : v1 === "18"
      ? "Tarjeta prepago"
      : v1 === "19"
      ? "Tarjeta de crédito"
      : v1 === "20"
      ? "Otros con utilización del sistema financiero"
      : v1 === "21"
      ? "Endoso de títulos"
      : "NO DEFINIDO";
  };

  render() {
    return this.props.data ? (
      <div className="">
        {this.props.data ? (
          <div className="">
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "15px",
              }}
            >
              <div className="" style={{ width: "33%!important" }}>
                <div className="">
                  <div className=" ">
                    <div></div>
                    <div className="" style={{ height: "150px" }}>
                      {" "}
                      <img
                        src={this.props.ima}
                        style={{ width: "250px", height: "150px" }}
                        alt=""
                      />
                    </div>
                    <br />
                    <div
                      className="bordecompleto p-2"
                      style={{ minHeight: "291px" }}
                    >
                      <div className="">
                        <table className="">
                          <tbody>
                            <tr>
                              <td>Razón social:</td>
                              <td>
                                {
                                  JSON.parse(this.props.data.emisor)
                                    .razon_social
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>nombre comercial:</td>
                              <td>
                                {
                                  JSON.parse(this.props.data.emisor)
                                    .nombre_comercial
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Obligado a llevar contabilidad:</td>
                              <td>
                                {" "}
                                {JSON.parse(this.props.data.emisor)
                                  .obligado_contabilidad === true
                                  ? "si"
                                  : "no"}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                {this.regimen(
                                  JSON.parse(this.props.data.emisor).regimen
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                {this.ifartesanal(
                                  JSON.parse(this.props.data.emisor)
                                    .calificacion_artesanal
                                )}
                              </td>
                            </tr>

                            <tr className="w-100" style={{ width: "100%" }}>
                              <td>Dirección:</td>
                            </tr>
                            <tr className="w-100">
                              <td colspan="2">
                                {
                                  JSON.parse(this.props.data.emisor)
                                    .establecimiento.direccion
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 m-0 p-0 ">
                <div
                  className="row bordecompleto p-2"
                  style={{ minHeight: "390px" }}
                >
                  <div className=" d-flex flex-column ">
                    <table className="emisor">
                      <tbody>
                        <tr>
                          <td className="ruc">Ruc:</td>
                          <td className="ruc">
                            {JSON.parse(this.props.data.emisor).ruc}
                          </td>
                        </tr>
                        <tr>
                          <td colspan={2} className="codigo_documento">
                            {this.tipodocumento(
                              this.props.data.codigo_documento
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Punto de emisión:</td>
                          <td>
                            {" "}
                            {
                              JSON.parse(this.props.data.emisor).establecimiento
                                .punto_emision
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Código:</td>
                          <td>
                            {" "}
                            {
                              JSON.parse(this.props.data.emisor).establecimiento
                                .codigo
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>N° Factura</td>
                          <td>{this.props.data.numero}</td>
                        </tr>

                        <tr>
                          <td colspan="2">NÚMERO DE AUTORIZACIÓN</td>{" "}
                        </tr>

                        <tr>
                          <td colspan="2">{this.props.data.clave_acceso}</td>{" "}
                        </tr>

                        <tr className="">
                          <td>
                            <div className="my-3">
                              {this.props.data.autorizacion
                                ? JSON.parse(this.props.data.autorizacion)
                                    .autorizacion.estado
                                : "NO AUTORIZADO"}
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <div className="">
                            {this.props.data.autorizacion ? (
                              JSON.parse(this.props.data.autorizacion)
                                .autorizacion.estado === "AUTORIZADO" ? (
                                <div>
                                  <td>Fecha de autorización</td>
                                  <td>
                                    {moment(
                                      JSON.parse(this.props.data.autorizacion)
                                        .autorizacion.fechaAutorizacion
                                    ).format("DD/MM/YYYY HH:MM:SS")}
                                  </td>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </tr>

                        {/*  <tr>
                        <td>Fecha de venta:</td>
                        <td>{this.props.data.fecha_venta}</td>
                      </tr> */}
                        <tr>
                          <td>Ambiente:</td>
                          <td>
                            {this.props.data.ambiente === 1
                              ? "PRUEBA"
                              : "PRODUCCIÓN"}
                          </td>
                        </tr>
                        <tr>
                          <td>Emisión:</td>
                          <td>
                            {parseInt(this.props.data.tipo_emision) === 1
                              ? "NORMAL"
                              : "XXX"}
                          </td>
                        </tr>

                        <tr>
                          <td colspan="2">Clave de acceso</td>{" "}
                        </tr>

                        <tr>
                          {
                            <td colspan="2">
                              <Barcode
                                className="svg2"
                                value={this.props.data.clave_acceso}
                              />
                            </td>
                          }
                        </tr>
                        {/*  <tr>
                          <td colspan="2">
                            {this.props.data.autorizacion
                              ? JSON.parse(this.props.data.autorizacion).estado ===
                                "AUTORIZADO"
                                ? JSON.parse(props.data.autorizacion).numero
                                : "NO AUTORIZADA"
                              : "NO AUTORIZADO"}
                          </td>{" "}
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                width: "100%",
              }}
            >
              <div className=" bordecompleto  p-3">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    className=""
                    style={{
                      width: "50%",
                    }}
                  >
                    Razon_social:
                    {JSON.parse(this.props.data.comprador).razon_social}
                  </div>
                  <div
                    className=""
                    style={{
                      width: "50%",
                    }}
                  >
                    Identificación:
                    {JSON.parse(this.props.data.comprador).identificacion}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    className=""
                    style={{
                      width: "50%",
                    }}
                  >
                    Teléfono:{JSON.parse(this.props.data.comprador).telefono}
                  </div>
                  <div
                    className=""
                    style={{
                      width: "50%",
                    }}
                  >
                    Correo:{JSON.parse(this.props.data.comprador).correo}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: "100%",
                  }}
                >
                  <div className=" col-sm-12">
                    Dirección:{JSON.parse(this.props.data.comprador).direccion}
                  </div>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                width: "100%",
              }}
            >
              <div className=" m-0 p-0 tablares bordecompleto ">
                <table className="w-100 tabla-detalle-factura">
                  <thead>
                    <tr className="bordecompleto">
                      <th>Código</th>
                      <th>Descripción</th>
                      <th>Cantidad</th>
                      <th>pvp</th>
                      <th>Descuento</th>
                      <th>iva</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.data.ventas_detalle.length > 0
                      ? this.props.data.ventas_detalle.map((data) => (
                          <tr className="bordecompleto">
                            <td>{data.codigo_producto}</td>
                            <td>{data.descripcion_producto}</td>
                            <td>{data.cantidad}</td>
                            <td>${data.precio_venta}</td>
                            <td>${data.descuento_total}</td>
                            <td>${data.iva_valor}</td>

                            <td>${data.subtotal}</td>
                          </tr>
                        ))
                      : "cargando..."}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "start",
                alignItems: "start",
                width: "100%",
              }}
            >
              <div className="bordecompleto p-1" style={{ width: "60%" }}>
                <div
                  className=" p-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "start",
                    alignItems: "start",
                    width: "100%",
                    float: "start",
                  }}
                >
                  <label
                    for=""
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Información adicional
                  </label>
                  <textarea className="infoadicional">
                    {JSON.parse(this.props.data.pagos)[0].notas}
                  </textarea>
                </div>
                <div className="" style={{ width: "100%" }}>
                  <table className="w-100 bordecompleto">
                    <tr className="">
                      <td className="">Forma de pago</td>
                      <td className="">valor</td>
                    </tr>
                    <tr className="">
                      <td className="">
                        {JSON.parse(this.props.data.pagos)[0].medio} -{" "}
                        {this.tipopago(
                          JSON.parse(this.props.data.pagos)[0].medio
                        )}
                      </td>
                      <td className=""> {this.props.data.valor_total}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="bordecompleto p-4" style={{ width: "40%" }}>
                <table className="tbfactura " style={{ width: "100%" }}>
                  <tr>
                    <td className="tb-left">Total_descuento</td>
                    <td>${this.props.data.total_descuento}</td>
                  </tr>
                  <tr>
                    <td className="tb-left">Subtotal 12%</td>
                    <td>${this.props.data.subtotal_iva}</td>
                  </tr>
                  <tr>
                    <td className="tb-left">Subtotal 0% </td>
                    <td>${this.props.data.subtotal_siniva}</td>
                  </tr>
                  <tr>
                    <td className="tb-left">Subtotal no objeto de iva </td>
                    <td>${this.props.data.subtotal_no_objeto_iva}</td>
                  </tr>
                  <tr>
                    <td className="tb-left">Subtotal exento de iva</td>
                    <td>${this.props.data.subtotal_exento_iva}</td>
                  </tr>
                  <tr>
                    <td className="tb-left">Subtotal sin impuestos </td>
                    <td>${this.props.data.subtotal_sin_impuestos}</td>
                  </tr>
                  <tr>
                    <td className="tb-left"> Iva</td>
                    <td>${this.props.data.iva}</td>
                  </tr>
                  <tr>
                    <td className="tb-left"> ICE </td>
                    <td>${this.props.data.ice}</td>
                  </tr>
                  <tr>
                    <td className="tb-left">IRBPNR </td>
                    <td>${this.props.data.irbpnr}</td>
                  </tr>
                  <tr className="bordecompleto">
                    <td className="tb-left negrita p-2"> Valor total </td>
                    <td className="negrita py-2">
                      {" "}
                      ${this.props.data.valor_total}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : (
      <div>xxxxxxxxxxxx</div>
    );
  }
}
