import React, { useEffect, useState,useContext } from "react";
import AuthContext from "../../Context/auth/authContext";
import Roles from "./roles/index"
const RolesPermisos = () => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  useEffect(() => {
  }, []);

  return (
    <div className="row p-0 m-0">
        <Roles veterinaria_id={veterinaria_id} />
    </div>
  );
};

export default RolesPermisos;
