import React, { useState, useEffect, useContext, useRef} from "react";
import DataTableDefault from "../../ReactDataTable";
import DataTableDefault2 from "../../extras/paginatedTable";

import ModalDefault from "../../modalDefault";
import ModalDefaultPreload from "../../modalpreload";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
DropdownItem,
  Table,
} from "reactstrap";
import Axios from "../../../config/Axios";
import { listarVentaApi } from "../../../api/contable/ventas";
import AuthContext from "../../../Context/auth/authContext";
import { Link } from "react-router-dom";
import {
  crearFirmarXmlVentaApi,
  anularventaApi,
} from "../../../api/contable/ventas";
import {
  verificarAutorizacionsriApi,
  sendmailclienteDocumento,
} from "../../../api/contable/facturacion";
import { toast } from "react-toastify";
import DetalleVenta from "./detalleventa";
import ComponentToPrint from "../../impresiones/facturas/imprimir";

const TablaVentas = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;
  const {
    api_key,
    firma_certificado_clave,
    facturacion_electronica,
    veterinaria_id,
    logo,
  } = sessiondatacli[index].veterinaria[0];
  const { usuario_id } = sessiondatacli[index].persona[0];
  const [ima, setima] = useState("");
  const [selectData, setselectData] = useState(null);
  const [dataCategorias, setDataCategoria] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadLista, setLoadLista] = useState(false);
  const [modalconfirmacion, setModalConfirmacion] = useState(false);
  const [modaldetallesfactura, setModalDetallesFactura] = useState(false);
  const [modalautorizarfactura, setModalAutorizacionFactura] = useState(false);
  const [imprimir, setImprimir] = useState(0);
  const [stateverificacionsri, setStateVerificacionSri] = useState(
    "Esperando respuesta ..."
  );
  const [modalsenmail, setModalsenmail] = useState(false);
  const [open,setOpen]=useState(false)

  const componentRef = useRef([]);

  const columns = [
    {
      name: "N° Factura",
      center: true,
      selector: (row) => row.n_factura,
      width: "100px",
    },
    {
      name: "Estado sri",
      center: true,
      selector: (row) =>
        row.estado_sri === "RECIBIDA" ? (
          <span class="badge rounded-pill bg-info">{row.estado_sri}</span>
        ) : row.estado_sri === "DEVUELTA" ? (
          <span class="badge rounded-pill bg-warning">{row.estado_sri}</span>
        ) : row.estado_sri === "AUTORIZADO" ? (
          <span class="badge rounded-pill bg-success">{row.estado_sri}</span>
        ) : row.estado_sri === "NO AUTORIZADO" ? (
          <span class="badge rounded-pill bg-danger">{row.estado_sri}</span>
        ) : (
          <span class="badge rounded-pill bg-secondary">BORRADOR</span>
        ),
      width: "150px",
    },

    /* {
      name: "Firmado",
      center: true,
      selector: (row) => row.xmlfirmado,
      width: "100px",
    }, */
    {
      name: "Tipo",
      center: true,
      selector: (row) =>
        row.codigo_documento === ""
          ? "RECIBO"
          : row.codigo_documento === "01"
          ? "FACTURA"
          : "OTRO DOCUMENTO",
      width: "100px",
    },
    {
      name: "Ambiente",
      center: true,
      selector: (row) => (row.ambiente === 1 ? "PRUEBA" : "PRODUCCION"),
      width: "100px",
    },
    {
      name: "Estado",
      center: true,
      selector: (row) => (row.estado_factura === 1 ? "ACTIVA" : "ANULADA"),
      width: "150px",
    },
    {
      name: "F/venta",
      center: true,
      selector: (row) => row.fecha_venta,
      width: "150px",
    },
    {
      name: "Total",
      center: true,
      selector: (row) => `$${row.valor_total}`,
      width: "60px",
    },
    {
      name: "Opciones",
      center: true,
      cell: (row,i) => opcionesx(row,i),
      width: `${80 * 4}`,
    },
  ];

  const accionescategoria = async (e, data) => {
    e.preventDefault();
    //props.ejecutarOpciones(data, true);
    setselectData(data);
    setModalDetallesFactura(true);
  };

  const eliminarCategoriaFn = async (categoria_id) => {
    const res = await null;
    if (res[0].sms === "ok") {
      getVentasEmpresa();
      setModalConfirmacion(false);
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      selectData(null);
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const verificarAutorizacionSriFn = async (row) => {
    setModalAutorizacionFactura(true);
    const res = await verificarAutorizacionsriApi({
      claveacceso: row.clave_acceso,
      ambiente: row.ambiente,
      venta_cabecera_id: row.venta_cabecera_id,
    });
    if (res[0].sms === "ok") {
      setStateVerificacionSri(res[0].mensaje);
      initComponent();
    } else {
      setStateVerificacionSri(res[0].mensaje);
    }
  };

  const editarfactura = (row) => {
    let update = {
      venta_cabecera_id: row.venta_cabecera_id,
      editar: "SI",
      x_key: row.x_key,
      x_password: row.x_password,
      anulado: row.anulado,
      facturado: row.facturado,
      identificacion_cliente: row.identificacion_cliente,
      tipo_emision: row.tipo_emision,
      codigo_identificacion: row.codigo_identificacion,
      codigo_documento: row.codigo_documento,
      codigo_forma_pago: row.codigo_forma_pago,
      codigo_forma_pago_sri: row.codigo_forma_pago_sri,
      fecha_venta: row.fecha_venta,
      n_establecimiento: row.n_establecimiento,
      n_punto_venta: row.n_punto_venta,
      n_factura: row.n_factura,
      subtotal_iva: row.subtotal_iva,
      subtotal_siniva: row.subtotal_siniva,
      subtotal_no_objeto_iva: row.subtotal_no_objeto_iva,
      subtotal_exento_iva: row.subtotal_exento_iva,
      subtotal_sin_impuestos: row.subtotal_sin_impuestos,
      total_descuento: row.total_descuento,
      ice: row.ice,
      iva: row.iva,
      total_devolucion_iva: row.total_descuento,
      irbpnr: row.irbpnr,
      propina: row.propina,
      valor_total: row.valor_total,
      empresa_id: row.veterinaria_id,
      activo: row.activo,
      ambiente: row.ambiente,
      ventas_detalle: row.ventas_detalle,
      emisor: JSON.parse(row.emisor),
      moneda: row.moneda,
      comprador: JSON.parse(row.comprador),
      numero: row.numero,
      pagos: [
        {
          medio: JSON.parse(row.pagos)[0].medio,
          total: JSON.parse(row.pagos)[0].total,
          propiedades: {
            numero: JSON.parse(row.pagos)[0].propiedades.numero,
            banco: JSON.parse(row.pagos)[0].propiedades.banco,
          },
          notas: "Pago de factura",
        },
      ],
    };
    localStorage.setItem("factura", JSON.stringify(update));
    window.location = "/facturacion/inicio";
  };

  const crearyenviarFn = async (row) => {
    const resfirmadoxml = await crearFirmarXmlVentaApi(
      row.veterinaria_id,
      row.venta_cabecera_id
    );
    toast.error(JSON.stringify(resfirmadoxml[0].mensaje), {
      position: "bottom-left",
    });
    initComponent();
  };

  const anularventaFn = async (row) => {
    const resanular = await anularventaApi(row.venta_cabecera_id);
    alert(JSON.stringify(resanular));
    initComponent();
  };

  const sendMailFn = async (row, e) => {
    e.preventDefault();
    const logores = await toDataURL(logo);
    const sndmail = await sendmailclienteDocumento(row, logores);
    if (sndmail[0].sms === "ok") {
      setModalsenmail(false);
      toast.success(`${sndmail[0].mensaje}`, {
        position: "bottom-left",
      });
    } else {
      setModalsenmail(false);
      toast.error(`${sndmail[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const opciones2 = (row) => {
    return (
      <div className="" direction="start">
        <div className="" style={{ position: "a" }}>
          <div header>
            {row.estado_sri === "RECIBIDA" ? (
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO"
                    ? true
                    : row.estado_factura !== 1
                    ? true
                    : false
                }
                className="btn btn-success  w-100 p-0 m-0"
                onClick={(e) => {
                  /*               setselectData(row);
                   */
                  verificarAutorizacionSriFn(row);
                }}
              >
                Verificar
              </button>
            ) : (
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO"
                    ? true
                    : row.estado_factura !== 1
                    ? true
                    : row.codigo_documento === ""
                    ? true
                    : false
                }
                className="btn btn-success w-100 p-0 m-0"
                style={{ width: "100px" }}
                onClick={(e) => {
                  /*               setselectData(row);
                   */
                  crearyenviarFn(row);
                }}
              >
                Autorizar
              </button>
            )}
          </div>
          <div>
            {" "}
            {
              <button
                disabled={
                  row.estado_sri === "AUTORIZADO" &&
                  JSON.parse(row.comprador).tipo_identificacion !== "07"
                    ? false
                    : false
                }
                className="btn btn-success w-100 p-0 m-0"
                onClick={(e) => {
                  setModalsenmail(true);
                  sendMailFn(row, e);
                }}
              >
                Enviar correo
              </button>
            }
          </div>
          <div>
            {" "}
            <button
              className="btn btn-success my-1 w-100 p-0 m-0"
              onClick={async (e) => {
                e.preventDefault();
                const logores = await toDataURL(logo);
                setima(logores);
                setselectData(row);
                setImprimir(imprimir + 1);
              }}
            >
              Imprimir
            </button>
          </div>
          <div divider />
          <div>
            {" "}
            <button
              disabled={
                row.estado_sri === "AUTORIZADO" || row.estado_sri === "RECIBIDA"
                  ? true
                  : row.estado_factura !== 1
                  ? true
                  : false
              }
              className="btn btn-primary  w-100 p-0 m-0"
              onClick={(e) => {
                console.log(e);
                editarfactura(row);
              }}
            >
              Editar
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary  w-100 p-0 m-0"
              onClick={(e) => {
                accionescategoria(e, row);
              }}
            >
              detalle
            </button>
          </div>
          <div>
            <button
              className="btn btn-danger w-100 p-0 m-0"
              disabled={row.estado_factura !== 1 ? true : false}
              onClick={(e) => {
                console.log(e);
                anularventaFn(row);
              }}
            >
              Anular
            </button>
          </div>
        </div>
      </div>
    );
  };

const [ultimo,setUltimo]=useState(null)

  const togglex = (i) => {
   
  };

   const opcionesx = (row) => {
     return (
       <UncontrolledDropdown className="me-2 " direction="start">
         <DropdownToggle caret color="primary">
           Acciones
         </DropdownToggle>
         <DropdownMenu className="ddmenu" style={{ position: "a" }}>
           <DropdownItem header>
             {row.estado_sri === "RECIBIDA" ? (
               <button
                 disabled={
                   row.estado_sri === "AUTORIZADO"
                     ? true
                     : row.estado_factura !== 1
                     ? true
                     : false
                 }
                 className="btn btn-success  w-100 p-0 m-0"
                 onClick={(e) => {
                   /*               setselectData(row);
                    */
                   verificarAutorizacionSriFn(row);
                 }}
               >
                 Verificar
               </button>
             ) : (
               <button
                 disabled={
                   row.estado_sri === "AUTORIZADO"
                     ? true
                     : row.estado_factura !== 1
                     ? true
                     : row.codigo_documento === ""
                     ? true
                     : false
                 }
                 className="btn btn-success w-100 p-0 m-0"
                 style={{ width: "100px" }}
                 onClick={(e) => {
                   /*               setselectData(row);
                    */
                   crearyenviarFn(row);
                 }}
               >
                 Autorizar
               </button>
             )}
           </DropdownItem>
           <DropdownItem>
             {" "}
             {
               <button
                 disabled={
                   row.estado_sri === "AUTORIZADO" &&
                   JSON.parse(row.comprador).codigo_identificacion !== "07"
                     ? false
                     : true
                 }
                 className="btn btn-success w-100 p-0 m-0"
                 onClick={(e) => {
                   setModalsenmail(true);
                   sendMailFn(row, e);
                 }}
               >
                 Enviar correo
               </button>
             }
           </DropdownItem>
           <DropdownItem>
             {" "}
             <button
               className="btn btn-success my-1 w-100 p-0 m-0"
               onClick={async (e) => {
                 e.preventDefault();
                 const logores = await toDataURL(logo);
                 setima(logores);
                 setselectData(row);
                 setImprimir(imprimir + 1);
               }}
             >
               Imprimir
             </button>
           </DropdownItem>
           <DropdownItem divider />
           <DropdownItem>
             {" "}
             <button
               disabled={
                 row.estado_sri === "AUTORIZADO" ||
                 row.estado_sri === "RECIBIDA"
                   ? true
                   : row.estado_factura !== 1
                   ? true
                   : false
               }
               className="btn btn-primary  w-100 p-0 m-0"
               onClick={(e) => {
                 console.log(e);
                 editarfactura(row);
               }}
             >
               Editar
             </button>
           </DropdownItem>
           <DropdownItem>
             <button
               className="btn btn-primary  w-100 p-0 m-0"
               onClick={(e) => {
                 accionescategoria(e, row);
               }}
             >
               detalle
             </button>
           </DropdownItem>
           <DropdownItem>
             <button
               className="btn btn-danger w-100 p-0 m-0"
               disabled={row.estado_factura !== 1 ? true : false}
               onClick={(e) => {
                 console.log(e);
                 anularventaFn(row);
               }}
             >
               Anular
             </button>
           </DropdownItem>
         </DropdownMenu>
       </UncontrolledDropdown>
     );
   };

  const opciones = (row,i) => {
    return (
      <Dropdown
        ref={(r) => (componentRef.current[`${i}`] = r)}
        toggle={function noRefCheck(e) {
          togglex(i);
        }}
        isOpen={open}
        id={row.venta_cabecera_id}
      >
        <DropdownToggle caret ></DropdownToggle>
        <DropdownMenu container="body">
          <DropdownItem onClick={function noRefCheck() {}}>
            Action 1
          </DropdownItem>
          <DropdownItem onClick={function noRefCheck() {}}>
            Action 2
          </DropdownItem>
          <DropdownItem onClick={function noRefCheck() {}}>
            Action 3
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const closeAction2 = (data) => {
    setModalsenmail(data);
  };
  useEffect(() => {
    initComponent();
  }, [props.actualizarLista]);

  const getVentasEmpresa = async () => {
    setLoad(true);
    const res = await listarVentaApi(props.empresa_id);
    if (res[0].sms === "ok") {
      setDataCategoria(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
    setLoad(false);
  };

  const initComponent = async () => {
    getVentasEmpresa();
  };

  const modalActions = (modal, bandera) => {
    setModalConfirmacion(modal);
  };

  const closeAction = (data) => {
    setModalDetallesFactura(data);
    setselectData(null);
  };
  /* const autorizarsri = async () => {
    const res = await autorizarFactura(selectData);
    const obj = {
      data: res,
      venta_cabecera_id: selectData.venta_cabecera_id,
      x_key: api_key,
      x_password: firma_certificado_clave,
      veterinaria_id:veterinaria_id
    };
    //console.log(res);
    const resAutorizacionSri= await autorizarFacturaApi(obj)
    console.log(resAutorizacionSri)
    if(resAutorizacionSri[0].sms==="ok"){
      setModalAutorizacionFactura(false);
      setselectData(null);
      initComponent()
    }else{
      alert(JSON.stringify(resAutorizacionSri[0].data));
    }

  }; */

  /*  const consultarfacturadatil =async(id)=>{
    const rest2= await getDatilFacturacId(api_key,firma_certificado_clave,id)
      console.log("respuesta factura =========")
      alert(JSON.stringify(rest2))
      
  } */

  const toDataURL = async () => {
    const firmaFotoVet = await Axios.post("/downloadStorageFile", {
      file: logo,
    });

    return firmaFotoVet.data.url;

    /* const response = await fetch(firmaFotoVet.data.url);
      const file = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        console.log(firmaFotoVet.data.url);
        console.log(reader.result);
        await setima(reader.result);
      }; */
  };

  return (
    <>
      {/*  <label>Listado de Categorias</label>
      {loadLista ? (
        <div className="d-flex justify-content-center align-items-center">
          {" "}
          <label>Cargando datos ...</label>
        </div>
      ) : (
        <div>
          {dataCategorias.length > 0 ? (
            <div></div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              {" "}
              <label>No existan categorias</label>
            </div>
          )}
        </div>
      )} */}

      <ComponentToPrint
        data={selectData}
        imprimir={imprimir}
        logo={logo}
        ima={ima}
        /* limpiar={limpiar}*/
        /*         updatedatesend={updatedatesend}
         */
      />

      <div className="tb-respo row">
        <DataTableDefault
          scrolx={""}
          data={dataCategorias}
          columns={columns}
          title={""}
          height={100}
        />
      </div>

      <ModalDefault
        bandera={"confirmacion"}
        modal={modalconfirmacion}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActions}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará la categoría{" "}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => setModalConfirmacion(false)}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectData !== null) {
                  eliminarCategoriaFn(selectData.categoria_id);
                } else {
                  toast.error("No existe el indice de la categoría");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        modal={modaldetallesfactura}
        size={"lg"}
        titulo="Detalles factura"
        closeAction={closeAction}
      >
        <DetalleVenta data={selectData}></DetalleVenta>
      </ModalDefault>
      <ModalDefault
        modal={modalautorizarfactura}
        size={"md"}
        titulo="Confirmación"
        closeAction={closeAction}
      >
        <div className="col-m-12">
          <div className="row" style={{ flexWrap: "wrap" }}>
            {stateverificacionsri}
          </div>
          {/*  <hr />
          <div className="row ">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <button
                type=""
                className="bg-btn-vet-danger  mx-1"
                onClick={() => setModalAutorizacionFactura(false)}
              >
                Cancelar
              </button>
              <button
                type=""
                className="bg-btn-vet mx-1"
                onClick={() => autorizarsri()}
              >
                Enviar
              </button>
            </div>
          </div> */}
        </div>
      </ModalDefault>
      <ModalDefaultPreload
        modal={modalsenmail}
        maxWidth={"35%"}
        closeAction={closeAction2}
      >
        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
          {/*  <Spinnericon className="svgpreloadfactura" /> */}
          <img
            style={{ width: "280px" }}
            src="https://i.pinimg.com/originals/af/10/b0/af10b0661568f8aa2f98a43f7298224e.gif"
            alt=""
          />
          <div>Enviando correo</div>
          <div>...</div>
        </div>
      </ModalDefaultPreload>
    </>
  );
};

export default TablaVentas;
