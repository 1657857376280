import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuthState from './Context/auth/authState';
import Pacientes from './Context/pacientes/pacientesState'
import Publicaciones from "./Context/publicaciones/PublicacionesState"
/* import 'bootstrap/dist/css/bootstrap.css';
 */import "react-toastify/dist/ReactToastify.css";
import "react-datalist-input/dist/styles.css";
import "./index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { PrimeReactProvider } from "primereact/api";

import { ToastContainer} from "react-toastify";

import reportWebVitals from './reportWebVitals';
import "primereact/resources/themes/lara-light-cyan/theme.css";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <PrimeReactProvider >
    <AuthState>
      <Publicaciones>
        <Pacientes>
          <App />
          <ToastContainer />
        </Pacientes>
      </Publicaciones>
    </AuthState>
  </PrimeReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


