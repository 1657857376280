import React, { useState, useContext } from "react";
import AuthContext from "../../../Context/auth/authContext";
import TablaServicioContratados from "./tabla";
import CrearEditarServicioContratado from "./crearEditar";

const ServicioContratados = ({ afiliado, setAfiliado, setModalActividad }) => {
  const { sessiondatacli, index } = useContext(AuthContext);
  const {veterinariaId,codigo }= sessiondatacli[index].veterinaria[0];
  
  const [actualizarLista, setActualizarLista] = useState(false);

  const [servicioContratado, setServicioContratado] = useState({
    afiliado_id: afiliado?.afiliado_id,
    forma_pago: "",
    cta_bancaria: "",
    cta_institucion: "",
    activo: true,
    empresa_id: veterinariaId,
    aplica_debito_bancario: false,
    estado_debito_bancario: "",
    auth_debito_bancario: "",
    servicio_afiliado_config_id: "",
  });

  const [editar, setEditar] = useState(false);

  const resetServicioContratado = () => {
    setServicioContratado({
      afiliado_id: afiliado?.afiliado_id,
      forma_pago: "",
      cta_bancaria: "",
      cta_institucion: "",
      activo: true,
      empresa_id: veterinariaId,
      aplica_debito_bancario: false,
      estado_debito_bancario: "",
      auth_debito_bancario: "",
      servicio_afiliado_config_id: "",
    });
  };

  const toggleActualizarLista = () => setActualizarLista(!actualizarLista);

  return (
    <div className="row m-0 p-0 ">
      <div className="col-md-3">
        <CrearEditarServicioContratado
          editar={editar}
          actualizarLista={toggleActualizarLista}
          servicioContratado={servicioContratado}
          setServicioContratado={setServicioContratado}
          resetServicioContratado={resetServicioContratado}
          setEditar={setEditar}
          empresa={veterinariaId}
          empresa_code={codigo}
        />
      </div>
      <div className="col-md-9">
        <TablaServicioContratados
          veterinaria_id={veterinariaId}
          actualizarLista={actualizarLista}
          setEditar={setEditar}
          setServicioContratado={setServicioContratado}
          empresa={veterinariaId}
          afiliado={afiliado?.afiliado_id}
          empresa_code={codigo}
        />
      </div>
    </div>
  );
};

export default ServicioContratados;
