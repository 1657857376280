import axios from "axios";
import { ApiCreatePresignedUrl } from "../api/s3/presigned";

// Función para subir un archivo
export const uploadFile = async (file, setProgress,empresa_code) => {
  const currentDate = Date.now();
  const name = file.name.split(".").slice(0, -1).join(".");
  const cleanName = cleanFileName(name);

  const extension = file.name.split(".").pop();

  const key = `${empresa_code}/${cleanName}-${currentDate}.${extension}`;

  try {
    const res = await ApiCreatePresignedUrl(key);
    //console.log("🚀 ~ uploadFile ~ ApiCreatePresignedUrl ~ res", res);

    if (res.sms === "err") {
      return {
        success: false,
        message: "Error al generar URL prefirmada: " + res.mensaje,
      };
    }

    const { url, fields } = res.data;

    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);

    // Enviar el archivo al servidor de S3
    await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress({
          status: true,
          progress: progress,
          file: file.name,
        });
      },
    });

    return {
      success: true,
      message: "Archivo subido correctamente.",
      key: key,
    };
  } catch (error) {
    console.error("❌ ~ uploadFile ~ error", error);
    return {
      success: false,
      message: "Error al subir el archivo: " + error.message,
    };
  }
};

const cleanFileName = (fileName) => {
  const regex = /[^\w\-.]/g; // Elimina todos los caracteres excepto letras, números, guiones, puntos y guiones bajos
  return fileName.replace(regex, "");
};
