import React, { useContext, useEffect, useState } from "react";

import Axios from "../../config/Axios";
import { Link ,useParams} from "react-router-dom";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import PublicacionesContext from "../../Context/publicaciones/PublicacionesContext";
/* import UbicacionesContext from "../../Context/ubicaciones/UbicacionesContext";
 */
import { Carousel } from "react-responsive-carousel";

import AuthContext from "../../Context/auth/authContext";
import Comentario from "./comentarios";
import { useMediaQuery } from "react-responsive";

const PublicacionCode = ({ data, match }) => {
  const params = useParams()
  moment.locale("es");

  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli } = AuthenticationContext;
  const { usuario_id} = sessiondatacli[0].persona[0];

  const { veterinaria_id } = sessiondatacli[0].veterinaria[0];

  const publicacionesContext = useContext(PublicacionesContext);
  const { publicacion, getPublicacionCodeFn, actualizartotalcomentarioFn } =
    publicacionesContext;

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const [stateLoadInfo, setStateLoadInfo] = useState({
    publicaciones: [],
    currentPage: 1,
    todosPerPage: 1,
  });

  const CargarImagenes = ({ imagen }) => {
    return (
      <img
        className="w-40"
        src={
          imagen == null
            ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
            : imagen
        }
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          border: `1px solid white`,
          borderRadius: "35px",
        }}
      ></img>
    );
  };

  const CargarImagenesPublic = ({ imagen }) => {
    return (
      <img
        className="w-40"
        src={
          imagen == null
            ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
            : imagen
        }
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          border: `1px solid white`,
        }}
      ></img>
    );
  };

  const init = async () => {
    getPublicacionCodeFn(params.code_pm,usuario_id);
  };



  //////////////////////////

  useEffect(() => {
    alert(params.code_pm);
    getPublicacionCodeFn(params.code_pm,usuario_id);
  }, []);

  return (
    <div className="row w-100">
      {!isMobile ? (
        <div className="col-md-3 ">
          <div className="" style={{ margin: "auto", maxWidth: "900px" }}>
            <div className="card-body"></div>
          </div>
        </div>
      ) : null}
      <div
        className="col-md-6"
        style={{
          background:"white"
        }}
      >
        {publicacion.length > 0 ? (
          <>
          {publicacion.map((data, index) => {
            return (
              <div key={index}>
                {data.datamascota !== null ? (
                 <div className="card bg-white my-2 borde-tarjeta" >
                 <div className="card-title mb-0 px-4 pt-4 d-flex justify-content-between">
                   <dvi>
                     <div
                       className="w-100"
                       key={index}
                     >
                       <Link to="#" className=" w-100">
                         <div className=" p-0 mx-1 d-flex justify-content-between align-items-center  ">
                             <div
                               className=""
                               style={{ width: "50px", height: "50px" }}
                             >
                               <CargarImagenes
                                 imagen={
                                   //data.datausuario[0].urlFotoUsuario
                                   data.datamascota[0].urlFotoMascota
                                 }
                               ></CargarImagenes>
                             </div>
                           <div className="mx-2">
                             <div className="font-weight-bold">
                               {data.datamascota[0].nombres}
                             </div>
                             <div className="text-muted" style={{fontSize:"12px"}}>
                               {" "}
                               Publicado por{" "}
                               {data.datausuario[0].nombres.toLowerCase()}
                             </div>
                             <div className="text-muted" style={{fontSize:"12px"}}>
                               {" "}
                               {moment(data.fpublicacion).fromNow()}
                             </div>
                           </div>
                         </div>
                       </Link>
                     </div>
                   </dvi>
                 </div>
                 <div className="card-body pb-0">
                   <TextareaAutosize
                     style={{
                       width: "100%",
                       textDecoration: "none",
                       border: "none",
                       background: "none",
                     }}
                     value={data.descripcion}
                   />{" "}
                   {data.archivos !== null ? (
                     data.archivos.length > 0 ? (
                       <div
                         className="row d-flex justify-content-center"
                         style={{
                           overflowX: "auto",
                           flexWrap: "initial",
                         }}
                       >
                         <Carousel
                           className="w-100"
                           showArrows={true}
                           swipeable={true}
                           showThumbs={false}
                           emulateTouch={true}
                           autoPlay={false}
                         >
                           {/* <div>
                             <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmRJA8VpG1f5dnAj5d1O_D-Q9coIt4kwnOdR0LQkaXBONlctj0z-eAMSe_YAztu7BF8-Q&usqp=CAU" />
                             <p className="legend">Legend 1</p>
                           </div> */}
                           {data.archivos.map((d, i) => {
                             return (
                               <div
                                 key={i}
                                 /*  className={
                                 data.archivos.length === 0
                                   ? null
                                   : data.archivos.length === 1
                                   ? "col-md-12 p-0 floatizquierda"
                                   : data.archivos.length > 1
                                   ? "col-md-12 p-0 floatizquierda"
                                   : null
                               } */
                               >
                                 {d.type=== "video" ? (
                                   <video
                                     controls
                                     style={{
                                       textAlign: "center",
                                       width: "100%",
                                       height: "100%",
                                     }}
                                     loop
                                     muted
                                     autoPlay
                                     src={d.urlFoto}
                                     preload={"auto"}
                                     //type={d.type}
                                     //className={classes.video}
                                     //ref={ref => this.headerVideo}
                                     //onLoadEnd={() => this.setState({isLoading: false})}
                                   ></video>
                                 ) : (
                                   <CargarImagenesPublic
                                     imagen={d.urlFoto}
                                   ></CargarImagenesPublic>
                                 )}
                               </div>
                             );
                           })}
                         </Carousel>
                       </div>
                     ) : null
                   ) : null}
                 </div>
                 
               </div>
                ) : data.dataveterinaria !== null ? (
                  <div className="card bg-white my-2 borde-tarjeta" >
                  <div className="card-title mb-0 px-4 pt-4 d-flex justify-content-between">
                    <dvi>
                      <div
                        className="w-100"
                        key={index}
                      >
                        <Link
                          to={
                            "/veterinariaperfil/" +
                            data.dataveterinaria[0].codigo
                          }
                          className=" w-100"
                        >
                          <div className=" p-0 mx-1 d-flex justify-content-between align-items-center">
                              <div
                                className=""
                                style={{ width: "50px", height: "50px" }}
                              >
                                <CargarImagenes
                                  imagen={
                                    data.dataveterinaria[0]
                                      .urlFotoVeterinaria
                                  }
                                ></CargarImagenes>
                              </div>
                            <div className="mx-2">
                              <div className="font-weight-bold">
                                {data.dataveterinaria[0].nombre}
                              </div>
                              <div className="text-muted" style={{fontSize:"12px"}}>
                                {" "}
                                {moment(data.fpublicacion).fromNow()}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </dvi>
                    {/* <div>x</div> */}
                  </div>
                  <div className="card-body pb-0">
                    <TextareaAutosize
                      style={{
                        width: "100%",
                        textDecoration: "none",
                        border: "none",
                        background: "none",
                      }}
                      value={data.descripcion}
                    />{" "}
                    {data.archivos !== null ? (
                      data.archivos.length > 0 ? (
                        <div
                          className="row d-flex justify-content-center"
                          style={{
                            overflowX: "auto",
                            flexWrap: "initial",
                          }}
                        >
                          <Carousel
                            className="w-100"
                            showArrows={true}
                            swipeable={true}
                            showThumbs={false}
                            emulateTouch={true}
                            autoPlay={false}
                          >
                            {/* <div>
                              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmRJA8VpG1f5dnAj5d1O_D-Q9coIt4kwnOdR0LQkaXBONlctj0z-eAMSe_YAztu7BF8-Q&usqp=CAU" />
                              <p className="legend">Legend 1</p>
                            </div> */}
                            {data.archivos.map((d, i) => {
                              return (
                                <div
                                  key={i}
                                  /*  className={
                                  data.archivos.length === 0
                                    ? null
                                    : data.archivos.length === 1
                                    ? "col-md-12 p-0 floatizquierda"
                                    : data.archivos.length > 1
                                    ? "col-md-12 p-0 floatizquierda"
                                    : null
                                } */
                                >
                                  {" "}
                                  {d.type==="video" ? (
                                    <video
                                      controls
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      loop
                                      muted
                                      autoPlay
                                      src={d.urlFoto}
                                      preload={"auto"}
                                      //type={d.type}
                                      //className={classes.video}
                                      //ref={ref => this.headerVideo}
                                      //onLoadEnd={() => this.setState({isLoading: false})}
                                    ></video>
                                  ) : (
                                    <CargarImagenesPublic
                                      imagen={d.urlFoto}
                                    ></CargarImagenesPublic>
                                  )}
                                </div>
                              );
                            })}
                          </Carousel>
                        </div>
                      ) : null
                    ) : null}
                    
                  </div>
                </div>
                ) : null}
              </div>
            );
          })}
           <div class="d-flex justify-content-between px-5 py-0 align-items-center ">
          <div>
            <button
              className=" px-1 py-1"
              style={{
                borderRadius: "20px",
                border: "none",
                background: "white",
              }}
            >
              <i
                style={{ fontSize: "18px", color: "grey" }}
                className="fas fa-comment ml-1 mr-1"
              ></i>
            </button>
          </div>
          <div>
            {" "}
            <a href="/#">
              {publicacion[0].totalcomentarios[0].totalcomentario + " comentarios"}
            </a>
          </div>
        </div>
        <Comentario
          init={init}
          publicacion={publicacion}
          usuario_id={usuario_id}
          totalp={0}
          veterinaria={veterinaria_id}
        ></Comentario>
          </>
          
        ) : (
          <h5 className="w-100 p-12 text-center">cargando ...</h5>
        )}
       
      </div>
      {!isMobile ? (
        <div className="col-md-3 ">
          <div className="" style={{ margin: "auto", maxWidth: "900px" }}>
            <div className="card-body"></div>
          </div>
        </div>
      ) : null}
      {/*           <div className="col-md-1"></div>
       */}{" "}
    </div>
 
  );
};

export default PublicacionCode;
