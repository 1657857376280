import React, { useState, useEffect } from "react";
import DataTableDefault from "../../ReactDataTable";
import {
  getProductos,
  deleteProducto,
  getServicios,
} from "../../../api/contable/productos";
import { DataTable } from "mantine-datatable";
import { filtrarTablaDocumentos } from "./filterarray";

import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import ModalDefault from "../../modalDefault";
import { ReactComponent as AdminIcon } from "../../../icons/admin.svg";
import CrearEditarProductos from "./crearEditarProductos";
import { toast } from "react-toastify";
import moment from "moment";
const TablaPanelServicios = (props) => {
  const [modalPerfil, setModalPerfil] = useState(false);
  const [selectData, setselectData] = useState(null);
  const [modalEliminarProducto, setModalEliminarProducto] = useState(false);

  const [datarespaldo, setdatarespaldo] = useState([]);

  const [search, setSearch] = useState("");

  const PAGE_SIZE = 10;
  const [data, setdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(null);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [page]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [data]);

  useEffect(() => {
    initComponent();
  }, [props.actualizar]);

  const initComponent = async () => {
    setfetching(true);
    let respuesta = [];

    if (props.type === "servicio") {
      respuesta = await getServicios(props.empresa_id);
    } else {
      respuesta = await getProductos(props.empresa_id);
    }

    if (respuesta[0].sms === "ok") {
      setdata(respuesta[0].data);
      setRecords(respuesta[0].data.slice(0, PAGE_SIZE));
      setdatarespaldo(data);
    }
    setfetching(false);
  };

  const modalActions = (modal, bandera) => {
    if (bandera === "perfil") {
      setModalPerfil(modal);
      setselectData(null);
      props.crearrModalActualizar();
      return;
    }

    if (bandera === "eliminarprod") {
      setModalEliminarProducto(modal);
    }
  };

  const eliminarproducto = async (e) => {
    const res = await deleteProducto(selectData);
    if (res[0].sms === "ok") {
      toast.success(res[0].mensaje, {
        position: "bottom-left",
      });
      setModalEliminarProducto(false);
      initComponent();
    } else
      toast.error(res[0].mensaje, {
        position: "bottom-left",
      });
  };

  const editarfn = (row) => {
    setselectData(row);
    setModalPerfil(true);
  };

  const eliminarfn = (row) => {
    setselectData(row);
    setModalEliminarProducto(true);
  };

  const filtrar = async (search) => {
    const res = await filtrarTablaDocumentos(data, search);
    setdata(res);
    setRecords(res.slice(0, PAGE_SIZE));
  };

  useEffect(() => {
    if (search === "") {
      setdata(datarespaldo);
      setRecords(datarespaldo.slice(0, PAGE_SIZE));
      initComponent();
    }
    if (data.length === 0) {
      setdata(datarespaldo);
      setRecords(datarespaldo.slice(0, PAGE_SIZE));
    }
  }, [search]);

  const opcionesx = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="start">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              editarfn(row);
            }}
          >
            <i className="fas fa-edit " style={{ color: "blue" }}></i>
            <label for="" style={{ color: "blue" }}>
              {" "}
              Detalle de {row.descripcion_producto}
            </label>
          </DropdownItem>

          <DropdownItem
            className=""
            onClick={() => {
              eliminarfn(row);
            }}
          >
            <i className="fas fa-trash" style={{ color: "red" }}></i>{" "}
            <label for="" style={{ color: "red" }}>
              Eliminar {row.descripcion_producto}{" "}
            </label>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const onchange = (e) => {
    if (e.target.name === "search") {
      setSearch(e.target.value);
    }
  };
  const onkeyup = (e) => {
    filtrar(e.target.value);
  };

  return (
    <>
      <div>
        <div className="row mb-3">
          <div className="col-md-12 p-0 d-flex justify-content-between align-items-center">
            <div className="w-50 ">
              <label className="negrita py-1">
                Buscar nombre/código del item
              </label>
              <div className={"d-flex justify-content-between"}>
                {" "}
                <input
                  className="inputtext2 bordecompleto bordecompleto bordesombreado"
                  id="search"
                  name="search"
                  value={search}
                  placeholder="Ingrese un valor"
                  type="search"
                  onChange={onchange}
                  onKeyUp={onkeyup}
                />
              </div>
            </div>
            <div className="w-50 py-1 px-1 d-flex justify-content-start align-self-start align-items-start align-self-end">
              {/*  <button
                type=""
                className=" mx-2 h-100 bg-btn-vet bordecompleto bordesombreado"
                onClick={() => {
                  setSearch("");
                  initComponent();
                }}
              >
                <i className="fas fa-search mx-1"></i>
                Buscar
              </button> */}
              <button
                type=""
                className=" h-100 bg-btn-vet bordecompleto bordesombreado"
                onClick={() => {
                  setSearch("");
                  initComponent();
                }}
              >
                <i className="fas fa-sync-alt mx-1"></i>
                Recargar
              </button>

              {/*  <ExportToExcel
                  finalDataDetail={data}
                  state={reporte}
                ></ExportToExcel> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <DataTable
          loaderVariant="dots"
          loaderSize="xl"
          loaderColor="blue"
          loaderBackgroundBlur={5}
          styles={(theme) => ({
            root: {
              border: `1px solid ${theme.colors.orange[6]}`,
              borderRadius: theme.radius.md,
            },
            header: { "&& tr": { background: "red!important" } },
            footer: { fontStyle: "italic" },
          })}
          borderRadius="md"
          className="bordecompleto bgfondo"
          noRecordsText="No se encontraron items"
          records={records}
          columns={[
            {
              accessor: "descripcion_producto",
              title: "Item",
              textAlignment: "left",
              render: ({ descripcion_producto }) => descripcion_producto,
            },
            {
              accessor: "precio_venta",
              title: "Valor",
              textAlignment: "left",
              render: ({ precio_venta }) => `$${precio_venta}`,
            },
            /* {
              accessor: "recurrente",
              title: "Recurrente",
              textAlignment: "left",
              render: ({ recurrente, periodo_pagos }) =>
                recurrente ? (
                  periodo_pagos ? (
                    <span className="badge rounded-pill bg-success">
                      {periodo_pagos.periodo}
                    </span>
                  ) : (
                    <span className="badge rounded-pill bg-warning">SI-N/A</span>
                  )
                ) : (
                  <span className="badge rounded-pill bg-primary">NO</span>
                ),
            }, */
            {
              accessor: "Categoría",
              title: "categoria",
              textAlignment: "left",
              render: ({ categorias }) =>
                categorias ? categorias.categoria : "",
            },
            {
              accessor: "create_at",
              title: "Fecha de cración",
              textAlignment: "center",
              render: ({ create_at }) => moment(create_at).format("MMM D YYYY"),
            },
            {
              accessor: "estado",
              textAlignment: "center",
              width: 120,
              render: ({ eliminado }) => (!eliminado ? "ACTIVO" : "INACTIVO"),
            },
            {
              accessor: "acciones",
              title: "Acciones",
              textAlignment: "center",
              render: (record) => opcionesx(record),
            },
          ]}
          totalRecords={data?.length}
          recordsPerPage={PAGE_SIZE}
          page={page}
          onPageChange={(p) => setPage(p)}
          fetching={fetching}
          rowContextMenu={{
            items: (record) => [
              // ...

              {
                key: "edit",
                color: "blue",
                icon: (
                  <i
                    className="fas fa-edit"
                    style={{ color: "blue!important" }}
                  ></i>
                ),
                title: `Editar  ${record.descripcion_producto}`,
                onClick: () => {
                  editarfn(record);
                },
              },
              {
                key: "delete",
                color: "red",
                icon: <i className="fas fa-trash"></i>,
                title: `Eliminar  ${record.descripcion_producto}`,
                onClick: (e) => {
                  eliminarfn(record);
                },
              },

              // ...
            ],
          }}
        />
      </div>
      <ModalDefault
        bandera={"perfil"}
        modal={modalPerfil}
        maxWidth={"50%"}
        icontitulo={<AdminIcon width={250} />}
        titulo={`  EDITANDO DE REGISTRO`}
        closeAction={modalActions}
      >
        {selectData ? (
          <CrearEditarProductos
            selectData={selectData}
            crearrModalActualizar={props.crearrModalActualizar}
            type={selectData.tipoitem}
          />
        ) : (
          "Espere un momento porfavor ..."
        )}
      </ModalDefault>
      <ModalDefault
        bandera={"eliminarprod"}
        modal={modalEliminarProducto}
        maxWidth={"40%"}
        icontitulo={<AdminIcon width={250} />}
        titulo={`CONFIRMACIÓN`}
        closeAction={modalActions}
      >
        <div className="d-flex justify-content-center align-items-center w-100 py-2">
          SE ELIMINARÁ EL ITEM
        </div>
        <div className="d-flex justify-content-center alig-items-center">
          <button
            type=""
            className="bg-btn-vet-danger bordesombreado mx-1"
            onClick={() => {
              setModalEliminarProducto(false);
              setselectData(null);
            }}
          >
            Cancelar
          </button>
          <button
            type=""
            className="bg-btn-vet mx-1 bordesombreado"
            onClick={() => eliminarproducto()}
          >
            Confirmar
          </button>
        </div>
      </ModalDefault>
      {/*  <ModalDefault bandera={"otracosa"} modal={false} size={"sm"} icontitulo={<AdminIcon/>} titulo={"Titulo modal"} closeAction={modalActions}>
                modal de editar usuario
            </ModalDefault> */}
    </>
  );
};

export default TablaPanelServicios;
