import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createRol } from "../../../api/roles";
const CreateRol = ({ veterinaria_id, rolcreadoFn }) => {
  const [state, setState] = useState({
    rol: "",
    allaccess: "",
    veterinaria_id: veterinaria_id,
  });

  const onchange = (e) => {
    if (e.target.name === "allaccess") {
      setState({
        ...state,
        allaccess: e.target.value === "true" ? true : false,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const createRolFn = async () => {

if(state.rol===""){
    toast.error("Debe ingresar el nombre del rol ",{
        position:"bottom-left",
    })
    return
}

if(state.allaccess===""){
     toast.error("Seleccione el tipo de acceso", {
       position: "bottom-left",
     });
     return;
}

    const res = await createRol(state);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      actualizarListaFn();
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };


  const actualizarListaFn = () => {
    rolcreadoFn();
  };
  return (
    <div>
      <div className="col-md-12">
        <div className="form-group row">
          <div className="col-md-3 d-flex d-flex justify-content-start align-items-center">
            {" "}
            <label className="" htmlFor="exampleInputEmail1">
              Nombre del rol * :
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              className="inputtext form-control"
              onChange={onchange}
              value={state.rol}
              id="rol"
              name="rol"
              placeholder="Ingrese el rol"
            />
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group row">
          <div className="col-md-3 d-flex d-flex justify-content-start align-items-center">
            {" "}
            <label className="" htmlFor="exampleInputEmail1">
              Tipo de acceso :
            </label>
          </div>
          <div className="col-md-8">
            <select
              className="inputselect2 form-control  select2bs4"
              id="allaccess"
              name="allaccess"
              style={{ width: "100%" }}
              onChange={onchange}
              value={state.allaccess}
            >
              <option value="">Seleccione ...</option>

              <option value="true">Todos los accesos</option>
              <option value="false">Personalizado</option>
            </select>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="col-md-12">
        <button
          type=""
          className="bg-btn-vet w-100"
          onClick={() => createRolFn()}
        >
          Crear rol
        </button>
      </div>
    </div>
  );
};

export default CreateRol;
