import React, { useEffect, useState, useContext } from "react";
import Menu from "../../../Components/Sidebar/SideBar";
import ConfiguracionesVeterinarias from "./veterianria/veterinaria";
import Servicios from "./Servicios";
import Ubicaciones from "./ubicaciones";
import Roles from "../../roles_permisos/index";
import Modulos from "../../modulos/index";
import ConfiguracionesUsuario from "./usuario/usuario";
import { useParams } from "react-router-dom";
const routes = [
  {
    path: "/usuario",
    name: "Perfil de usuario",
    icon: "fa fa-user",
    exact: true,
    type: "",
  },
  {
    path: "/perfilempresa",
    name: "Perlfil establecimiento",
    icon: "fas fa-building",
    exact: true,
    type: "",
  },
  {
    path: "/modulos",
    name: "Módulos de establecimiento",
    icon: "fas fa-table",
    exact: true,
    type: "",
  },
  {
    path: "/roles",
    name: "Roles y permisos",
    icon: "fas fa-users",
    exact: true,
    type: "",
  },
  {
    path: "/servicios",
    name: "Servicios",
    icon: "fas fa-voicemail",
    exact: true,
    type: "veterinaria",
  },
  {
    path: "/ubicacion",
    name: "Ubicación",
    icon: "fas fa-map",
    exact: true,
    type: "",
  },
  /* {
    path: "/#",
    name: "Configuraciones generales",
    icon: <CogIcon width="100%" />,
    exact: true,
    type:"",
    subRoutes: [
      {
        path: "/seguridad/tiposeventos",
        name: "Tipo de eventos",
        icon: <HomeIcon width="100%" />,
      },
    ],
  }, */
];

const ConfiguracionesIndex = () => {
  const { panel } = useParams();

  const [stateContenedor, setContenedor] = useState(null);

  useEffect(() => {
    switch (panel) {
      case "ubicacion":
        setContenedor(<Ubicaciones />);
        break;
      case "servicios":
        setContenedor(<Servicios />);
        break;
      case "roles":
        setContenedor(<Roles />);
        break;
      case "modulos":
        setContenedor(<Modulos />);
        break;
      case "usuario":
        setContenedor(<ConfiguracionesUsuario />);
        break;
      default:
        setContenedor(<ConfiguracionesVeterinarias />);
        break;
    }
    return () => {};
  }, [panel]);

  return (
    <div className="w-100 d-flex justify-content-between ">
      <div className="">
        <Menu
          routes={routes}
          titulo="Seguridad"
          subpath={"/seguridad"}
        ></Menu>
      </div>
      <div className="w-100 bgfondo  h-100 " style={{ minHeight: "90vh" }}>
        {stateContenedor}
      </div>
    </div>
  );
};

export default ConfiguracionesIndex;
