import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import moment from "moment";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import ModalDefault from "../../modalDefault";

import {
  GetAfiliadosServiciosEmpresaApi,
  EliminarAfiliadoServiciosApi,
} from "../../../api/afiliaciones_socios/servicios";
import {
  EliminarAsignacionServicioApi,
  GetServicioAsignacionEmpresaUsuario,
} from "../../../api/afiliaciones_socios/servicioContratado";

const TablaServicioContratados = (props) => {
  const PAGE_SIZES = 5; // Define los tamaños de página disponibles
  const [page, setPage] = useState(1); // Estado para la página actual
  const [data, setData] = useState([]); // Estado para los datos totales
  const [records, setRecords] = useState([]); // Estado para los registros que se muestran actualmente

  const [selectedServicioContratado, setSelectedServicioContratado] =
    useState(null);
  const [modalCofirmacion, setModalConfirmacion] = useState(false);

  const tipoServicios = [
    {
      tipo: "REGISTRO",
      code: "REGISTRO_SOCIOS",
    },
    {
      tipo: "BENEFICIOS",
      code: "BENEFICIOS_SOCIOS",
    },
  ];

  useEffect(() => {
    const getRecordsEmpresa = async () => {
      const res = await GetServicioAsignacionEmpresaUsuario({
        empresa_id: props.empresa,
        afiliado_id: props.afiliado,
      });
      if (res.sms === "ok") {
        setData(res.data);
      } else {
        toast.error(res.mensaje);
      }
    };

    getRecordsEmpresa();
  }, [props.actualizarLista]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZES;
    const to = from + PAGE_SIZES;
    setRecords(data.slice(from, to));
  }, [page, data]);

  const handleEdit = (servicioContratado) => {
    props.setServicioContratado(servicioContratado);
    props.setEditar(true);
  };

  const handleDelete = (servicioContratado) => {
    setSelectedServicioContratado(servicioContratado);
    setModalConfirmacion(true);
  };

  const eliminarServicioContratadoFn = async (afiliado_servicio_asignacion) => {
    try {
      const respuesta = await EliminarAsignacionServicioApi({
        afiliado_servicio_asignacion,
      });
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        const d = data.filter(
          (cat) =>
            cat.afiliado_servicio_asignacion !== afiliado_servicio_asignacion
        );
        setData(d);
        setSelectedServicioContratado(null);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar la petición");
    } finally {
      setModalConfirmacion(false);
    }
  };

  const acciones = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="end">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleEdit(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> editar
          </DropdownItem>

          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleDelete(row);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      <DataTable
        borderRadius="md"
        className="bordecompleto bgfondo"
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        columns={[
          {
            accessor: "afiliado_servicio_asignacion",
            title: " ",
            render: (row) => (
              <div className="d-flex justify-content-center">
                {acciones(row)}
              </div>
            ),
          },
          {
            accessor:
              "afiliados_servicios_config.productos.descripcion_producto",
            title: "Servicio",
            textAlignment: "left",
          },
          {
            accessor: "afiliados_servicios_config.forma_pago",
            title: "Forma de pago",
            textAlignment: "left",
            render: (row) => (
              <div className="d-flex justify-content-center">
                {row.forma_pago}
              </div>
            ),
          },
          {
            accessor: "afiliados_servicios_config.detalle_pago",
            title: "Detalle",
            textAlignment: "left",
          },
          {
            accessor: "afiliados_servicios_config.tipo_servicio",
            title: "Tipo de servicio",
            textAlignment: "left",
            render: ({ afiliados_servicios_config }) =>
              tipoServicios.find(
                (t) => t.code === afiliados_servicios_config.tipo_servicio
              )?.tipo,
          },
          {
            accessor: "afiliado_categoria",
            title: "Categoria",
            textAlignment: "left",
            render: ({ afiliados_servicios_config }) =>
              afiliados_servicios_config.afiliado_categoria
                ? afiliados_servicios_config.afiliado_categoria.categoria
                : "Sin categoria",
          },
          {
            accessor: "afiliados_servicios_config.periodo_pagos",
            title: "Periodo de pago",
            textAlignment: "left",
            render: ({ afiliados_servicios_config }) =>
              afiliados_servicios_config.periodo_pagos
                ? afiliados_servicios_config.periodo_pagos.periodo
                : "Sin periodo",
          },
          {
            accessor: "afiliados_servicios_config.valor",
            title: "Valor",
            textAlignment: "left",
            render: (row) =>
              row.afiliados_servicios_config.valor
                ? `$${row.afiliados_servicios_config.valor}`
                : row.afiliados_servicios_config.productos.precio_venta
                ? `$${row.afiliados_servicios_config.productos.precio_venta}`
                : "Sin valor",
          },
          {
            accessor: "fecha",
            title: "Fecha de registro",
            textAlignment: "left",
            render: ({ create_at }) => moment(create_at).format("MM/DD/YYYY"),
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={PAGE_SIZES}
        page={page}
        onPageChange={setPage}
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalCofirmacion}
        size={"sm"}
        titulo={`Confirmación`}
        closeAction={() => {
          setModalConfirmacion(false);
        }}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará la contratacion del servicio{" "}
            {selectedServicioContratado?.servicioContratado}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => {
                setModalConfirmacion(false);
                setSelectedServicioContratado(null);
              }}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectedServicioContratado !== null) {
                  eliminarServicioContratadoFn(
                    selectedServicioContratado.afiliado_servicio_asignacion
                  );
                } else {
                  toast.error("No existe el indice de la configuracion.");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
    </>
  );
};

export default TablaServicioContratados;
