import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { getServiciosEmpresa } from "../../../api/contable/productos";
import { GetAllPeriodosPagosApi } from "../../../api/afiliaciones_socios/periodos_pagos";
import { getAllCategoriasServiciosApi } from "../../../api/afiliaciones_socios/categorias.js";
import {
  crearAfiliadoSocioServiciosApi,
  EditarAfiliadoSocioServiciosApi,
} from "../../../api/afiliaciones_socios/servicios";

const CrearEditarAfiliadoServicios = ({
  editar,
  configServicio,
  setConfigServicio,
  actualizarLista,
  resetConfigServicio,
  setEditar,
  empresa,
}) => {
  const tipoServicios = [
    { tipo: "REGISTRO", code: "REGISTRO_SOCIOS" },
    { tipo: "BENEFICIOS", code: "BENEFICIOS_SOCIOS" },
  ];

  const [periodosPagos, setPeriodosPagos] = useState([]);
  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPeriodosPagosFn = async () => {
      const respuesta = await GetAllPeriodosPagosApi();
      setPeriodosPagos(respuesta.data);
    };
    const getServiciosEmpresaFn = async () => {
      const respuesta = await getServiciosEmpresa(empresa);
      setProductos(respuesta.data);
    };

    const getCategoriasEmpresaFn = async () => {
      const respuesta = await getAllCategoriasServiciosApi(empresa);
      setCategorias(respuesta.data);
    };

    getPeriodosPagosFn();
    getServiciosEmpresaFn();
    getCategoriasEmpresaFn();
  }, [empresa]);

  const onchange = (e) => {
    const { name, value } = e.target;
    let update = { [name]: value };

    if (name === "producto_id") {
      const producto = productos.find(
        (p) => p.producto_id.toString() === value
      );
      if (producto) {
        update.valor = producto.precio_venta;
      }
    }

    setConfigServicio((prevConfig) => ({
      ...prevConfig,
      ...update,
    }));
  };

  const guardarConfigServicio = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      configServicio.producto_id === "" ||
      configServicio.valor === "" ||
      configServicio.tipo_servicio === "" ||
      configServicio.detalle_pago === "" ||
      configServicio.afiliado_categoria_id === ""
    ) {
      toast.error("Todos los campos son obligatorios");
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const apiFunc = editar
        ? EditarAfiliadoSocioServiciosApi
        : crearAfiliadoSocioServiciosApi;

      const respuesta = await apiFunc(configServicio);
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        actualizarLista();
        resetConfigServicio();
        setEditar(false);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar la petición");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form inline className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label className=" negrita">Servicio</label>
          <select
            className=" inputselect bordecompleto"
            id="producto_id"
            name="producto_id"
            style={{ width: "100%" }}
            onChange={onchange}
            value={configServicio.producto_id}
          >
            <option value="">Serivicio</option>
            {productos.map((data, index) => (
              <option value={data.producto_id} key={index}>
                {data.descripcion_producto} (tarifa - {data.iva_tarifa}%)
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group">
          <label className=" negrita">Valor</label>
          <div className="d-flex justify-content-between align-items-center">
            <h4>$</h4>
            <input
              value={configServicio.valor}
              className="inputtext bordecompleto mx-1"
              type="numeric"
              name="valor"
              onChange={onchange}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label className=" negrita">Tipo de servicio</label>
          <select
            className=" inputselect bordecompleto"
            id="tipo_servicio"
            name="tipo_servicio"
            style={{ width: "100%" }}
            onChange={onchange}
            value={configServicio.tipo_servicio}
          >
            <option value="">Seleccione un tipo</option>
            {tipoServicios.map((data, index) => (
              <option value={data.code} key={index}>
                {data.tipo}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-md-12 d-flex flex-column">
        <label className="negrita">Seleccione una categoria para este servicio.</label>
        <div className="form-group d-flex justify-content-center">
          <select
            select
            className=" inputselect2 bordecompleto"
            id="afiliado_categoria_id"
            name="afiliado_categoria_id"
            style={{ width: "100%" }}
            onChange={onchange}
            value={configServicio.afiliado_categoria_id}
          >
            <option value={""}>Seleccione</option>
            {categorias.length > 0 &&
              categorias.map((data, index) => {
                return (
                  <option value={data.afiliado_categoria_id} key={index}>
                    {data.categoria}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="col-md-12 my-1 d-flex justify-content-start flex-column">
        <FormGroup
          switch
          className="d-flex justify-content-start align-items-center py-1"
        >
          <Input
            style={{ width: "50px", height: "20px" }}
            type="switch"
            id="aplica_recurrencia"
            name="aplica_recurrencia"
            checked={configServicio.aplica_recurrencia}
            onClick={() => {
              setConfigServicio((prevState) => ({
                ...prevState,
                aplica_recurrencia: !prevState.aplica_recurrencia,
                periodo_pago_id: prevState.aplica_recurrencia
                  ? ""
                  : prevState.periodo_pago_id,
              }));
            }}
          />
          <Label className="mx-2" check>
            Pago recurrente
          </Label>
        </FormGroup>

        {configServicio.aplica_recurrencia === true && (
          <div className="col-md-12 bordecompleto py-2 bgfondo2">
            <div className="row">
              <div className="col-md-8 d-flex flex-column">
                <label className="negrita">
                  Seleccione el periodo de tiempo.
                </label>
                <div className="form-group d-flex justify-content-center">
                  <select
                    select
                    className=" inputselect2 bordecompleto"
                    id="periodo_pago_id"
                    name="periodo_pago_id"
                    style={{ width: "100%" }}
                    onChange={onchange}
                    value={configServicio.periodo_pago_id}
                  >
                    <option value={""}>Seleccione</option>
                    {periodosPagos.length > 0 &&
                      periodosPagos.map((data, index) => {
                        return (
                          <option value={data.periodo_pago_id} key={index}>
                            {data.periodo}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className=" negrita">Dias de pago extra</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <input
                      value={configServicio.dia_maximo_pago_config}
                      className="inputtext bordecompleto mx-1"
                      type="number"
                      name="dia_maximo_pago_config"
                      onChange={onchange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="col-md-12 py-2">
        <div className="form-group ">
          <label className="negrita">Detalle del comprobante:</label>
          <div className={"d-flex justify-content-between"}>
            <input
              className="inputtext bordecompleto"
              disabled={editar ? true : false}
              id="detalle_pago"
              name="detalle_pago"
              value={configServicio.detalle_pago}
              placeholder="Ingrese el detalle que se generara en el comprobante de pago"
              type="text"
              onChange={onchange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center flex-row align-items-center my-3">
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        ) : (
          <>
            <button
              className="w-50 bg-btn-vet-opciones bordesombreado mx-1"
              disabled={loading}
              onClick={guardarConfigServicio}
            >
              {editar ? "Editar servicio" : "Crear servicio"}
            </button>
            {editar && (
              <button
                className="w-5 bg-btn-vet-opciones bordesombreado"
                onClick={() => {
                  resetConfigServicio();
                  setEditar(false);
                }}
              >
                X
              </button>
            )}
          </>
        )}
      </div>
    </Form>
  );
};

export default CrearEditarAfiliadoServicios;
