import React, { useState, useEffect, useContext } from "react";
import Items from "../../helpers/facturacion/getDataItems";

import CabeceraFactura from "./cabecera";
import TransorteTraslado from "./transporteytraslado";

import ConfigurablesPagos from "./configurables";
import moment from "moment";
import AuthContext from "../../Context/auth/authContext";

import {
  editarVentaApi,
  guardarVentaApi,
  crearFirmarXmlVentaApi,
} from "../../api/contable/ventas";
import { verificarAutorizacionsriApi } from "../../api/contable/facturacion";
import {
  GETPorcentajeSustentoApi,
  GETTipoSustentoApi,
  GETtipoImpuestosRetencionesApi,
} from "../../api/contable/retenciones";

import {
  getTipoIdentificacion,
  getTipoDeDocumento,
  getTipoPago,
  getTipoImpuesto,
  getPorcentajeImpuesto,
  getTipoPagoSri,
} from "../../api/contable/configuraciones";
import { useNavigate } from "react-router-dom";
import ModalDefault from "../modalDefault";
import ModalDefaultPreload from "../modalpreload";

import TablaVentaslimit from "../contable/documentos/documentos";
import TablaGuiasRemision from "../contable/documentos/guiaRemision/listadoGuiaremision";
import TablaFacturas from "../contable/documentos/facturas/listadoFacturas";
import TablaRetenciones from "../contable/documentos/retenciones/listadoRetenciones";
import Tablaliquidaciones from "../contable/documentos/liquidacionesCompra/listadoLiquidaciones";
import TablaRecibos from "../contable/documentos/recibos/listadoRecibos";

import { validarfactura, validarRetencion } from "./validaciones";
import { toast } from "react-toastify";
import { ReactComponent as Spinnericon } from "../../icons/spiner.svg";
import "./index.css";
import {
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { getInfoDocumento } from "../../helpers/facturacion/infoDocumento";
import { getPagosData } from "../../helpers/facturacion/getPagos";
const VentasFacturas = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;
  const infovet = sessiondatacli[index].veterinaria[0];
  const {
    veterinaria_id,
    establecimiento_punto_emision,
    contribuyente_especial,
    obligado_contabilidad,
    establecimiento_codigo,
    nombre,
    direccion,
    ruc,
    regimen,
    init_number_venta,
    init_number_liquidacion,
    init_number_retencion,
    init_number_guia_remision,
    init_number_recibos,
    api_key,
    firma_certificado_clave,
    nombre_comercial,
    agente_retencion,
    calificacion_artesanal,
  } = infovet;
  const { usuario_id, nombres } = sessiondatacli[index].persona[0];
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [tiposSustentosRetenciones, settiposSustentosRetenciones] = useState(
    []
  );
  const [porcentajeRetenciones, setporcentajeRetenciones] = useState([]);
  const [tipoImpuestosRetencion, settipoImpuestosRetencion] = useState([]);
  const [tiposDocumentos, setTiposDocumenos] = useState([]);
  const [tiposPagos, setTiposPagos] = useState([]);
  const [tiposPagossri, setTiposPagossri] = useState([]);

  const [tiposImpuestos, setTiposImpuestos] = useState([]);
  const [porcentajeImpuestos, setporcentajeImpuestos] = useState([]);

  const [modalListarVentas, setModalListarVentas] = useState(false);
  const [modalGuardarVentas, setModalGuardarVentas] = useState(false);

  const [stateguardar, setStateGuardar] = useState("");
  const [stateguardarerror, setStateGuardarError] = useState("");
  const [infoadicionalState, setInfoAdicionalState] = useState({
    atributo: "",
    detalle: "",
  });
  const [infoDocInitState, setInfoDocInitState] = useState({
    codigo_documento: "99",
    ambiente: "2",
  });

  const [validateDoc, setValidateDoc] = useState({
    acces: false,
    message: "",
  });

  const [modalInfoAdicional, setModalInfoAdicional] = useState(false);

  const [totalpendiente, setTotalpendiente] = useState(0.0);


  const [state, setState] = useState(null);

  const [pagoselect, setPagoSelect] = useState({
    medio: "",
    total: "",
    propiedades: {
      numero: "",
      banco: "",
    },
    notas: "",
  });

  const [pagoselectreset, setPagoSelectreset] = useState({
    medio: "",
    total: "",
    propiedades: {
      numero: "",
      banco: "",
    },
    notas: "",
  });

  const onchange = async (e) => {
    //TRASLADO
    if (e.target.name === "codigo_identificacion_transportista") {
      const restransportista = await tiposIdentificacion.filter(
        (elemento) => elemento.codigo === e.target.value
      );
      if (restransportista.length > 0) {
        let obj1 = {
          tipoidentificaciontransportista: restransportista[0].codigo,
          ructransportista: "",
          razonsocialtransportista: "",
          fechainitransporte: "",
          fechafintransporte: "",
          correotranportista: "",
          placa: "",
          telefonotransportista: "",
          dirpartida: "",
        };
        setState({
          ...state,
          traslado: obj1,
          codigo_identificacion_transportista: restransportista[0].codigo,
          identificacion_transportista: "",
        });
      }

      return;
    }
    if (e.target.name === "ructransportista") {
      const obj1 = state.traslado;
      obj1.ructransportista = e.target.value;
      setState({
        ...state,
        traslado: obj1,
        identificacion_transportista: e.target.value,
      });

      return;
    }
    if (e.target.name === "razonsocialtransportista") {
      let obj1 = state.traslado;
      obj1.razonsocialtransportista = e.target.value;
      setState({
        ...state,
        traslado: obj1,
      });
      return;
    }
    if (e.target.name === "telefonotransportista") {
      let obj1 = state.traslado;
      obj1.telefonotransportista = e.target.value;
      setState({
        ...state,
        traslado: obj1,
      });
      return;
    }
    if (e.target.name === "placa") {
      let obj1 = state.traslado;
      obj1.placa = e.target.value;
      setState({
        ...state,
        traslado: obj1,
      });
      return;
    }
    if (e.target.name === "dirpartida") {
      let obj1 = state.traslado;
      obj1.dirpartida = e.target.value;
      setState({
        ...state,
        traslado: obj1,
      });
      return;
    }
    if (e.target.name === "fechafintransporte") {
      let obj1 = state.traslado;
      obj1.fechafintransporte = e.target.value;
      setState({
        ...state,
        traslado: obj1,
      });
      return;
    }
    if (e.target.name === "fechainitransporte") {
      let obj1 = state.traslado;
      obj1.fechainitransporte = e.target.value;
      setState({
        ...state,
        traslado: obj1,
      });
      return;
    }
    if (e.target.name === "fechaemisiondocsustento") {
      let obj1 = state.docsustento;
      obj1.fechaemisiondocsustento = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }
    if (e.target.name === "fechaemisiondocsustento") {
      let obj1 = state.docsustento;
      obj1.fechaemisiondocsustento = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }
    if (e.target.name === "numautdocsustento") {
      let obj1 = state.docsustento;
      obj1.numautdocsustento = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }
    if (e.target.name === "numdocsustento") {
      let obj1 = state.docsustento;
      obj1.numdocsustento = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }
    if (e.target.name === "coddocsustento") {
      let obj1 = state.docsustento;
      obj1.coddocsustento = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }
    if (e.target.name === "totalSinImpuestosretencion") {
      let obj1 = state.docsustento;
      obj1.totalSinImpuestos = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }
    if (e.target.name === "importeTotalretencion") {
      let obj1 = state.docsustento;
      obj1.importeTotal = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }
    if (e.target.name === "codsustento") {
      let obj1 = state.docsustento;
      obj1.codsustento = e.target.value;
      setState({
        ...state,
        docsustento: obj1,
      });
      return;
    }

    if (e.target.name === "periodofiscal") {
      let obj1 = state.comprador;
      obj1.periodofiscal = e.target.value;
      setState({
        ...state,
        comprador: obj1,
      });
      return;
    }

    //FIN TRASLADO
    if (e.target.name === "razon_social") {
      let obj1 = state.comprador;
      obj1.razon_social = e.target.value;
      setState({
        ...state,
        comprador: obj1,
      });
      return;
    }
    if (e.target.name === "telefono") {
      let obj1 = state.comprador;
      obj1.telefono = e.target.value;
      setState({
        ...state,
        comprador: obj1,
      });
      return;
    }
    if (e.target.name === "direccion") {
      let obj1 = state.comprador;
      obj1.direccion = e.target.value;
      setState({
        ...state,
        comprador: obj1,
      });
      return;
    }

    if (e.target.name === "correo") {
      let obj1 = state.comprador;
      obj1.correo = e.target.value;
      setState({
        ...state,
        comprador: obj1,
      });
      return;
    }
    if (e.target.name === "motivotraslado") {
      let obj1 = state.comprador;
      obj1.motivotraslado = e.target.value;
      setState({
        ...state,
        comprador: obj1,
      });
      return;
    }
    if (e.target.name === "ruta") {
      let obj1 = state.comprador;
      obj1.ruta = e.target.value;
      setState({
        ...state,
        comprador: obj1,
      });
      return;
    }

    if (e.target.name === "facturado") {
      setState({
        ...state,
        ambiente: e.target.value === "true" ? true : false,
      });
      return;
    }

    if (e.target.name === "ambiente") {
      setState({
        ...state,
        ambiente: e.target.value,
      });
      return;
    }

    if (e.target.name === "tipo_identificacion_id") {
      if (e.target.value === "") {
        setState({
          ...state,
          codigo_identificacion: "",
          tipo_identificacion_id: "",
        });
      } else {
        const res = tiposIdentificacion.filter(
          (elemento) => elemento.codigo === e.target.value
        );
        if (res) {
          if (res[0].codigo === "07") {
            const obj1 = {
              correo: "consumidorfinal@envio.com",
              identificacion: "9999999999999",
              tipo_identificacion: "07",
              razon_social: "CONSUMIDOR FINAL",
              direccion: "9999999999999",
              telefono: "9999999999999",
            };

            setState({
              ...state,
              comprador: obj1,
              codigo_identificacion: res[0].codigo,
              tipo_identificacion_id: e.target.value,
            });
          } else {
            const obj1 = {
              correo: "",
              identificacion: "",
              tipo_identificacion: res[0].codigo,
              razon_social: "",
              direccion: "",
              telefono: "",
            };

            setState({
              ...state,
              comprador: obj1,
              codigo_identificacion: res[0].codigo,
              tipo_identificacion_id: e.target.value,
            });
          }
        }
      }
      return;
    }

    if (e.target.name === "tipo_documento_id") {
      if (e.target.value === "") {
        setState({
          ...state,
          codigo_documento: "",
          tipo_documento_id: "",
        });
      } else {
        const res = tiposDocumentos.filter(
          (elemento) => elemento.codigo_documento === e.target.value
        );
        if (res) {
          setState({
            ...state,
            codigo_documento: res[0].codigo_documento,
            tipo_documento_id: e.target.value,
          });
        }
      }
      return;
    }
    //tipo_forma_pago_id
    if (e.target.name === "medio") {
      /* if (e.target.value === "") {
        setState({
          ...state,
          codigo_forma_pago: "",
          tipo_pago_id: "",
          codigo_forma_pago_sri: "",
          valor_pendiente: "0.00",
        });
      } else {
       
      } */

      const res = tiposPagos.filter(
        (elemento) => elemento.codigo_forma_pago === e.target.value
      );
      if (res) {
        /* setState({
            ...state,
            codigo_forma_pago: res[0].codigo_forma_pago,
            codigo_forma_pago_sri: res[0].codigo_sri,
            tipo_forma_pago_id: e.target.value,
            valor_pendiente: state.valor_total,
          }); */
        let totalaux = 0;
        if (state.pagos.length > 0) {
          for (let index = 0; index < state.pagos.length; index++) {
            totalaux = totalaux + parseInt(state.pagos[index].total);
          }
        }

        setPagoSelect({
          ...pagoselect,
          medio: res[0].codigo_forma_pago,
          total: state.valor_total - totalaux,
        });
      }
      return;
    }
    if (e.target.name === "motivotraslado") {
      let auxcomprador = state.comprador;
      auxcomprador.motivotraslado = e.target.value;
      setState({
        ...state,
        comprador: auxcomprador,
      });
    }
    if (e.target.name === "numero") {
      /* let auxbanco = state.pagos;

      auxbanco[0].propiedades.numero = e.target.value;

      setState({
        ...state,
        pagos: auxbanco,
      });
 */
      const propiedad = {
        numero: e.target.value,
        banco: pagoselect.propiedades.banco,
      };
      setPagoSelect({
        ...pagoselect,
        propiedades: propiedad,
      });
      return;
    }

    if (e.target.name === "notas") {
      /* let auxbanco = state.pagos;

      auxbanco[0].notas = e.target.value;
 */
      setPagoSelect({
        ...pagoselect,
        notas: e.target.value,
      });

      return;
    }

    if (e.target.name === "total") {
      /* let auxbanco = state.pagos;

      auxbanco[0].notas = e.target.value;
 */

      setPagoSelect({
        ...pagoselect,
        total: e.target.value,
      });

      return;
    }

    if (e.target.name === "banco") {
      /* let auxbanco = state.pagos;

      auxbanco[0].propiedades.banco = e.target.value;

      setState({
        ...state,
        pagos: auxbanco,
      }); */
      const propiedad = {
        numero: pagoselect.propiedades.numero,
        banco: e.target.value,
      };
      setPagoSelect({
        ...pagoselect,
        propiedades: propiedad,
      });
      return;
    }

    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const actionItems = async (data) => {
    let dataaux = data;
    let totalconiva = 0.0;
    let totalsiniva = 0.0;
    let totalcatorce = 0.0;
    let totalcinco = 0.0;
    let totalquince = 0.0;
    let totalexcentoiva = 0.0;
    let totalnoobjetoiva = 0.0;
    let totalsinimpuestos = 0.0;
    let totaldescuento = 0.0;
    let iva = 0.0;
    let ivacinco = 0.0;
    let ivaquince = 0.0;
    let rice = 0.0;
    let valor_total = 0.0;
    let totales = [];

    await dataaux.forEach(async (elemento, index) => {
      elemento.iva_valor = parseFloat(elemento.iva_valor);
      elemento.cantidad = parseFloat(elemento.cantidad);
      elemento.precio_venta = parseFloat(elemento.precio_venta);
      totaldescuento = totaldescuento + parseFloat(elemento.descuento_total);
      if (elemento.iva_code_porcent === 2) {
        iva = parseFloat(
          parseFloat(elemento.iva_valor) + parseFloat(iva)
        ).toFixed(2);
        totalconiva = parseFloat(
          parseFloat(elemento.subtotal) + parseFloat(totalconiva)
        ).toFixed(2);
      }

      if (elemento.iva_code_porcent === 4) {
        ivaquince = parseFloat(
          parseFloat(elemento.iva_valor) + parseFloat(ivaquince)
        ).toFixed(2);
        totalquince = parseFloat(
          parseFloat(elemento.subtotal) + parseFloat(totalquince)
        ).toFixed(2);
      }
      if (elemento.iva_code_porcent === 5) {
        ivacinco = parseFloat(
          parseFloat(elemento.iva_valor) + parseFloat(ivacinco)
        ).toFixed(2);
        totalcinco = parseFloat(
          parseFloat(elemento.subtotal) + parseFloat(totalcinco)
        ).toFixed(2);
      }
      if (elemento.iva_code_porcent === 0) {
        totalsiniva = parseFloat(
          parseFloat(elemento.subtotal) + parseFloat(totalsiniva)
        ).toFixed(2);
      }

      /* if (elemento.iva_code_porcent === 3) {
        totalcatorce = parseFloat(
          parseFloat(elemento.subtotal) + parseFloat(totalcatorce)
        ).toFixed(2);
      } */

      if (elemento.iva_code_porcent === 6) {
        totalnoobjetoiva = parseFloat(
          parseFloat(elemento.subtotal) + parseFloat(totalnoobjetoiva)
        ).toFixed(2);
      }

      if (elemento.iva_code_porcent === 7) {
        totalexcentoiva = parseFloat(
          parseFloat(elemento.subtotal) + parseFloat(totalexcentoiva)
        ).toFixed(2);
      }

      totalsinimpuestos =
        parseFloat(totalconiva) +
        parseFloat(totalquince) +
        parseFloat(totalsiniva) +
        parseFloat(totalnoobjetoiva) +
        parseFloat(totalexcentoiva) +
        parseFloat(totalcinco);
    });

    valor_total = parseFloat(
      parseFloat(totalsinimpuestos) +
        parseFloat(iva) +
        parseFloat(ivacinco) +
        parseFloat(ivaquince)
    ).toFixed(2);

    //FUNCION QUE VERIFICA SI YA HAY METODOS DE PAGOS AGREGADOS CON VALORES INICIALES
    //LUEGO SUMA LOS TOTALES DE LOS METODOS DE PAGO AGREGADOS Y SACA LA DIFERENCIA PARA EL NUEVO METODO DE PAGO HASTA
    //COMPLETAR EL TOTAL DE LA COMPRA

    setState({
      ...state,
      subtotal_iva: totalconiva,
      subtotal_cinco: totalcinco,
      subtotal_siniva: totalsiniva,
      subtotal_quince: totalquince,
      iva: iva === null || iva === "" ? 0 : iva,
      ivacinco: ivacinco === null || iva === "" ? 0 : ivacinco,
      ivaquince: ivaquince === null || iva === "" ? 0 : ivaquince,
      ventas_detalle: data,
      valor_total: valor_total,
      subtotal_sin_impuestos: totalsinimpuestos,
      subtotal_no_objeto_iva: totalnoobjetoiva,
      subtotal_exento_iva: totalexcentoiva,
      total_descuento: totaldescuento,
    });
    /* console.log("=====")
    console.log(totales) */
  };

  useEffect(() => {
    if (state) {
      getTotalPagos();
    }
  }, [state]);

  const getTotalPagos = async () => {
    let totalaux = 0;
    if (state.pagos.length > 0) {
      for (let index = 0; index < state.pagos.length; index++) {
        totalaux = parseFloat(totalaux) + parseFloat(state.pagos[index].total);
      }
    }

    const pagopendiente = parseFloat(
      parseFloat(state.valor_total) - parseFloat(totalaux)
    ).toFixed(2);
    setPagoSelect({
      ...pagoselect,
      total: pagopendiente,
    });
    setTotalpendiente(pagopendiente);
  };

  const initTiposdeIdentificacion = async () => {
    const res = await getTipoIdentificacion();
    if (res[0].sms === "ok") {
      setTiposIdentificacion(res[0].data);
    } else {
      setTiposIdentificacion([]);
    }
  };

  const initTipoDocumento = async () => {
    const res = await getTipoDeDocumento();

    console.log(res[0].data);
    if (res[0].sms === "ok") {
      const filterdocs = await res[0].data.filter(
        (op) =>
          op.codigo_documento === "" ||
          op.codigo_documento === "01" ||
          op.codigo_documento === "06" ||
          op.codigo_documento === "03" ||
          op.codigo_documento === "07" ||
          op.codigo_documento === null
      );
      setTiposDocumenos(filterdocs);
    } else {
      setTiposDocumenos([]);
    }
  };

  const initTipoPago = async () => {
    const res = await getTipoPago();
    console.log(res);
    if (res[0].sms === "ok") {
      setTiposPagos(res[0].data);
    } else {
      setTiposPagos([]);
    }
  };

  const initTipoPagoSri = async () => {
    const res = await getTipoPagoSri();
    console.log(res);
    if (res[0].sms === "ok") {
      setTiposPagossri(res[0].data);
    } else {
      setTiposPagossri([]);
    }
  };

  const initTipoImpuesto = async () => {
    const res = await getTipoImpuesto();
    if (res[0].sms === "ok") {
      setTiposImpuestos(res[0].data);
    } else {
      setTiposImpuestos([]);
    }
  };

  const initPorcentajeImpuesto = async () => {
    const res = await getPorcentajeImpuesto();
    if (res[0].sms === "ok") {
      setporcentajeImpuestos(res[0].data);
    } else {
      setporcentajeImpuestos([]);
    }
  };

  const guardardocumentofn = async () => {
    setStateGuardarError("");
    setStateGuardar("Guardando documento ...");
    const resvalidacion =
      (await state.codigo_documento) === "07"
        ? validarRetencion(state)
        : validarfactura(state);
    if (resvalidacion.sms === "err") {
      setStateGuardarError(resvalidacion.mensaje);
      setModalGuardarVentas(false);
      return;
    }
    setModalGuardarVentas(true);
    let aux = state;

    setState(aux);
    const res = await guardarVentaApi(state);
    if (res[0].sms === "ok") {
      //localStorage.removeItem("factura");
      if (res[0].data.codigo_documento === "") {
        toast.success("Se guardo el recibo de venta", {
          position: "bottom-left",
        });
        setModalGuardarVentas(false);
        setModalListarVentas(true);
        initdocfn(state ? state.codigo_documento : "99");
      } else {
        setStateGuardar("Firmando y enviando documento al sri ...");
        const resfirmadoxml = await crearFirmarXmlVentaApi(
          veterinaria_id,
          res[0].data.venta_cabecera_id
        );
        console.log(res[0]);
        if (resfirmadoxml[0].sms === "ok") {
          setStateGuardarError("");

          setStateGuardar("Verificando autorización ...");
          setTimeout(() => {
            verificarAutorizacionSriFn(
              res[0].data.venta_cabecera_id,
              res[0].data.clave_acceso,
              res[0].data.ambiente
            );
          }, 3000);
        } else {
          toast.error(resfirmadoxml.mensaje);
          setStateGuardarError("");
          setModalGuardarVentas(false);
          setModalListarVentas(true);
          initdocfn(state ? state.codigo_documento : "99");
        }
      }
    } else {
      setStateGuardarError(res[0].mensaje);
      setModalGuardarVentas(false);
    }
  };

  const verificarAutorizacionSriFn = async (
    venta_cabecera_id,
    clave_acceso,
    ambiente
  ) => {
    console.log(state.clave_acceso);
    const resverify = await verificarAutorizacionsriApi({
      claveacceso: clave_acceso,
      ambiente: ambiente,
      venta_cabecera_id: venta_cabecera_id,
    });
    if (resverify[0].sms === "ok") {
      //setStateGuardar(resverify[0].mensaje);
      initdocfn(state ? state.codigo_documento : "99");
      toast.success(resverify[0].mensaje, {
        position: "bottom-left",
      });
      setModalGuardarVentas(false);
      setModalListarVentas(true);

      /* let auxreset = stateReset;
      auxreset.codigo_documento = state.codigo_documento;
      setState(auxreset); */
    } else {
      initdocfn(state ? state.codigo_documento : "99");
      setStateGuardar(resverify[0].mensaje);
      toast.success(resverify[0].mensaje, {
        position: "bottom-left",
      });
      setModalGuardarVentas(false);
      setModalListarVentas(true);
      /* let auxreset = stateReset;
      auxreset.codigo_documento = state.codigo_documento;
      setState(auxreset); */
    }
  };

  const editarVentafn = async () => {
    if (state.tipo_forma_pago_id === "") {
      alert("Seleccione una forma de pago");
      return;
    }
    let aux = state;

    setState(aux);
    const res = await editarVentaApi(state);
    if (res[0].sms === "ok") {
      initdocfn(state ? state.codigo_documento : "99");
      setStateGuardarError("");
      setModalGuardarVentas(false);
      setModalListarVentas(true);
      //localStorage.removeItem("factura");
      if (
        res[0].data.codigo_documento === "" ||
        res[0].data.codigo_documento === "00"
      ) {
        toast.success(res[0].mensaje, {
          position: "bottom-left",
        });
      }
    } else {
      toast.success(res[0].mensaje, {
        position: "bottom-left",
      });
    }
  };

  const cargarcomprador = (data) => {
    setState({
      ...state,
      comprador: {
        identificacion:
          state.codigo_identificacion === "04"
            ? data.ruc
            : state.codigo_identificacion === "05"
            ? data.cedula
            : state.codigo_identificacion === "06"
            ? data.pasaporte
            : state.codigo_identificacion === "07"
            ? "9999999999999"
            : state.codigo_identificacion === "08"
            ? data.idexterior
            : state.codigo_identificacion === "09"
            ? data.placa
            : "",
        razon_social: data.razonsocial,
        telefono: data.telefono,
        direccion: data.direccion,
        tipo_identificacion: state.codigo_identificacion,
        correo: data.correo,
        periodofiscal: state.comprador.periodofiscal,
        parterel: state.comprador.parterel,
      },
    });
  };

  const cargartransportista = (data) => {
    setState({
      ...state,
      traslado: {
        ructransportista:
          state.codigo_identificacion_transportista === "04"
            ? data.ruc
            : state.codigo_identificacion_transportista === "05"
            ? data.cedula
            : state.codigo_identificacion_transportista === "06"
            ? data.pasaporte
            : state.codigo_identificacion_transportista === "07"
            ? "9999999999999"
            : state.codigo_identificacion_transportista === "08"
            ? data.idexterior
            : state.codigo_identificacion_transportista === "09"
            ? data.placa
            : "",
        razonsocialtransportista: data.razonsocial,
        telefonotransportista: data.telefono,
        tipoidentificaciontransportista:
          state.codigo_identificacion_transportista,
        correotransportista: data.correo,
      },
    });
  };
  const closeAction = (data) => {
    setModalListarVentas(data);
  };
  const closeAction2 = (data) => {
    setModalGuardarVentas(data);
    setStateGuardar("Guardando documentos ...");
  };

  const closeActionModalInformacionAdicional = (data) => {
    setModalInfoAdicional(data);
  };

  useEffect(() => {
    initTipoDocumento();

    if (localStorage.getItem("factura")) {
      /*  */
      setState(JSON.parse(localStorage.getItem("factura")));
      setInfoDocInitState({
        ...infoDocInitState,
        codigo_documento: JSON.parse(localStorage.getItem("factura"))
          .codigo_documento,
        ambiente: JSON.parse(localStorage.getItem("factura")).ambiente,
      });
      setValidateDoc({
        ...validateDoc,
        acces: true,
        message: "",
      });
    } else {
      initdocfn(infoDocInitState.codigo_documento);
    }
    return () => {};
  }, []);

  useEffect(() => {
    initTiposdeIdentificacion();
    initTipoPago();
    initTipoPagoSri();
    initTipoImpuesto();
    initPorcentajeImpuesto();
    GetTipoSustentoRetenciones();
    GetPorcentajeSustentos();
    GetTipoImpuestosRetenciones();

    return () => {};
  }, []);

  const GetTipoSustentoRetenciones = async () => {
    const res = await GETTipoSustentoApi();
    if (res[0].sms === "ok") {
      settiposSustentosRetenciones(res[0].data);
    } else {
      settiposSustentosRetenciones([]);
    }
  };

  const GetPorcentajeSustentos = async () => {
    const res = await GETPorcentajeSustentoApi();
    if (res[0].sms === "ok") {
      setporcentajeRetenciones(res[0].data);
    } else {
      setporcentajeRetenciones([]);
    }
  };

  const GetTipoImpuestosRetenciones = async () => {
    const res = await GETtipoImpuestosRetencionesApi();
    if (res[0].sms === "ok") {
      settipoImpuestosRetencion(res[0].data);
    } else {
      settipoImpuestosRetencion([]);
    }
  };

  const initdocfn = async (codigo_documento) => {
    setPagoSelect(pagoselectreset);
    const infodoc = await getInfoDocumento(
      infovet,
      usuario_id,
      codigo_documento,
      infoDocInitState.ambiente
    );
    console.log(infodoc);
    if (infodoc.validate === "ok") {
      setState(infodoc.data);
      setValidateDoc({
        ...validateDoc,
        acces: true,
        message: "",
      });
    } else {
      setValidateDoc({
        ...validateDoc,
        acces: false,
        message: infodoc.message,
      });
    }
  };

  const getTituloPorDocumento = () => {
    let titulo =
      state.codigo_documento === ""
        ? "RECIBOS EMITIDOS "
        : state.codigo_documento === "01"
        ? "FACTURAS EMITIDAS"
        : state.codigo_documento === "03"
        ? "LIQUIDACIONES EMITIDAS"
        : state.codigo_documento === "06"
        ? "GUÍAS DE REMISIÓN EMITIDAS"
        : state.codigo_documento === "07"
        ? "RETENCIONES EMITIDAS"
        : "";
    return titulo;
  };

  const getCabeceraModal = () => {
    return (
      <div className="w-100 d-flex justify-content-between align-items-center">
        <label for="" className="negrita">
          {getTituloPorDocumento()}
        </label>
        <label for="" className="mx-3">
          |
        </label>{" "}
        <label for="" className="negrita">
          CAJA USUARIO : {`${nombres}`}
        </label>
      </div>
    );
  };

  const BotonesAccionts = () => {
    return (
      <div className="col-md-12 d-flex flex-row justify-content-center align-items-center">
        <button
          type=""
          className="bg-btn-vet-danger mx-1 bordesombreado bordecompletoerr"
          onClick={(e) => {
            initdocfn("99");
            localStorage.removeItem("factura");
          }}
        >
          <i className="fas fa-list iconsize mx-1" />
          <label for="">Cerrar</label>
        </button>

        <button
          type=""
          className="bg-btn-vet mx-1 bordesombreado bordecompleto"
          onClick={() => setModalListarVentas(true)}
        >
          <i className="fas fa-list iconsize mx-1" />
          <label for="">{getTituloPorDocumento()}</label>
        </button>
      </div>
    );
  };

  const goToLink = () => {
    navigate("/seguridad/perfilempresa");
  };

  const cambiartipodedocumento = (tipo) => {
    setState({
      ...state,
      codigo_documento: tipo,
    });
  };

  const actiondeletes = (datadeletes) => {
    if (datadeletes !== "") {
      let auxdeletes = state.deletes;
      auxdeletes.push(datadeletes);
      console.log("----");
      console.log(auxdeletes);
      setState({
        ...state,
        deletes: auxdeletes,
      });
    }
  };

  const onchangeinfoadicional = (e) => {
    e.preventDefault();
    setInfoAdicionalState({
      ...infoadicionalState,
      [e.target.name]: e.target.value,
    });
  };

  const eliminaritemInformacionAdicional = async (index) => {
    const res = await state.info_adicional.filter((data, i) => i !== index);
    setState({
      ...state,
      info_adicional: res,
    });
  };

  const listadoinformacionadicional = () => {
    return (
      <div className="col-md-12 px-3 ">
        <hr />
        <table className="w-100 bordecompleto mb-3 ">
          <thead className="bgfondo2">
            <th className="bordecompleto " style={{ width: "10%" }}>
              {" "}
              -{" "}
            </th>
            <th className="bordecompleto" style={{ width: "30%" }}>
              Atributo
            </th>
            <th className="bordecompleto" style={{ width: "60%" }}>
              Detalle
            </th>
          </thead>
          <tbody>
            {state.info_adicional.length === 0
              ? null
              : state.info_adicional.map((data, index) => {
                  return (
                    <tr className="bordevertical" key={index}>
                      <td className="align-items-center bordecircular d-flex justify-content-center">
                        <button
                          type=""
                          className=""
                          style={{
                            width: "25px",
                            height: "25px",
                            background: "red",
                            color: "white",
                          }}
                          onClick={() =>
                            eliminaritemInformacionAdicional(index)
                          }
                        >
                          {" "}
                          -{" "}
                        </button>
                      </td>
                      <td className="bordecompleto">{data.atributo}</td>
                      <td className="bordecompleto">{data.detalle}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    );
  };

  const agregarInformacionAdicionl = () => {
    let auxindormacionadicional = state.info_adicional;
    auxindormacionadicional.push(infoadicionalState);
    setState({
      ...state,
      info_adicional: auxindormacionadicional,
    });

    setInfoAdicionalState({
      ...infoadicionalState,
      atributo: "",
      detalle: "",
    });
  };

  const getdataporcentajesimpuestos = async (valor) => {
    const res = await porcentajeImpuestos.filter(
      (elemento) => `${elemento.codigo}` === `${valor}`
    );
    console.log(res);

    const resverifica = await state.docsustento.impuestosdocsustento.filter(
      (elemento) => `${elemento.codImpuestoDocSustento}` === `${res[0].codigo}`
    );
    if (resverifica.length > 0) {
      toast.error("El impuesto seleccionado ya se encuentra en la lista", {
        theme: "colored",
        position: "bottom-left",
      });
    } else {
      return res[0];
    }
  };

  const getdataretencionesporcentajes = async (valor) => {
    const res = await porcentajeRetenciones.filter(
      (elemento) => `${elemento.codigo_del_anexo}` === `${valor}`
    );
    const resverifica = await state.docsustento.retencionesdocsustento.filter(
      (elemento) =>
        `${elemento.codgioRetencion}` === `${res[0].codigo_del_anexo}`
    );
    if (resverifica.length > 0) {
      toast.error("La retención seleccionado ya se encuentra en la lista", {
        theme: "colored",
        position: "bottom-left",
      });
    } else {
      return res[0];
    }
  };

  const onchangeimpuestosporcent = async (e) => {
    let auximpuestossustento = state.docsustento;
    let datares = await getdataporcentajesimpuestos(e.target.value);
    let obj = {
      porcentaje: `Iva - ${datares.porcentaje}`,
      codImpuestoDocSustento: datares.codigo,
      codigoPorcentaje: datares.codigo,
      baseImponible: 0.0,
      tarifa: datares.tarifa,
      valorImpuesto: 0.0,
    };
    auximpuestossustento.impuestosdocsustento.push(obj);
    setState({
      ...state,
      docsustento: auximpuestossustento,
    });
    console.log(state.docsustento.impuestosdocsustento);
  };

  const onchangeimpuestosreten = async (e) => {
    let auxretencionsustento = state.docsustento;
    let datares = await getdataretencionesporcentajes(e.target.value);
    let obj = {
      codigo: datares.tipo_impuestos_retencion.codigo,
      codgioRetencion: datares.codigo_del_anexo,
      porcentajeRetener: datares.porcentajes_vigentes,
      detalleporcent: datares.detalle_porcentajes_retencion,
      formulario103: datares.campo_formulario_103,
      baseImponible: 0.0,
      valorRetenido: 0.0,
    };
    auxretencionsustento.retencionesdocsustento.push(obj);
    setState({
      ...state,
      docsustento: auxretencionsustento,
    });
    /*     console.log(state.docsustento.impuestosdocsustento);
     */
  };

  const Getdataxcodigoretencion = async (props) => {
    console.log("******");
    const res = await porcentajeRetenciones.filter(
      (elemento) => `${elemento.codigo_del_anexo}` === `${props}`
    );
    console.log(res[0].codigo_del_anexo);
    return `${res[0].codigo_del_anexo}`;
    // return `(${res[0].codigo_del_anexo}) - ${res[0].detalle_porcentajes_retencion}`
  };

  const documentosustento = () => {
    return (
      <div className="row  ">
        <div className="col-md-12 p-0 m-0">
          {state.codigo_documento === "06" ||
          state.codigo_documento === "07" ? (
            <div className="row m-0 p-0 d-flex justify-content-start align-items-start">
              <div className="negrita" style={{ textAlign: "left" }}>
                DOCUMENTO DE SUSTENTO
              </div>
              <hr />
            </div>
          ) : null}
        </div>
        <div className="col-md-12 bgfondo2 bordecompleto  bordesombreado">
          {state.codigo_documento === "06" ||
          state.codigo_documento === "07" ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 " style={{ display: "block" }}>
                  <div className="form-group">
                    <label className=" negrita">Tipo de documento</label>
                    <select
                      className=" inputselect bordecompleto"
                      id="coddocsustento"
                      name="coddocsustento"
                      style={{ width: "100%" }}
                      onChange={(e) => onchange(e)}
                      value={state.docsustento.coddocsustento}
                    >
                      <option value="">Seleccione un documento...</option>
                      {tiposDocumentos.length === 0
                        ? null
                        : tiposDocumentos.map((data) => {
                            return (
                              <option value={data.codigo_documento}>
                                {data.documento}
                              </option>
                            );
                          })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4  d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      N. documento de sustento :{" "}
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="text"
                        className=" inputtext bordecompleto"
                        onChange={onchange}
                        value={state.docsustento.numdocsustento}
                        id="numdocsustento"
                        name="numdocsustento"
                        placeholder="Número de documento"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      Fecha emisióm documento de sustento:{" "}
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="date"
                        className=" inputtext bordecompleto"
                        onChange={onchange}
                        value={state.docsustento.fechaemisiondocsustento}
                        id="fechaemisiondocsustento"
                        name="fechaemisiondocsustento"
                        placeholder="Fecha de emisión"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={"col-md-12 d-flex flex-row justify-content-start"}
                >
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      N. Autorización documento de sustento ( Opcional ) :
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="text"
                        className=" inputtext bordecompleto"
                        onChange={onchange}
                        value={state.docsustento.numautdocsustento}
                        id="numautdocsustento"
                        name="numautdocsustento"
                        placeholder="Número de autorización del documento ( Opcional )"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {state.codigo_documento === "07" ? (
            <div className="col-md-12">
              <div className="row">
                {/* <div className="col-md-4">
                            <div className="form-group">
                              <label className=" negrita">
                                Tipo de sustento
                              </label>
                              <select
                                className=" inputselect bordecompleto"
                                id="codsustento"
                                name="codsustento"
                                style={{ width: "100%" }}
                                onChange={(e) => onchange(e)}
                                value={state.docsustento.codsustento}
                              >
                                <option value="">
                                  Seleccione un sustento...
                                </option>
                                {tiposSustentosRetenciones.length === 0
                                  ? null
                                  : tiposSustentosRetenciones.map((data) => {
                                      return (
                                        <option value={data.codigo}>
                                          {data.tiposustento}
                                        </option>
                                      );
                                    })}
                              </select>
                            </div>
                          </div> */}
                <div
                  className={"col-md-4 d-flex flex-row justify-content-start"}
                >
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      Periodo fiscal
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? true : false
                        }
                        type="month"
                        className=" inputtext bordecompleto"
                        onChange={onchange}
                        value={state.comprador.periodofiscal}
                        id="periodofiscal"
                        name="periodofiscal"
                        placeholder="Periodo fiscal"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4  d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      TotalSinImpuestos :
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? false : false
                        }
                        type="text"
                        className=" inputtext bordecompleto"
                        onChange={onchange}
                        value={state.docsustento.totalSinImpuestos}
                        id="totalSinImpuestosretencion"
                        name="totalSinImpuestosretencion"
                        placeholder="Total sin impuestos"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4  d-flex flex-row justify-content-start">
                  <div className="form-group w-100">
                    {" "}
                    <label for="" className=" negrita">
                      Importe total :{" "}
                    </label>
                    <div className="col-md-12">
                      <input
                        readOnly={
                          state.codigo_identificacion === "07" ? false : false
                        }
                        type="text"
                        className=" inputtext bordecompleto"
                        onChange={onchange}
                        value={state.docsustento.importeTotal}
                        id="importeTotalretencion"
                        name="importeTotalretencion"
                        placeholder="Importe total"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const DataRetenciones = () => {
    return (
      <div className="row ">
        <div className="col-md-12 mb-3">
          <div className="row bgfondo2">
            <div className="col-md-12 " style={{ textAlign: "left" }}>
              <label for="" className="negrita " style={{ color: "black" }}>
                Valores retenido
              </label>
              <hr />
            </div>
            <div className="col-md-12 " style={{ display: "block" }}>
              <div className="form-group">
                <label className=" negrita">Listado de reteciones</label>
                <select
                  className=" inputselect bordecompleto bordesombreado"
                  id="porceimpuesto"
                  name="porceimpuesto"
                  style={{ width: "100%" }}
                  onChange={(e) => onchangeimpuestosreten(e)}
                  value={""}
                >
                  <option value="">Seleccione una retención...</option>
                  {porcentajeRetenciones.length === 0
                    ? null
                    : porcentajeRetenciones.map((data, index) => {
                        return (
                          <option key={index} value={data.codigo_del_anexo}>
                            ({data.codigo_del_anexo}) -{" "}
                            {data.detalle_porcentajes_retencion}
                          </option>
                        );
                      })}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <table className="w-100 scroll">
                <thead>
                  <tr className="bordecompleto">
                    <th> - </th>
                    <th>Retención</th>
                    <th>Tipo impuesto</th>
                    <th>Porcentaje</th>
                    <th>Base Imponible</th>
                    <th>Valor Retenido</th>
                  </tr>
                </thead>
                <tbody>
                  {state.docsustento.retencionesdocsustento.length > 0 ? (
                    state.docsustento.retencionesdocsustento.map((d, i) => (
                      <tr className="bordecompleto">
                        <td>
                          <button
                            type=""
                            className="bg-btn-vet-danger bordecompleto"
                            onClick={(e) => deletedocsustentoretencion(e, i)}
                          >
                            <label for="" style={{ color: "black" }}>
                              {" "}
                              -{" "}
                            </label>
                          </button>
                        </td>

                        <td>
                          ({d.codgioRetencion}) - {d.detalleporcent}
                        </td>
                        <td>
                          <select
                            className="inputselect bordeabajo center "
                            disabled={true}
                            id="codigo"
                            name="codigo"
                            style={{ width: "100%" }}
                            onChange={(e) => onchange(e)}
                            value={
                              state.docsustento.retencionesdocsustento.length >
                              0
                                ? state.docsustento.retencionesdocsustento[i]
                                    .codigo
                                : ""
                            }
                          >
                            <option value="">seleccione</option>
                            {tipoImpuestosRetencion.map((data, index) => (
                              <option value={data.codigo} key={index}>
                                {data.impuesto}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>{d.porcentajeRetener} %</td>
                        <td className="py-2 ">
                          <div className="d-flex justify-content-center align-items-center align-self-center w-100">
                            <label for="">$</label>
                            <input
                              type="text"
                              className="inputtext bordeabajo bordesombreado mx-2"
                              onChange={(e) =>
                                onchangedocsustentoretencionretenciones(e, i)
                              }
                              name="baseImponible"
                              value={
                                state.docsustento.retencionesdocsustento
                                  .length > 0
                                  ? state.docsustento.retencionesdocsustento[i]
                                      .baseImponible
                                  : ""
                              }
                            />
                          </div>
                        </td>
                        <td>$ {d.valorRetenido}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="center py-3">
                        No se ah retenido ningún valor
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const habilitardocumento = () => {
    return (
      <div className="row bgfondo2 m-0 d-flex flex-column justify-content-start align-items-center h-100 ">
        <div className="col-md-12 m-0 p-0 bordecompleto">
          <div className=" h-100  row  w-100 d-flex justify-content-start align-items-start flex-column h-auto ">
            <div className="col-md-12 p-0 m-0">
              <div className="row  ">
                <div
                  className={
                    state.codigo_documento !== "06"
                      ? "col-md-3  bgfondo bordesombreado p-1 m-0   "
                      : "   col-md-3 bgfondo bordesombreadop-1 m-0 "
                  }
                >
                  <div className="bgfondo h-100">
                    <div className="row p-2 ">
                      <BotonesAccionts></BotonesAccionts>
                    </div>
                    <div className="row p-2 ">
                      <div className="w-100 p-2 azulmarino flex-column bordecircular d-flex justify-content-center flex-row align-items-center">
                        <label
                          className="negrita letrablanca "
                          style={{ fontSize: "16px" }}
                        >
                          {state.editar === "SI" ? "EDITANDO " : ""}
                          {state.codigo_documento === "01"
                            ? "FACTURA "
                            : state.codigo_documento === "03"
                            ? "LIQUIDACIÓN DE COMPRA "
                            : state.codigo_documento === "06"
                            ? "GUÍA DE REMISIÓN"
                            : state.codigo_documento === "07"
                            ? "RETENCIÓN "
                            : state.codigo_documento === ""
                            ? "RECIBO "
                            : "DOCUMENTO NO DISPONIBLE"}
                        </label>
                        <label
                          className="negrita letrablanca"
                          style={{ fontSize: "16px" }}
                        >
                          {" "}
                          {parseInt(state.ambiente) === 2
                            ? "AMBIENTE DE PRODUCCION"
                            : parseInt(state.ambiente) === 1
                            ? "AMBIENTE DE PRUEBAS"
                            : "NO SE AH SELECCIONADO EL AMBIENTE"}
                        </label>{" "}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div targetId="2" className="">
                        {state.codigo_documento === "01" ||
                        state.codigo_documento === ""
                          ? "INFORMACIÓN DEL CLIENTE"
                          : state.codigo_documento === "06"
                          ? "INFORMACIÓN DEL DESTINATARIO"
                          : state.codigo_documento === "07"
                          ? "INFORMACIÓN DEL RETENIDO"
                          : "INFORMACIÓN DEL PROVEEDOR"}
                      </div>
                      <CabeceraFactura
                        onchangeprincipal={onchange}
                        state={state}
                        tipoDocumentos={tiposDocumentos}
                        cargarcomprador={cargarcomprador}
                        tiposIdentificacion={tiposIdentificacion}
                      ></CabeceraFactura>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    state.codigo_documento !== "06"
                      ? "col-md-9 bgfondo2  p-1 m-0 px-0  "
                      : "   col-md-9 bgfondo2 p-1 m-0 px-0 "
                  }
                >
                  <div className="  h-100  row px-0 m-0 mb-3 ">
                    {state.codigo_documento !== "06" ? null : (
                      <div
                        className={
                          state.codigo_documento !== "06"
                            ? "col-md-12    p-0 m-0 "
                            : "col-md-12 p-0 m-0 "
                        }
                      >
                        <TransorteTraslado
                          onchangeprincipal={onchange}
                          state={state}
                          cargartransportista={cargartransportista}
                          tiposIdentificacion={tiposIdentificacion}
                        ></TransorteTraslado>
                      </div>
                    )}
                    {state.codigo_documento !== "06" &&
                    state.codigo_documento !== "07" ? null : (
                      <div
                        className={
                          state.codigo_documento !== "06"
                            ? "col-md-12    p-1 m-0 "
                            : "col-md-12 p-1 m-0 "
                        }
                      >
                        {documentosustento()}
                      </div>
                    )}

                    {state.codigo_documento === "07" ? (
                      <div className="col-md-12 ">
                        <DataRetenciones />
                      </div>
                    ) : null}

                    <div
                      className={
                        state.codigo_documento !== "06"
                          ? "col-md-12    "
                          : "col-md-12   "
                      }
                    >
                      {state.codigo_documento ===
                      "07" ? null : state.codigo_documento === "06" ? (
                        <Items
                          items={state.ventas_detalle}
                          veterinaria_id={veterinaria_id}
                          actionItems={actionItems}
                          deletesitems={state.deletes}
                          actiondeletes={actiondeletes}
                          datafactura={state}
                        ></Items>
                      ) : (
                        <Items
                          items={state.ventas_detalle}
                          veterinaria_id={veterinaria_id}
                          actionItems={actionItems}
                          deletesitems={state.deletes}
                          actiondeletes={actiondeletes}
                          datafactura={state}
                        ></Items>
                      )}
                    </div>
                    {state ? (
                      state.codigo_documento === "06" ||
                      state.codigo_documento === "07" ? (
                        <div className="row py-2 d-flex justify-content-between align-items-center flex-row">
                          <div className="col-md-4 ">
                            <div className="w-100">
                              <button
                                className="bg-btn-vet bordecompleto bordesombreado w-100"
                                onClick={() => setModalInfoAdicional(true)}
                              >
                                Agregar informacion adicional
                              </button>
                            </div>
                          </div>
                          {state.codigo_documento !== "07" ? null : (
                            <div className="col-md-4  ">
                              <div className="azulmarino p-1">
                                <label
                                  for=""
                                  className=" negrita d-flex justify-content-start align-items-start "
                                  style={{ color: "white" }}
                                >
                                  {state.codigo_documento !== "07"
                                    ? "Fecha de venta :"
                                    : "Fecha de emisión :"}
                                </label>
                                <input
                                  type="date"
                                  className=" inputtext bordecompleto "
                                  onChange={onchange}
                                  value={state.fecha_venta}
                                  id="fecha_venta"
                                  name="fecha_venta"
                                  placeholder="Fecha de venta"
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-md-4 ">
                            <div className="w-100">
                              {state.editar === "SI" ? (
                                <button
                                  type=""
                                  className="bg-btn-vet w-100 bordesombreado bordecompleto"
                                  onClick={() => {
                                    editarVentafn();
                                    //setModalListarVentas(true)
                                  }}
                                >
                                  GUARDAR CAMBIOS
                                </button>
                              ) : (
                                <button
                                  type=""
                                  className="bg-btn-vet w-100 bordesombreado bordecompleto w-100 "
                                  onClick={() => {
                                    guardardocumentofn();
                                    //setModalListarVentas(true)
                                  }}
                                >
                                  Guardar{" "}
                                  {state.codigo_documento === ""
                                    ? "Recibo"
                                    : state.codigo_documento === "01"
                                    ? "Factura"
                                    : state.codigo_documento === "03"
                                    ? "Liquidacidación"
                                    : state.codigo_documento === "07"
                                    ? "Retención"
                                    : state.codigo_documento === "06"
                                    ? "Guía de remisión"
                                    : null}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {state ? (
                      state.codigo_documento !== "06" &&
                      state.codigo_documento !== "07" ? (
                        <div className="col-md-12 ">
                          <div className="row bgfondo h-100 ">
                            <div className="col-md-3 bordecompleto  p-0 m-0 d-flex justify-content-between flex-column ">
                              <div
                                className="w-100 azulmarino p-1"
                                style={{ minHeight: "70px", maxHeight: "70%" }}
                              >
                                {state.codigo_documento === "06" ? null : (
                                  <div className="col-md-12  m-0 p-0  ">
                                    <div className="">
                                      <label
                                        for=""
                                        className=" negrita d-flex justify-content-start align-items-start "
                                        style={{ color: "white" }}
                                      >
                                        {state.codigo_documento !== "07"
                                          ? "Fecha de venta :"
                                          : "Fecha de emisión :"}
                                      </label>
                                      <input
                                        type="date"
                                        className=" inputtext bordecompleto "
                                        onChange={onchange}
                                        value={state.fecha_venta}
                                        id="fecha_venta"
                                        name="fecha_venta"
                                        placeholder="Fecha de venta"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="w-100">
                                {state.codigo_documento === "06" ||
                                state.codigo_documento === "07" ? null : (
                                  <div className="w-100 ">
                                    <table className="tbfactura negrita mx-2">
                                      <tbody>
                                        <tr>
                                          <td className="textfloatleft">
                                            Descuento
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.total_descuento
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            Subtotal 15%
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.subtotal_quince
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            Subtotal 5%
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.subtotal_cinco
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            Subtotal 0%{" "}
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.subtotal_siniva
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            Subtotal no objeto de iva{" "}
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.subtotal_no_objeto_iva
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            Subtotal exento de iva
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.subtotal_exento_iva
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            Subtotal sin impuestos{" "}
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.subtotal_sin_impuestos
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            {" "}
                                            Iva 15%{" "}
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(
                                              state.ivaquince
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="textfloatleft">
                                            {" "}
                                            Iva 5%{" "}
                                          </td>
                                          <td>
                                            $
                                            {parseFloat(state.ivacinco).toFixed(
                                              2
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>

                                      {/* <tr>
                                  <td className="tb-left"> ICE </td>
                                  <td> $0.00</td>
                                </tr>
                                <tr>
                                  <td className="tb-left">IRBPNR </td>
                                  <td> $0.00</td>
                                </tr> */}
                                    </table>
                                  </div>
                                )}
                              </div>
                              <div className="w-100 bordearriba p-1">
                                <div className="w-100">
                                  <button
                                    className="bg-btn-vet bordecompleto bordesombreado w-100"
                                    onClick={() => setModalInfoAdicional(true)}
                                  >
                                    Agregar informacion adicional
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 p-0 m-0  d-flex flex-column justify-content-between align-items-center  bordecompleto">
                              <div
                                className="w-100 p-1 azulmarino"
                                style={{ minHeight: "70px", maxHeight: "70px" }}
                              >
                                <label
                                  for=""
                                  className="negrita textfloatleft"
                                  style={{ color: "white" }}
                                >
                                  Formas de pagos:
                                </label>
                                {state.codigo_documento === "06" ||
                                state.codigo_documento === "07" ? null : (
                                  <div
                                    className="col-md-12 m-0 p-0 "
                                    style={{ display: "block" }}
                                  >
                                    <select
                                      className=" inputselect bordecompleto"
                                      id="medio"
                                      name="medio"
                                      style={{
                                        width: "100%",
                                      }}
                                      onChange={(e) => onchange(e)}
                                      value={pagoselect ? pagoselect.medio : ""}
                                    >
                                      <option value="">Forma de Pago...</option>
                                      {tiposPagos.map((data, index) => (
                                        <option
                                          value={data.codigo_forma_pago}
                                          key={index}
                                        >
                                          ( {data.forma_pago} ){` --> `}{" "}
                                          {data.detalle_sri}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </div>
                              <ConfigurablesPagos
                                onchangeprincipal={onchange}
                                state={state}
                                tiposDocumentos={tiposDocumentos}
                                tiposPagos={tiposPagos}
                                tiposImpuestos={tiposImpuestos}
                                pagoselect={pagoselect}
                              ></ConfigurablesPagos>
                              <div className="w-100 bordearriba p-1">
                                <button
                                  className="bg-btn-vet w-100 bordecompleto bordesombreado"
                                  onClick={async () => {
                                    if (pagoselect.medio === "") {
                                      toast.error(
                                        `No se seleccionó el metodo de pago`,
                                        {
                                          theme: "colored",
                                          position: "bottom-left",
                                        }
                                      );
                                      return;
                                    }

                                    if (
                                      totalpendiente + pagoselect.total >
                                      state?.valor_total
                                    ) {
                                      toast.error(
                                        `Se está ingresando un pago que supera el total de la factura`,
                                        {
                                          theme: "colored",
                                          position: "bottom-left",
                                        }
                                      );
                                    }
                                    const getD = await getPagosData(
                                      state ? state.pagos : [],
                                      pagoselect
                                    );
                                    setState({
                                      ...state,
                                      pagos: getD,
                                    });
                                  }}
                                >
                                  Agregar pago
                                </button>
                              </div>
                            </div>
                            <div className="col-md-5  d-flex justify-content-start align-items-start flex-column bordecompleto">
                              {/* INICIO DEL PANEL DE PAGOS */}

                              <div className="row my-2 bgfondo2 w-100 d-flex justify-content-center align-items-center flex-column">
                                <div className=" ">
                                  <div className="negrita">Pagos agregados</div>
                                  <p>Listado de pagos agregados</p>
                                  <table className="w-100 py-2">
                                    <tbody className="w-100">
                                      {state
                                        ? state.pagos.map((data, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="w-100 d-flex flex-row w-100 px-1 py-2 bordevertical"
                                              >
                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                    width: "40%",
                                                  }}
                                                >
                                                  <h6>{data.medio} </h6>
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                    width: "25%",
                                                  }}
                                                >
                                                  {" "}
                                                  <h6
                                                    for=""
                                                    className="negrita"
                                                  >
                                                    {"========>"}
                                                  </h6>
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                    width: "25%",
                                                  }}
                                                >
                                                  <h6> {`$${data.total}`}</h6>
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                    width: "10%",
                                                  }}
                                                >
                                                  <button
                                                    type=""
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                      background: "red",
                                                      border: "1px solid red",
                                                      fontSize: "20px",
                                                      color: "white",
                                                    }}
                                                    className="mx-2 negrita d-flex justify-content-center align-items-center bordecircular"
                                                    onClick={async () => {
                                                      const res =
                                                        await state.pagos.filter(
                                                          (op) =>
                                                            op.medio !==
                                                            data.medio
                                                        );
                                                      setState({
                                                        ...state,
                                                        pagos: res,
                                                      });
                                                    }}
                                                  >
                                                    <label
                                                      for=""
                                                      style={{
                                                        color: "white",
                                                        fontSize: "30px",
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      {" "}
                                                      -{" "}
                                                    </label>
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : null}
                                    </tbody>
                                  </table>{" "}
                                </div>
                                <div className="d-flex flex-row justify-content-between  aling-items-center">
                                  <h5>PAGO PENDIENTE</h5>
                                  <h2>$ {totalpendiente}</h2>
                                </div>
                              </div>
                              {/* FIN DEL PANEL DE PAGOS */}
                              {/* INICIO TOTALES */}
                              <div
                                className="p-0 m-0 w-100 color-plomo azulmarino p-2 d-flex flex-row justify-content-between "
                                style={{ fontSize: "60px" }}
                              >
                                <h2>TOTAL</h2>
                                <h2>$ {state ? state.valor_total : 0.0}</h2>
                              </div>
                              {stateguardarerror !== "" ? (
                                <div
                                  className="p-3 my-2 bordecircular row d'flex justify-content-center flex row"
                                  style={{ background: "red" }}
                                >
                                  <label
                                    style={{ color: "white" }}
                                    className="valortotal"
                                  >
                                    NOTIFICACIÓN
                                  </label>
                                  <label
                                    style={{ color: "white" }}
                                    className="valortotal"
                                  >
                                    {stateguardarerror}
                                  </label>
                                </div>
                              ) : null}
                              <div className="w-100 py-1">
                                {state.editar === "SI" ? (
                                  <button
                                    type=""
                                    className="bg-btn-vet bordesombreado bordecompleto"
                                    onClick={() => {
                                      editarVentafn();
                                      //setModalListarVentas(true)
                                    }}
                                  >
                                    GUARDAR CAMBIOS
                                  </button>
                                ) : (
                                  <button
                                    type=""
                                    className="bg-btn-vet bordesombredo w-100 my-3 py-5"
                                    onClick={() => {
                                      guardardocumentofn();
                                      //setModalListarVentas(true)
                                    }}
                                  >
                                    Guardar{" "}
                                    {state.codigo_documento === ""
                                      ? "Recibo"
                                      : state.codigo_documento === "01"
                                      ? "Factura"
                                      : state.codigo_documento === "03"
                                      ? "Liquidacidación"
                                      : state.codigo_documento === "07"
                                      ? "Retención"
                                      : state.codigo_documento === "06"
                                      ? "Guía de remisión"
                                      : null}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/*  <div className="col-md-12">
              <div className="row my-1">
                <Dataitemspanel />
              </div>
            </div> */}
            {/* INICIO DE INFORMACION ADICIONAL */}

            {/* FIN DE INFORMACION ADICIONAL */}

            <div className=" col-md-12  px-3">
              <UncontrolledAccordion
                defaultOpen={["1", "2", "3", "4", "5"]}
                stayOpen
              >
                {/* <AccordionItem className="mb-3 bordesombreado bordecompleto ">
                    <AccordionHeader targetId="1">CABECERA</AccordionHeader>
                    <AccordionBody accordionId="1" className="py-3">
                      <div className="row">
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Razon_social:{" "}
                          </label>
                          {nombre}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Nombre comercial:{" "}
                          </label>
                          {nombre}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Ruc:
                          </label>
                          {ruc}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Régimen:{" "}
                          </label>
                          {regimen}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Obligado a llevar contabilidad:
                          </label>
                          {obligado_contabilidad}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            contribuyente especial:
                          </label>
                          {contribuyente_especial}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Punto de emisión :
                          </label>
                          {establecimiento_punto_emision}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Código de establecimiento :
                          </label>
                          {establecimiento_codigo}
                        </div>
                        <div className="col-md-6 d-flex flex-row justify-content-start">
                          <label for="" className="negrita">
                            Calificación artesanal :
                          </label>
                          {calificacion_artesanal}
                        </div>
                        <div className="col-md-12 d-flex flex-row justify-content-start">
                          <label for="">
                            <label className="m-1 negrita">Dirección:</label>{" "}
                            {direccion}
                          </label>
                        </div>
                      </div>
                    </AccordionBody>
                  </AccordionItem> */}
              </UncontrolledAccordion>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onchangedocsustentoretencionbaseimponible = (e, i) => {
    let aux = state.docsustento;
    aux.impuestosdocsustento[i].baseImponible = e.target.value;
    aux.impuestosdocsustento[i].valorImpuesto =
      (parseFloat(e.target.value) * aux.impuestosdocsustento[i].tarifa) / 100;
    setState({
      ...state,
      docsustento: aux,
    });
  };

  const onchangedocsustentoretencionretenciones = (e, i) => {
    let aux = state.docsustento;
    aux.retencionesdocsustento[i].baseImponible = e.target.value;
    aux.retencionesdocsustento[i].valorRetenido =
      parseFloat((parseFloat(e.target.value) *
        aux.retencionesdocsustento[i].porcentajeRetener) /
      100).toFixed(2);
    setState({
      ...state,
      docsustento: aux,
    });
  };

  const deletedocsustentoretencionbaseimponible = async (e, i) => {
    e.preventDefault();
    let aux = await state.docsustento;
    const auxtotal = await aux.impuestosdocsustento.filter(
      (data, index) => index !== i
    );
    aux.impuestosdocsustento = auxtotal;

    setState({
      ...state,
      docsustento: aux,
    });
  };

  const deletedocsustentoretencion = async (e, i) => {
    e.preventDefault();
    let aux = await state.docsustento;
    const auxtotal = await aux.retencionesdocsustento.filter(
      (data, index) => index !== i
    );
    aux.retencionesdocsustento = auxtotal;

    setState({
      ...state,
      docsustento: aux,
    });
  };

  const addonchangedocsustentoretencionformapago = async (e) => {
    const aux = state.docsustento;
    const resvaluepago = await tiposPagos.filter(
      (data) => data.codigo_sri === e.target.value
    );
    console.log(resvaluepago);
    let obj = {
      formaPago: resvaluepago[0].codigo_sri,
      detallesri: resvaluepago[0].detalle_sri,
      total: state.docsustento.importeTotal,
      notas: "",
    };
    aux.pagosdocsustento.push(obj);
    setState({
      ...state,
      docsustento: aux,
    });
  };

  const deletedocsustentoretencionformapago = async (e, i) => {
    e.preventDefault();
    let aux = await state.docsustento;
    const auxtotal = await aux.pagosdocsustento.filter(
      (data, index) => index !== i
    );
    aux.pagosdocsustento = auxtotal;

    setState({
      ...state,
      docsustento: aux,
    });
  };

  const getDocumentosDisponibles = () => {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column  px-5">
        <div className="bgfondo3 p-3 m-2 w-100">
          <h4>SELECCION EL AMBIENTE DE LOS DOCUMENTOS</h4>
          <select
            className=" inputselect negrita bordecompleto bordesombreado  w-50 mx-2"
            id="ambiente"
            name="ambiente"
            style={{ fontSize: "18px", textAlign: "center" }}
            onChange={(e) => onchange(e)}
            value={state.ambiente}
          >
            <option value={2}>AMBIENTE DE PRODUCCION</option>
            <option value={1}>AMBIENTE DE PRUEBAS</option>
          </select>
          {state.ambiente ? <label for=""></label> : null}
        </div>
        <div className="negrita">
          <h3>SELECCIONE UN DOCUMENTO PARA INICIAR</h3>
        </div>
        <div
          className="row w-100 d-flex justify-content-center "
          style={{ height: "200px" }}
        >
          {tiposDocumentos.map((data, index) => {
            return (
              <div className="col-md-3 col-sm-3 p-2" key={index}>
                <button
                  onClick={() => {
                    if (!state.ambiente) return alert("Elija el ambiente ");
                    setState({
                      ...state,
                      codigo_documento: data.codigo_documento
                        ? data.codigo_documento
                        : "",
                    });
                  }}
                  className="card w-100 shadow  h-100 d-flex  justify-content-center flex-column align-items-center"
                >
                  <i className="fas fa-book" style={{ fontSize: "50px" }}></i>
                  <div className="negrita my-2">
                    <h6>{data.documento.toUpperCase()}</h6>
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const compruebaDataDocumentos = (codigo_documento) => {
    if (codigo_documento === "99") return getDocumentosDisponibles();

    if (codigo_documento === "06" && parseInt(!init_number_guia_remision))
      return (
        <div>No se ah configurado el número inicial de la guia de remisión</div>
      );
    if (codigo_documento === "07" && parseInt(!init_number_retencion))
      return (
        <div>
          No se ah configurado el número inicial inicial de la retención
          {init_number_retencion}
        </div>
      );
    if (codigo_documento === "03" && parseInt(!init_number_liquidacion))
      return (
        <div>
          No se ah configurado el número inicial de la liquidación de compras
        </div>
      );
    if (codigo_documento === "01" && !init_number_venta)
      return <div>No se ah configurado el número inicial de la factura</div>;

    if (codigo_documento === "" && !init_number_recibos)
      return (
        <div>
          No se ah configurado el número inicial del recibo{" "}
          {init_number_recibos}
        </div>
      );

    return habilitardocumento();
  };

  return (
    <div className="col-md-12 p-0 m-0">
      {validateDoc.acces ? (
        <div className="w-100 bgfondo2  p-0 m-0" style={{ height: "90vh" }}>
          {state ? compruebaDataDocumentos(state.codigo_documento) : "loading"}
        </div>
      ) : (
        <div className="w-100" style={{ height: "90vh" }}>
          <div className="alert alert-warning" role="alert">
            <h3>{validateDoc.message}</h3>
            <h6> Para redirigite a la configuración de la empresa</h6>

            <button
              href="/#"
              className="bg-btn-vet"
              onClick={() => {
                window.location = "/seguridad/inicio";
              }}
            >
              Presiona aquí
            </button>
          </div>
        </div>
      )}
      <ModalDefault
        modal={modalListarVentas}
        //maxWidth={"75%"}
        fullscreen={true}
        titulo={state ? getCabeceraModal() : ""}
        closeAction={closeAction}
        classnamebody="pt-1 pb-0 px-2"
        classnameheader="bordesombreado"
      >
        {state ? (
          state.codigo_documento === "01" ? (
            <TablaFacturas
              empresa_id={veterinaria_id}
              usuario_id={usuario_id}
              ocultar={true}
              codigo_documento={state.codigo_documento}
              ambiente={state.ambiente}
            ></TablaFacturas>
          ) : state.codigo_documento === "03" ? (
            <Tablaliquidaciones
              empresa_id={veterinaria_id}
              usuario_id={usuario_id}
              ocultar={true}
              codigo_documento={state.codigo_documento}
              ambiente={state.ambiente}
            ></Tablaliquidaciones>
          ) : state.codigo_documento === "06" ? (
            <TablaGuiasRemision
              empresa_id={veterinaria_id}
              usuario_id={usuario_id}
              ocultar={true}
              codigo_documento={state.codigo_documento}
            ></TablaGuiasRemision>
          ) : state.codigo_documento === "07" ? (
            <TablaRetenciones
              empresa_id={veterinaria_id}
              usuario_id={usuario_id}
              ocultar={true}
              codigo_documento={state.codigo_documento}
            ></TablaRetenciones>
          ) : state.codigo_documento === "" ? (
            <TablaRecibos
              empresa_id={veterinaria_id}
              usuario_id={usuario_id}
              ocultar={true}
              codigo_documento={state.codigo_documento}
            ></TablaRecibos>
          ) : null
        ) : null}
      </ModalDefault>
      <ModalDefaultPreload
        modal={modalGuardarVentas}
        maxWidth={"35%"}
        closeAction={closeAction2}
      >
        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
          <Spinnericon className="svgpreloadfactura" />
          <div>{stateguardar}</div>
        </div>
      </ModalDefaultPreload>

      <ModalDefault
        modal={modalInfoAdicional}
        maxWidth={"50%"}
        closeAction={closeActionModalInformacionAdicional}
      >

        {state ? (
          <div className="col-md-12">
            <div className="row my-3">
              <div className="row">
                <div className="col-md-12 bgfondo">
                  <div className="row">
                    <div className="col-md-12 my-2 ">
                      <div className="row">
                        <div className="col-md-4  d-flex flex-row justify-content-start">
                          <div className="form-group w-100">
                            {" "}
                            <label for="" className=" negrita">
                              Atributo :
                            </label>
                            <div className="col-md-12">
                              <input
                                type="text"
                                className=" inputtext bordecompleto"
                                onChange={onchangeinfoadicional}
                                value={infoadicionalState.atributo}
                                id="atributo"
                                name="atributo"
                                placeholder="Atributo adicional"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8  d-flex flex-row justify-content-start">
                          <div className="form-group w-100">
                            {" "}
                            <label for="" className=" negrita">
                              Detalle del atributo :
                            </label>
                            <div className="col-md-12">
                              <input
                                type="text"
                                className=" inputtext bordecompleto"
                                onChange={onchangeinfoadicional}
                                value={infoadicionalState.detalle}
                                id="detalle"
                                name="detalle"
                                placeholder="Detalle del atributo"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 align-self-center d-flex">
                          <button
                            type=""
                            onClick={() => agregarInformacionAdicionl()}
                            className="bg-btn-vet  w-100   bordesombreado bordecompleto bordecompleto"
                          >
                            Agregar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {listadoinformacionadicional()}
                </div>
              </div>

            </div>
          </div>
        ) : null}
      </ModalDefault>
    </div>
  );
};

export default VentasFacturas;
