import React, { useState, useEffect } from "react";
import ModalDefault from "../../modalDefault";

import {
  getBancosEmpresaApi,
  eliminarBancosEmpresaApi,
} from "../../../api/contable/bancos";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
const TablaFiles = (props) => {
  const [selectData, setselectData] = useState(null);

  const [load, setLoad] = useState(false);
  const [modalconfirmacion, setModalConfirmacion] = useState(false);
  const [fetching, setfetching] = useState(false);

  

  const PAGE_SIZE = 7;
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [page]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [data]);

  const accionescategoria = (data) => {
    props.ejecutarOpciones(data, true);
  };

  const eliminarCategoriaFn = async (banco_id) => {
    alert(banco_id);
    const res = await eliminarBancosEmpresaApi(banco_id);
    if (res[0].sms === "ok") {
      setModalConfirmacion(false);
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      selectData(null);
    } else {
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
    }
  };

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={(e) => {
              accionescategoria(e, row);
            }}
          >
            editar
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger mx-1"
            onClick={() => {
              setselectData(row);
              setModalConfirmacion(true);
            }}
          >
            Eliminar
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    // initComponent();
    setdata(props.dataArchivos);
    setRecords(props.dataArchivos.slice(0, PAGE_SIZE));
  }, [props.dataArchivos]);

  

  const modalActions = (modal, bandera) => {
    setModalConfirmacion(modal);
  };

  const opcionesx = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="start">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              accionescategoria(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> editar
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              setselectData(row);
              setModalConfirmacion(true);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const [expandedRecordIds, setExpandedRecordIds] = useState(["0","1"]);


const collapseAllRows = () => {
  setExpandedRecordIds([]);
};
  return (
    <>
      <DataTable
        borderRadius="md"
        striped
        /*  verticalAlignment="top" */
        className="bordecompleto bgfondo "
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        idAccessor="indexrecords"
        columns={[
          {
            accessor: "infoFactura",
            title: "PROVEEDOR",
            textAlignment: "left",
            render: ({ children }) =>
              `${
                children[0].children.filter(
                  (data) => data.name === "razonSocial"
                )[0].value
              } ( ${
                children[0].children.filter((data) => data.name === "ruc")[0]
                  .value
              } )`,
            width: "180px",
          },
          {
            accessor: "identificacionComprador",
            title: "COMPRADOR",
            textAlignment: "left",
            render: (
              { children } /* JSON.stringify(children[1].children[5].name), */
            ) =>
              `${
                children[1].children.filter(
                  (data) => data.name === "razonSocialComprador"
                )[0].value
              } -( ${
                children[1].children.filter(
                  (data) => data.name === "identificacionComprador"
                )[0].value
              } )`,
            width: "180px",
          },

          {
            accessor: "codDoc",
            title: "TIPO DOC",
            textAlignment: "left",
            render: ({ children }) =>
              `${
                children[0].children.filter((data) => data.name === "codDoc")[0]
                  .value === "01"
                  ? "FACTURA"
                  : ""
              } `,
            width: "8%",
          },
          {
            accessor: "secuencial",
            title: "SECUENCIAL",
            textAlignment: "left",
            render: ({ children }) =>
              `${
                children[0].children.filter(
                  (data) => data.name === "secuencial"
                )[0].value
              } `,
            width: "8%",
          },
          {
            accessor: "fechaEmision",
            title: "FECHA",
            textAlignment: "left",
            render: ({ children }) =>
              `${
                children[1].children.filter(
                  (data) => data.name === "fechaEmision"
                )[0].value
              } `,
            width: "8%",
          },
          {
            accessor: "totalConImpuestos",
            title: "TOTAL CON IMPUESTOS",
            textAlignment: "left",
            render: ({ children }) =>
              `${
                children[1].children.filter(
                  (data) => data.name === "totalConImpuestos"
                )[0].value
              } `,
            width: "12%",
          },
          {
            accessor: "totalSinImpuestos",
            title: "TOTAL SIN IMPUESTOS",
            textAlignment: "left",
            render: ({ children }) =>
              `${
                children[1].children.filter(
                  (data) => data.name === "totalSinImpuestos"
                )[0].value
              } `,
            width: "12%",
          },
          {
            accessor: "importeTotal",
            title: "IMPORTE TOTAL",
            textAlignment: "left",
            render: ({ children }) =>
              `${
                children[1].children.filter(
                  (data) => data.name === "importeTotal"
                )[0].value
              } `,
            width: "12%",
          },
          {
            accessor: "acciones",
            title: "Acciones",
            textAlignment: "center",
            render: (record) => opcionesx(record),
            width: "30%",
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={data.length}
        page={page}
        onPageChange={(p) => setPage(p)}
        withColumnBorders
        fetching={fetching}
        rowExpansion={{
          allowMultiple: true,
          collapseProps: {
            transitionDuration: 500,
            animateOpacity: false,
            transitionTimingFunction: "ease-out",
          },
          content: ({ record }) => (
            <div className="w-100 px-5 py-4 azulmarino">
              <table className="w-100 bordecompleto">
                <thead>
                  <tr className="azulmarino ">
                    {record.children[2].children[0].children.map((record) => {
                      return (
                        <th className="color-blanco">
                          {record.name.toUpperCase()}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {record.children[2].children[0].children.map((record) => {
                      return <td>{record.value}</td>;
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          ),
        }}
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalconfirmacion}
        size={"sm"}
         
        titulo={`Confirmación`}
        closeAction={modalActions}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará el banco {selectData ? selectData.banco : null}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => setModalConfirmacion(false)}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectData !== null) {
                  eliminarCategoriaFn(selectData.banco_id);
                } else {
                  toast.error("No existe el indice del banco");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      {/*  <ModalDefault bandera={"otracosa"} modal={false} size={"sm"} icontitulo={<AdminIcon/>} titulo={"Titulo modal"} closeAction={modalActions}>
                modal de editar usuario
            </ModalDefault> */}
    </>
  );
};

export default TablaFiles;
