import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import Axios from "../../../config/Axios";
import Swal from "sweetalert2";
import {
  soloPermitirTextoConEspacio,
  soloPermitirNumeros,
  soloPermitirTextoMayusculaConEspacio,
  soloPermitirTextoConEspacioCaracteresBasicos,
} from "../../../config/validaciones";
import {
  getPaisesFn,
  getProvinciasFn,
  getCiudadesFn,
} from "../../../api/ubicaciones";
import { BuscarUsers } from "../../../api/usuarios";

const CrearPaciente = ({ actualizaLista }) => {
  const [ubicaciones, setUbicaciones] = useState({
    paises: [],
    provincias: [],
    ciudades: [],
  });
  const { paises, ciudades, provincias } = ubicaciones;

  const [usuario, setUsuario] = useState(null);
  const [search, setSearch] = useState("");

  const [state, setState] = useState({
    chip: "",
    nombres: "",
    pelaje: "",
    fec_naci: "",
    especie_id: "",
    raza_id: "",
    sexo_id: "",
    usuario_id: "",
    castrado: "",
    pais_id: "",
    ciudad: "",
    provincia_id: "",
    color: "",
    persona_id: "",
  });

  const [stateReset, setStateReset] = useState({
    chip: "",
    nombres: "",
    pelaje: "",
    fec_naci: "",
    especie_id: "",
    raza_id: "",
    sexo_id: "",
    usuario_id: "",
    castrado: "",
    pais_id: "",
    ciudad: "",
    provincia_id: "",
    color: "",
    persona_id: "",
  });
  const [stateSaveInfo, setStateSaveInfo] = useState({
    mascota_id: "",
    nombres: "",
  });

  const [stateLoadInfo, setStateLoadInfo] = useState({
    especies: [],
    razas: [],
  });

  const [stateModal, setStateModal] = useState({
    nuevoPacienteModal: false,
    userdata: {},
    configModal: false,
  });

  useEffect(() => {}, []);

  const BuscarUsersFn = async (e) => {
    if (e.target.value === "") {
    } else if (e.key === "Enter") {
      const respuesta = await BuscarUsers(e.target.value);
      console.log(respuesta);
      if (respuesta[0].sms === "ok") {
        setUsuario(respuesta[0].result[0]);
        setState({
          ...state,
          usuario_id: respuesta[0].result[0].usuario_id,
          persona_id: respuesta[0].result[0].persona_id,
        });
      } else {
        setUsuario(null);
      }
    }
  };

  const iniciarPanelEspecies = async () => {
    try {
      const result = await Axios.get(`/allDataEspecies`);
      setStateLoadInfo({
        ...stateLoadInfo,
        especies: result.data.result,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const iniciarPanelRazas = async (especie_id) => {
    try {
      const result = await Axios.get(`/allDataRazas/${especie_id}`);
      console.log(result);
      setStateLoadInfo({
        ...stateLoadInfo,
        razas: result.data.result,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onchange = async (e, valor) => {
    e.preventDefault();
    if (e.target.name === "search") {
      setSearch(e.target.value);
      return;
    }
    if (e.target.name === "nombres") {
      //inicio if principal campo nombres ====================================================
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            state.nombres[
              state.nombres.length === 0
                ? state.nombres.length
                : state.nombres.length - 1
            ] !== " "
          ) {
            setState({
              ...state,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //inicio elseif principal campo pelaje ====================================================
    } else if (e.target.name === "pelaje") {
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            state.pelaje[
              state.pelaje.length === 0
                ? state.pelaje.length
                : state.pelaje.length - 1
            ] !== " "
          ) {
            setState({
              ...state,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "direccion") {
      if (
        e.target.value[
          e.target.value.length === 0
            ? e.target.value.length
            : e.target.value.length - 1
        ] === " "
      ) {
        if (
          state.direccion[
            state.direccion.length === 0
              ? state.direccion.length
              : state.direccion.length - 1
          ] !== " "
        ) {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } else {
        setState({
          ...state,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "chip") {
      console.log(e.target.value.length);
      if (soloPermitirNumeros.test(e.target.value)) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "ciudad") {
      if (
        e.target.value[
          e.target.value.length === 0
            ? e.target.value.length
            : e.target.value.length - 1
        ] === " "
      ) {
        if (
          state.ciudad[
            state.ciudad.length === 0
              ? state.ciudad.length
              : state.ciudad.length - 1
          ] !== " "
        ) {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } else {
        setState({
          ...state,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }

      //fin if principal otros compos  ====================================================
    } else if (e.target.name === "pais_id") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
      const provincias = await getProvinciasFn(e.target.value);
      setUbicaciones({
        ...ubicaciones,
        provincias: provincias[0].data,
      });
    } else if (e.target.name === "provincia_id") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
      const ciudades = await getCiudadesFn(e.target.value);
      setUbicaciones({
        ...ubicaciones,
        ciudades: ciudades[0].data,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }

    if (valor === 1) {
      iniciarPanelRazas(e.target.value);
    }
  };

  const CrearPaciente = async (e) => {
    e.preventDefault();
    if (state.nombres === "") {
      alert("El nombre es oblgatorio");
    } else if (state.pelaje === "") {
      alert("El pelaje es obligatorio");
    } else if (state.castrado === "") {
      alert("Se debe seleccionar si es castrado");
    } else if (state.sexo_id === "") {
      alert("Se debe seleccionar el sexo");
    } else if (state.especie_id === "") {
      alert("Se debe seleccionar la especie");
    } else if (state.raza_id === "") {
      alert("Se debe seleccionar la raza");
    } else if (!navigator.onLine) {
      alert("El explorador no tiene acceso a la red");
    } else {
      try {
        const respuesta = await Axios.post("/createMascotas", state);
        if (respuesta.data.sms === "ok") {
          Swal.fire("Aviso!", `${respuesta.data.mensaje}!`, "success");

          /* setStateModal({
            ...stateModal,
            userdata: {
              mascota_id: respuesta.data.mascota_id,
              nombreMascota: state.nombres,
            },
          }); */

          actualizaLista(respuesta.data.mascota_id, state.nombres);
          setState(stateReset);
        } else {
          Swal.fire("Aviso!", `${respuesta.data.mensaje}!`, "error");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    iniciarPanelEspecies();
  }, []);

  useEffect(() => {
    inizializar();
    return () => {};
  }, []);

  const inizializar = async () => {
    const paises = await getPaisesFn();
    setUbicaciones({
      ...ubicaciones,
      paises: paises[0].data,
    });
  };

  return (
    <>
      {/* Main content */}
      <section className="">
        <div className="">
          <div className="row">
            {usuario ? (
              <div className="col-md-12 d-flex justify-content-center align-items-center flex-column">
                <div>
                  Usuario Master: {usuario.nombres + " " + usuario.apellidos}
                </div>
                <div>
                  <button
                    className="btn btn-danger my-2"
                    onClick={() => {
                      setUsuario(null);
                      setState(stateReset);
                    }}
                  >
                    Cerrar
                  </button>
                </div>
                <div className="col-md-12">
                  {/* general form elements */}
                  <div className="card card-primary elevation-2">
                    {/* /.card-header */}
                    {/* form start */}
                    <form role="form">
                      <div className="card-body">
                        <div className="col-md-12">
                          <div className="">
                            <p style={{ fontSize: "16px" }}>
                              {" "}
                              INFORMACIÓN GENERAL
                            </p>
                            <hr></hr>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Chip :</label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control inputtext"
                                onChange={onchange}
                                value={state.chip}
                                id="chip"
                                name="chip"
                                placeholder="Ingrese un número identificación"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1 my-1">
                                Nombres* :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control inputtext"
                                onChange={onchange}
                                value={state.nombres}
                                id="nombres"
                                name="nombres"
                                placeholder="Ingrese los nombres "
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1 my-1">
                                Pelaje* :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control inputtext"
                                onChange={onchange}
                                value={state.pelaje}
                                id="pelaje"
                                name="pelaje"
                                placeholder="Ingrese los pelaje "
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Color* :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control inputtext"
                                onChange={onchange}
                                value={state.color}
                                id="color"
                                name="color"
                                placeholder="Ingrese los color "
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1 my-1">
                                Fecha Nacimiento :
                              </label>
                              <br></br>
                              <input
                                type="date"
                                className="form-control inputselect"
                                onChange={onchange}
                                value={state.fec_naci}
                                id="fec_naci"
                                name="fec_naci"
                                placeholder="Ingrese teléfono "
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1 my-1">
                                Castrado* :
                              </label>
                              <br></br>
                              <select
                                className="form-control inputselect select2bs4"
                                id="castrado"
                                name="castrado"
                                style={{ width: "100%" }}
                                onChange={onchange}
                                value={state.castrado}
                              >
                                <option value="">Seleccione ...</option>
                                <option value="1">SI</option>
                                <option value="2">NO</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1 my-1">
                                Sexo* :
                              </label>
                              <br></br>
                              <select
                                className="form-control inputselect select2bs4"
                                id="sexo_id"
                                name="sexo_id"
                                style={{ width: "100%" }}
                                onChange={onchange}
                                value={state.sexo_id}
                              >
                                <option value="">Seleccione ...</option>
                                <option value="1">MACHO</option>
                                <option value="2">HEMBRA</option>
                              </select>
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ display: "block" }}
                          >
                            <div className="form-group">
                              <label>Seleccione la especie* :</label>
                              <select
                                className="form-control inputselect select2bs4"
                                id="especie_id"
                                name="especie_id"
                                style={{ width: "100%" }}
                                onChange={(e) => onchange(e, 1)}
                                value={state.especie_id}
                              >
                                <option value="">Seleccione ...</option>
                                {stateLoadInfo.especies.map((data, index) => (
                                  <option value={data.especie_id} key={index}>
                                    {data.especie}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ display: "block" }}
                          >
                            <div className="form-group">
                              <label>Seleccione la raza* :</label>
                              <select
                                className="form-control inputselect select2bs4"
                                id="raza_id"
                                name="raza_id"
                                style={{ width: "100%" }}
                                onChange={(e) => onchange(e, 2)}
                                value={state.raza_id}
                              >
                                <option value="">Seleccione ...</option>
                                {stateLoadInfo.razas.map((data, index) => (
                                  <option value={data.raza_id} key={index}>
                                    {data.raza}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Pais* :</label>
                              <select
                                className="form-control inputselect select2bs4"
                                id="pais_id"
                                name="pais_id"
                                style={{ width: "100%" }}
                                onChange={onchange}
                                value={state.pais_id}
                              >
                                <option value="0">Seleccione ...</option>
                                {paises.map((data, index) => (
                                  <option value={data.pais_id} key={index}>
                                    {data.pais}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Provincia* :</label>
                                <select
                                  className="form-control inputselect select2bs4"
                                  id="provincia_id"
                                  name="provincia_id"
                                  style={{ width: "100%" }}
                                  onChange={onchange}
                                  //onClick={cargarCiudades}
                                  value={state.provincia_id}
                                >
                                  <option value="0">Seleccione ...</option>
                                  {provincias.map((data, index) => (
                                    <option
                                      value={data.provincia_id}
                                      key={index}
                                    >
                                      {data.provincia}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Ciudad* :</label>
                                <input
                                  type="text"
                                  className="form-control  inputtext"
                                  onChange={onchange}
                                  value={state.ciudad}
                                  id="ciudad"
                                  name="ciudad"
                                  placeholder="Ingrese la ciudad"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      {/* /.card-body */}
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-primary w-100 p-2 "
                          style={{ float: "right" }}
                          onClick={(e) => CrearPaciente(e)}
                        >
                          Guardar Mascota
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Filtrar cliente por número de cédula:
                  </label>
                  <br></br>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Filtre la cédula del cliente y precione enter"
                    aria-label="codigobuscar"
                    aria-describedby="addon-wrapping"
                    onChange={onchange}
                    onKeyUp={(e) => BuscarUsersFn(e)}
                    id="search"
                    name="search"
                    value={search}
                  />
                  <span className="sizeInfoInput" style={{color:"blue"}}>
                    Es necesario identificar el usuario al que se asignara la
                    mascota!.
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  );
};

export default CrearPaciente;
