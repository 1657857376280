import React, {  useContext } from "react";
import TablaVentas from "./listarventas";
import AuthContext from "../../../Context/auth/authContext";

const Ventas = () => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;
  const {
    veterinaria_id,
  } = sessiondatacli[index].veterinaria[0];
  return (
    <div className="col-md-12">
      <TablaVentas
        empresa_id={veterinaria_id}
        xkey={123}
        xpassword={555}
      ></TablaVentas>
    </div>
  );
};

export default Ventas;
