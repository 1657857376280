import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
const ModalDefaultPreload = (props) => {
  //normalize('NFD').replace(/[\u0300-\u036f]/g,"")

  const modal = props.modal;
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(modal);
  }, [modal]);

  const cerrarModal = () => {
    props.closeAction(!modal, props.bandera);
  };

  return (
    <Modal
      isOpen={state}
      toggle={() => {}}
      centered
      size={props.size}
      style={{ maxWidth: props.maxWidth }}
    >
      <ModalBody      style={{background:props.bg }} >{props.children}</ModalBody>
    </Modal>
  );
};

export default ModalDefaultPreload;
