import React, { useContext, useEffect, useState } from 'react';

import Axios from "../../../config/Axios"
import Swal from 'sweetalert2'
import {
    soloPermitirTextoConEspacio,
    soloPermitirNumeros,
    soloPermitirTextoMayusculaConEspacio,
    soloPermitirTextoConEspacioCaracteresBasicos
} from "../../../config/validaciones"



const EditarPaciente = ({ data }) => {

    const { mascota_id,chip, nombres, pelaje, fec_naci, especie_id, raza_id, sexo_id, usuario_id, castrado
    } = data

    const [state, setState] = useState({
        mascota_id:mascota_id,
        chip: chip,
        nombres: nombres,
        pelaje: pelaje,
        fec_naci: fec_naci,
        especie_id: especie_id,
        raza_id: raza_id,
        sexo_id: sexo_id,
        usuario_id: usuario_id,
        castrado: castrado
    })

    const [stateLoadInfo, setStateLoadInfo] = useState({
        especies: [],
        razas: []
    })


    useEffect(() => {

    }, [])


    const iniciarPanelEspecies = async () => {
        try {
            const result = await Axios.get(`/allDataEspecies`)
            const result2 = await Axios.get(`/allDataRazas/${especie_id}`)
            setStateLoadInfo({
                ...stateLoadInfo,
                especies: result.data.result,
                razas: result2.data.result
            })

        } catch (e) {
            console.log(e)
        }
    }

    const iniciarPanelRazas = async (especie_id) => {
        try {
            const result = await Axios.get(`/allDataRazas/${especie_id}`)
            setStateLoadInfo({
                ...stateLoadInfo,
                razas: result.data.result
            })

        } catch (e) {
            console.log(e)
        }
    }


    const onchange = (e, valor) => {
        e.preventDefault()

        if (e.target.name === "nombres")  //inicio if principal campo nombres ==================================================== 
        {

            if (soloPermitirTextoConEspacio.test(e.target.value))//inicio if para comprobar que el valor ingresado solo contenga texto y espacios    
            {//** */
                if (e.target.value[e.target.value.length === 0 ? e.target.value.length : e.target.value.length - 1] === " ") {
                    if (state.nombres[state.nombres.length === 0 ? state.nombres.length : state.nombres.length - 1] !== " ") {
                        setState({
                            ...state,
                            [e.target.name]: e.target.value.toUpperCase()
                        })
                    }
                } else {
                    setState({
                        ...state,
                        [e.target.name]: e.target.value.toUpperCase()
                    })
                }

            } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios    

            //inicio elseif principal campo pelaje ==================================================== 
        } else if (e.target.name === "pelaje") {

            if (soloPermitirTextoConEspacio.test(e.target.value))//inicio if para comprobar que el valor ingresado solo contenga texto y espacios    
            {//** */
                if (e.target.value[e.target.value.length === 0 ? e.target.value.length : e.target.value.length - 1] === " ") {
                    if (state.pelaje[state.pelaje.length === 0 ? state.pelaje.length : state.pelaje.length - 1] !== " ") {
                        setState({
                            ...state,
                            [e.target.name]: e.target.value.toUpperCase()
                        })
                    }
                } else {
                    setState({
                        ...state,
                        [e.target.name]: e.target.value.toUpperCase()
                    })
                }

            } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios    

            //fin if principal otros compos  ==================================================== 

        } else if (e.target.name === "chip") {
            console.log(e.target.value.length)
            if (soloPermitirNumeros.test(e.target.value)) {
                setState({
                    ...state,
                    [e.target.name]: e.target.value
                })
            }
        }/*
        else if (e.target.name === "fec_naci") {
            if (soloPermitirNumeros.test(e.target.value)) {
                setState({
                    ...state,
                    [e.target.name]: e.target.value
                })
            }
        } */else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
        }



        if (valor === 1) {
            iniciarPanelRazas(e.target.value)
        }
    }



    const editarPaciente = async (e) => {
        e.preventDefault()
        if (state.nombre === "") {
            alert("El nombre es oblgatorio")
        } else if (state.chip === "") {
            alert("El chip es obligatorio?")
        } else if (state.pelaje === "") {
            alert("El pelaje es obligatorio")
        } else if (state.fec_naci === "" || (state.fec_naci.length !== 10)) {
            alert("Se debe ingresa una fecha correcta")
        } else if (state.castrado === "") {
            alert("Se debe seleccionar si es castrado")
        } else if (state.genero === "") {
            alert("Se debe seleccionar el sexo")
        } else if (state.especie_id === "" || state.especie_id === "" || stateLoadInfo.especies.length===0) {
            alert("Se debe seleccionar la especie")
        } else if (state.raza_id === "" || state.raza_id === "" || stateLoadInfo.razas.length===0) {
            alert("Se debe seleccionar la raza")
        } else if (!navigator.onLine) {
            alert("El explorador no tiene acceso a la red")
        } else {
            try {
                const respuesta = await Axios.post("/editarMascotas", state);
                if (respuesta.data.sms === "ok") {
                    Swal.fire(
                        'Aviso!',
                        `${respuesta.data.mensaje}!`,
                        'success'
                    )


                } else {
                    Swal.fire(
                        'Aviso!',
                        `${respuesta.data.mensaje}!`,
                        'success'
                    )
                }
            } catch (e) {console.log(e)}
        }
    }


    useEffect(() => {
        iniciarPanelEspecies()
    }, [])

    return (

        <>

            {/* Main content */}
            <section className="">
                <div className="">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-12">
                            {/* general form elements */}
                            <div className="card card-primary elevation-2">

                                {/* /.card-header */}
                                {/* form start */}
                                <form role="form">
                                    <div className="card-body" >
                                        <div className="col-md-12">
                                            <div className="row mb-9" style={{ borderBottom: "1px solid #d8d8d8" }}>
                                                <p style={{ fontSize: "16px" }}> INFORMACIÓN PERSONAL </p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Chip* :</label><br></br>
                                                        <input type="text" className="form-control" onChange={onchange} value={state.chip} id="chip" name="chip" placeholder="Ingrese un número identificación" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Nombres* :</label><br></br>
                                                        <input type="text" className="form-control" onChange={onchange} value={state.nombres} id="nombres" name="nombres" placeholder="Ingrese los nombres " />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Pelaje* :</label><br></br>
                                                        <input type="text" className="form-control" onChange={onchange} value={state.pelaje} id="pelaje" name="pelaje" placeholder="Ingrese los pelaje " />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Fecha Nacimiento* :</label><br></br>
                                                        <input type="date" className="form-control" onChange={onchange} value={state.fec_naci} id="fec_naci" name="fec_naci" placeholder="Ingrese teléfono " />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Castrado* :</label><br></br>
                                                        <select className="form-control select2bs4" id="castrado" name="castrado" style={{ width: '100%' }} onChange={onchange} value={state.castrado}>
                                                            <option value="">Seleccione ...</option>
                                                            <option value="1">SI</option>
                                                            <option value="2">NO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Genero* :</label><br></br>
                                                        <select className="form-control select2bs4" id="sexo_id" name="sexo_id" style={{ width: '100%' }} onChange={onchange} value={state.sexo_id}>
                                                            <option value="">Seleccione ...</option>
                                                            <option value="1">MACHO</option>
                                                            <option value="2">HEMBRA</option>
                                                            <option value="3">OTRO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">

                                                <div className="col-md-6" style={{ display: "block" }}>
                                                    <div className="form-group">
                                                        <label>SELECCIONE LA ESPECIE* :</label>
                                                        <select className="form-control select2bs4" id="especie_id" name="especie_id" style={{ width: '100%' }} onChange={(e) => onchange(e, 1)} value={state.especie_id} >
                                                            <option value="">Seleccione ...</option>
                                                            {
                                                                stateLoadInfo.especies.map((data, index) => (
                                                                    <option value={data.especie_id} key={index}>{data.especie}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" style={{ display: "block" }}>
                                                    <div className="form-group">
                                                        <label>SELECCIONE LA RAZA* :</label>
                                                        <select className="form-control select2bs4" id="raza_id" name="raza_id" style={{ width: '100%' }} onChange={(e) => onchange(e, 2)} value={state.raza_id} >
                                                            <option value="">Seleccione ...</option>
                                                            {
                                                                stateLoadInfo.razas.map((data, index) => (
                                                                    <option value={data.raza_id} key={index}>{data.raza}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                            </div>


                                        </div>
                                    </div>


                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary" style={{ float: "right" }} onClick={(e) => editarPaciente(e)}>Guardar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/*/.col (left) */}
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}



        </>
    )
}


export default EditarPaciente