
export const getEmisorData = async({
  ruc,
  obligado_contabilidad,
  contribuyente_especial,
  nombre_comercial,
  nombre,
  regimen,
  calificacion_artesanal,
  agente_retencion,
  direccion,
  establecimiento_punto_emision,
  establecimiento_codigo,
}) => {
  
    if (ruc.trim().length !== 13) {
      //messageFuncion need ("validate code -ok- , --error- ","message","data")
      return {
        validate: "error",
        message: "El ruc de la empresa debe contener 13 caracteres.",
        data: null,
      };
    }

    if (
      obligado_contabilidad===""
    ) {
      return {
        validate: "error",
        message:
          "Se debe definir -SI- o  -NO- estas obligado a llevar contabilidad  ",
        data: null,
      };
    }

    if (regimen !== "RIMPE" && regimen !== "POPULAR" && regimen !== "GENERAL") {
      return {
        validate: "error",
        message: "Se debe seleccionar el régimen de la empresa",
        data:null,
      };
    }

  const emisorStructure = {
    ruc: ruc,
    obligado_contabilidad: obligado_contabilidad,
    contribuyente_especial: contribuyente_especial,
    nombre_comercial: nombre_comercial,
    razon_social: nombre,
    regimen: regimen,
    calificacion_artesanal: calificacion_artesanal,
    agente_retencion: agente_retencion,
    direccion: direccion,
    establecimiento: {
      punto_emision: establecimiento_punto_emision,
      codigo: establecimiento_codigo,
      direccion: direccion,
    },
  };
  return {
    validate: "ok",
    message: "",
    data: emisorStructure,
  };
  
};


