import React, { useEffect, useState, useContext, useRef } from "react";
import {
  editarMedidasApi,
  guardarMedidaApi,
} from "../../../api/contable/medidas";
import AuthContext from "../../../Context/auth/authContext";
import TablaMedidas from "./tablamedidas";
import { toast } from "react-toastify";
const Medidas = (props) => {
  const medidiasref = useRef(null);
  const paneleditarcrearRef = useRef(null);
  const tablacategoriasRef = useRef(null);
  const [actualizarLista, setActualizarLista] = useState(false);

  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [state, setState] = useState({
    medidas: "",
    empresa_id: veterinaria_id,
  });
  const [statereset, setStateReset] = useState({
    medidas: "",
    empresa_id: veterinaria_id,
  });
  const [editState, setEditState] = useState({
    campoedit: "",
    nuevovalor: "",
    medida_id: "",
  });

  const [respaldoEditField, setRespaldoEditField] = useState("");

  const [editar, setEditar] = useState(false);

  const [buttonState, setButtonState] = useState(false);

  const [load, setLoad] = useState(false);

  const onchange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (editar) ed(e.target.name, e.target.value);
  };

  const guardarCategoria = async () => {
    setLoad(true);

    if (state.medidas === "") {
      toast.error(`El campo medidas se encuentra vacio`, {
        position: "bottom-right",
        theme:"colored"
       /*  className:"toast-message-error" */
      });
      setLoad(false);
      return;
    }
    const res = await guardarMedidaApi(state);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, { position: "bottom-left" });
      setActualizarLista(!actualizarLista);
      setState(statereset);
    } else {
      alert(`${res[0].mensaje}`);
    }
    setLoad(false);
  };

  const habilitarEdicion = (edit, data) => {
    setEditState({
      ...editState,
      campoedit: edit,
      medida_id: data.medida_id,
    });
    setRespaldoEditField(state[edit]);
    medidiasref.current.focus();
  };
  const cancelarEdicion = (e, campoeditar) => {
    e.preventDefault();
    setState(statereset);
    setEditar(false);
    setEditState({
      ...editState,
      campoedit: "",
      nuevovalor: "",
      medida_id: "",
    });
    setRespaldoEditField("");
    document.getElementById(campoeditar).focus();
  };

  const editarFn = async (e) => {
    e.preventDefault();
    setButtonState(true);
    if (editState.campoedit === "") {
      toast.error("El campo no puede ir vacio", { position: "bottom-right" });
      return;
    }
    const res = await editarMedidasApi(editState);
    console.log(res[0]);
    if (res[0].sms === "ok") {
      cancelarEdicion(e, editState.campoedit);
      toast.success(`${res[0].mensaje}`, {
        position: "bottom-left",
      });
      window.scrollTo(0, tablacategoriasRef.current);
      setButtonState(false);
      setActualizarLista(!actualizarLista);
    } else if (res[0].ssm === "err") {
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
      });
      setButtonState(false);
    }
  };

  const editbtn = (campoeditar) => {
    if (editState.campoedit === campoeditar) {
      document.getElementById(campoeditar).disabled = false;
    }

    return editar ? (
      <div className="w-100">
        {editState.campoedit !== campoeditar ? (
          <button
            className=" bg-btn-vet"
            onClick={(e) => habilitarEdicion(e, campoeditar)}
          >
            editar
          </button>
        ) : editState.campoedit === campoeditar ? (
          <div className=" d-flex flex-row justify-content-between">
            <button
              className="bg-btn-vet-danger mx-2 bordesombreado bordecompleto"
              onClick={(e) => {
                e.preventDefault();
                cancelarEdicion(e, campoeditar);
              }}
            >
              x
            </button>
            <button
              className={"bg-btn-vet w-100  bordesombreado bordecompleto"}
              disabled={load === false ? true : false}
              onClick={editarFn}
            >
              Guardar cambios
            </button>
          </div>
        ) : null}
      </div>
    ) : null;
  };
  const ed = (name, valor) => {
    if (editState.campoedit === name) {
      setEditState({
        ...editState,
        nuevovalor: valor,
      });
    }
  };

  const editField = (data) => {
    setState({
      ...state,
      medidas: data.medidas,
    });
  };

  const ejecutaropciones = (data, editar) => {
    console.log(data);
    if (editar === true) {
      setEditar(true);
      editField(data);
      habilitarEdicion("medidas", data);
      window.scrollTo(0, paneleditarcrearRef.current);
    }
  };

  return (
    <div className="col-md-12 m-0 p-0 ">
      <div className="row d-flex flex-column p-0 m-0">
        <div
          className="d-flex justify-content-start bgfondo align-items-center "
          style={{ height: "60px" }}
        >
          <h5 className="titulosubmenusize negrita">PANEL MEDIDAS</h5>
        </div>
        <hr />
        <div ref={paneleditarcrearRef} className="my-3">
          <div className="col-md-12 p-0 m-0 ">
            <div className="row p-0 m-0 ">
              <label className="" htmlFor="exampleInputEmail1">
                Descripción de la medida* :
              </label>
              <div className="col-md-9 m-0 p-0 ">
                {" "}
                <input
                  ref={medidiasref}
                  type="text"
                  className="inputtext2 w-100 bordecompleto "
                  onChange={onchange}
                  value={state.medidas}
                  id="medidas"
                  name="medidas"
                  placeholder="Ingrese el nombre de la medida. "
                />
              </div>
              <div className="col-md-3 d-flex d-flex justify-content-center align-items-center m-0 px-2">
                {" "}
                {editar ? (
                  editbtn("medidas")
                ) : (
                  <button
                    className=" bg-btn-vet w-100 bordesombreado bordecompleto"
                    onClick={() => guardarCategoria()}
                  >
                    <i className="fas fa-save mx-2"></i>
                    Guardar medida
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div ref={tablacategoriasRef}>
          <TablaMedidas
            veterinaria_id={veterinaria_id}
            ejecutarOpciones={ejecutaropciones}
            actualizarLista={actualizarLista}
          ></TablaMedidas>
        </div>
      </div>
    </div>
  );
};

export default Medidas;
