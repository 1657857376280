import Axios from '../config/Axios'


 export  const getPaisesFn = async () => {
    try{
        const respuesta = await Axios.get(`/loadPaises`)
        return [{
            smslocal:"ok",
            data:respuesta.data.paises
        }]
        
     }catch(e){
        return [{
           smslocal:"err",
           data:[]
        }]
     }
   
  };

  export const getProvinciasFn = async (pais_id) => {
    try{
        const respuesta = await Axios.get(`/loadProvincias/${pais_id}`)
        return [{
            smslocal:"ok",
            data:respuesta.data.provincias
        }]
        
     }catch(e){
        return [{
           smslocal:"err",
           data:[]
        }]
     }
   
  };



  export const getCiudadesFn = async (provincia_id) => {
    try{
        
        const respuesta = await Axios.get(`/loadCiudades/${provincia_id}`)
        return [{
            smslocal:"ok",
            data:respuesta.data.ciudades
        }]
        
     }catch(e){
        return [{
           smslocal:"err",
           data:[]
        }]
     }
   
  };


  