import Axios from "../../config/AxiosContable";

export const guardarCategoriaApi = async (state) => {
  try {
    const res = await Axios.post("/createCategoria", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getCategoriasEmpresaApi = async (empresa_id) => {
  try {
    const res = await Axios.get("/getCategoriasEmpresa/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const editarCategoriaApi = async (state) => {
  try {
    const res = await Axios.post("/editCategoria", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};


export const eliminarCategoriasEmpresaApi = async (categoria_id) => {
  try {
    const res = await Axios.get("/deleteCategoria/" + categoria_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};