import React, { useState, useEffect } from "react";
import ModalDefault from "../modalDefault";
import RegistrarCliente from "./cliente";
import { actualizarCLientesFacturacion } from "../../api/clientesFacturacion";
import { getCLientesFacturacion } from "../../api/clientesFacturacion/index";
import { toast } from "react-toastify";
const CabeceraFactura = ({
  state,
  onchangeprincipal,
  tiposIdentificacion,
  cargarcomprador,
  tipoDocumentos
}) => {
  const [modalRegistrarCliente, setModalRegistrarCliente] = useState(false);
  const [editar,seteditar]=useState(false)
  const [cliente, setCliente] = useState({
    correo: "",
    identificacion: "",
    tipo_identificacion: "",
    razon_social: "",
    direccion: "",
    telefono: "",
    cliente_facturacion_id: "",
  });

  const onchange = (e) => {
    onchangeprincipal(e);
  };

  const onchangeciente = (e) => {
    setCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };

  const keyevent = (e) => {
    if (e.key === "Enter") {
      filtrarclientefactura();
    }
  };

  useEffect(() => {
    setCliente(state.comprador);
  }, [state]);

  const closeAction = (modal, x) => {
    setModalRegistrarCliente(modal);
  };

  const filtrarclientefactura = async () => {
    
    if (state.codigo_identificacion === "") {
      toast.error(`Seleccione el tipo de identificación !`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    if (state.identificacion_cliente === "") {
      toast.error(`Ingrese la idetificación !`, {
        theme: "colored",
        position: "bottom-left",
      });
      return 
    }

    if (
      state.identificacion_cliente.length !== 13 &&
      state.codigo_identificacion === "04"
    ) {
      toast.error(`El ruc debe contener 13 digitos!`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (
      state.identificacion_cliente.length !==10 &&
      state.codigo_identificacion === "05"
    ) {
      toast.error(`La cédula debe contener 10 digitos!`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    const res = await getCLientesFacturacion(
      state.identificacion_cliente,
      state.codigo_identificacion
    );
    if (res[0].sms === "ok") {
      if (res[0].data === null) {
        setCliente({
          ruc:
            state.codigo_identificacion === "04"
              ? state.identificacion_cliente
              : "",
          cedula:
            state.codigo_identificacion === "05"
              ? state.identificacion_cliente
              : "",
          pasaporte:
            state.codigo_identificacion === "06"
              ? state.identificacion_cliente
              : "",
          idexterior:
            state.codigo_identificacion === "08"
              ? state.identificacion_cliente
              : "",
          placa:
            state.codigo_identificacion === "09"
              ? state.identificacion_cliente
              : "",
        });
        setModalRegistrarCliente(true);
      } else {
        console.log(res[0].data);

        cargarcomprador(res[0].data);
      }
    } else {
    }
  };

  const updateClientefactura = async () => {
    if (state.identificacion_cliente === "") {
      alert("Es necesario un campo de identificacion");
    } else {
      const res = await actualizarCLientesFacturacion(cliente);
      if (res[0].sms === "ok") {
        setCliente(res[0].data);
        toast.success(res[0].mensaje);
        filtrarclientefactura();
      } else {
        toast.error(res[0].mensaje);
      }
    }
  };

  const retornocliente = (data) => {
    setCliente(data);
    setModalRegistrarCliente(false);
  };


  //RETENCION
  //*************************** */
  
  //********************* */

  return (
    <div className="row w-100 d-flex justify-content-between align-items-center p-0 m-0 ">
      {" "}
      <div></div>
      <div className="row p-0 m-0">
        <div
          className={
            state.codigo_documento === "06"
              ? "col-md-12"
              : "col-md-12"
          }
          style={{ display: "block" }}
        >
          <div className="my-1">
            <label className=" negrita d-flex justify-content-start">
              Tipo de identificación* :
            </label>
            <select
              className="inputselectsmall bordecompleto "
              disabled={state.codigo_documento === "07" ? false : false}
              id="tipo_identificacion_id"
              name="tipo_identificacion_id"
              style={{ width: "100%" }}
              onChange={(e) => onchange(e)}
              value={state.codigo_identificacion}
            >
              <option value="">Tipo de identificación...</option>
              {tiposIdentificacion.map((data, index) => (
                <option value={data.codigo} key={index}>
                  {data.tipo}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={
            state.codigo_documento === "06"
              ? "col-md-12  d-flex flex-row justify-content-start"
              : "col-md-12  d-flex flex-row justify-content-start"
          }
        >
          <div className="row w-100 d-flex flex-column justify-content-center align-items-center p-0 m-0">
            <div className="col-md-12 col-sm-12 p-0 m-0 ">
              {state.codigo_identificacion !== "" ? (
                state.codigo_identificacion !== "07" ? (
                  <div className="my-1 ">
                    {" "}
                    <label
                      className=" negrita d-flex justify-content-start"
                      htmlFor="exampleInputEmail1 "
                    >
                      Identificación:
                    </label>
                    <div className="col-md-12 d-flex justify-content-between justify-content-center align-items-center">
                      <input
                        type="text"
                        disabled={
                          state.codigo_documento === "07" ? false : false
                        }
                        className=" inputtextsmall bordecompleto "
                        onChange={onchange}
                        value={state.identificacion_cliente}
                        onKeyUp={keyevent}
                        id="identificacion_cliente"
                        name="identificacion_cliente"
                        placeholder="Digite el número de identificación "
                      />
                      <button
                        disabled={
                          state.codigo_documento === "07" ? false : false
                        }
                        className="bordecompleto borderedondeado mx-1 d-flex justify-content-center align-items-center "
                        style={{ height: "28px", width: "28px" }}
                        onClick={filtrarclientefactura}
                      >
                        <i
                          className="fas fa-search"
                          style={{ fontSize: "10px" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="my-1 row ">
                    <div className="col-md-12 d-flex d-flex justify-content-center align-items-center">
                      {" "}
                      <label
                        className="d-flex justify-content-start"
                        htmlFor="exampleInputEmail1"
                      >
                        Consumidor final
                      </label>
                    </div>
                  </div>
                )
              ) : (
                <div className="my-1 row">
                  <div className="col-md-12 d-flex d-flex justify-content-center align-items-center">
                    {" "}
                    <label
                      className="d-flex justify-content-start"
                      htmlFor="exampleInputEmail1"
                    >
                      Seleccione el tipo de identificacion para continuar
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
        <div className="col-md-12 d-flex flex-row justify-content-start">
          <div className="my-1 w-100">
            {" "}
            <label for="" className="  negrita d-flex justify-content-start">
              Razon Social :{" "}
            </label>
            <div className="col-md-12 d-flex justify-content-between ">
              <input
                readOnly={state.codigo_identificacion === "07" ? false : false}
                type="text"
                className=" inputtextsmall bordecompleto "
                onChange={onchange}
                value={state.comprador.razon_social}
                id="razon_social"
                name="razon_social"
                placeholder="Razon social"
                disabled={state.codigo_documento === "07" ? false : false}
              />
            </div>
          </div>
        </div>
        <div
          className={
            state.codigo_documento === "06"
              ? "col-md-12  d-flex flex-row justify-content-start"
              : "col-md-12  d-flex flex-row justify-content-start"
          }
        >
          <div className="my-1 w-100">
            {" "}
            <label for="" className=" negrita d-flex justify-content-start">
              Teléfono :{" "}
            </label>
            <div className="col-md-12">
              <input
                readOnly={state.codigo_identificacion === "07" ? false : false}
                type="text"
                className=" inputtextsmall bordecompleto"
                onChange={onchange}
                value={state.comprador.telefono}
                id="telefono"
                name="telefono"
                placeholder="Teléfono"
                disabled={state.codigo_documento === "07" ? false : false}
              />
            </div>
          </div>
        </div>

        <div
          className={
            state.codigo_documento === "06"
              ? "col-md-12  d-flex flex-row justify-content-start"
              : "col-md-12  d-flex flex-row justify-content-start"
          }
        >
          <div className="my-1 w-100">
            {" "}
            <label for="" className="  negrita d-flex justify-content-start">
              Correo :{" "}
            </label>
            <div className="col-md-12">
              <input
                readOnly={state.codigo_identificacion === "07" ? false : false}
                type="text"
                className=" inputtextsmall bordecompleto"
                onChange={onchange}
                value={state.comprador.correo}
                id="correo"
                name="correo"
                placeholder="Correo"
                disabled={state.codigo_documento === "07" ? false : false}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 m-0 p-0">
          <div className="row">
            <div className="col-md-12  d-flex flex-row justify-content-start">
              <div className="my-1 w-100">
                {" "}
                <label for="" className=" negrita d-flex justify-content-start">
                  Dirección :{" "}
                </label>
                <div className="col-md-12">
                  <input
                    readOnly={
                      state.codigo_identificacion === "07" ? false : false
                    }
                    type="text"
                    className=" inputtextsmall bordecompleto"
                    onChange={onchange}
                    value={state.comprador.direccion}
                    id="direccion"
                    name="direccion"
                    placeholder="Dirección"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {state.codigo_documento === "06" ? (
          <div className="col-md-12 m-0 p-0">
            <div className="row">
              <div className="col-md-12  d-flex flex-row justify-content-start">
                <div className="my-1 w-100">
                  {" "}
                  <label
                    for=""
                    className="  negrita d-flex justify-content-start"
                  >
                    Motivo traslado :{" "}
                  </label>
                  <div className="col-md-12">
                    <input
                      readOnly={
                        state.codigo_identificacion === "07" ? false : false
                      }
                      type="text"
                      className=" inputtextsmall bordecompleto"
                      onChange={onchange}
                      value={state.comprador.motivotraslado}
                      id="motivotraslado"
                      name="motivotraslado"
                      placeholder="Motivo del traslado"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {state.codigo_documento === "06" ? (
          <div className="col-md-12 m-0 p-0">
            <div className="row py-0 my-0 ">
              <div className=" py-0 my-0 d-flex flex-column justify-content-start align-items-start">
                {" "}
                <label for="" className=" negrita">
                  Ruta :{" "}
                </label>
                <div className="w-100">
                  <input
                    readOnly={
                      state.codigo_identificacion === "07" ? false : false
                    }
                    type="text"
                    className=" inputtextsmall bordecompleto"
                    onChange={onchange}
                    value={state.traslado.ruta}
                    id="ruta"
                    name="ruta"
                    placeholder="ruta"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-md-12">
        {state.identificacion_cliente === "" ? (
          "Para modificar los datos del comprador se debe definir previamente una identifcación válida"
        ) : cliente.razon_social === state.comprador.razon_social &&
          cliente.telefono === state.comprador.telefono &&
          cliente.direccion === state.comprador.direccion &&
          cliente.n_remision === state.comprador.n_remision &&
          cliente.correo === state.comprador.correo &&
          cliente.razon_social === state.comprador.razon_social ? null : (
          <button className="bg-btn-vet  w-100" onClick={updateClientefactura}>
            Guardar cambios de la identificación {state.identificacion_cliente}
          </button>
        )}
      </div>
      {/* <div className="col-md-12">
        <div>
          <label for="" className="negrita">
            Razon social:{" "}
            <label for="" className="color-azulmarino">
              {cliente.razon_social}
            </label>
          </label>
        </div>
        <div>
          <label for="" className="negrita">
            Correo:{" "}
            <label for="" className="color-azulmarino">
              {cliente.correo}
            </label>
          </label>
        </div>
        <div>
          <label for="" className="negrita">
            Teléfono:{" "}
            <label for="" className="color-azulmarino">
              {cliente.telefono}
            </label>
          </label>
        </div>
        <div>
          <label for="" className="negrita">
            Dirección:{" "}
            <label for="" className="color-azulmarino">
              {cliente.direccion}
            </label>
          </label>
        </div>
      </div> */}
      <ModalDefault
        modal={modalRegistrarCliente}
        size={"lg"}
        titulo="REGISTRO DE NUEVOS CLIENTES"
        closeAction={closeAction}
      >
        <RegistrarCliente
          editar={editar}
          cliente={cliente}
          setcliente={retornocliente}
        ></RegistrarCliente>
      </ModalDefault>
    </div>
  );
};

export default CabeceraFactura;
