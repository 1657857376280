import React, { useEffect, useState, useContext, useRef } from "react";
import {
  editarCategoriaApi,
  guardarCategoriaApi,
} from "../../../api/contable/categorias";
import AuthContext from "../../../Context/auth/authContext";
import TablaCategoria from "./tablaCategorias";
import { toast } from "react-toastify";
import SubCategorias from "../subcategorias/subcategorias"
const Categorias = (props) => {
  const categoriaref = useRef(null);
  const paneleditarcrearRef = useRef(null);
  const tablacategoriasRef = useRef(null);
  const [actualizarLista,setActualizarLista]=useState(false)

  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [state, setState] = useState({
categoria: "",empresa_id: veterinaria_id,
  });
  const [statereset, setStateReset] = useState({
    categoria: "",
    empresa_id: veterinaria_id,
  });
  const [editState, setEditState] = useState({
    campoedit: "",
    nuevovalor: "",
    categoria_id: "",
  });

  const [respaldoEditField, setRespaldoEditField] = useState("");
 const [editar, setEditar] = useState(false);

  const [buttonState, setButtonState] = useState(false);

  const [load, setLoad] = useState(false);

  const onchange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (editar) ed(e.target.name, e.target.value);
  };

  const guardarCategoria = async () => {
    setLoad(true);
    const res = await guardarCategoriaApi(state);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`,{position:"bottom-left"});
            setActualizarLista(!actualizarLista);
     setState(statereset)
    } else {
      alert(`${res[0].mensaje}`);
    }
    setLoad(false);
  };

  const habilitarEdicion = (edit, data) => {
    setEditState({
      ...editState,
      campoedit: edit,
      categoria_id: data.categoria_id,
    });
    setRespaldoEditField(state[edit]);
    categoriaref.current.focus();
  };
  const cancelarEdicion = (e, campoeditar) => {
    e.preventDefault();
    setState(statereset);
    setEditar(false);
    setEditState({
      ...editState,
      campoedit: "",
      nuevovalor: "",
      categoria_id: "",
    });
    setRespaldoEditField("");
    document.getElementById(campoeditar).focus();
  };

  const editarFn = async (e) => {
    e.preventDefault();
    setButtonState(true);
    if (editState.campoedit === "") {
      toast.error("El campo no puede ir vacio", { position: "bottom-right" });
      return;
    }
    const res = await editarCategoriaApi(editState);
    console.log(res[0]);
    if (res[0].sms === "ok") {
      cancelarEdicion(e, editState.campoedit);
      window.scrollTo(0, tablacategoriasRef.current);
      setButtonState(false);
      setActualizarLista(!actualizarLista)
    } else if (res[0].ssm === "err") {
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
      });
      setButtonState(false);
    }
  };

  const editbtn = (campoeditar) => {
    if (editState.campoedit === campoeditar) {
      document.getElementById(campoeditar).disabled = false;
    }

    return editar ? (
      <div className="w-100 ">
        {editState.campoedit !== campoeditar ? (
          <button
            className=" bg-btn-vet w-100 bordecompleto bordesombreado"
            onClick={(e) => habilitarEdicion(e, campoeditar)}
          >
            editar
          </button>
        ) : editState.campoedit === campoeditar ? (
          <div className="d-flex flex-row justify-content-between">
            <button
              className="bg-btn-vet-danger mx-2 bordesombreado bordecompleto"
              onClick={(e) => {
                e.preventDefault();
                cancelarEdicion(e, campoeditar);
              }}
            >
              x
            </button>
            <button
              className=" bg-btn-vet  w-100 bordecompleto bordesombreado"
              disabled={editState.nuevovalor === "" ? true : false}
              onClick={editarFn}
            >
              Guardar cambios
            </button>
          </div>
        ) : null}
      </div>
    ) : null;
  };
  const ed = (name, valor) => {
    if (editState.campoedit === name) {
      setEditState({
        ...editState,
        nuevovalor: valor,
      });
    }
  };

  const editField = (data) => {
    setState({
      ...state,
      categoria: data.categoria,
    });
  };

  const ejecutaropciones = (data, editar) => {
    if (editar === true) {
      setEditar(true);
      editField(data);
      habilitarEdicion("categoria", data);
      window.scrollTo(0, paneleditarcrearRef.current);
    }
  };

  return (
    <div className="col-md-12  ">
      <div className="row  d-flex flex-column ">
        <div
          className="d-flex justify-content-start bgfondo align-items-center"
          style={{ height: "60px" }}
        >
          <h5 className="titulosubmenusize">PANEL CATEGORÍAS</h5>
        </div>
        <div ref={paneleditarcrearRef} className="my-3">
          <div className="col-md-12 p-0">
            <div className="row">
              <label className="px-0 py-2" htmlFor="exampleInputEmail1">
                Nombre de la categoría* :
              </label>
              <div className="col-md-9 p-0 m-0">
                {" "}
                <input
                  ref={categoriaref}
                  type="text"
                  className="inputtext2  w-100 bordecompleto"
                  onChange={onchange}
                  value={state.categoria}
                  id="categoria"
                  name="categoria"
                  placeholder="Ingrese el nombre de la categoría. "
                />
              </div>
              <div className="col-md-3 d-flex d-flex justify-content-center align-items-center m-0 px-2">
                {" "}
                {editar ? (
                  editbtn("categoria")
                ) : (
                  <button
                    className=" bg-btn-vet w-100 bordesombreado bordecompleto"
                    onClick={() => guardarCategoria()}
                  >
                    <i className="fas fa-save px-2"></i>
                    Guardar categoría
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div ref={tablacategoriasRef}>
          <TablaCategoria
            veterinaria_id={veterinaria_id}
            ejecutarOpciones={ejecutaropciones}
            actualizarLista={actualizarLista}
          ></TablaCategoria>
        </div>
      </div>
      <hr/>
     <div className="row">
          <SubCategorias/>

     </div>
    </div>
  );
};

export default Categorias;
