import React, { useEffect, useState } from "react";
import {
  getAllTags,
  asignarTagsEmpresas,
  getAllTagsEmpresa,
  eliminarTagsEmpresa
} from "../../../api/tags";
import { toast } from "react-toastify";
const Tags = ({ veterinaria_id }) => {
  const [tags, setTags] = useState([]);
  const [tagsEmpresa, setTagsEmpresa] = useState([]);

  const [load, setLoad] = useState(false);
  const [loadasignar, setLoadasignar] = useState(false);
  const [loadtagempresa, setLoadtagempresa] = useState(false);

  const [state, setState] = useState({
    tag_id: "",
    veterinaria_id: veterinaria_id,
  });

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    initComponentes();
    getTagEmpresa();
  }, []);

  const initComponentes = async () => {
    setLoad(true);
    const res = await getAllTags();
    console.log(res);
    switch (res[0].sms) {
      case "ok":
        setTags(res[0].data);
        setLoad(false);
        //toast.info(`${res[0].mensaje}`)
        break;
      default:
        setLoad(false);
        break;
    }
  };

  const getTagEmpresa = async () => {
    setLoadtagempresa(true);
    const res = await getAllTagsEmpresa(veterinaria_id);
    console.log(res);
    switch (res[0].sms) {
      case "ok":
        setTagsEmpresa(res[0].data);
        setLoadtagempresa(false);
        //toast.info(`${res[0].mensaje}`)
        break;
      default:
        setLoadtagempresa(false);
        break;
    }
  };

  const asignarTag = async () => {
    setLoadasignar(true);
    const res = await asignarTagsEmpresas(state);
    console.log(res[0].data[0]);
    if (res[0].sms === "ok") {
      getTagEmpresa();
      setLoadasignar(false);
      toast.success(`${res[0].mensaje}`);
      return
    }
    
    if(res[0].sms==="err"){
          toast.error(`${res[0].mensaje}`);
          setLoadasignar(false);
          return
    }
  };
  const eliminartag = async (e,tag_veterinaria_id) => {
    e.preventDefault()
    const res = await eliminarTagsEmpresa(tag_veterinaria_id);
    if (res[0].sms === "ok") {
      getTagEmpresa();
    }
  };
  return (
    <div className="row ">
      <div className="mb-3 col-md-12 mx-1">
        <label style={{ color: "blue" }}>
          NOTA: Los tags de busqueda son etiquetas que los usuarios del sistema
          podrán utilizar para encontrar su empresa según los servicios
          específicos que necesiten.
        </label>
      </div>
      <br></br>
      {load ? (
        <div>
          <label>Cargando tags</label>
        </div>
      ) : (
        <div className="col-md-12">
          <div className="form-group row">
            <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
              {" "}
              <label>Seleccione el tag de busqueda.</label>
            </div>
            <div className="col-md-8 d-flex d-flex justify-content-center align-items-center">
              {" "}
              <select
                className="inputselect2"
                id="tag_id"
                name="tag_id"
                style={{ width: "100%" }}
                onChange={onchange}
                value={state.tag_id}
              >
                <option value="0">Seleccione ...</option>
                {tags.map((data, index) => (
                  <option value={data.tag_id} key={index}>
                    {data.tag}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2 d-flex d-flex justify-content-center align-items-center">
              {" "}
              <button className="bg-btn-vet" onClick={() => asignarTag()}>
                {" "}
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="col-md-12">
        <label for="">Listado de tags agregados</label>
        <hr />
        <div className="d-flex justify-content-center align-items-center">
          {tagsEmpresa.length > 0 ? (
            <div className="w-100 d-flex  flex-wrap justify-content-center align-items-center">
              {  tagsEmpresa.map((data, index) => {
              return (
                <label className="bg-btn-vet m-2" type="" key={index}>
                  {data.tags.tag}{" "}
                  <button className="bg-btn-vet-danger" onClick={(e)=>{eliminartag(e, data.tag_veterinaria_id);}}>x</button>
                </label>
              );
            })}
            </div>
          ) : (
            <label for="">No se asignaron tags</label>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tags;
