import Axios from "../../config/AxiosContable";

export const crearServicioAsignacionApi = async (servicioContratado) => {
  try {
    const result = await Axios.post(
      `/POSTCrearServiciosAsignaciones`,
      servicioContratado
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const EditarServicioAsignacionApi = async (servicioContratado) => {
  try {
    const result = await Axios.post(
      `/POSTEditarServiciosAsignaciones`,
      servicioContratado
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const GetServicioAsignacionEmpresaUsuario = async (data) => {
  try {
    const result = await Axios.post(`/GETAllServiciosAsignaciones/`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const EliminarAsignacionServicioApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTEliminarServiciosAsignaciones`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};
