import React, { useState, useContext } from "react";
import AuthContext from "../../../Context/auth/authContext";
import TablaConfigServicios from "./tabla";
import CrearEditarConfigServicioServicio from "./crearEditar";

const ConfigServicios = () => {
  const { sessiondatacli, index } = useContext(AuthContext);
  const veterinariaId = sessiondatacli[index].veterinaria[0].veterinaria_id;

  const [actualizarLista, setActualizarLista] = useState(false);

  const [configServicio, setConfigServicio] = useState({
    servicio_afiliado_config_id: "",
    producto_id: "",
    periodo_pago_id: "",
    detalle_pago: "",
    tipo_servicio: "",
    empresa_id: veterinariaId,
    aplica_categoria: true,
    afiliado_categoria_id: "",
    valor: "0",
    dia_maximo_pago_config:"0",
    aplica_recurrencia: false,
  });

  const [editar, setEditar] = useState(false);

  const resetConfigServicio = () => {
    setConfigServicio({
      servicio_afiliado_config_id: "",
      producto_id: "",
      periodo_pago_id: "",
      detalle_pago: "",
      tipo_servicio: "",
      empresa_id: veterinariaId,
      aplica_categoria: true,
      afiliado_categoria_id: "",
      dia_maximo_pago_config: "0",
      valor: "0",
      aplica_recurrencia: false,
    });
  };

  const toggleActualizarLista = () => setActualizarLista(!actualizarLista);

  return (
    <div className="col-md-12 m-0 p-0 ">
      <div className="row  d-flex flex-column ">
        <CrearEditarConfigServicioServicio
          editar={editar}
          actualizarLista={toggleActualizarLista}
          configServicio={configServicio}
          setConfigServicio={setConfigServicio}
          resetConfigServicio={resetConfigServicio}
          setEditar={setEditar}
          empresa={veterinariaId}
        />
        <div>
          <TablaConfigServicios
            veterinaria_id={veterinariaId}
            actualizarLista={actualizarLista}
            setEditar={setEditar}
            setConfigServicio={setConfigServicio}
            empresa={veterinariaId}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigServicios;
