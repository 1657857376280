import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../../Context/auth/authContext";
import MenuConfiguracion from "./menu";
import UbicacionVeterinaria from "../mapa/index";

//import { url, urlLogoImagen, urlPortadaImagen } from "../../../../config/urls";
//MONCA

const Ubicacion = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli, index } = AuthenticationContext;

  const {
    nombre,
    direccion,
    latitud,
    longitud,
    atencion,
    logo,
    ruc,
    portada,
    provincia_id,
    telefono = "",
    ciudad,
    codigo,
    veterinaria_id,
    pais_id,
  } = sessiondatacli[index].veterinaria[0];

  return (
    <>
      <div className="col-md-12 p-7 card">
        <div className="card-body p-0">
          <UbicacionVeterinaria veterinaria_id={veterinaria_id}></UbicacionVeterinaria>
        </div>
      </div>
    </>
  );
};

export default Ubicacion;
