import React, { useEffect, useState, useContext } from "react";

import Menu from "../Components/Sidebar/SideBar";
import "../Components/contable/menucontable.css";
import Medidas from "../Components/contable/medidas/medidas";
import Categorias from "../Components/contable/categorias/categorias";
import ReporteComprasAllMetales from "../Components/contable/reportes/reportedecomprasallmetales";

import Inventario from "../Components/contable/stock/inventario";
import InventarioServicios from "../Components/contable/stock/inventarioServicios";
import Ventas from "../Components/contable/ventas/ventas";
import Facturas from "../Components/contable/documentos/facturas/facturasPanel";
import Recibos from "../Components/contable/documentos/recibos/recibosPanel";
import Liquidaciones from "../Components/contable/documentos/liquidacionesCompra/liquidacionesPanel";
import GuiaRemision from "../Components/contable/documentos/guiaRemision/guiaremisionPanel";
import Retenciones from "../Components/contable/documentos/retenciones/retencionesPanel";
import NotaCredito from "../Components/contable/documentos/recibos/recibosPanel";
import NotaDebito from "../Components/contable/documentos/recibos/recibosPanel";

import SubirComprobanteXML from "../Components/contable/compras/subircomprobantesxml";

import Cajas from "../Components/contable/cajas/index";

import Bancos from "../Components/contable/bancos/bancos";
import CuentasBancarias from "../Components/contable/bancos_pagos/bancos_pagos";

import { useParams } from "react-router-dom";
const routes = [
  {

    path: "/productos",
    name: "Productos",
    icon: "fa-solid fa-cube",
    exact: true,
    type: "",
  },
  {
    path: "/servicios",
    name: "Servicios",
    icon: "fa-solid fa-cube",
    exact: true,
    type: "",
  },
  {
    path: "/cajas",
    name: "Cajas",
    icon: "fa-solid fa-computer",
    exact: true,
    type: "",
  },
  {
    path: "/categorias",
    name: "Categorías",
    icon: "fa-solid fa-tag",
    exact: true,
    type: "",
  },
  /*  {
    path: "/medidas",
    name: "Medidas",
    icon: "fa-solid fa-tag",
    exact: true,
    type: "",
  }, */
  {
    path: "/cuentasbancarias",
    name: "Instituciones financieras",
    icon: "fa-solid fa-building-columns",
    exact: true,
    type: "",
  },
  /*  {
    path: "/#",
    name: "Cuentas bancarias",
    icon: "fa-solid fa-building-columns",
    exact: true,
    type: "",
    subRoutes: [
      {
        path: "/cuentasbancarias",
        name: "Listado de bancos",
        icon: "",
      },
      {
        path: "/bancos",
        name: "Configurar bancos",
        icon: "",
      },
    ],
  }, */
  {
    path: "/#",
    name: "Reportes allmetales",
    icon: "fas fa-caret-right",
    exact: true,
    type: "",
    subRoutes: [
      {
        path: "/reportecompras",
        name: "Reporte de compras",
        icon: "",
      },
    ],
  },
  {
    path: "/#",
    name: "Productos / servicios",
    icon: "fas fa-caret-right",
    exact: true,
    type: "",
    subRoutes: [
      {
        path: "/productos",
        name: "Productos",
        icon: "",
      },
      {
        path: "/servicios",
        name: "Servicios",
        icon: "",
      },
    ],
  },
  {
    path: "/#",
    name: "Documentos electrónicos",
    icon: "fa-regular fa-folder-open",
    exact: true,
    type: "",
    subRoutes: [
      {
        path: "/recibos",
        name: "Recibos",
        icon: "",
      },
      {
        path: "/facturas",
        name: "Facturas",
        icon: "",
      },
      {
        path: "/liquidacionescompra",
        name: "Liquidaciones de compra",
        icon: "",
      },
      {
        path: "/guiaremision",
        name: "Guía de remisión",
        icon: "",
      },
      {
        path: "/retenciones",
        name: "Retenciones",
        icon: "",
      },
    ],
  },
  {
    path: "/#",
    name: "Cofiguraciones del sisitema",
    icon: "fas fa-cog",
    exact: true,
    type: "",
    subRoutes: [
      {
        path: "/medidas",
        name: "Medidas",
        icon: "",
      },
      {
        path: "/bancos",
        name: "Bancos",
        icon: "",
      },
    ],
  },
];

const Contable = () => {
  const { panel } = useParams();
  const [stateContenedor, setContenedor] = useState(null);
  useEffect(() => {
    if (panel === "medidas") {
      setContenedor(<Medidas />);
    }
    if (panel === "categorias") {
      setContenedor(<Categorias />);
    }
    if (panel === "productos") {
      setContenedor(<Inventario />);
    }
    if (panel === "servicios") {
      setContenedor(<InventarioServicios />);
    }
    if (panel === "ventas") {
      setContenedor(<Ventas />);
    }
    if (panel === "documentos" || panel === "facturas") {
      setContenedor(<Facturas ocultar={false} codigo_documento="01" />);
    }
    if (panel === "recibos") {
      setContenedor(<Recibos ocultar={false} codigo_documento="" />);
    }
    if (panel === "liquidacionescompra") {
      setContenedor(<Liquidaciones />);
    }
    if (panel === "guiaremision") {
      setContenedor(<GuiaRemision />);
    }
    if (panel === "retenciones") {
      setContenedor(<Retenciones />);
    }
    if (panel === "comprasxml") {
      setContenedor(<SubirComprobanteXML />);
    }
    /* 
    
   
    
    */

    if (panel === "reportecompras") {
      setContenedor(<ReporteComprasAllMetales />);
    }
    if (panel === "bancos") {
      setContenedor(<Bancos />);
    }
    if (panel === "cuentasbancarias") {
      setContenedor(<CuentasBancarias />);
    }
    if (panel === "items") {
      setContenedor(<Inventario />);
    }
     if (panel === "cajas") {
       setContenedor(<Cajas />);
     }
    return () => {};
  }, [panel]);
  return (
    <div className="w-100 d-flex justify-content-between ">
      <div className="">
        <Menu
          routes={routes}
          titulo=" Opciones contables"
          subpath={"/contable"}
        ></Menu>
      </div>
      <div className="w-100 bgfondo  h-100 " style={{ minHeight: "90vh" }}>
        {stateContenedor}
      </div>
    </div>
  );
};

export default Contable;
