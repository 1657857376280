import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataTable } from "mantine-datatable";
import moment from "moment";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import ModalDefault from "../../modalDefault";

import {
  GetAfiliadosServiciosEmpresaApi,
  EliminarAfiliadoServiciosApi,
} from "../../../api/afiliaciones_socios/servicios";

const TablaConfigServicios = (props) => {
  const PAGE_SIZES = 5; // Define los tamaños de página disponibles
  const [page, setPage] = useState(1); // Estado para la página actual
  const [data, setData] = useState([]); // Estado para los datos totales
  const [records, setRecords] = useState([]); // Estado para los registros que se muestran actualmente

  const [selectedConfigServicio, setSelectedConfigServicio] = useState(null);
  const [modalCofirmacion, setModalConfirmacion] = useState(false);

  const tipoServicios = [
    {
      tipo: "REGISTRO",
      code: "REGISTRO_SOCIOS",
    },
    {
      tipo: "BENEFICIOS",
      code: "BENEFICIOS_SOCIOS",
    },
  ];

  useEffect(() => {
    const getRecordsEmpresa = async () => {
      const res = await GetAfiliadosServiciosEmpresaApi(props.empresa);
      if (res.sms === "ok") {
        setData(res.data);
      } else {
        toast.error(res.mensaje);
      }
    };

    getRecordsEmpresa();
  }, [props.actualizarLista]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZES;
    const to = from + PAGE_SIZES;
    setRecords(data.slice(from, to));
  }, [page, data]);

  const handleEdit = (configServicio) => {
    props.setConfigServicio({
      ...configServicio,
      aplica_categoria: true,
    });
    props.setEditar(true);
  };

  const handleDelete = (configServicio) => {
    setSelectedConfigServicio(configServicio);
    setModalConfirmacion(true);
  };

  const eliminarConfigServicioFn = async (servicio_afiliado_config_id) => {
    try {
      const respuesta = await EliminarAfiliadoServiciosApi({
        servicio_afiliado_config_id,
      });
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        const d = data.filter(
          (cat) =>
            cat.servicio_afiliado_config_id !== servicio_afiliado_config_id
        );
        setData(d);
        setSelectedConfigServicio(null);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar la petición");
    } finally {
      setModalConfirmacion(false);
    }
  };

  const acciones = (row) => {
    return (
      <UncontrolledDropdown className="me-2 contextMenu " direction="end">
        <DropdownToggle
          caret
          color="#f0f2f5"
          className="p-0 m-0 w-50"
        ></DropdownToggle>
        <DropdownMenu
          className="ddmenu borde-tarjeta"
          style={{ position: "a" }}
        >
          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleEdit(row);
            }}
          >
            <i className="fas fa-edit mx-2"></i> editar
          </DropdownItem>

          <DropdownItem
            onClick={(e) => {
              e.preventDefault();
              handleDelete(row);
            }}
          >
            <i className="fas fa-trash mx-2"></i> Eliminar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      <DataTable
        borderRadius="md"
        className="bordecompleto bgfondo"
        highlightOnHover
        noRecordsText="No se encontraron datos"
        records={records}
        columns={[
          {
            accessor: "servicio_afiliado_config_id",
            title: " ",
            render: (row) => (
              <div className="d-flex justify-content-center">
                {acciones(row)}
              </div>
            ),
          },
          {
            accessor: "productos.descripcion_producto",
            title: "Servicio",
            textAlignment: "left",
          },
          {
            accessor: "detalle_pago",
            title: "Detalle",
            textAlignment: "left",
          },
          {
            accessor: "tipo_servicio",
            title: "Tipo de servicio",
            textAlignment: "left",
            render: ({ tipo_servicio }) =>
              tipoServicios.find((t) => t.code === tipo_servicio)?.tipo,
          },
          {
            accessor: "afiliado_categoria",
            title: "Categoria",
            textAlignment: "left",
            render: ({ afiliado_categoria }) =>
              afiliado_categoria
                ? afiliado_categoria.categoria
                : "Sin categoria",
          },
          {
            accessor: "periodo_pagos",
            title: "Periodo de pago",
            textAlignment: "left",
            render: ({ periodo_pagos }) =>
              periodo_pagos ? periodo_pagos.periodo : "Sin periodo",
          },
          {
            accessor: "valor",
            title: "Valor",
            textAlignment: "left",
            render: (row) =>
              row.valor
                ? `$${row.valor}`
                : row.productos.precio_venta
                ? `$${row.productos.precio_venta}`
                : "Sin valor",
          },
          {
            accessor: "activo",
            title: "Estado",
            textAlignment: "left",
            render: ({ activo }) => (activo ? "ACTIVO" : "INACTIVO"),
          },
          {
            accessor: "fecha",
            title: "Fecha de registro",
            textAlignment: "left",
            render: ({ create_at }) => moment(create_at).format("MM/DD/YYYY"),
          },
        ]}
        totalRecords={data.length}
        recordsPerPage={PAGE_SIZES}
        page={page}
        onPageChange={setPage}
      />
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalCofirmacion}
        size={"sm"}
        titulo={`Confirmación`}
        closeAction={() => {
          setModalConfirmacion(false);
        }}
      >
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <label for="" className="my-4  w-100" style={{ textAlign: "center" }}>
            Se eliminará la configuracion del servicio{" "}
            {selectedConfigServicio?.configServicio}
          </label>
          <hr className="w-100" />
          <div className="d-flex justify-content-center align-items-center">
            <button
              type=""
              className="bg-btn-vet-danger mx-1"
              onClick={() => {
                setModalConfirmacion(false);
                setSelectedConfigServicio(null);
              }}
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet mx-1"
              onClick={() => {
                if (selectedConfigServicio !== null) {
                  eliminarConfigServicioFn(
                    selectedConfigServicio.servicio_afiliado_config_id
                  );
                } else {
                  toast.error("No existe el indice de la configuracion.");
                }
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
    </>
  );
};

export default TablaConfigServicios;
