import React, { useState, useContext } from "react";
import AuthContext from "../../../Context/auth/authContext";
import TablaPagos from "./tabla";
import CrearEditarPagoServicio from "./crearEditar";

const Pagos = ({ afiliado }) => {
  const { sessiondatacli, index } = useContext(AuthContext);
  const veterinariaId = sessiondatacli[index].veterinaria[0].veterinaria_id;

  const [actualizarLista, setActualizarLista] = useState(false);
  const [pago, setPago] = useState({
    empresa_id: veterinariaId,
    forma_pago: "", //
    valor: "",
    valor_total: "",
    //fecha de hoy en formato yyyy-mm-dd
    fecha_pago: new Date().toISOString().slice(0, 10), //
    detalle_pago: "",
    codigo_producto: "",
    descripcion_producto: "",
    afiliado_servicio_asignacion: "", //
  });
  const [editar, setEditar] = useState(false);

  const resetPago = () => {
    setPago({
      empresa_id: veterinariaId,
      forma_pago: "", //
      valor: "",
      valor_total: "",
      //fecha de hoy en formato yyyy-mm-dd
      fecha_pago: new Date().toISOString().slice(0, 10), //
      detalle_pago: "",
      codigo_producto: "",
      descripcion_producto: "",
      afiliado_servicio_asignacion: "", //
    });
  };

  const toggleActualizarLista = () => setActualizarLista(!actualizarLista);

  return (
    <div className="col-md-12 m-0 p-0 ">
      <div className="row  d-flex flex-column ">
        <CrearEditarPagoServicio
          editar={editar}
          actualizarLista={toggleActualizarLista}
          pago={pago}
          setPago={setPago}
          resetPago={resetPago}
          setEditar={setEditar}
          empresa={veterinariaId}
          afiliado={afiliado?.afiliado_id}
        />
        <div>
          <TablaPagos
            veterinaria_id={veterinariaId}
            actualizarLista={actualizarLista}
            setEditar={setEditar}
            setPago={setPago}
            empresa={veterinariaId}
            afiliado={afiliado?.afiliado_id}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagos;
