import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import * as MapboxGeocoder from "mapbox-gl-geocoder";
import "mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./index.css";
import toastr from "toastr";
import Axios from "../../../config/Axios";
import { notifyErr } from "../../notify/notify";
const MapView = (props) => {
  var marker = null;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
  const mapContainer = useRef(null);
  const map = useRef(null);
  navigator.permissions
    .query({ name: "geolocation" })
    .then(function (permissionStatus) {
      permissionStatus.onchange = async ({ currentTarget }) => {
        //console.log(currentTarget.state)
        permisos();
      };
    });
  const [coord, setCoord] = useState({
    lng: null,
    lat: null,
  });
  const [zoom, setZoom] = useState(14);
  const [permiso, setPermiso] = useState({
    permiso: 0,
    mensaje: "Esperando el permiso de ubicación",
  });

  const permisos = async () => {
    let notifi = 0;
    if (navigator.geolocation) {
      var config = {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000,
      };
      await navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log(position);
          setPermiso({
            permiso: 1,
            mensaje: "Ubicación activada",
          });
          setCoord({
            ...coord,
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          });

          addMarker(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude)
          );

          setTimeout(() => {
            map.current.flyTo({
              center: [
                parseFloat(position.coords.longitude),
                parseFloat(position.coords.latitude),
              ],
              essential: true, // this animation is considered essential with respect to prefers-reduced-motion
            });
            map.current.removeLayer(marker);
          }, 1000);
        },
        function async(error) {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setPermiso({
                permiso: 0,
                mensaje:
                  "No tenemos permiso para obtener su ubicación , por favor activelo",
              });

              notifyErr(
                "No tenemos permiso para obtener su ubicación , por favor activelo","top-right"
              );

              break;
            case error.POSITION_UNAVAILABLE:
              setPermiso({
                permiso: 2,
                mensaje: "La ubicación no está disponible",
              });

              notifyErr("La ubicación no está disponible","top-right");

              break;
            case error.TIMEOUT:
              setPermiso({
                permiso: 3,
                mensaje:
                  "Se exedió el tiempo de conexion actualize la pantalla",
              });

              notifyErr(
                "Se exedió el tiempo de conexion actualize la pantalla",
                "top-right"
              );
              break;
            case error.UNKNOWN_ERROR:
              setPermiso({
                permiso: 4,
                mensaje: "Ocurrio un error desconocido",
              });
              notifyErr("Ocurrio un error desconocido","top-right");

              break;
          }
        }
      );
    }
  };

  /* navigator.permissions
    .query({ name: "geolocation" })
    .then(function (permissionStatus) {
      //console.log('geolocation permission state is ', permissionStatus.state);

      permissionStatus.onchange = async (state) => {
        console.log("geolocation permission state has changed to ", state);
        window.location.reload();
      };
    }); */

  const crearMapa = () => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [coord.lng, coord.lat],
      zoom: zoom,
    });

    const nav = new mapboxgl.NavigationControl();
    map.current.addControl(nav, "top-right");
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });

    map.current.addControl(geolocate, "top-right");

    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: "Buscar",
      }),
      "top-left"
    );

    //map.current.on("click", addMarker);
  };

  useEffect(() => {
    permisos();
    crearMapa();
  }, []);

  function addMarker(lat, lng) {
    if (marker !== null) {
      marker.remove();
    }
    marker = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([parseFloat(lng), parseFloat(lat)])
      .addTo(map.current);

    function onDragEnd() {
      const lngLat = marker.getLngLat();
      setCoord({
        ...coord,
        lat: parseFloat(lngLat.lat),
        lng: parseFloat(lngLat.lng),
      });
      map.current.flyTo({
        center: [parseFloat(lngLat.lng), parseFloat(lngLat.lat)],
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
    }

    marker.on("dragend", onDragEnd);
  }

  /*  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setCoord({
        ...coord,
        lat: parseFloat(map.current.getCenter().lat),
        lng: parseFloat(map.current.getCenter().lng),
      });
      addMarker(map.current.getCenter().lat,map.current.getCenter().lng)
    });
  }, []); */

  const guardar = async () => {
    try {
      const respuesta = await Axios.post("/modificarUbicacion", {
        lat: coord.lat,
        lng: coord.lng,
        veterinaria_id: props.veterinaria_id,
      });
      if (respuesta.data.sms === "ok") {
        toastr.success(respuesta.data.mensaje, { timeOut: 5000 });
        window.location = "/";
      } else {
        toastr.error(respuesta.data.mensaje, { timeOut: 5000 });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {/* <div id="mapContainer" className="map-container"></div> */}
      <div className="sidebar">
        Longitude: {coord.lng} | Latitude: {coord.lat}
      </div>
      <div ref={mapContainer} className="map-container" />
      <pre id="coordinates" class="coordinates"></pre>
      <div className="d-flex justify-content-center py-5 bgfondo">
        <button
          className="bg-btn-vet w-50"
          onClick={() => {
            if (coord.lat === null && coord.lng === null) {
              alert("Debe tener una ubicación correcta");
            } else {
              guardar();
            }
          }}
        >
          Guardar ubicación
        </button>
      </div>
    </div>
  );
};

export default MapView;
