import Axios from "../../config/AxiosContable";

export const guardarProductosApi = async (state) => {
  try {
    const res = await Axios.post("/createProduct", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const guardarServiciosApi = async (state) => {
  try {
    const res = await Axios.post("/createServicio", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const updateProductosApi = async (editstate) => {
  try {
    const res = await Axios.post("/editProduct", editstate);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
//getMedidasEmpresaApi
export const getProductos = async (empresa_id) => {
  try {
    const res = await Axios.get("/alldataproducts/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
export const getServiciosProductos = async (empresa_id) => {
  try {
    const res = await Axios.get("/alldatageneral/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getServicios = async (empresa_id) => {
  try {
    const res = await Axios.get("/alldataservicios/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
export const getProductosEmpresa = async (empresa_id) => {
  try {
    const res = await Axios.get("/GETalldataproductos/" + empresa_id);
    return res.data;
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getServiciosEmpresa = async (empresa_id) => {
  try {
    const res = await Axios.get("/GETalldataServicios/" + empresa_id);
    return res.data;
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const deleteProducto = async (data) => {
  try {
    const res = await Axios.get("/deleteproductos/" + data.producto_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
