import Axios from "../config/Axios";
import { toast } from "react-toastify";
import tokenAuth  from "../config/Token";

export const uploadUpdateperfil = async (file,persona_id,fotoanterior) => {
    console.log(file)
  toast(`Actualizando foto ( 0% )`, {
    toastId: "init",
    type: toast.TYPE.INFO,
    autoClose: 5000,
    icon: "🚀",
    theme: "colored"
  });
  try {
    tokenAuth(localStorage.getItem("accesTokenCmm"));
    const formdata = new FormData();
    formdata.append("persona_id", persona_id);
    formdata.append("fotoanterior", fotoanterior);
    formdata.append("file", file);
    const result = await Axios.post("/editarfotoperfil", formdata, {
      onUploadProgress: (p) => {
        const progress = p.loaded / p.total;
        const percento = Math.round((100 * p.loaded) / p.total);
        toast.update("init", {
          type: toast.TYPE.INFO,
          render: `Actualizando foto ( ${percento}% )`,
          autoClose: 5000,
          icon: "🚀",
          theme: "colored",
          progress,
        });
      },
    });
    toast.dismiss()
    return [result.data]
  } catch (e) {
    console.log(e);
    toast.dismiss()
    return [
        {
          data: [],
          sms: "err",
          mensaje: e,
        },
      ];
  }
};


export const uploadLogoVet = async (file,veterinaria_id,fotoanterior) => {
  console.log(file)
toast(`Actualizando foto ( 0% )`, {
  toastId: "init",
  type: toast.TYPE.INFO,
  autoClose: 5000,
  icon: "🚀",
  theme: "colored"
});
try {
  tokenAuth(localStorage.getItem("accesTokenCmm"));
  const formdata = new FormData();
  formdata.append("veterinaria_id", veterinaria_id);
  formdata.append("fotoanterior", fotoanterior);
  formdata.append("file", file);
  const result = await Axios.post("/editarLogoVeterinaria", formdata, {
    onUploadProgress: (p) => {
      const progress = p.loaded / p.total;
      const percento = Math.round((100 * p.loaded) / p.total);
      toast.update("init", {
        type: toast.TYPE.INFO,
        render: `Actualizando foto ( ${percento}% )`,
        autoClose: 5000,
        icon: "🚀",
        theme: "colored",
        progress,
      });
    },
  });
  toast.dismiss()
  return [result.data]
} catch (e) {
  console.log(e);
  toast.dismiss()
  return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
}
};


export const uploadFirmaVet = async (file, veterinaria_id, fotoanterior) => {
  toast(`Actualizando foto ( 0% )`, {
    toastId: "init",
    type: toast.TYPE.INFO,
    autoClose: 5000,
    icon: "🚀",
    theme: "colored",
  });
  try {
    tokenAuth(localStorage.getItem("accesTokenCmm"));
    const formdata = new FormData();
    formdata.append("veterinaria_id", veterinaria_id);
    formdata.append("fotoanterior", fotoanterior);
    formdata.append("file", file);
    const result = await Axios.post("/uploadfirma", formdata, {
      onUploadProgress: (p) => {
        const progress = p.loaded / p.total;
        const percento = Math.round((100 * p.loaded) / p.total);
        toast.update("init", {
          type: toast.TYPE.INFO,
          render: `Actualizando foto ( ${percento}% )`,
          autoClose: 5000,
          icon: "🚀",
          theme: "colored",
          progress,
        });
      },
    });
    
    toast.dismiss();
    return [result.data];
  } catch (e) {
    console.log(e);
    toast.dismiss();
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};




export const firmarFoto =async(foto)=>{
  try{
    const firmaFoto = await Axios.post("/downloadStorageFile", {
      file: foto,
    });
    return [
      {
        data: firmaFoto.data.url,
        sms: "ok",
        mensaje: "Foto firmada",
      },
    ];

  }catch(e){
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
}
