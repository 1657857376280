import Axios from "../../config/AxiosClientesFacturacion";

export const getCLientesFacturacion = async (identificacion,tipoidentificacion) => {
  try {
    const res = await Axios.get("/filtrarclientefacturacion/"+identificacion+"/"+tipoidentificacion)
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};


export const crearCLientesFacturacion = async (state) => {
  try {
    const res = await Axios.post(
      "/crearclientefacturacion/",state
    );
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};

export const actualizarCLientesFacturacion = async (state) => {
  try {
    const res = await Axios.post("/editarclientefacturacion/", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};

