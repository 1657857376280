import React, { useEffect, useState, useContext, useRef } from "react";
import {
  ApiPOSTCrearCaja,
  ApiPOSTEditarCaja,
} from "../../../api/contable/cajas";
import AuthContext from "../../../Context/auth/authContext";
import TablaCajas from "./ListadoCajas";
import { toast } from "react-toastify";
import ModalDefault from "../../modalDefault";
const Cajas = (props) => {
  const nombreref = useRef(null);
  const paneleditarcrearRef = useRef(null);
  const tablacategoriasRef = useRef(null);
  const [actualizarLista, setActualizarLista] = useState(false);

  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];
  const { usuario_id } = sessiondatacli[index].persona[0];

  const [state, setState] = useState({
    nombre: "",
    empresa_id: veterinaria_id,
    codigo: "",
    caja_id: "",
    usuario_id: usuario_id,
  });
  const [statereset, setStateReset] = useState({
    nombre: "",
    empresa_id: veterinaria_id,
    codigo: "",
    caja_id: "",
    usuario_id: usuario_id,
  });

  const [edtdata, seteditdata] = useState({
    nombre: "",
    caja_id: "",
    codigo: "",
    empresa_id: veterinaria_id,
  });

  const [editar, setEditar] = useState(false);

  const [buttonState, setButtonState] = useState(false);

  const [load, setLoad] = useState(false);
  const [modalcaja, setModalcaja] = useState(false);

  const onchange = (e) => {
    if(editar)  seteditdata({ ...edtdata, [e.target.name]: e.target.value });
    else setState({ ...state, [e.target.name]: e.target.value });
  };

  const guardarCategoria = async () => {
    setLoad(true);
    if (state.nombre === "") {
      toast.error("Debe ingresar el nombre de la caja", {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (state.codigo === "") {
      toast.error("Debe ingresar el código de la caja", {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (
      state.usuario_id === "" ||
      state.usuario_id === undefined ||
      state.usuario_id === null
    ) {
      toast.error(
        "No se encontró el usuario de la sesión , porfavor actualize la ventana",
        {
          theme: "colored",
          position: "bottom-left",
        }
      );
      return;
    }

    if (
      state.empresa_id === "" ||
      state.empresa_id === undefined ||
      state.empresa_id === null
    ) {
      toast.error(
        "No se encontró la empresa de la sesión , porfavor actualize la ventana",
        {
          theme: "colored",
          position: "bottom-left",
        }
      );
      return;
    }
    const res = await ApiPOSTCrearCaja(state);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, { position: "bottom-left" });
      setActualizarLista(!actualizarLista);
      setState(statereset);
    } else {
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
        position: "bottom-left",
      });
    }
    setLoad(false);
  };

  const editarFn = async (e) => {
    setButtonState(true);
    if (edtdata.nombre === "") {
      toast.error("El campo no puede ir vacio", { position: "bottom-right" });
      return;
    }
    if (edtdata.codigo === "") {
      toast.error("El campo no puede ir vacio", { position: "bottom-right" });
      return;
    }

    if (edtdata.caja_id === "") {
      toast.error("No se ah identificado ninguna caja", {
        position: "bottom-right",
      });
      return;
    }
    const res = await ApiPOSTEditarCaja(edtdata);
    console.log(res[0]);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, {
        theme: "colored",
        position:"bottom-left"
      });
      setModalcaja(false);
      setButtonState(false);
      setActualizarLista(!actualizarLista);
    } else {
      setEditar(true);
      setButtonState(false);
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
      });
    }
  };



  const ejecutaropciones = (data, editar) => {
    if (editar === true) {
      setEditar(true);
      seteditdata({
        nombre:data.nombre,
        caja_id:data.caja_id,
        codigo:data.codigo,
        empresa_id:veterinaria_id
      })
      setModalcaja(true);
    }
  };

  const modalActions = (modal) => {
    setModalcaja(modal);
    setState(statereset);
    setEditar(false);
  };

  return (
    <div className="col-md-12  ">
      <div className="row  d-flex flex-column ">
        <div  className=" ">
          <div className="col-md-12 bgfondo2  py-3 mx-0 mt-3   ">
            <div className="w-100 px-4 d-flex flex-row justify-content-between align-items-center">
              <h6 className="titulosubmenusize">CAJAS</h6>
              <button
                onClick={() => {
                  setModalcaja(true);
                }}
                type=""
                className="bg-btn-vet bordesombreado2"
              >
                <i className="fas fa-plus mx-2"></i> Nueva caja
              </button>
            </div>
          </div>
        </div>
        <div ref={tablacategoriasRef} className="my-3 ">
          <TablaCajas
            veterinaria_id={veterinaria_id}
            ejecutarOpciones={ejecutaropciones}
            actualizarLista={actualizarLista}
          ></TablaCajas>
        </div>
      </div>
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalcaja}
        size={"sm"}
         
        titulo={`CREAR CAJAS`}
        closeAction={modalActions}
      >
        <div className="row">
          <div className="col-md-12">
            <label className="px-0 py-2 negrita" htmlFor="exampleInputEmail1">
              Nombre * :
            </label>
            <div className=" p-0 m-0">
              {" "}
              <input
                ref={nombreref}
                type="text"
                className="inputtext2  w-100 bordecompleto"
                onChange={onchange}
                value={editar ? edtdata.nombre : state.nombre}
                id="nombre"
                name="nombre"
                placeholder="Ingrese el nombre de la caja. "
              />
            </div>
          </div>
          <div className="my-1"></div>
          <div className="col-md-12">
            <label className="px-0 py-2 negrita" htmlFor="exampleInputEmail1">
              Código * :
            </label>
            <div className=" p-0 m-0">
              {" "}
              <input
                type="text"
                className="inputtext2  w-100 bordecompleto"
                onChange={onchange}
                value={editar ? edtdata.codigo : state.codigo}
                id="codigo"
                name="codigo"
                placeholder="Ingrese el código de la caja. "
              />
            </div>
          </div>
          <div className="col-md-12">
            <hr className="w-100" />
          </div>
          <div className="col-md-12 d-flex d-flex justify-content-end align-items-end ">
            {" "}
            {load ? (
              <button
                className="bg-btn-vet w-100 bordesombreado2 bordecompleto"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Guardando...
              </button>
            ) : editar ? (
              <button
                className=" bg-btn-vet w-100 bordesombreado2 bordecompleto"
                onClick={() => editarFn()}
              >
                <i className="fas fa-save px-2"></i>
                Guardar cambios
              </button>
            ) : (
              <button
                className=" bg-btn-vet w-100 bordesombreado2 bordecompleto d-flex align-items-center justify-content-center"
                onClick={() => guardarCategoria()}
              >
                <i
                  className="fas fa-save px-2"
                  style={{ fontSize: "16px" }}
                ></i>
                Crear caja
              </button>
            )}
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default Cajas;
