import React, { useEffect, useState, useContext } from "react";
import Axios from "../../../config/Axios";
import { Link } from "react-router-dom";
import PacienteContext from "../../../Context/pacientes/pacientesContext";
import InsertarPacientePersona from "../pacientes/insertarPacientePersona";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";

const Perfil = (props) => {
  const PacientesContext = useContext(PacienteContext);
  const {
    BuscarDataPaciente,
    tipoExamenes,
    paciente,
    urlFotoMascota,
    getAllTipoExamenes,
    limpiarEstado,
  } = PacientesContext;

  console.log(paciente);

  const [state, setState] = useState({
    todos: [],
    currentPage: 0,
    edadactual: "",
    bghover: "none",
    asignarDuenioControl: false,
  });

  const [stateModal, setStateModal] = useState({
    mascotaModal: false,
  });

  useEffect(() => {
    //if (paciente.length === 0) {
    BuscarDataPaciente(props.mascota);
    /*}

    if (tipoExamenes.length === 0) {
      getAllTipoExamenes();
    }*/

    return () => {
      //limpiarEstado()
    };
  }, []);

  const sexoMascota = (sexo) => {
    return sexo === 1 ? (
      <div className="textoPerfil">
        <span className="text-primary">SEXO:</span> MACHO{" "}
        <i className="fa fa-mars" aria-hidden="true"></i>
      </div>
    ) : sexo === 2 ? (
      <div className="textoPerfil">
        <span className="text-primary">SEXO:</span> HEMBRA{" "}
        <i className="fa fa-venus" aria-hidden="true"></i>
      </div>
    ) : (
      <div className="textoPerfil">
        <span className="text-primary">SEXO:</span> OTRO{" "}
        <i className="fas fa-neuter" aria-hidden="true"></i>
      </div>
    );
  };

  const asignacionDuenio = () => {
    return state.asignarDuenioControl ? (
      <InsertarPacientePersona
        data={{
          mascota_id: paciente[0].mascota_id,
          nombreMascota: paciente[0].nombres,
        }}
      />
    ) : (
      <div>
        <hr className="my-4" />
        <div className="text-primary h3" style={{ textAlign: "center" }}>
          AÚN NO TIENE DUEÑO
        </div>
        <div className="text-center">
          <button
            className="btn btn-primary h2"
            style={{
              padding: "10px 20px 10px 20px",
            }}
            onClick={() => setState({ ...state, asignarDuenioControl: true })}
          >
            ASIGNAR
          </button>
        </div>
      </div>
    );
  };

  const datosDuenio = (duenio) => {
    return duenio === null ? (
      asignacionDuenio()
    ) : (
      <div>
        <hr className="my-4" />
        <div className="text-primary h3" style={{ textAlign: "center" }}>
          INFORMACIÓN DEL DUEÑO
        </div>
        <Table style={{ width: "100%" }} className="mb-1">
          <thead className="bg-integralvet" style={{ textAlign: "center" }}>
            <tr>
              <th>NOMBRES</th>
              <th>APELLIDOS</th>
              <th>CEDULA</th>
              <th>CORREO</th>
            </tr>
          </thead>
          <tbody style={{ padding: "5px", textAlign: "center" }}>
            <tr>
              <td>{duenio[0].nombres}</td>
              <td>{duenio[0].apellidos}</td>
              <td>{duenio[0].cedula}</td>
              <td>{duenio[0].correo}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const PacientePerfil = (paciente) => {
    return (
      <div>
        <div className="row m-5">
          <div className="col-md-auto">
            <img
              src={
                state.reader
                  ? state.reader
                  : urlFotoMascota === "" ||
                    urlFotoMascota === null ||
                    urlFotoMascota === "null"
                  ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
                  : urlFotoMascota
              }
              style={{
                textAlign: "center",
                width: "150px",
                height: "150px",
                border: "5px solid white",
              }}
            ></img>
          </div>
          <div className="col-sm w-100">
            <div className="row">
              <div className="col-sm">
                <div className="h5">
                  <span className="text-primary">NOMBRE:</span>{" "}
                  {paciente[0].nombres}{" "}
                </div>
                <div className="h5">
                  <span className="text-primary">ESPECIE:</span>{" "}
                  {paciente[0].especie}
                </div>
                <div className="h5">
                  <span className="text-primary">RAZA:</span> {paciente[0].raza}
                </div>
                <div className="h5">
                  <span className="text-primary">CHIP:</span> {paciente[0].chip}
                </div>
                <div className="h5">
                  <span className="text-primary">CASTRADO:</span>{" "}
                  {paciente[0].castrado === 1 ? (
                    <span
                      className="bg-primary text-light"
                      style={{
                        padding: "5px 10px 5px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      SI
                    </span>
                  ) : (
                    <span
                      className="bg-secondary"
                      style={{
                        padding: "5px 10px 5px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      NO
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm">
                <div className="h5">
                  <span className="text-primary">FECHA DE NACIMIENTO:</span>{" "}
                  {paciente[0].fec_naci}
                </div>
                <div className="h5">
                  <span className="text-primary">EDAD:</span>{" "}
                  {Edad(paciente[0].fec_naci)}
                </div>
                <div className="h5">
                  <span className="text-primary">PELAJE:</span>{" "}
                  {paciente[0].pelaje}
                </div>
                {sexoMascota(paciente[0].sexo_id)}
              </div>
            </div>
          </div>
        </div>
        <div className="row m-5">
          <div className="col-md-12">
            {datosDuenio(paciente[0].tieneduenio)}
          </div>
        </div>
      </div>
    );
  };
  const PacientePerfilInicio = (paciente) => {
    return (
      <div>
        <div className="row">
          <div className=" d-flex  flex-row justify-content-center align-items-center">
            <img
              src={
                state.reader
                  ? state.reader
                  : urlFotoMascota === "" ||
                    urlFotoMascota === null ||
                    urlFotoMascota === "null"
                  ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
                  : urlFotoMascota
              }
              style={{
                textAlign: "center",
                width: "170px",
                height: "150px",
                border: "5px solid white",
              }}
            ></img>
            <div className="">
           {/*  <div className="textoPerfil">
              <span className="text-primary">NOMBRE:</span>{" "}
              {paciente[0].nombres}{" "}
            </div> */}
            <div className="textoPerfil">
              <span className="text-primary">ESPECIE:</span>{" "}
              {paciente[0].especie}
            </div>
            <div className="textoPerfil">
              <span className="text-primary">RAZA:</span> {paciente[0].raza}
            </div>
            <div className="textoPerfil">
              <span className="text-primary">CHIP:</span> {paciente[0].chip}
            </div>
            <div className="textoPerfil">
              <span className="text-primary">CASTRADO:</span>{" "}
              {paciente[0].castrado === 1 ? (
                <span
                  className="bg-primary text-light"
                  style={{
                    padding: "5px 10px 5px 10px",
                    borderRadius: "10px",
                  }}
                >
                  SI
                </span>
              ) : (
                <span
                  className="bg-secondary"
                  style={{
                    padding: "5px 10px 5px 10px",
                    borderRadius: "10px",
                  }}
                >
                  NO
                </span>
              )}
            </div>
            <div className="textoPerfil">
              <span className="text-primary">FECHA DE NACIMIENTO:</span>{" "}
              {paciente[0].fec_naci}
            </div>
            <div className="textoPerfil">
              <span className="text-primary">EDAD:</span>{" "}
              {Edad(paciente[0].fec_naci)}
            </div>
            <div className="textoPerfil">
              <span className="text-primary">PELAJE:</span> {paciente[0].pelaje}
            </div>
            {sexoMascota(paciente[0].sexo_id)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Edad = (data) => {
    let fecha = data;
    var nacimiento = new Date();
    var hoy = new Date(fecha);

    nacimiento.setMonth();
    nacimiento.setDate();
    nacimiento.setHours();

    var tiempo = new Date() - hoy;

    var anios = Math.floor(tiempo / (1000 * 60 * 60 * 24 * 365));
    tiempo = tiempo - anios * (1000 * 60 * 60 * 24 * 365);

    var meses = Math.floor(tiempo / (1000 * 60 * 60 * 24 * 30));
    tiempo = tiempo - meses * (1000 * 60 * 60 * 24 * 30);

    var dias = Math.floor(tiempo / (1000 * 60 * 60 * 31));
    tiempo = tiempo - dias * (1000 * 60 * 60 * 31);

    //calcula horas
    //var horas = Math.floor(tiempo /(1000*60*60*24))
    //tiempo = tiempo - (horas * (1000*60*60*24))

    //var minutos = Math.floor(tiempo /(1000*60*60))
    //tiempo = tiempo - (minutos * (1000*60*60))

    var edadactual = `${anios} años ,  ${meses} meses , ${dias} dias `;
    return edadactual;
    /*setState({
            ...state,
            edadactual: `${anios} años ,  ${meses} meses , ${dias} dias `
        })*/
    //console.log(`Han pasado ${dias} dias ${horas} horas y ${minutos} minutis desde que naciste ${meses} meses  ${anios} años`)
  };

  const mouseEnter = (e) => {
    e.preventDefault();
    if (
      e.target.querySelector("i") !== null ||
      e.target.querySelector("span") != null ||
      e.target.querySelector("div") !== null
    ) {
      e.target.style.background = "#f0f2f5";
      e.target.querySelector("span").style.color = "black";
      e.target.querySelector("i").style.color = "black";
    }
  };
  const mouseleave = (e) => {
    e.preventDefault();
    if (
      e.target.querySelector("i") !== null ||
      e.target.querySelector("span") != null ||
      e.target.querySelector("div") !== null
    ) {
      e.target.style.background = "none";
      e.target.querySelector("i").style.color = "black";
      e.target.querySelector("span").style.color = "black";
    }
  };

  return (
    <>
      {/*begin::Card*/}

      <div className="row p-2 bg-white bordecircular borde-tarjeta d-flex  flex-column ">
        <div className="col-md-12 p-0">
          <span className="text-dark px-3 titulosSize floatizquierda">{paciente.length>0?paciente[0].nombres:"Cargando datos ..."} </span>
          <span
            style={{
              color: "#d2d2d2",
              float: "right",
              background: "white",
              fontSize: "17px",
            }}
            className=""
          >
            |
          </span>
          <button
            style={{ float: "right", background: "white" }}
            className=" btn btn-default p-0"
            onClick={() => setStateModal({ ...stateModal, mascotaModal: true })}
          >
            <i className="fas fa-info-circle" style={{ fontSize: "20px" }}></i>{" "}
          </button>
          <span
            style={{
              color: "#d2d2d2",
              float: "right",
              background: "white",
              fontSize: "17px",
            }}
            className="h-100 p-0 pl-1 pr-1"
          >
            |
          </span>
          <Link
            style={{ float: "right", background: "white" }}
            className=" btn btn-default p-0"
            to={"/clinica"}
          >
            <i className="fas fa-reply" style={{ fontSize: "20px" }}></i>{" "}
          </Link>
          <span
            style={{
              color: "#d2d2d2",
              float: "right",
              background: "white",
              fontSize: "17px",
            }}
            className="h-100 p-0 pl-1 pr-1"
          >
            |
          </span>
        </div>

        <div
          className="col-md-12 p-0"
          style={{ textAlign: "center", padding: "0", margin: "0" }}
        >
          {/*  <div className="col-md-auto">
            <img
              src={
                state.reader
                  ? state.reader
                  : urlFotoMascota === "" ||
                    urlFotoMascota === null ||
                    urlFotoMascota === "null"
                  ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
                  : urlFotoMascota
              }
              style={{
                textAlign: "center",
                width: "150px",
                height: "150px",
                border: "5px solid white",
                borderRadius:"150px"
              }}
            ></img>
           
          </div> */}
          {paciente.length !== 0
            ? PacientePerfilInicio(paciente)
            : "cargando..."}
        </div>
      </div>

      {/* MODAL MASCOTA*/}
      <Modal
        isOpen={stateModal.mascotaModal}
        toggle={() => {
          setStateModal({
            ...stateModal,
            mascotaModal: false,
          });
        }}
        style={{ maxWidth: "70%", height: "600px", padding: "20px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              mascotaModal: false,
            });
          }}
          style={{ background: "white" }}
        >
          INFORMACION DETALLADA DE{" "}
          {paciente.length !== 0 ? paciente[0].nombres : "cargando..."}
        </ModalHeader>
        <ModalBody>
          {paciente.length !== 0 ? PacientePerfil(paciente) : "cargando..."}
        </ModalBody>
        {/*<ModalFooter></ModalFooter>*/}
      </Modal>
      {/* FIN MODAL MASCOTA*/}
      {/*end::Content*/}
    </>
  );
};

export default Perfil;
