import React, { useContext,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
/* import { ReactComponent as HomeIcon } from "../../icons/home.svg";
import { ReactComponent as CogIcon } from "../../icons/cog.svg";
 */
import AuthContext from "../../Context/auth/authContext";
import "./index.css";

//import { ReactComponent as HomeIcon } from "../../svg/home.svg";

import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";


const SideBar = ({ children, routes, titulo, subpath }) => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli } = AuthenticationContext;
  const { type, ruc } = sessiondatacli[0].veterinaria[0];
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.1,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.1,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.1,
      },
    },
  };

  /* const salir = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location = "/";
  }; */

  useEffect(()=>{
   setTimeout(()=>setIsOpen(true),600)
  },[])

  const crearMenu = (route, index) => {
    if (route.subRoutes) {
      return ruc !== "0962596649001" &&
        route.name === "Reportes allmetales" ? null : (
        <SidebarMenu
          key={index}
          setIsOpen={setIsOpen}
          route={route}
          showAnimation={showAnimation}
          isOpen={isOpen}
          subpath={subpath}
        />
      );
    }

    return (
      <>
        {route.type === "" || route.type === type ? (
          <NavLink
            to={`${subpath}${route.path}`}
            key={index}
            className="link py-2"
            activeClassName="active"
          >
              <i className={route.icon} style={{fontSize:"16px"}}></i>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="link_text"
                >
                  {route.name}
                </motion.div>
              )}
            </AnimatePresence>
          </NavLink>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className="w-auto  h-100 bgfondo2 bordesombreado-submenu ">
        <motion.div
          animate={{
            width: isOpen ? "275px" : "50px",
            minWidth: isOpen ? "275px" : "50px",
            transition: {
              duration: 0.5,
              //type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar2 m-0  ${isOpen ? "w-auto" : ""}`}
        >
          <div
            className={`top_section  d-flex flex-row w-100  ${
              isOpen ? "justify-content-between" : "justify-content-center"
            } align-items-center align-self px-3 py-4`}
          >
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo m-0 titulosubmenusize"
                >
                  {titulo}
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars icon">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <hr className="m-0 p-0" />

          <section className="routes" style={{ padding: "10px" }}>
            {!routes ? (
              <div>.......</div>
            ) : (
              routes.map((route, index) => {
                return crearMenu(route, index);
              })
            )}
            {/*<NavLink
              to={"/"}
              className="link"
              activeClassName="active"
              onClick={(e) => salir(e)}
            >
              <div className="icon">
                <HomeIcon></HomeIcon>
              </div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    Salir
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>*/}
          </section>
        </motion.div>
        {/* <main
          className="d-flex flex-column w-100 justify-content-center align-items-center align-self-center "
          style={{ overflow: "auto", height: "100vh" }}
        >
          
          <div className="w-100 h-100 ">{children}</div>
        </main> */}
      </div>
    </>
  );
};

export default SideBar;
