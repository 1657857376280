import React, { useEffect, useState, useContext, useRef } from "react";
import Axios from "../../../../config/Axios";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, Table, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import AuthContext from "../../../../Context/auth/authContext";
import CustomOverlay from "../../../extras/customOverlay";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import StilysedInput from "../../../extras/stylisedInput";
import { Link } from "react-router-dom";
import ModalDefault from "../../../Modal/modalDefault";
import { ReactComponent as TimerIcon } from "../../../../icons/timer.svg";
import { ReactComponent as RefreshIcon } from "../../../../icons/refresh.svg";

const Atp = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, color, textColor, index } = authContext;
  const toastId = useRef(null);
  
  const {
    nombre,
    direccion,
    latitud,
    longitud,
    atencion,
    logo,
    ruc,
    portada,
    provincia_id,
    ciudad_id,
    codigo,
    veterinaria_id,
  } = sessiondatacli[index].veterinaria[0];

  const [state, setState] = useState({
    itemsAtp: [],
    todos: [],
    currentPage: 1,
    todosPerPage: 2,
    search: "",
  });

  const { todos, currentPage, todosPerPage } = state;

  const [stateAtp, setstateAtp] = useState({
    atp_id: "",
    fecha_aplicacion: "",
    fecha_programada: "",
    estado: "",
    item_id: "",
    mascota_id: props.mascota,
    veterinaria_id: sessiondatacli[index].veterinaria[0].veterinaria_id,
    usuario_id: sessiondatacli[index].persona[0].usuario_id,
    foto: "",
    nombre: "",
    dosis: "",
  });

  const [programarAtp, setProgramarAtp] = useState(false);

  const [stateModal, setStateModal] = useState({
    UsuarioModal: false,
    EliminarModal: false,
    AplicarModal: false,
    userdata: {},
    configModal: false,
    atp_id: "",
    foto: null,
    atpAnticipada: 0,
    ConfigurarAtpModal: false,
  });

  const onchangeVacunas = (e) => {
    e.preventDefault();
    setstateAtp({
      ...stateAtp,
      [e.target.name]: e.target.value,
    });
  };

  const [img, setCom] = useState({
    imagenes: [],
    todosArchivos: [],
    reader: "",
  });

  useEffect(() => {
    inicializarTodasAtp();
  }, []);

  const Input = () => {
    return (
      <label
        style={{
          backgroundColor: "none",
          color: "#fff",
          cursor: "pointer",
          padding: 5,
          borderRadius: 3,
          margin: "0px",
        }}
      >
        <input
          style={{ display: "none" }}
          type="file"
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              let aux = [];
              let reader = URL.createObjectURL(chosenFiles[0]);
              let tipodearchivo = chosenFiles[0].type.substring(
                0,
                chosenFiles[0].type.indexOf("/")
              );
              let todo = {
                imagen: chosenFiles[0],
                reader: reader,
                type: tipodearchivo,
              };
              aux.push(todo);
              setCom({
                ...img,
                imagenes: aux,
              });
            });
          }}
          accept="image/jpeg,image/gif,image/png,video/*"
        />
        <i
          className="fas fa-image"
          style={{ fontSize: "30px", color: "blue" }}
        ></i>
      </label>
    );
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const CrearAtp = async (e) => {
    e.preventDefault();
    let newDate = new Date();
    let date2 = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let date = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
      date2 < 10 ? `0${date2}` : `${date2}`
    }`;

    if (stateAtp.fecha_programada === "" || stateAtp.fecha_programada < date) {
      toast.error("Ingrese una fecha correcta", {
        theme: "colored",
        autoClose: 3000,
      });
    } else {
      try {
        const result = await Axios.post("/createAtp", stateAtp/* {
          onUploadProgress: (p) => {
            const percento = Math.round((100 * p.loaded) / p.total);
            const progress = p.loaded / p.total;
            if (toastId.current === null) {
              toastId.current = toast(
                `Iniciando ...(${percento}%)`,
                {
                  type: toast.TYPE.INFO,
                  theme: "colored",
                  autoClose:false,
                  progress,
                }
              );
            } else {
              toast.update(toastId.current, {
                type: toast.TYPE.INFO,
                render: `Estableciendo programación del ATP (${percento}%)`,
                theme: "colored",
              });
            }
          },
        } */);
        if (result.data.sms === "ok") {
          toast.success(`Se programó la fecha del ATP`,{
            theme: "colored",
            autoClose:2000
          }); 
          setstateAtp({
            ...stateAtp,
            atp_id: "",
            fecha_aplicacion: "",
            fecha_programada: "",
            estado: "",
            item_id: "",
          });
          setProgramarAtp(false)
          
          inicializarTodasAtp();
          
        } else {
          toast.error(`${result.data.mensaje}`,{
            theme: "colored",

          });        }
      } catch (e) {
        toast.error(`${e}`,{
          theme: "colored",

        });          
      }
    }
  };

  const ConfirmarAtpAnticipada = async (e, todo) => {
    e.preventDefault();

    setstateAtp({
      ...stateAtp,
      atp_id: todo.atp_id,
      estado: todo.estado,
      foto: todo.foto,
    });

    let newDate = new Date();
    let date2 = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let date = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
      date2 < 10 ? `0${date2}` : `${date2}`
    }`;

    if (todo.estado === 1) {
      alert("atp ya aplicada");
    } else if (date < todo.fecha_programada) {
      setStateModal({
        ...stateModal,
        AplicarModal: true,
        atpAnticipada: 1,
      });
    } else {
      setStateModal({
        ...stateModal,
        AplicarModal: true,
        atpAnticipada: 2,
      });
    }
  };


  const atpAplicada = async () => {
    try {
      if (stateAtp.nombre === "") {
        toast.error("Se debe identificar el antiparasitario !");
      } else {
        const formdata = new FormData();
        formdata.append("file", img.imagenes[0].imagen);
        formdata.append("atp_id", stateAtp.atp_id);
        formdata.append("nombre", stateAtp.nombre);
        formdata.append("dosis", "");
        const result = await Axios.post("/aplicarAtp", formdata,{
          onUploadProgress: (p) => {
            const progress = p.loaded / p.total;
            const percento = Math.round((100 * p.loaded) / p.total);
            if (toastId.current === null) {
              toastId.current = toast(
                `Procesando... (${percento}%)`,
                {
                  type: toast.TYPE.INFO,
                  theme: "colored",
                  autoClose:progress*1000,
                  progress,
                }
              );
            } else {
              toast.update(toastId.current, {
                type: toast.TYPE.INFO,
                render: `Procesando... (${percento}%)`,
                theme: "colored",
                autoClose:progress*1000,

              });
            }
          },
        } );
        toast.dismiss()
        if (result.data.sms === "ok") {
          toast.success(`${result.data.mensaje}`)
          setstateAtp({
            ...stateAtp,
            atp_id: "",
            fecha_aplicacion: "",
            fecha_programada: "",
            estado: "",
            item_id: "",
          });

          setStateModal({
            ...stateModal,
            AplicarModal: false,
            atpAnticipada: 0,
          });
          
          inicializarTodasAtp();
        }else{
          toast.error(`${result.data.mensaje}`)
        }

      }
      
    } catch (e) {
      toast.error(`${e}`);
        
    }

  };



  const ConfirmarEliminarAtp = async (e, todo) => {
    e.preventDefault();
    setStateModal({
      ...stateModal,
      EliminarModal: true,
      atp_id: todo.atp_id,
      foto: todo.foto,
    });
  };

  const EliminarAtp = async (e, todo) => {
    try {
      const result = await Axios.post("/eliminarAtp", {
        atp_id: stateModal.atp_id,
        file: stateModal.foto,
      });
      if (result.data.sms === "ok") {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
        setStateModal({
          ...stateModal,
          EliminarModal: false,
        });
        inicializarTodasAtp();
      } else {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
      }
    } catch (e) {}
  };

  const onchange = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleClick = (event) => {
    setState({
      ...state,
      currentPage: Number(event.target.id),
    });
  };

  // Logic for displaying current todos
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

  const renderTodos = currentTodos.map((todo, index) => {
    return (
      <tr key={index}>
        <td>
          {todo.fecha_programada ? (
            todo.fecha_programada
          ) : (
            <span class="badge rounded-pill bg-secondary">APLICADA</span>
          )}
        </td>

        <td>
          {todo.fecha_aplicacion ? (
            todo.fecha_aplicacion
          ) : (
            <span class="badge rounded-pill bg-warning text-dark">
              NO APLICADA
            </span>
          )}
        </td>
        <td>
          {todo.estado === 1 ? (
            <button
              type="button"
              disabled
              className="btn btn-success btn-sm"
              style={{
                marginRight: "5px",
                background: "green",
                borderColor: "green",
              }}
              onClick={(e) => ConfirmarAtpAnticipada(e, todo)}
            >
              Aplicada
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-success btn-sm"
                style={{
                  marginRight: "5px",
                  background: "green",
                  borderColor: "green",
                }}
                onClick={(e) => ConfirmarAtpAnticipada(e, todo)}
              >
                Aplicar
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={(e) => ConfirmarEliminarAtp(e, todo)}
              >
                <li className="fa fa-trash"></li>
              </button>
            </>
          )}
        </td>
      </tr>
    );
  });

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        style={{ border: "1px solid", padding: "5px" }}
      >
        {number}
      </li>
    );
  });

  const inicializarTodasAtp = async () => {
    try {
      const respuesta = await Axios.get(
        `/filtrarPorCoincidenciasAtp/` + props.mascota
      );
      if (respuesta.data.sms === "ok") {
        setState({
          ...state,
          todos: respuesta.data.result,
          currentPage: 1,
        });
      } else {
      }
    } catch (e) {}
  };

  const updateStateItems = (data) => {
    setState({
      ...state,
      itemsAtp: data,
    });
  };

  const closeActionModal = (data) => {
    setProgramarAtp(data);
  };

  const ProgramarVacunaFn = () => {
    return (
      <div className="d-flex flex-column justify-content-center">
        <div className="w-100">
          <div className="form-group">
            <label>FECHA DE APLICACIÓN DEL ATP* :</label>
            <input
              type="date"
              placeholder="Ingrese fecha de VACUNA"
              className="form-control"
              id="fecha_programada"
              name="fecha_programada"
              onChange={onchangeVacunas}
              value={stateAtp.fecha_programada}
            />
          </div>
        </div>
        <hr />
        <div className="w-100">
          <button
            type="button"
            className="btn btn-success w-100 h-100"
            onClick={CrearAtp}
          >
            <i class="fas fa-clock"></i>Programar atp
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className=" row bordecircular bgfondo bordesombreado">
        <CustomOverlay mascota={props.mascota} />
        <div className="card-body p-0 mb-2">
          <div className="row py-2">
            <div className="col-md-12  ">
              <span className="text-dark px-3 titulosSize floatizquierda">
                Antiparasitarios (Atp){" "}
              </span>

              <button
                style={{ float: "right", background: "white" }}
                className=" btn btn-default p-0 mx-1"
                onClick={(e) => {
                  e.preventDefault();
                  inicializarTodasAtp();
                }}
              >
                <RefreshIcon width={26} />
              </button>

              <button
                style={{ float: "right", background: "white" }}
                className=" btn btn-default p-0 mx-1"
                onClick={(e) => {
                  e.preventDefault();
                  setProgramarAtp(true);
                }}
              >
                <TimerIcon width={26} />
              </button>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 py-1 ">
              <div style={{ overflow: "auto", maxHeight: "134px" }}>
                {/* TABLA DE USUARIOS */}

                <Table>
                  <thead
                    className=""
                    style={{ background: color, textAlign: "center" }}
                  >
                    <tr>
                      <th style={{ color: textColor }}>PROGRAMADA</th>
                      <th style={{ color: textColor }}>APLICADA</th>
                      <th style={{ color: textColor }}>OPCIONES</th>
                    </tr>
                  </thead>
                  <tbody style={{ padding: "0px", textAlign: "center" }}>
                    {renderTodos}
                  </tbody>
                </Table>
                {state.todos.length > 0 ? null : (
                  <div
                    style={{
                      width: "100%",

                      textAlign: "center",
                    }}
                  >
                    <label
                      style={{
                        width: "100%",

                        textAlign: "center",
                      }}
                    >
                      Sin ATP
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* FIN TABLA DE USAURIOS*/}
        </div>
      </div>

      {/* MODAL CONFIRMACIÓN*/}
      <Modal
        isOpen={stateModal.EliminarModal}
        toggle={() => {
          setStateModal({
            ...stateModal,
            EliminarModal: false,
          });
          setstateAtp({
            ...stateAtp,
            foto: {},
          });
          setCom({
            ...img,
            imagenes: [],
          });
        }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              EliminarModal: false,
            });
          }}
          style={{ background: "white" }}
        >
          {"CONFIRMAR ELIMINACIÓN"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {
                  <div>
                    <h4>¿Está seguro que desea eliminar esta información?</h4>

                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ marginLeft: "5px", float: "right" }}
                      onClick={EliminarAtp}
                    >
                      Eliminar
                    </button>
                  </div>
                }

               
              </div>
            </div>
          </div>
        </ModalBody>

        {/*<div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Filtrar Vacuna:</label><br></br>
                                        <input type="text" className="form-control" placeholder="Filtre las iniciales de la vacuna y precione enter" aria-label="codigobuscar" aria-describedby="addon-wrapping" onChange={onchange} onKeyUp={(e) => BuscarSearch(e)} id="search" name="search" value={state.search} />
                                    </div>
                                </div>*/}
      </Modal>
      <Modal
        isOpen={stateModal.AplicarModal}
        toggle={() => {
          setStateModal({
            ...stateModal,
            AplicarModal: false,
          });
          setstateAtp({
            ...stateAtp,
            foto: {},
          });
          setCom({
            ...img,
            imagenes: [],
          });
        }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              AplicarModal: false,
            });
          }}
          style={{ background: "white" }}
        >
          {stateModal.atpAnticipada === 1
            ? "ATP ANTICIPADO"
            : stateModal.atpAnticipada === 2
            ? "CONFIRMACIÓN"
            : "CONFIRMAR ELIMINACIÓN"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {stateModal.atpAnticipada === 1 ? (
                  <>
                    {" "}
                    {img.imagenes.length > 0 ? (
                      <div
                        className="row mx-12 d-flex justify-content-center align-items-center"
                        style={{ border: "0px solid #f0f2f5" }}
                      >
                        <div>
                          {" "}
                          <h5>
                            Se aplicará la atp anticipada a la fecha programada
                          </h5>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <h5 className="text-muted">
                          Agrega la etiqueta de la atp para continuar
                        </h5>
                        <Input></Input>
                      </div>
                    )}
                  </>
                ) : stateModal.atpAnticipada === 2 ? (
                  <>
                    {img.imagenes.length > 0 ? (
                      <div
                        className="row mx-12 d-flex justify-content-center align-items-center"
                        style={{ border: "0px solid #f0f2f5" }}
                      >
                        <div>
                          {" "}
                          <h5>
                            Se aplicará la atp , presione aplicar para continuar
                          </h5>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <h5 className="text-muted">
                          Agrega la etiqueta de la atp para continuar
                        </h5>
                        <Input></Input>
                      </div>
                    )}
                  </>
                ) : null}

                {img.imagenes.length > 0 ? (
                  <>
                    {img.imagenes.map((data, indice) => (
                      <>
                        <div
                          className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3"
                          style={{ position: "relative" }}
                        >
                          {data.type === "image" ? (
                            <img
                              className="img-responsive"
                              style={{ width: "100%", height: "200px" }}
                              src={data.reader}
                              alt
                            />
                          ) : null}
                          <div
                            className=""
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                            }}
                          >
                            <button
                              style={{
                                background: "red",
                                padding: "5px 9px",
                                borderRadius: "20px",
                              }}
                              onClick={(e) => {
                                /*removeItemFromArr(e, indice)*/
                                setCom({
                                  ...img,
                                  imagenes: [],
                                });
                              }}
                            >
                              <i
                                className="fas fa-trash"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                          </div>
                        </div>

                        <StilysedInput
                          stateManager={setstateAtp}
                          statePropertieName="nombre"
                          labelText="Nombre comercial"
                          onChange={onchangeVacunas}
                          inputType="text"
                        />
                      </>
                    ))}
                    <div className="col-md-12">
                      <div className="">
                        {stateModal.atpAnticipada > 0 ? (
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginLeft: "5px", float: "right" }}
                            onClick={atpAplicada}
                          >
                            Aplicar
                          </button>
                        ) : null}

                        <button
                          type="button"
                          className="btn btn-default"
                          style={{ float: "right" }}
                          onClick={() => {
                            setStateModal({
                              ...stateModal,
                              AplicarModal: false,
                              atpAnticipada: 0,
                            });
                            setstateAtp({
                              ...stateAtp,
                              foto: {},
                            });
                            setCom({
                              ...img,
                              imagenes: [],
                            });
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </ModalBody>

        {/*<div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Filtrar Vacuna:</label><br></br>
                                        <input type="text" className="form-control" placeholder="Filtre las iniciales de la vacuna y precione enter" aria-label="codigobuscar" aria-describedby="addon-wrapping" onChange={onchange} onKeyUp={(e) => BuscarSearch(e)} id="search" name="search" value={state.search} />
                                    </div>
                                </div>*/}
      </Modal>
      <ModalDefault
        size="md"
        modal={programarAtp}
        bandera="excel"
        closeAction={closeActionModal}
        titulo="Programar Atp"
        icontitulo={<TimerIcon width={30} fill={"blue"} className="mx-2" />}
      >
        <ProgramarVacunaFn></ProgramarVacunaFn>
      </ModalDefault>
    </>
  );
};

export default Atp;
