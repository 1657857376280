import "./index.css";
import { ReactComponent as BellIcon } from "../../icons/bell.svg";
import { ReactComponent as CaretIcon } from "../../icons/caret.svg";
import { ReactComponent as HospitalIcon } from "../../icons/hospital.svg";
import { ReactComponent as ExitIcon } from "../../icons/exit.svg";
import { ReactComponent as CogIcon } from "../../icons/cog.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrow.svg";
import { ReactComponent as BoltIcon } from "../../icons/bolt.svg";
import { ReactComponent as HomeIcon } from "../../icons/home.svg";

import React, { useState, useEffect, useRef,useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { Link, useNavigate } from "react-router-dom";
import PerfilMenu from "./perfil/perfil";
import AuthContext from "../../Context/auth/authContext";
function NavApp(props) {
   const authContext = useContext(AuthContext)
   const {modulos}=authContext
   const canvas=()=>{
    props.canvas()
   }
  return (
    <Navbar tipo={props.tipo} className="color-plomo">
      {modulos.length > 0 ? (
        modulos.map((data, index) => (
          <NavItemInicio
            key={index}
            canvas={canvas}
            icon="fas fa-caret-right"
            link={data.modulos.link}
            text={data.modulos.modulo}
          />
        ))
      ) : (
        <div className="w-100 d-flex justify-content-end align-items-center px-3 ">
          <label>Cargando módulos...</label>
        </div>
      )}
      
    </Navbar>
  );
}

function Navbar(props) {
  return (
    <nav
      className={`navbar-iv d-flex justify-content-end  m-0 p-0`}
      style={{ maxWidth: "100%", background: "none" }}
    >
      <ul
        className={`  ${
          props.tipo === 0
            ? "navbar-nav-iv "
            : " d-flex justify-content-start flex-column w-100 m-0 p-0"
        }`}
      >
        {props.children}
      </ul>
    </nav>
  );
}

function NavItemInicio(props) {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(!open);
    props.canvas()
  };
  return (
    <Link
      to={props.link}
      className="  w-100 py-2 d-flex justify-content-start p-2 align-items-center bordecompleto hovernaranja"
      onClick={() => close()}
    >
      <i className={`${props.icon} iconmenu mx-2`}></i>
      <label
        className="mx-2 textmenu negrita cursor"
        style={{ zIndex: "100" }}
      >
        {props.text}
      </label>
    </Link>
  );
}

function NavItemBell(props) {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(!open);
  };
  return (
    <li className="nav-item">
      <a href="#" className="icon-button" onClick={() => setOpen(!open)}>
        {props.icon}
      </a>
      {open && <DropdownMenuBell close={close}></DropdownMenuBell>}
    </li>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(!open);
  };
  return (
    <li className="nav-item justify-content-start ">
      <a href="#" className="icon-button" onClick={() => setOpen(!open)}>
        {props.icon}
      </a>
      {open && <DropdownMenu close={close}></DropdownMenu>}
    </li>
  );
}

function DropdownMenuBell(props) {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <div
        className="col-md-12 "
        style={{ overflowX: "scroll", maxHeight: "500px" }}
      >
        {props.children}
      </div>
    );
  }

  const mouseleave = (e) => {
    props.close();
  };

  return (
    <div
      className="dropdown-iv borde-tarjeta"
      style={{ background: "white" }}
      onMouseLeave={(e) => mouseleave(e)}
      ref={dropdownRef}
    >
      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
        //onExit={()=>{alert("saliendo")}}
      >
        <DropdownItem></DropdownItem>
      </CSSTransition>
    </div>
  );
}

function DropdownMenu(props) {
  let history = useNavigate();


  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <a
        href="#"
        className="menu-item-iv"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button mx-2">{props.leftIcon}</span>
        {props.children}
        <span
          className="icon-right d-flex justify-content-between"
          style={{ width: "12px" }}
        >
          {props.rightIcon}
        </span>
      </a>
    );
  }

  function DropdownItemOnly(props) {
    return (
      <a href="#" className="menu-item-iv" onClick={props.onclick}>
        <span className="icon-button mx-2">{props.leftIcon}</span>
        {props.children}
      </a>
    );
  }

  function DropdownICabecera(props) {
    return <PerfilMenu mouseleave={props.mouseleave} />;
  }
  const mouseleave = (e) => {
    props.close();
  };

  return (
    <div
      className="dropdown-iv-menu borde-tarjeta"
      style={{ background: "white" }}
      onMouseLeave={(e) => mouseleave(e)}
      ref={dropdownRef}
    >
      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
        //onExit={()=>{alert("saliendo")}}
      >
        <div className="menu-iv">
          <DropdownICabecera mouseleave={mouseleave} />
          <hr />
          {/*  <DropdownItem
            leftIcon={<CogIcon />}
            rightIcon={<ChevronIcon />}
            goToMenu="settings"
          >
            Configucaciones
          </DropdownItem> */}
          {/*  <DropdownItemOnly
            leftIcon={<CogIcon></CogIcon>}
            onclick={() => {
              mouseleave()
              history.push("/")
            }}
          >
            Salir
          </DropdownItemOnly> */}
         {/*  <DropdownItemOnly
            leftIcon={<CogIcon></CogIcon>}
            onclick={() => {
              history("/configuraciones/inicio");
            }}
          >
            Configuraciones
          </DropdownItemOnly> */}
          <DropdownItemOnly
            leftIcon={<ExitIcon></ExitIcon>}
            onclick={() => {
              localStorage.removeItem("selectVet");
              window.location = "/";
            }}
          >
            cerrar sesión establecimiento
          </DropdownItemOnly>
          <DropdownItemOnly
            leftIcon={<ExitIcon></ExitIcon>}
            onclick={() => {
              localStorage.clear();
              window.location = "/";
            }}
          >
            Salir
          </DropdownItemOnly>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "settings"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu-iv">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h4>Configuraciones</h4>
          </DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>HTML</DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>CSS</DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>JavaScript</DropdownItem>
          <DropdownItem leftIcon={<BoltIcon />}>Awesome!</DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "animals"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu-iv">
          <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
            <h2>Animals</h2>
          </DropdownItem>
          <DropdownItem leftIcon="🦘">Kangaroo</DropdownItem>
          <DropdownItem leftIcon="🐸">Frog</DropdownItem>
          <DropdownItem leftIcon="🦋">Horse?</DropdownItem>
          <DropdownItem leftIcon="🦔">Hedgehog</DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}

export default NavApp;
