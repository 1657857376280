import React, { useContext, useState } from "react";
import TablaFacturas from "./listadoFacturas";
import AuthContext from "../../../../Context/auth/authContext";
import moment from "moment";

const DocumentosFacturas = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;
  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];
  
  return (
    <div className={"col-md-12 m-0 p-0 "}>
      {!props.ocultar ? (
        <div className="row">
          <div
            className="d-flex justify-content-start bgfondo align-items-center"
            style={{ height: "60px" }}
          >
            <h5 className="titulosubmenusize">PANEL FACTURAS</h5>
          </div>
        </div>
      ) : null}
      <div className={!props.ocultar ? "w-100  px-2 py-2" : " w-100 "}>
        <TablaFacturas
          empresa_id={veterinaria_id}
          xkey={123}
          xpassword={555}
          ocultar={props.ocultar}
          codigo_documento={"01"}
        ></TablaFacturas>
      </div>
    </div>
  );
};

export default DocumentosFacturas;
