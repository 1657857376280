import Axios from "../../config/AxiosContable";

export const guardarMedidaApi = async (state) => {
  try {
    const res = await Axios.post("/createMedidas", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getMedidasEmpresaApi = async (empresa_id) => {
  try {
    const res = await Axios.get("/getMedidasEmpresa/" + empresa_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const editarMedidasApi = async (state) => {
  try {
    const res = await Axios.post("/editMedidas", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const eliminarMedidasEmpresaApi = async (categoria_id) => {
  try {
    const res = await Axios.get("/deleteMedidas/" + categoria_id);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};
