import React, { useEffect, useState, useContext } from "react";
import Axios from "../../../../config/Axios";
import momment from "moment";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import Swal from "sweetalert2";
import AuthContext from "../../../../Context/auth/authContext";
import CustomOverlay from "../../../extras/customOverlay";
import PacienteContext from "../../../../Context/pacientes/pacientesContext";
import ExamenFisico from "./examenFisico";
import ExamenComplementario from "./examenesComplementarios";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as RefreshIcon } from "../../../../icons/refresh.svg";
import { ReactComponent as CreateFolderIcon } from "../../../../icons/createfolder.svg";
import { toast } from "react-toastify";

const HistoriasMedicas = (props) => {
  const PacientesContext = useContext(PacienteContext);
  const { paciente } = PacientesContext;

  const authContext = useContext(AuthContext);
  const { sessiondatacli, color, textColor, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [state, setState] = useState({
    itemsFichasMedicas: [],
    todos: [],
    currentPage: 1,
    todosPerPage: 1,
    search: "",
  });

  const [stateLoading, setStateLoading] = useState({
    loadingCrearFicha: false,
  });

  const { todos, currentPage, todosPerPage } = state;

  const [stateFichasMedicas, setstateFichasMedicas] = useState({
    registro_ficha_id: "",
    observacion: "",
    tratamiento: "",
    mascota_id: parseInt(props.mascota),
    veterinaria_id: sessiondatacli[index].veterinaria[0].veterinaria_id,
  });

  const [stateModal, setStateModal] = useState({
    ConfirmacionModal: false,
    dataProp: {},
    fichaMedicaModal: false,
    crearfichaMedicaModal: false,
    modalAction: 0,
  });

  const onchange = (e) => {
    e.preventDefault();
    setstateFichasMedicas({
      ...stateFichasMedicas,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    inicializarLastFichaMedica();
  }, []);

  const crearfichaMedicaModal = async (e) => {
    setStateLoading({
      ...stateLoading,
      loadingCrearFicha: false,
    });
    try {
      const result = await Axios.post("/createFichaMedica", stateFichasMedicas);
      if (result.data.sms === "ok") {
        inicializarLastFichaMedica()
        toast.success(`${result.data.mensaje}!`, {
          position: "bottom-right",
          theme: "colored",
        });
        setStateModal({
          ...stateModal,
          ConfirmacionModal: false,
          modalAction: 0,
        });
        setStateLoading({
          ...stateLoading,
          loadingCrearFicha: false,
        });
      } else {
        toast.error(`${result.data.mensaje}!`, {
          position: "bottom-right",
          theme: "colored",
        });
        setStateLoading({
          ...stateLoading,
          loadingCrearFicha: false,
        });
      }
    } catch (e) {}
  };

  const ModificarFichaMedica = async () => {
    try {
      const result = await Axios.post("/editarFichaMedica", stateFichasMedicas);
      if (result.data.sms === "ok") {
        alert("Modificado");
        inicializarLastFichaMedica()
      } else {
        alert("no se pudo modificar");
      }
    } catch (e) {}
  };

  const FinalizarFichaMedica = async () => {
    try {
      const result = await Axios.post(
        "/finalizarFichaMedica",
        stateFichasMedicas
      );
      if (result.data.sms === "ok") {
        alert("Modificado");
      } else {
        alert("no se pudo modificar");
      }
    } catch (e) {}
  };

  const ConfirmarEliminarVacuna = async (e, todo) => {
    e.preventDefault();
    setStateModal({
      ...stateModal,
      ConfirmacionModal: true,
      dataProp: todo,
    });
  };

  const eliminarFichaMedica = async (e) => {
    setStateLoading({
      ...stateLoading,
      loadingCrearFicha: true,
    });
    try {
      const result = await Axios.post("/eliminarFichaMedica", {
        registro_ficha_id: stateModal.dataProp.registro_ficha_id,
      });
      if (result.data.sms === "ok") {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
        setStateModal({
          ...stateModal,
          ConfirmacionModal: false,
          dataProp: {},
        });
        setStateLoading({
          ...stateLoading,
          loadingCrearFicha: false,
        });
        inicializarLastFichaMedica();
      } else {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
      }
    } catch (e) {}
  };

  const handleClick = (event) => {
    setState({
      ...state,
      currentPage: Number(event.target.id),
    });
  };

  // Logic for displaying current todos
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

  const renderTodos = currentTodos.map((todo, index) => {
    return (
      <tr key={index}>
        <td>{todo.serie}</td>
        <td>
          {" "}
          {momment(todo.fecha_creacion).format("YYYY-MM-DD")}
        </td>
        <td>
          {todo.estado === 0 ? (
            <span class="badge rounded-pill bg-pimary">PENDIENTE</span>
          ) : todo.estado === 1 ? (
            <span class="badge rounded-pill bg-secondary">FINALIZADA</span>
          ) : null}
        </td>
        <td>
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={(e) => {
              setStateModal({
                ...stateModal,
                fichaMedicaModal: true,
                dataProp: todo,
              });

              setstateFichasMedicas({
                ...stateFichasMedicas,
                observacion: todo.observacion,
                tratamiento: todo.tratamiento,
                registro_ficha_id: todo.registro_ficha_id,
              });
              // BuscarDataPaciente(props.mascota)
            }}
          >
            <li className="fa fa-eye"></li>
          </button>
          {todo.estado === 0 && veterinaria_id===todo.veterinaria_id? (
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={(e) => ConfirmarEliminarVacuna(e, todo)}
            >
              <li className="fa fa-trash"></li>
            </button>
          ) : null}
        </td>
      </tr>
    );
  });

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        style={{ border: "1px solid", padding: "5px" }}
      >
        {number}
      </li>
    );
  });

   const inicializarLastFichaMedica = async (e) => {
     try {
       const respuesta = await Axios.get(
         `/getLastFichaMedica/${props.mascota}`
       );
       console.log(respuesta.data);

       if (respuesta.data.sms === "ok") {
         setState({
           ...state,
           todos: respuesta.data.result,
           // todos: result.data.result,
           currentPage: 1,
         });
       } else {
       }
     } catch (e) {}
   };

  const inicializarTodasItems = async (e) => {
    try {
      const respuesta = await Axios.get(`/getAllFichaMedicas/${props.mascota}`);
      console.log(respuesta.data);

      if (respuesta.data.sms === "ok") {
        setState({
          ...state,
          todos: respuesta.data.result,
          // todos: result.data.result,
          currentPage: 1,
        });
      } else {
      }
    } catch (e) {}
  };

  const sexoMascota = (sexo) => {
    return sexo === 1 ? (
      <div className="h5">
        <span className="text-primary">SEXO:</span> MACHO{" "}
        <i className="fa fa-mars" aria-hidden="true"></i>
      </div>
    ) : sexo === 2 ? (
      <div className="h5">
        <span className="text-primary">SEXO:</span> HEMBRA{" "}
        <i className="fa fa-venus" aria-hidden="true"></i>
      </div>
    ) : (
      <div className="h5">
        <span className="text-primary">SEXO:</span> OTRO{" "}
        <i className="fas fa-neuter" aria-hidden="true"></i>
      </div>
    );
  };

  const PacientePerfil = (paciente) => {
    return (
      <div>
        <div className="row m-5">
          <div className="col-sm w-100">
            <div className="row d-flex justify-content-center align-items-center align-self-start">
              <div className="col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-start align-self-start">
                <div className="h5">
                  <span className="text-primary">NOMBRE:</span>{" "}
                  {paciente[0].nombres}{" "}
                </div>
                <div className="h5">
                  <span className="text-primary">ESPECIE:</span>{" "}
                  {paciente[0].especie}
                </div>
                <div className="h5">
                  <span className="text-primary">RAZA:</span> {paciente[0].raza}
                </div>
                <div className="h5">
                  <span className="text-primary">CHIP:</span> {paciente[0].chip}
                </div>
                <div className="h5">
                  <span className="text-primary">CASTRADO:</span>{" "}
                  {paciente[0].castrado === 1 ? (
                    <span
                      className="bg-primary text-light"
                      style={{
                        padding: "5px 10px 5px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      SI
                    </span>
                  ) : (
                    <span
                      className="bg-secondary"
                      style={{
                        padding: "5px 10px 5px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      NO
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-start align-self-start">
                <div className="h5">
                  <span className="text-primary">FECHA DE NACIMIENTO:</span>{" "}
                  {paciente[0].fec_naci}
                </div>
                <div className="h5">
                  <span className="text-primary">EDAD:</span>{" "}
                  {Edad(paciente[0].fec_naci)}
                </div>
                <div className="h5">
                  <span className="text-primary">PELAJE:</span>{" "}
                  {paciente[0].pelaje}
                </div>
                {sexoMascota(paciente[0].sexo_id)}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row m-5">
          <div className="col-md-12">
            {datosDuenio(paciente[0].tieneduenio)}
          </div>
        </div> */}
      </div>
    );
  };

  const Edad = (data) => {
    let fecha = data;
    var nacimiento = new Date();
    var hoy = new Date(fecha);

    nacimiento.setMonth();
    nacimiento.setDate();
    nacimiento.setHours();

    var tiempo = new Date() - hoy;

    var anios = Math.floor(tiempo / (1000 * 60 * 60 * 24 * 365));
    tiempo = tiempo - anios * (1000 * 60 * 60 * 24 * 365);

    var meses = Math.floor(tiempo / (1000 * 60 * 60 * 24 * 30));
    tiempo = tiempo - meses * (1000 * 60 * 60 * 24 * 30);

    var dias = Math.floor(tiempo / (1000 * 60 * 60 * 31));
    tiempo = tiempo - dias * (1000 * 60 * 60 * 31);

    //calcula horas
    //var horas = Math.floor(tiempo /(1000*60*60*24))
    //tiempo = tiempo - (horas * (1000*60*60*24))

    //var minutos = Math.floor(tiempo /(1000*60*60))
    //tiempo = tiempo - (minutos * (1000*60*60))

    var edadactual = `${anios} años ,  ${meses} meses , ${dias} dias `;
    return edadactual;
    /*setState({
            ...state,
            edadactual: `${anios} años ,  ${meses} meses , ${dias} dias `
        })*/
    //console.log(`Han pasado ${dias} dias ${horas} horas y ${minutos} minutis desde que naciste ${meses} meses  ${anios} años`)
  };

  return (
    <>
      <div className="col-md-12  ">
        <div className="">
          <CustomOverlay mascota={props.mascota} />

          <div className="row">
            <div className="card-body col-md-12 p-0 mb-2 card">
              <div className="row py-2">
                <div className="col-md-12 py-2">
                  <span className="text-dark px-2 titulosSize floatizquierda">
                    Última ficha médica
                  </span>

                  <button
                    style={{ float: "right", background: "white" }}
                    className=" btn btn-default p-0 mx-1"
                    onClick={(e) => {
                      inicializarLastFichaMedica();
                    }}
                  >
                    <RefreshIcon width={26} />
                  </button>
                  <button
                    style={{ float: "right", background: "white" }}
                    className=" btn btn-default p-0 mx-1"
                    onClick={(e) => {
                      setStateModal({
                        ...stateModal,
                        ConfirmacionModal: true,
                        modalAction: 1,
                      });
                    }}
                  >
                    <CreateFolderIcon width={30} />
                  </button>
                </div>
              </div>

              <div
                className="col-md-12 col-sm-12 "
                style={{ overflow: "auto", maxHeight: "285px" }}
              >
                <Table>
                  <thead
                    className="w-100"
                    style={{
                      background: color,
                      textAlign: "center",
                      // maxHeight: "240px",
                    }}
                  >
                    <tr>
                      <th style={{ color: textColor }}>SERIE</th>
                      <th style={{ color: textColor }}>FECHA DE CREACIÓN</th>
                      <th style={{ color: textColor }}>ESTADO</th>
                      <th style={{ color: textColor }}>OPCIONES</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      padding: "12px",
                      textAlign: "center",
                      overflowX: "scroll",
                    }}
                  >
                    {renderTodos}
                  </tbody>
                </Table>
                {state.todos.length > 0 ? null : (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <label
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      No existen registros
                    </label>
                  </div>
                )}
              </div>
              {/* FIN TABLA DE USAURIOS*/}
            </div>
          </div>

          <div className="d-flex row">
            <div className="card-body col-md-6  col-md-12 p-0 my-2 card">
              <div className="row py-2">
                <div className="col-md-12 py-2">
                  <span className="text-dark px-2 titulosSize floatizquierda">
                    Última observación
                  </span>
                </div>
              </div>

              <div className="col-md-12 col-sm-12 ">
                {state.todos.length > 0 ? (
                  state.todos[0].observacion !== null ? (
                    <div className="d-flex justify-content-start align-items-center p-4">
                      <label>{state.todos[0].observacion}</label>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center p-5">
                      <label>No hay obsercaciones</label>
                    </div>
                  )
                ) : (
                  <div className="d-flex justify-content-center align-items-center p-5">
                    <label>No hay obsercaciones</label>
                  </div>
                )}
              </div>
              {/* FIN TABLA DE USAURIOS*/}
            </div>
            <div className="card-body col-md-6  col-md-12 p-0 my-2 card">
              <div className="row py-2">
                <div className="col-md-12 py-2">
                  <span className="text-dark px-2 titulosSize floatizquierda">
                    Último tratamiento
                  </span>
                </div>
              </div>

              <div
                className="col-md-12 col-sm-12 "
                style={{ overflow: "auto", maxHeight: "285px" }}
              >
                {state.todos.length > 0 ? (
                  state.todos[0].tratamiento !== null ? (
                    <div className="d-flex justify-content-start align-items-center p-4">
                      <label>{state.todos[0].tratamiento}</label>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center p-5">
                      <label>No hay tratamientos</label>
                    </div>
                  )
                ) : (
                  <div className="d-flex justify-content-center align-items-center p-5">
                    <label>No hay tratamientos</label>
                  </div>
                )}
              </div>
              {/* FIN TABLA DE USAURIOS*/}
            </div>
          </div>
        </div>
      </div>

      {/* MODAL FICHA MEDICA*/}
      <Modal
        isOpen={stateModal.fichaMedicaModal}
        toggle={() => {
          /*  setStateModal({
            ...stateModal,
            fichaMedicaModal: false,
          }); */
        }}
        style={{
          maxWidth: "70%",
          height: "100VH",
        }}
      >
        <ModalBody>
          <fieldset className="fieldset borde-tarjeta ">
            {/*  <legend className="legend borde-tarjeta">
              INFROMACIÓN MASCOTA
            </legend> */}
            <div className=" row  m-1 ">
              <div className="col-md-12 d-flex justify-content-between align-items-center">
                <div className="d-flex w-50 flex-row justify-content-between pt-3">
                  <h3>SERIE : {stateModal.dataProp.serie} </h3>
                  <h3>
                    FECHA :{" "}
                    {momment(stateModal.dataProp.fecha_creacion).format(
                      "YYYY-MM-DD"
                    )}
                  </h3>
                </div>
                <div>
                  <button
                    className="btn btn-danger mx-1 p-2"
                    style={{
                      fontSize: "18px",
                      border: "none",
                      minWidth: "100px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setStateModal({
                        ...stateModal,
                        fichaMedicaModal: false,
                        dataProp: {},
                      });
                      setstateFichasMedicas({
                        ...stateFichasMedicas,
                        registro_ficha_id: "",
                        observacion: "",
                        tratamiento: "",
                      });
                    }}
                  >
                    Cerrar
                  </button>
                  {stateModal.dataProp.estado === 1 || stateModal.dataProp.veterinaria_id!==veterinaria_id? (
                   null
                  ) : (
                    <button
                      className="btn btn-primary mx-1 p-2"
                      style={{
                        fontSize: "18px",
                        border: "none",
                        minWidth: "100px",
                      }}
                      onClick={() => {
                        setStateModal({
                          ...stateModal,
                          ConfirmacionModal: true,
                          modalAction: 2,
                        });
                      }}
                    >
                      Finalizar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
          <br /> <br /> <br />
          <fieldset className="fieldset borde-tarjeta ">
            <legend className="legend borde-tarjeta">
              INFROMACIÓN MASCOTA
            </legend>
            {paciente.length > 0 ? PacientePerfil(paciente) : null}
          </fieldset>
          <br /> <br /> <br />
          <fieldset className="fieldset borde-tarjeta">
            <legend className="legend borde-tarjeta">EXAMEN FÍSICO</legend>
            <br />
            <br />
            <ExamenFisico
              registro_ficha_id={stateModal.dataProp.registro_ficha_id}
              estado={stateModal.dataProp.estado}
              veterinaria_id={veterinaria_id}
              vetregistro={stateModal.dataProp.veterinaria_id}
            />
          </fieldset>
          <br /> <br /> <br />
          <fieldset className="fieldset borde-tarjeta">
            <legend className="legend borde-tarjeta">
              EXAMENES COMPLEMENTARIOS
            </legend>
            <div className="my-4 p-2">
              <ExamenComplementario
                registro_ficha_id={stateModal.dataProp.registro_ficha_id}
                estado={stateModal.dataProp.estado}
                veterinaria_id={veterinaria_id}
                vetregistro={stateModal.dataProp.veterinaria_id}
              />
            </div>
          </fieldset>
          <br /> <br /> <br />
          <fieldset className="fieldset borde-tarjeta ">
            <legend className="legend borde-tarjeta">OBSERVACIONES</legend>
            <div className="form-group py-4">
              <TextareaAutosize
                rows="2"
                className="textareapublicar"
                onChange={(e) => onchange(e)}
                value={stateFichasMedicas.observacion}
                id="observacion"
                name="observacion"
                placeholder={"Observación"}
                style={{
                  height: "70px!important",
                  width: "100%",
                  border: "none",
                }}
              />{" "}
              {stateModal.dataProp.estado === 1 ||
              veterinaria_id !== stateModal.dataProp.veterinaria_id ? null : (
                <button
                  style={{ float: "right" }}
                  className="btn btn-success w-100 mt-3 p-2"
                  onClick={ModificarFichaMedica}
                >
                  Guardar observación
                </button>
              )}
            </div>
          </fieldset>
          <br /> <br /> <br /> <br />
          <fieldset className="fieldset borde-tarjeta">
            <legend className="legend borde-tarjeta">TRATAMIENTO</legend>
            <div className="form-group py-4">
              <TextareaAutosize
                rows="2"
                className="textareapublicar"
                onChange={(e) => onchange(e)}
                value={stateFichasMedicas.tratamiento}
                id="tratamiento"
                name="tratamiento"
                placeholder={"Tratamiento"}
                style={{
                  height: "70px!important",
                  width: "100%",
                  border: "none",
                }}
              />{" "}
              {stateModal.dataProp.estado === 1 ||
              veterinaria_id !== stateModal.dataProp.veterinaria_id ? null : (
                <button
                  style={{ float: "right" }}
                  className="btn btn-success w-100 p-2 mt-3   "
                  onClick={ModificarFichaMedica}
                >
                  Guardar tratamiento
                </button>
              )}
            </div>
          </fieldset>
        </ModalBody>
        {/*<ModalFooter></ModalFooter>*/}
      </Modal>
      {/* FIN MODAL FICHA MEDICA*/}

      {/* MODAL CONFIRMACIÓN*/}
      <Modal
        centered
        isOpen={stateModal.ConfirmacionModal}
        toggle={() => {
          setStateModal({
            ...stateModal,
            ConfirmacionModal: false,
          });
        }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              ConfirmacionModal: false,
              modalAction: 0,
            });
          }}
          style={{ background: "white" }}
        >
          {stateLoading.loadingCrearFicha
            ? "Procesando ..."
            : stateModal.modalAction === 1
            ? "CONFIRMACIÓN"
            : stateModal.modalAction === 2
            ? "CONFIRMACIÓN"
            : "CONFIRMAR ELIMINACIÓN"}
        </ModalHeader>
        <ModalBody className="">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: "center" }}>
                {stateLoading.loadingCrearFicha ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-success" role="status">
                      <span className="sr-only black">Loading...</span>
                    </div>
                  </div>
                ) : stateModal.modalAction === 1 ? (
                  <h4>¿ESTÁ SEGURO DE CREAR LA FICHA ClÍNICA?</h4>
                ) : stateModal.modalAction === 2 ? (
                  <>
                    <h4>¿ESTÁ SEGURO DE FINALIZAR LA FICHA ClÍNICA?</h4>
                    <br />
                    <h4>
                      RECUERDE QUE UNA VEZ FINALIZADA NO SE PODRRÁ
                      MODIFICAR,VERIFIQUE QUE TODOOS LOS DATOS SEAN CORRECTOS
                    </h4>
                  </>
                ) : (
                  <h4>¿ESTÁ SEGURO QUE DESEA ELIMINAR ESTA FICHA ClÍNICA?</h4>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="">
                {stateLoading.loadingCrearFicha ? null : stateModal.modalAction ===
                  1 ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginLeft: "5px", float: "right" }}
                    onClick={crearfichaMedicaModal}
                  >
                    Crear
                  </button>
                ) : stateLoading.loadingCrearFicha ? null : stateModal.modalAction ===
                  2 ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginLeft: "5px", float: "right" }}
                    onClick={FinalizarFichaMedica}
                  >
                    Finalziar
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger p-2"
                    style={{ marginLeft: "5px", float: "right" }}
                    onClick={(e) => eliminarFichaMedica(e)}
                  >
                    Eliminar
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-primary p-2"
                  style={{ float: "right" }}
                  onClick={() => {
                    setStateModal({
                      ...stateModal,
                      ConfirmacionModal: false,
                      modalAction: 0,
                    });
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
        {/*<ModalFooter></ModalFooter>*/}
      </Modal>
      {/* FIN MODAL CONFIRMACIÓN PARA ELIMINAR*/}
    </>
  );
};

export default HistoriasMedicas;
