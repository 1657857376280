import Axios from "../config/Axios";
import TokenAuth from "../config/Token";

export const BuscarUsers = async (data) => {
  try {
    const respuesta = await Axios.get("/filtarPersona/" + data);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        sms: "err",
        mensaje: e,
        data: [],
      },
    ];
  }
};
