import React, { useEffect, useState, useContext, useRef } from "react";
import { editarBancoPagosApi, guardarBancosPagosApi } from "../../../api/contable/bancos_pagos";
import AuthContext from "../../../Context/auth/authContext";
import TablaCategoria from "./tabla_bancos_pagos";
import { getBancosEmpresaApi } from "../../../api/contable/bancos";
import { toast } from "react-toastify";
const BancosPagos = (props) => {
  const bancoref = useRef(null);
  const paneleditarcrearRef = useRef(null);
  const tablacategoriasRef = useRef(null);
  const [actualizarLista, setActualizarLista] = useState(false);

  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [state, setState] = useState({
    numero: "",
    banco_id: "",
    banco_pago_id:"",
    empresa_id: veterinaria_id,
  });

  const [listadobancos, setlistadobancos] = useState([]);
  const [statereset, setStateReset] = useState({
    numero: "",
    banco_id: "",
    banco_pago_id: "",
    empresa_id: veterinaria_id,
  });
  const [editState, setEditState] = useState({
    numero: "",
    banco_id: "",
    banco_pago_id:""
  });

  const [respaldoEditField, setRespaldoEditField] = useState("");
  const [editar, setEditar] = useState(false);

  const [buttonState, setButtonState] = useState(false);

  const [load, setLoad] = useState(false);

  const onchange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    //if (editar) ed(e.target.name, e.target.value);
  };

  useEffect(() => {
    getListadoBancos();
  }, []);

  const getListadoBancos = async () => {
    setLoad(true);
    const res = await getBancosEmpresaApi(veterinaria_id);

    if (res[0].sms === "ok") {
      setlistadobancos(res[0].data);
    } else {
      toast.error(`${res[0].mensaje}`);
    }
    setLoad(false);
  };

  const guardarFn = async () => {
    setLoad(true);
    if (state.banco_id === "") {
      toast.error("Seleccione la entidad bancaria ",{
        theme:"colored",
        position:"bottom-left"
      });
      setLoad(false)
      return;
    }
    if(state.numero==="") {
        toast.error("Ingrese el número de cuenta bancaria",{
        theme:"colored",
        position:"bottom-left"
      })
        setLoad(false)
        return 
    }
    const res = await guardarBancosPagosApi(state);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, { position: "bottom-left" });
      setActualizarLista(!actualizarLista);
      setState(statereset);
    } else {
      alert(`${res[0].mensaje}`);
    }
    setLoad(false);
  };

  const habilitarEdicion = (data) => {
    setState(data);
    bancoref.current.focus();
  };
  /* const cancelarEdicion = (e, campoeditar) => {
    e.preventDefault();
    setState(statereset);
    setEditar(false);
    setEditState({
      ...editState,
      campoedit: "",
      nuevovalor: "",
      banco_id: "",
    });
    setRespaldoEditField("");
    document.getElementById(campoeditar).focus();
  }; */

  const editarFn = async (e) => {
    e.preventDefault();
     if (state.banco_id === "") {
       toast.error("Seleccione la entidad bancaria ", {
         theme: "colored",
         position: "bottom-left",
       });
       setLoad(false);
       return;
     }
     if (state.numero === "") {
       toast.error("Ingrese el número de cuenta bancaria", {
         theme: "colored",
         position: "bottom-left",
       });
       setLoad(false);
       return;
     }
    const res = await editarBancoPagosApi(state);
    if (res[0].sms === "ok") {
     // cancelarEdicion(e, editState.campoedit);
     setState(statereset)
     setEditar(false)
      window.scrollTo(0, tablacategoriasRef.current);
      setActualizarLista(!actualizarLista);
    } else if (res[0].ssm === "err") {
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
        position:"bottom-left"
      });
    }
  };

  const editbtn = (campoeditar) => {
    if (editState.campoedit === campoeditar) {
      document.getElementById(campoeditar).disabled = false;
    }

    return editar ? (
      <div className="w-100 ">
        {editState.campoedit !== campoeditar ? (
          <div className="d-flex flex-row justify-content-between">
            <button
              className="bg-btn-vet-danger mx-2 bordesombreado bordecompleto"
              onClick={(e) => {
                e.preventDefault();
                // cancelarEdicion(e, campoeditar);
                setState(statereset)
                setEditar(false)
              }}
            >
              x
            </button>
            <button
              className=" bg-btn-vet  w-100 bordecompleto bordesombreado"
              disabled={editState.nuevovalor === "" ? true : false}
              onClick={editarFn}
            >
              Guardar cambios
            </button>
          </div>
        ) : editState.campoedit === campoeditar ? null : null}
      </div>
    ) : null;
  };
 /*  const ed = (name, valor) => {
    if (editState.campoedit === name) {
      setEditState({
        ...editState,
        nuevovalor: valor,
      });
    }
  }; */

  const editField = (data) => {
    setState({
      ...state,
      banco: data.banco,
    });
  };

  const ejecutaropciones = (data, editar) => {
    if (editar === true) {
      setEditar(true);
      //editField(data);
      habilitarEdicion(data);
      window.scrollTo(0, paneleditarcrearRef.current);
    }
  };

  return (
    <div className="col-md-12 m-0 p-0 ">
      <div className="row  d-flex flex-column ">
        <div className=" ">
          <div className="col-md-12 bgfondo2  py-3 mx-0 mt-3   ">
            <div className="w-100 px-4 d-flex flex-row justify-content-between align-items-center">
              <h6 className="titulosubmenusize">INSTITUCIONES FINANCIERAS</h6>
              {/* <button
                onClick={() => {
                  //setModalcaja(true);
                }}
                type=""
                className="bg-btn-vet bordesombreado2"
              >
                <i className="fas fa-plus mx-2"></i> Nueva caja
              </button> */}
            </div>
          </div>
        </div>
        <div ref={paneleditarcrearRef} className="my-3 row">
          <div className="col-md-4 px-0 ">
            <div className=" row">
              <label className="">Listado de bancos :</label>
              <div className={"col-md-12 "}>
                <select
                  className="inputselect bordecompleto bordesombreado2"
                  id="banco_id"
                  name="banco_id"
                  style={{ width: "100%" }}
                  onChange={onchange}
                  value={state.banco_id}
                >
                  <option value="">Seleccione un banco...</option>
                  {listadobancos.map((data, index) => (
                    <option value={data.banco_id} key={index}>
                      {data.banco}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-8 p-0">
            <div className="row">
              <label className="" htmlFor="exampleInputEmail1">
                Número de cuenta bancaria* :
              </label>
              <div className="col-md-9">
                {" "}
                <input
                  ref={bancoref}
                  type="text"
                  className="inputtext2  w-100 bordecompleto bordesombreado2"
                  onChange={onchange}
                  value={state.numero}
                  id="numero"
                  name="numero"
                  placeholder="Ingrese el número de cuenta bancaria "
                />
              </div>
              <div className="col-md-3 d-flex d-flex justify-content-center align-items-center ">
                {" "}
                {editar ? (
                  editbtn("banco")
                ) : (
                  <button
                    className=" bg-btn-vet w-100 bordesombreado bordecompleto"
                    onClick={() => guardarFn()}
                  >
                    <i className="fas fa-save px-2"></i>
                    Agregar cuenta
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div ref={tablacategoriasRef}>
          <TablaCategoria
            veterinaria_id={veterinaria_id}
            ejecutarOpciones={ejecutaropciones}
            actualizarLista={actualizarLista}
          ></TablaCategoria>
        </div>
      </div>
    </div>
  );
};

export default BancosPagos;
