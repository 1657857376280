import React, { useEffect, useState, useContext } from "react";
import Axios from "../../../../config/Axios";
import toastr from "toastr";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import Swal from "sweetalert2";
import AuthContext from "../../../../Context/auth/authContext";
import CustomOverlay from "../../../extras/customOverlay";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import StilysedInput from "../../../extras/stylisedInput";
import ModalDefault from "../../../Modal/modalDefault";
import { ReactComponent as RefreshIcon } from "../../../../icons/refresh.svg";
import { ReactComponent as TimerIcon } from "../../../../icons/timer.svg";
import { toast } from "react-toastify";
const Vacunas = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, color, textColor, index } = authContext;

  const {
    nombre,
    direccion,
    latitud,
    longitud,
    atencion,
    logo,
    ruc,
    portada,
    provincia_id,
    ciudad_id,
    codigo,
    veterinaria_id,
  } = sessiondatacli[index].veterinaria[0];

  const [programarVacunas, setProgramarVacunas] = useState(false);

  const [state, setState] = useState({
    itemsVacunas: [],
    todos: [],
    currentPage: 1,
    todosPerPage: 2,
    search: "",
  });

  const { todos, currentPage, todosPerPage } = state;

  const [stateVacunas, setstateVacunas] = useState({
    vacuna_id: "",
    fecha_aplicacion: "",
    fecha_programada: "",
    estado: "",
    item_id: null,
    mascota_id: props.mascota,
    veterinaria_id: sessiondatacli[index].veterinaria[0].veterinaria_id,
    usuario_id: sessiondatacli[index].persona[0].usuario_id,
    foto: "",
    nombre: "",
    dosis: "",
  });

  const [stateModal, setStateModal] = useState({
    UsuarioModal: false,
    EliminarModal: false,
    EliminarModal2: false,
    userdata: {},
    configModal: false,
    vacuna_id: "",
    foto: null,
    vacunaAnticipada: 0,
    ConfigurarVacunaModal: false,
  });

  const onchangeVacunas = (e) => {
    e.preventDefault();
    setstateVacunas({
      ...stateVacunas,
      [e.target.name]: e.target.value,
    });
  };

  const [img, setCom] = useState({
    imagenes: [],
    todosArchivos: [],
    reader: "",
  });

  const closeActionModal = (data) => {
    setProgramarVacunas(data);
  };

  const ProgramarVacunaFn = () => {
    return (
      <div className="d-flex flex-column justify-content-center">
        <div className="w-100">
          <div className="form-group">
            <label>FECHA DE APLICACIÓN DEL LA VACUNA* :</label>
            <input
              type="date"
              placeholder="Ingrese fecha de VACUNA"
              className="form-control"
              id="fecha_programada"
              name="fecha_programada"
              onChange={onchangeVacunas}
              value={stateVacunas.fecha_programada}
            />
          </div>
        </div>
        <hr />
        <div className="w-100">
          <button
            type="button"
            className="btn btn-success w-100 h-100"
            onClick={CrearVacunas}
          >
            <i class="fas fa-clock"></i>Programar Vacuna
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    inicializarTodasVacunas();
  }, []);

  const Input = () => {
    return (
      <label
        style={{
          backgroundColor: "none",
          color: "#fff",
          cursor: "pointer",
          padding: 5,
          borderRadius: 3,
          margin: "0px",
        }}
      >
        <input
          style={{ display: "none" }}
          type="file"
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              let aux = [];
              let reader = URL.createObjectURL(chosenFiles[0]);
              let tipodearchivo = chosenFiles[0].type.substring(
                0,
                chosenFiles[0].type.indexOf("/")
              );
              let todo = {
                imagen: chosenFiles[0],
                reader: reader,
                type: tipodearchivo,
              };
              aux.push(todo);
              setCom({
                ...img,
                imagenes: aux,
              });
            });
          }}
          accept="image/jpeg,image/gif,image/png,video/*"
        />
        <i
          className="fas fa-image"
          style={{ fontSize: "30px", color: "blue" }}
        ></i>
      </label>
    );
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const CrearVacunas = async (e) => {
    e.preventDefault();
    let newDate = new Date();
    let date2 = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let date = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
      date2 < 10 ? `0${date2}` : `${date2}`
    }`;
    if (
      stateVacunas.fecha_programada === "" ||
      stateVacunas.fecha_programada < date
    ) {
      toast.error("Ingrese una fecha correcta", {
        position: "top-right",
      });
    } else {
      try {
        const result = await Axios.post("/createVacunas", stateVacunas);
        console.log(result)
        if (result.data.sms === "ok") {
         alert(result.data.mensaje);
          setstateVacunas({
            ...stateVacunas,
            vacuna_id: "",
            fecha_aplicacion: "",
            fecha_programada: "",
            estado: "",
            item_id: "",
          });

          setStateModal({
            ...stateModal,
            UsuarioModal: false,
          });

          inicializarTodasVacunas();
          setProgramarVacunas(false);
        } else {
          alert(result.data.mensaje);
        }
      } catch (e) {
        alert(e);
      }
    }
  };

  const ConfirmarVacunaAnticipada = async (e, todo) => {
    e.preventDefault();

    setstateVacunas({
      ...stateVacunas,
      vacuna_id: todo.vacuna_id,
      estado: todo.estado,
      foto: todo.foto,
    });

    let newDate = new Date();
    let date2 = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let date = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
      date2 < 10 ? `0${date2}` : `${date2}`
    }`;

    if (todo.estado === 1) {
      alert("Vacuna ya aplicada");
    } else if (date < todo.fecha_programada) {
      setStateModal({
        ...stateModal,
        EliminarModal: true,
        vacunaAnticipada: 1,
      });
    } else {
      setStateModal({
        ...stateModal,
        EliminarModal: true,
        vacunaAnticipada: 2,
      });
    }
  };

  

  const vacunaAplicada = async () => {
    try {
      if (stateVacunas.nombre === "") {
        toast.error("Se debe ingresar el nombre de la vacuna");
       } else {
        const formdata = new FormData();
        formdata.append("file", img.imagenes[0].imagen);
        formdata.append("vacuna_id", stateVacunas.vacuna_id);
        formdata.append("nombre", stateVacunas.nombre);

        const result = await Axios.post("/aplicarVacunas", formdata);
        if (result.data.sms === "ok") {
          toast(result.data.mensaje);
          setstateVacunas({
            ...stateVacunas,
            vacuna_id: "",
            fecha_aplicacion: "",
            fecha_programada: "",
            estado: "",
            item_id: "",
          });

          setStateModal({
            ...stateModal,
            EliminarModal: false,
            vacunaAnticipada: 0,
          });

          inicializarTodasVacunas();
        } else {
          toast(result.data.mensaje);
        }
      }
    } catch (e) {}
  };

  const ConfirmarEliminarVacuna = async (e, todo) => {
    e.preventDefault();
    console.log(todo.foto);

    setStateModal({
      ...stateModal,
      EliminarModal2: true,
      vacuna_id: todo.vacuna_id,
      foto: todo.foto,
    });
  };

  const EliminarVacunas = async (e, todo) => {
    try {
      const result = await Axios.post("/eliminarVacunas", {
        vacuna_id: stateModal.vacuna_id,
        file: stateModal.foto,
      });
      if (result.data.sms === "ok") {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
        setStateModal({
          ...stateModal,
          EliminarModal: false,
        });
        inicializarTodasVacunas();
      } else {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
      }
    } catch (e) {}
  };

  const onchange = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = (event) => {
    setState({
      ...state,
      currentPage: Number(event.target.id),
    });
  };

  // Logic for displaying current todos
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

  const renderTodos = currentTodos.map((todo, index) => {
    return (
      <tr key={index}>
        <td>
          {todo.fecha_programada ? (
            todo.fecha_programada
          ) : (
            <span class="badge rounded-pill bg-secondary">APLICADA</span>
          )}
        </td>
        <td>
          {todo.fecha_aplicacion ? (
            todo.fecha_aplicacion
          ) : (
            <span class="badge rounded-pill bg-warning text-dark">
              NO APLICADA
            </span>
          )}
        </td>
        <td>
          {todo.estado === 1 ? (
            <button
              type="button"
              disabled
              className="btn btn-success btn-sm"
              style={{
                marginRight: "5px",
                background: "green",
                borderColor: "green",
              }}
              onClick={(e) => ConfirmarVacunaAnticipada(e, todo)}
            >
              Aplicada
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-success btn-sm"
                style={{
                  marginRight: "5px",
                  background: "green",
                  borderColor: "green",
                }}
                onClick={(e) => ConfirmarVacunaAnticipada(e, todo)}
              >
                Aplicar
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={(e) => ConfirmarEliminarVacuna(e, todo)}
              >
                <li className="fa fa-trash"></li>
              </button>
            </>
          )}
        </td>
      </tr>
    );
  });

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        style={{ border: "1px solid", padding: "5px" }}
      >
        {number}
      </li>
    );
  });

  const inicializarTodasVacunas = async () => {
    try {
      const respuesta = await Axios.get(
        `/filtrarPorCoincidenciasVacunas/` + props.mascota
      );
      if (respuesta.data.sms === "ok") {
        setState({
          ...state,
          todos: respuesta.data.result,
          currentPage: 1,
        });
      } else {
      }
    } catch (e) {}
  };

  const updateStateItems = (data) => {
    setState({
      ...state,
      itemsVacunas: data,
    });
  };

  return (
    <>
      <div className=" row bordecircular  card ">
        <CustomOverlay mascota={props.mascota} />
        <div className="card-body p-0 mb-2">
          <div className="row py-2">
            <div className="col-md-12 ">
              <span className="text-dark px-3 titulosSize floatizquierda">
                Vacunas{" "}
              </span>

              <button
                style={{ float: "right", background: "white" }}
                className=" btn btn-default p-0 mx-1"
                onClick={(e) => {
                  e.preventDefault();
                  inicializarTodasVacunas();
                }}
              >
                <RefreshIcon width={26} />
              </button>

              <button
                style={{ float: "right", background: "white" }}
                className=" btn btn-default p-0 mx-1"
                onClick={(e) => {
                  e.preventDefault();
                  setProgramarVacunas(true);
                }}
              >
                <TimerIcon width={26} />
              </button>
            </div>
          </div>
          <div className="">
            <div className="row ">
              <div className="col-md-12 py-1">
                {/* TABLA DE USUARIOS */}
                <div
                  className=" "
                  style={{ overflow: "auto", maxHeight: "134px" }}
                >
                  <Table>
                    <thead
                      className=""
                      style={{ background: color, textAlign: "center" }}
                    >
                      <tr>
                        <th style={{ color: textColor }}>PROGRAMADA</th>
                        <th style={{ color: textColor }}>APLICADA</th>
                        <th style={{ color: textColor }}>OPCIONES</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>{renderTodos}</tbody>
                  </Table>
                  {state.todos.length > 0 ? null : (
                    <div
                      style={{
                        width: "100%",

                        textAlign: "center",
                      }}
                    >
                      <label
                        style={{
                          width: "100%",

                          textAlign: "center",
                        }}
                      >
                        Sin vacunas
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* FIN TABLA DE USAURIOS*/}
          </div>
        </div>
      </div>
      <Modal
        isOpen={stateModal.EliminarModal2}
        toggle={() => {
          setStateModal({
            ...stateModal,
            EliminarModal2: false,
          });
          setstateVacunas({
            ...stateVacunas,
            foto: {},
          });
          setCom({
            ...img,
            imagenes: [],
          });
        }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              EliminarModal2: false,
            });
          }}
          style={{ background: "white" }}
        >
          {"CONFIRMAR ELIMINACIÓN"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {
                  <div>
                    <h4>¿Está seguro que desea eliminar esta información?</h4>

                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ marginLeft: "5px", float: "right" }}
                      onClick={EliminarVacunas}
                    >
                      Eliminar
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </ModalBody>

        {/*<div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Filtrar Vacuna:</label><br></br>
                                        <input type="text" className="form-control" placeholder="Filtre las iniciales de la vacuna y precione enter" aria-label="codigobuscar" aria-describedby="addon-wrapping" onChange={onchange} onKeyUp={(e) => BuscarSearch(e)} id="search" name="search" value={state.search} />
                                    </div>
                                </div>*/}
      </Modal>
      {/* MODAL */}
      <Modal
        isOpen={stateModal.UsuarioModal}
        toggle={() => {
          setStateModal({
            ...stateModal,
            UsuarioModal: false,
          });
        }}
        style={{ maxWidth: "50%", height: "600px", padding: "20px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              UsuarioModal: false,
            });
          }}
          style={{ background: "white" }}
        >
          {state.editarTipoItems ? "ACTUALIZAR VACUNA" : "REGISTRAR VACUNA"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12" style={{ display: "block" }}>
              <div className="form-group">
                <label>SELECCIONE LA VACUNA* :</label>
                <select
                  className="form-control select2bs4"
                  id="item_id"
                  name="item_id"
                  style={{ width: "100%" }}
                  onChange={onchangeVacunas}
                  value={stateVacunas.item_id}
                >
                  <option value="">Seleccione ...</option>
                  {state.itemsVacunas.map((data, index) => (
                    <option value={data.item_id} key={index}>
                      {data.nombre}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>FECHA DE PROGRAMADA* :</label>
                <input
                  type="date"
                  placeholder="Ingrese fecha de VACUNA"
                  className="form-control"
                  id="fecha_aplicacion"
                  name="fecha_aplicacion"
                  onChange={onchangeVacunas}
                  value={stateVacunas.fecha_aplicacion}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="">
                {state.editarTipoItems ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-success"
                      style={{ marginLeft: "5px", float: "right" }}
                    >
                      Guardar cambios
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      style={{ float: "right" }}
                      onClick={() => {
                        setState({ ...state, editarTipoItems: false });
                        setStateModal({ ...stateModal, UsuarioModal: false });
                      }}
                    >
                      Cancelar edicion
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{ float: "right" }}
                  >
                    Nuevo item
                  </button>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        {/*<ModalFooter></ModalFooter>*/}
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL CONFIRMACIÓN*/}
      {/* MODAL CONFIRMACIÓN*/}
      <Modal
        isOpen={stateModal.EliminarModal}
        toggle={() => {
          setStateModal({
            ...stateModal,
            EliminarModal: false,
          });
          setstateVacunas({
            ...stateVacunas,
            foto: {},
          });
          setCom({
            ...img,
            imagenes: [],
          });
        }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              EliminarModal: false,
            });
          }}
          style={{ background: "white" }}
        >
          {stateModal.vacunaAnticipada === 1
            ? "VACUNA ANTICIPADO"
            : stateModal.vacunaAnticipada === 2
            ? "CONFIRMACIÓN"
            : "CONFIRMAR ELIMINACIÓN"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {stateModal.vacunaAnticipada === 1 ? (
                  <>
                    {" "}
                    {img.imagenes.length > 0 ? (
                      <div
                        className="row mx-12 d-flex justify-content-center align-items-center"
                        style={{ border: "0px solid #f0f2f5" }}
                      >
                        <div>
                          {" "}
                          <h5>
                            Se aplicará la atp anticipada a la fecha programada
                          </h5>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <h5 className="text-muted">
                          Agrega la etiqueta de la atp para continuar
                        </h5>
                        <Input></Input>
                      </div>
                    )}
                  </>
                ) : stateModal.vacunaAnticipada === 2 ? (
                  <>
                    {img.imagenes.length > 0 ? (
                      <div
                        className="row mx-12 d-flex justify-content-center align-items-center"
                        style={{ border: "0px solid #f0f2f5" }}
                      >
                        <div>
                          {" "}
                          <h5>
                            Se aplicará la atp , presione aplicar para continuar
                          </h5>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <h5 className="text-muted">
                          Agrega la etiqueta de la atp para continuar
                        </h5>
                        <Input></Input>
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <h4>¿Está seguro que desea eliminar esta información?</h4>

                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ marginLeft: "5px", float: "right" }}
                      onClick={EliminarVacunas}
                    >
                      Eliminar
                    </button>
                  </div>
                )}

                {img.imagenes.length > 0 ? (
                  <>
                    {img.imagenes.map((data, indice) => (
                      <>
                        <div
                          className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3"
                          style={{ position: "relative" }}
                        >
                          {data.type === "image" ? (
                            <img
                              className="img-responsive"
                              style={{ width: "100%", height: "200px" }}
                              src={data.reader}
                              alt
                            />
                          ) : null}
                          <div
                            className=""
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                            }}
                          >
                            <button
                              style={{
                                background: "red",
                                padding: "5px 9px",
                                borderRadius: "20px",
                              }}
                              onClick={(e) => {
                                /*removeItemFromArr(e, indice)*/
                                setCom({
                                  ...img,
                                  imagenes: [],
                                });
                              }}
                            >
                              <i
                                className="fas fa-trash"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                          </div>
                        </div>

                        <StilysedInput
                          stateManager={setstateVacunas}
                          statePropertieName="nombre"
                          labelText="Nombre comercial"
                          onChange={onchangeVacunas}
                          inputType="text"
                        />
                      </>
                    ))}
                    <div className="col-md-12">
                      <div className="">
                        {stateModal.vacunaAnticipada > 0 ? (
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginLeft: "5px", float: "right" }}
                            onClick={vacunaAplicada}
                          >
                            Aplicar
                          </button>
                        ) : null}

                        <button
                          type="button"
                          className="btn btn-default"
                          style={{ float: "right" }}
                          onClick={() => {
                            setStateModal({
                              ...stateModal,
                              EliminarModal: false,
                              vacunaAnticipada: 0,
                            });
                            setstateVacunas({
                              ...stateVacunas,
                              foto: {},
                            });
                            setCom({
                              ...img,
                              imagenes: [],
                            });
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </ModalBody>

        {/*<div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Filtrar Vacuna:</label><br></br>
                                        <input type="text" className="form-control" placeholder="Filtre las iniciales de la vacuna y precione enter" aria-label="codigobuscar" aria-describedby="addon-wrapping" onChange={onchange} onKeyUp={(e) => BuscarSearch(e)} id="search" name="search" value={state.search} />
                                    </div>
                                </div>*/}
      </Modal>

      <ModalDefault
        size="md"
        modal={programarVacunas}
        bandera="excel"
        closeAction={closeActionModal}
        titulo="Programar vacuna"
        icontitulo={<TimerIcon width={30} fill={"blue"} className="mx-2" />}
      >
        <ProgramarVacunaFn></ProgramarVacunaFn>
      </ModalDefault>
    </>
  );
};

export default Vacunas;
