import React, { useEffect, useState, useContext } from 'react'
import { Table } from 'reactstrap';
import UbicacionesContext from '../../../Context/ubicaciones/UbicacionesContext';
import Axios from '../../../config/Axios'
import AuthContext from '../../../Context/auth/authContext'
import Swal from 'sweetalert2'
import './style.css'
//MONCA

const Configuraciones = ({ data, actualizaLista }) => {

    const { persona_id, cedula, nombres, apellidos, telefono, correo, genero, direccion, provincia_id, ciudad_id, usuario_id
    } = data


    const AuthenticationContext = useContext(AuthContext)
    const {
        departamento,
        paneles,
        sessiondata
    } = AuthenticationContext


    const ubicacionesContext = useContext(UbicacionesContext)
    const {
        getCiudadesFn,
        getProvinciasFn,
        provincias,
        ciudades,
    } = ubicacionesContext

    const [stateModal, setStateModal] = useState({
        nuevoUsuarioModal: false
    })

    const [state, setState] = useState({
        todosRolesUsuarios: [],
        currentPage: 1,
        todosPerPage: 5,
        modalinfo: false,
        modaldata: {},
        todosroles: [],
        todosVeteriraniaXCiudad: [],
        search: "",
        tipo: "",
        provincia_id: "",
        ciudad_id: "",
        rol_id: "",
        rol: "",
        verBotonEditarAcceso: "none",
        

    })

    const [stateAccordion, setStateAccordion] = useState({
        accordion1: "collapse",
        accordion2: "collapse",
    })


    const [stateUser, setStateUser] = useState({
        persona_id: persona_id,
        usuario_id: usuario_id,
        cedula: cedula,
        nombres: nombres,
        apellidos: apellidos,
        telefono: telefono,
        correo: correo,
        direccion: direccion,
        provincia_id: provincia_id,
        ciudad_id: ciudad_id,
        genero: genero,
        parroquia: null,
        selected: null,
    })

    const [stateRol, setStateRol] = useState({

        rol_id: "",
        provincia_id: provincia_id,
        ciudad_id: ciudad_id,
        usuario_id: usuario_id,
        panel_id: 2,
        veterinaria_id: sessiondata[0].veterinaria[0].veterinaria_id,
        estado: "",
        rolesUsuarios: []
    })

    const { todosRolesUsuarios, currentPage, todosPerPage } = state;

    const ControlAcordeon = () => {

        if (stateAccordion.accordion1 === "collapse") {
            setStateAccordion({
                ...stateAccordion,
                accordion1: "collapse show",
                accordion2: "collapse"
            })
            iniciarPanelAccess()
        } else {
            setStateAccordion({
                ...stateAccordion,
                accordion1: "collapse"
            })
        }
    }

    const ControlAcordeon2 = () => {

        if (stateAccordion.accordion2 === "collapse") {
            setStateAccordion({
                ...stateAccordion,

                accordion2: "collapse show",
                accordion1: "collapse"
            })
            iniciarPanelEditar()
        } else {
            setStateAccordion({
                ...stateAccordion,
                accordion2: "collapse"
            })
        }
    }



    const handleClick = (event) => {
        setState({
            ...state,
            currentPage: Number(event.target.id)
        });
    }

    const onchange = (e, valor) => {
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]: e.target.value
        })


        if (valor === 1) {
            getCiudadesFn(e.target.value)
        }
    }


    const onchangeRol = (e, valor) => {
        e.preventDefault()
        setStateRol({
            ...stateRol,
            [e.target.name]: e.target.value
        })


        if (valor === 1) {
            getCiudadesFn(e.target.value)
        }
        if (valor === 2) {
            getVeterinariaXciudad(e.target.value)
        }
    }



    const onchangePerson = (e, valor) => {
        e.preventDefault()

        setStateUser({
            ...stateUser,
            [e.target.name]: e.target.value
        })

        if (valor === 1) {
            getCiudadesFn(e.target.value)

        }


    }

    const deleteRolDeUsuario = async (e, data) => {
        e.preventDefault()
        Swal.fire("Está seguro que desea eliminar este registro?", '', 'warning', {
            confirmButtonText: `OK`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (data.llc_movimientosatje_id === "") {
                    alert("Ocurrio un problema actualize el explorador")
                } else {
                    try {
                        const result = await Axios.get(`/deleteRolDeUsuario/${data.llc_movimientosatje_id}`)
                        if (result.data.sms === "ok") {
                            Swal.fire(
                                'Aviso!',
                                `${result.data.mensaje}!`,
                                'success'
                            )
                            //allmovimientosData(props.causa_id)
                        } else {
                            Swal.fire(
                                'Aviso!',
                                `${result.data.mensaje}!`,
                                'error'
                            )
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        })
    }


    const ActivarRolDeUsuario = async (e, data) => {
        e.preventDefault()
        try {
            const result = await Axios.post(`/activarRolUsuario`, { usuariorol_id: data.usuariorol_id, usuario_id: usuario_id })
            if (result.data.sms === "ok") {
                
                Swal.fire(
                    'Aviso!',
                    `${result.data.mensaje}!`,
                    'success',
                )
                cargarRolesAsignadosDeUsuario()

            } else {
                Swal.fire(
                    'Aviso!',
                    `${result.data.mensaje}!`,
                    'error'
                )
            }
        } catch (e) {
            console.log(e)
        }

    }

    const DesactivarRolDeUsuario = async (e, data) => {
        e.preventDefault()
        try {
            const result = await Axios.post(`/desactivarRolUsuario`, { usuariorol_id: data.usuariorol_id })
            if (result.data.sms === "ok") {
                
                Swal.fire(
                    'Aviso!',
                    `${result.data.mensaje}!`,
                    'success',
                )
                cargarRolesAsignadosDeUsuario()

            } else {
                Swal.fire(
                    'Aviso!',
                    `${result.data.mensaje}!`,
                    'error'
                )
            }
        } catch (e) {
            console.log(e)
        }

    }

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = todosRolesUsuarios.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((todo, index) => {
        return (

            <tr key={index}>
                <th>
                    {todo.estado === null || todo.estado === 0 ? <button className="btn  bg-green" onClick={(e) => { ActivarRolDeUsuario(e, todo) }}><li className="fa fa-check"></li></button> :
                        <button className="btn  bg-red" onClick={(e) => { DesactivarRolDeUsuario(e, todo) }}><li className="fa fa-close"></li></button>
                    }
                    <button className="btn  bg-red" onClick={(e) => { }}><li className="fa fa-trash"></li></button>
                </th>
                <td>{todo.rol}</td>
                <td>{todo.panel === 1 ? "ADMINISTRACIÓN GENERAL" : todo.panel === 2 ? "ADMINISTRACIÓN X VETERINARIA" : "SIN ASIGNAR"}</td>
                <th><p style={{ padding: "0px", textAlign: "center" }}>{todo.estado === null || todo.estado === 0 ? "Inactivo" : "Activo"}</p></th>
            </tr>
            
        )
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(todosRolesUsuarios.length / todosPerPage); i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <li
                key={number}
                id={number}
                onClick={handleClick}
                style={{ border: "1px solid", padding: "5px" }}
            >
                {number}
            </li>
        );
    });


    useEffect(() => {

        cargarRolesAsignadosDeUsuario()
    }, [])


    //FUNCION PARA CARGAR LOS DATOS DE LOS TERRENOS


    const allRoles = async () => {
        try {
            const result = await Axios.get(`/allDataRol`)
            console.log(result.data.result)
            setState({
                ...state,
                todosroles: result.data.result

            })
        } catch (e) {
            console.log(e)
        }
    }

    const getVeterinariaXciudad = async (ciudad_id) => {
        console.log(ciudad_id);
        try {
            const result = await Axios.get(`/getVeterinariaXciudad/${ciudad_id}`)
            setState({
                ...state,
                todosVeteriraniaXCiudad: result.data.result

            })
        } catch (e) {
            console.log(e)
        }
    }


    const editarPersona = async () => {
        if (stateUser.cedula === null) {
            alert("la cédula es obligatoria")
        } else if (stateUser.nombres === "") {
            alert("los nombres son oblgatorios")
        } else if (stateUser.apellidos === "") {
            alert("los apellidos son obligatorios")
        } else if (stateUser.telefono === "") {
            alert("se debe ingresa un telefono")
        } else if (stateUser.correo === "") {
            alert("se debe ingresar un correo")
        } else if (stateUser.direccion === "") {
            alert("se debe ingresar una direccion")
        } else if (stateUser.provincia === null || stateUser.provincia === "") {
            alert("debe seleccionar la provincia")
        } else if (stateUser.ciudad === null || stateUser.ciudad === "") {
            alert("se debe ingresar la ciudad")
        } else if (stateUser.genero === null || stateUser.genero === "") {
            alert("se debe seleccionar un genero")
        } else {
            try {
                const result = await Axios.post(`/editarPerson`, stateUser)
                if (result.data.sms === "ok") {
                    Swal.fire(
                        'Aviso!',
                        `${result.data.mensaje}!`,
                        'success'
                    )
                    cargarRolesAsignadosDeUsuario()

                    actualizaLista(result.data.usuario)

                } else {
                    Swal.fire(
                        'Aviso!',
                        `${result.data.mensaje}!`,
                        'error'
                    )
                }
            } catch (e) {
                console.log(e)
            }
        }
    }


    //EJECUTA ESTA FUNCION CUANDO SE PRECIONA "PANEL DE USUARIOS" EN LA OPCION DE CONFIGURACION DE USUARIO
    const iniciarPanelEditar = () => {
        if (provincia_id !== "") {
            if (ciudad_id !== "") {
                getCiudadesFn(provincia_id)
            }
        }

        setStateUser({
            ...stateUser,
            provincia_id: provincia_id,
            ciudad_id: ciudad_id
        })
    }



    const iniciarPanelAccess = () => {
        getCiudadesFn(0)
        allRoles()
    }

    const cargarRolesAsignadosDeUsuario = async () => {
        try {
            const result = await Axios.get(`/getRolesUsuarioVeterinaria/${usuario_id}/${sessiondata[0].veterinaria[0].veterinaria_id}`, stateRol)
            if (result.data.sms === "ok") {
                console.log(result.data.result)
                setState({
                    ...state,
                    todosRolesUsuarios: result.data.result
                }
                )
            } else {
                alert(result.data.mensaje)
            }
        } catch (e) {
            console.log(e)
        }
    }



    const crearRolAccesoUsuario = async () => {
        if (stateRol.rol_id === "") {
            alert("se debe asignar un rol")
        } else if (stateRol.usuario_id === "") {
            alert("debe seleccionar un usuario")
        } else {
            crearRolAccesoUsuarioFuncion()
        }
    }




    const crearRolAccesoUsuarioFuncion = async () => {
        try {
            const result = await Axios.post(`/createRolUsuario`, stateRol)
            console.log(result.data.sms)
            if (result.data.sms === "ok") {
                Swal.fire(
                    'Aviso!',
                    `${result.data.mensaje}!`,
                    'success'
                )

                actualizaLista(result.data.usuario)

            } else {
                Swal.fire(
                    'Aviso!',
                    `${result.data.mensaje}!`,
                    'error'
                )
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (

        <div>


            {/*===================================================================================
                    ROLES DEL USUARIO
                ===================================================================================*/}

            <div id="accordion">
                <div className="" >
                    <div className="card-header-acordion card-header  " style={{ background: "black!important" }} id="headingOne" >
                        <button
                            className="btn btn-link acordionBotonColorText "
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            onClick={ControlAcordeon}
                        >
                            (ADMINISTRAR ROLES DE ACCESO) </button>
                        <button
                            className="btn btn-link acordionBotonColorText"
                            style={{ float: "right", display: `${state.verBotonEditarAcceso}` }}
                            

                        >
                            EDITAR </button>
                    </div>
                    <div id="collapseOne" className={stateAccordion.accordion1 == "collapse" ? "collapse" : "collapse show"} aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body bordesBodyAcordion">

                            <div className="row">
                                <div className="col-md-12" style={{textAlign:"center"}}>
                                    <div className="form-group">
                                        
                                        <label htmlFor="exampleInputEmail1" style={{fontWeight: "bold",fontStyle:"italic"}}>VETERINARIA {sessiondata.length>0?sessiondata[0].veterinaria[0].nombre:null}</label><br></br>
                                    </div>
                                </div>


                                <div className="col-md-6"  >
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">ASIGNACIÓN:</label><br></br>
                                        <label style={{ marginTop: "7px" }} >Se debe escoger el rol para el usuario: </label><br></br>
                                    </div>
                                </div>
                                <div className="col-md-4" >
                                    <div className="form-group">
                                        <label>ROL* :</label>
                                        <select className="inputtext select2bs4" id="rol_id" name="rol_id" style={{ width: '100%' }} onChange={onchangeRol} value={stateRol.rol_id} >
                                            <option value="">Seleccione ...</option>
                                            {

                                                state.todosroles.map((data, index) => (
                                                    <option value={data.rol_id} key={index}>{data.rol}</option>
                                                ))

                                            }
                                        </select>
                                    </div>
                                </div>




                                {departamento !== "DEP. ADMINISTRATIVO" ?
                                    <div className="col-md-2">
                                        <div className=""><br></br>
                                            <button type="button" className="btn btn-success" style={{ float: "right",marginTop:"7px" }} onClick={crearRolAccesoUsuario}>Crear acceso</button>
                                        </div>

                                    </div>
                                    :
                                    null}
                            </div>
                        </div>

                        <div className="card elevation-3" >
                            <div className="card-body" style={{ padding: "0px" }}>
                                <div className="row" style={{ padding: "12px" }} >
                                    <Table>
                                        <thead className="bg-integralvet" style={{ textAlign: "center" }}>
                                            <tr>
                                                <th>ACCIONES</th>
                                                <th>ROL</th>
                                                <th>PANEL</th>
                                                <th>ESTADO</th>


                                            </tr>
                                        </thead>
                                        <tbody style={{ padding: "12px", textAlign: "center" }}  >
                                            {renderTodos}
                                        </tbody>
                                    </Table>
                                    {
                                        state.todosRolesUsuarios.length > 0 ?
                                            null :
                                            <div style={{ width: "100%", padding: "12px", textAlign: "center" }}>
                                                <label style={{ width: "100%", padding: "12px", textAlign: "center" }}>NO SE ASIGNARON ROLES </label>
                                            </div>
                                    }
                                </div>
                            </div>
                            <ul id="page-numbers">
                                {renderPageNumbers}
                            </ul>
                        </div>
                    </div>
                </div>

                {/*===================================================================================
                    DATOS DEL USUARIO 
                ===================================================================================*/}

                <div className="" >
                    <div className="card-header-acordion card-header " style={{ background: "black!important" }} id="headingOne">
                        <button
                            className="btn btn-link acordionBotonColorText "
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                            onClick={ControlAcordeon2}

                        >
                            (EDITAR INFORMACIÓN PERSONAL) </button>

                    </div>
                    <div id="collapseTwo" className={stateAccordion.accordion2 == "collapse" ? "collapse" : "collapse show"} aria-labelledby="headingOne" data-parent="#accordion">

                        <div className="card-body bordesBodyAcordion">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Cédula* :</label><br></br>
                                                <input type="text" className="inputtext" onChange={onchangePerson} value={stateUser.cedula} id="cedula" name="cedula" placeholder="Ingrese un número de cedula" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Nombres* :</label><br></br>
                                                <input type="text" className="inputtext" onChange={onchangePerson} value={stateUser.nombres} id="nombres" name="nombres" placeholder="Ingrese los nombres " />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Apellidos* :</label><br></br>
                                                <input type="text" className="inputtext" onChange={onchangePerson} value={stateUser.apellidos} id="apellidos" name="apellidos" placeholder="Ingrese los apellidos " />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Teléfono* :</label><br></br>
                                                <input type="text" className="inputtext" onChange={onchangePerson} value={stateUser.telefono} id="telefono" name="telefono" placeholder="Ingrese teléfono " />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Correo* :</label><br></br>
                                                <input type="text" className="inputtext" onChange={onchangePerson} value={stateUser.correo} id="correo" name="correo" placeholder="Ingrese el correo" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Genero* :</label><br></br>
                                                <select className="inputtext select2bs4" id="genero" name="genero" style={{ width: '100%' }} onChange={onchangePerson} value={stateUser.genero}>
                                                    <option value="">Seleccione ...</option>

                                                    <option value="1">MASCULINO</option>
                                                    <option value="2">FENENINO</option>
                                                    <option value="3">OTRO</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">dirección* :</label><br></br>
                                                <textarea rows="2" className="inputtext" onChange={onchangePerson} value={stateUser.direccion} id="direccion" name="direccion" placeholder="Ingrese la dirección" />
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Provincia* :</label>
                                                <select className="inputtext select2bs4" id="provincia_id" name="provincia_id" style={{ width: '100%' }} onChange={(e) => onchangePerson(e, 1)} value={stateUser.provincia_id}>
                                                    <option value="">Seleccione ...</option>
                                                    {

                                                        provincias.map((data, index) => (
                                                            <option value={data.provincia_id} key={index}>{data.provincia}</option>
                                                        ))
                                                    }

                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Ciudad* :</label>
                                                <select className="inputtext select2bs4" id="ciudad_id" name="ciudad_id" style={{ width: '100%' }} onChange={(e) => onchangePerson(e, 2)} value={stateUser.ciudad_id}>
                                                    <option value="">Seleccione ...</option>
                                                    {
                                                        ciudades.map((data, index) => (
                                                            <option value={data.ciudad_id} key={index}>{data.ciudad}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        departamento !== "DEP. ADMINISTRATIVO" ?
                                            <div className="col-md-12">
                                                <div className="">
                                                    <button type="button" className="btn btn-success" style={{ float: "right" }} onClick={editarPersona}>Guardar</button>
                                                </div>

                                            </div>
                                            :
                                            null}



                                </div>

                            </div>
                        </div>
                    </div>
                </div>



            </div>


        </div>

    )
}

export default Configuraciones