import React, { useContext, useEffect, useState } from "react";
import Axios from "../../../../config/Axios";
import Swal from "sweetalert2";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Table,
} from "reactstrap";
import PacienteContext from "../../../../Context/pacientes/pacientesContext";
import AuthContext from "../../../../Context/auth/authContext";
import UbicacionesContext from "../../../../Context/ubicaciones/UbicacionesContext";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

const ExamenesComplementarios = (props) => {
  const PacientesContext = useContext(PacienteContext);
  const { mismascotas } = PacientesContext;

  const authContext = useContext(AuthContext);
  const { sessiondatacli,index } = authContext;

  const [state, setState] = useState({
    mascota_id: 0,
    descripcion: "",
    usuario_id: sessiondatacli[index].persona[0].usuario_id,
    veterinaria_id: sessiondatacli[index].veterinaria[0].veterinaria_id,
  });

  const [img, setCom] = useState({
    imagenes: [],
    todosArchivos: [],
    reader: "",
  });

  const [LoadingPorcentange, setLoadingPorcentange] = useState({
    valor: 0,
  });

  const [statepanel, setStatepanel] = useState({
    dispaly: "none",
  });

  const [stateModal, setStateModal] = useState({
    ConfirmacionModal: false,
    dataProp: {},
    archivoModal: false,
    creararchivoModal: false,
    modalAction: 0,
  });

  const Input = () => {
    return (
      <label
        style={{
          backgroundColor: "none",
          color: "#fff",
          cursor: "pointer",
          padding: 5,
          borderRadius: 3,
          margin: "0px",
        }}
      >
        <input
          style={{ display: "none" }}
          type="file"
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              setStatepanel({
                ...statepanel,
                dispaly: "block",
              });
              let aux = [];
              if (chosenFiles && chosenFiles.length > 0) {
                aux = img.imagenes;
                for (let x = 0; x < chosenFiles.length; x++) {
                  let reader = URL.createObjectURL(chosenFiles[x]);
                  let tipodearchivo = chosenFiles[x].type.substring(
                    0,
                    chosenFiles[x].type.indexOf("/")
                  );

                  let extencion = chosenFiles[x].type.substring(
                    chosenFiles[x].type.lastIndexOf("/") + 1
                  );

                  let todo = {
                    imagen: chosenFiles[x],
                    reader: reader,
                    type: tipodearchivo,
                    extType: extencion,
                  };
                  aux.push(todo);
                }
              } else {
                aux = [];
              }

              setCom({
                ...img,
                imagenes: aux,
              });
              //console.log(aux.length)
              aux = [];
            });
          }}
          accept="image/jpeg,image/jpg,image/png,application/pdf*"
        />
        <i
          className="fas fa-upload d-flex align-items-center"
          style={{ fontSize: "30px", color: "blue" }}
        >
          {" "}
          <h5>ADJUNTAR ARCHIVO</h5>
        </i>
      </label>
    );
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const getAllFiles = async () => {
    try {
      const result = await Axios.get(
        "/getAllFilesFicha/" + props.registro_ficha_id
      );
      //console.log(respuesta.data.result[0])
      if (result.data.sms === "ok") {
        const aux = result.data.result;
        if (result.data.result.length > 0) {
          result.data.result.map(async (data, index) => {
            const firmarArchivo = await Axios.post("/downloadStorageFile", {
              file: data.archivo,
            });
            aux[index].urlFotoArchivo = firmarArchivo.data.url;
          });
          setTimeout(function () {
            setCom({
              ...img,
              imagenes: [],
              todosArchivos: aux,
            });
          }, 5000);
        } else {
          setCom({
            ...img,
            imagenes: [],
            todosArchivos: result.data.result,
          });
        }
      } else {
        alert(`${result.data.mensaje}`);
      }
    } catch (e) {}
  };

  const OnSubtmit = async (e) => {
    e.preventDefault();
    try {
      if (img.imagenes.length === 0) {
        alert("No existen archivos");
      } else {
        var cont = 0;
        img.imagenes.map((data, index) => {
          if (
            data.extType === "png" ||
            data.extType === "jpg" ||
            data.extType === "jpeg" ||
            data.extType === "pdf"
          ) {
            cont = 0;
          } else {
            cont = 1;
          }
        });
        if (cont === 0) {
          const formdata = new FormData();
          img.imagenes.forEach((element) => {
            formdata.append("file", element.imagen);
          });
          formdata.append("registro_ficha_id", props.registro_ficha_id);
          const config = {
            onUploadProgress: (progressEvent) => {
              let complete =
                ((progressEvent.loaded / progressEvent.total) * 100) | 0;
              setLoadingPorcentange({
                ...LoadingPorcentange,
                valor: complete,
              });
            },
          };
          const respuesta = await Axios.post(
            "/createExamenComplementario",
            formdata,
            config
          );
          if (respuesta.data.sms === "ok") {
            //alert(`${respuesta.data.mensaje}`)
            Swal.fire("Atencion", `${respuesta.data.mensaje}`, "success");
            //LIMPIO LOS CAMPOS QUE SE LLENARO
            getAllFiles();
            setLoadingPorcentange({
                ...LoadingPorcentange,valor:0
            })
            //document.getElementById("tarchivos").innerHTML = "";
            //document.getElementById("tbrarchivos").innerHTML = "";
          } else {
            //alert(`${respuesta.data.mensaje}`)
          }
        } else {
          Swal.fire(
            "Atencion",
            `Detectamos formatos no permitidos , revisa  los archivos que estas intentando subir !`,
            "error"
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeItemFromArr = (e, indice) => {
    // var i = img.imagenes.indexOf(item);
    e.preventDefault();
    if (indice !== -1) {
      img.imagenes.splice(indice, 1);
      setCom({
        ...img,
        imagenes: img.imagenes,
      });
    }

    if (img.imagenes.length === 0) {
      setStatepanel({
        ...statepanel,
        dispaly: "none",
      });
    }
  };


  const eliminarArchivo=async(data)=>{
      if(data.archivo!==null){
        const eliminarArchivo = await Axios.post("/eliminarArchivoFichaMedica", {
            file: data.archivo,
            examen_complementario_id:data.examen_complementario_id
          });
          if(eliminarArchivo.data.sms==="ok"){
            getAllFiles();
              alert(eliminarArchivo.data.mensaje)
          }
        
      }
  }

  useEffect(() => {
    getAllFiles();
  }, [props]);

  return (
    <>
      <section className="">
        {props.estado === 1 ? null : (
          <>
            <div className="row d-flex align-items-center justify-content-center align-self-center  ">
             {props.estado===1 || props.veterinaria_id!==props.vetregistro?null:( <button
                style={{ minWidth: "180px!important", width: "180px" }}
                className="btn btn-primary p-2"
                onClick={() => {
                  setStateModal({
                    ...stateModal,
                    archivoModal: true,
                  });
                }}
              >
                <i
                  className="fas fa-upload mx-2 "
                  style={{ fontSize: "35px", color: "white" }}
                ></i>
                Subir archivos
              </button>)}
            </div>
            <br />
          </>
        )}
        {img.todosArchivos.length > 0
          ? img.todosArchivos.map((data, indice) => (
              <div
                className="col-lg-2 col-md-2 col-sm-2 col-xs-4 floatizquierda"
                style={{ position: "relative" }}
              >
                {data.formato === "pdf" ? (
                  <>
                    <img
                      className="img-responsive"
                      style={{ width: "100%", height: "150px" }}
                      src={data.urlFotoArchivo}
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="img-responsive"
                      style={{ width: "100%", height: "150px" }}
                      src={data.urlFotoArchivo}
                      alt=""
                    />
                  </>
                )}
                <div
                  className=""
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                  }}
                >
                  {props.estado===1 || props.veterinaria_id!==props.vetregistro?null:<button
                    className="btn btn-danger"
                    style={{
                      padding: "7px 9px",
                      /*  borderRadius: "20px", */
                    }}
                    onClick={(e) => eliminarArchivo(data)}
                  >
                    <i className="fas fa-trash" style={{ color: "white" }}></i>
                  </button>}
                  <a
                    className="btn btn-primary"
                    style={{
                      padding: "7px 9px",
                      /*   borderRadius: "20px", */
                    }}
                    href={data.urlFotoArchivo}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fas fa-download"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
              </div>
            ))
          : null}
      </section>

      {/* MODAL F*/}
      <Modal
        isOpen={stateModal.archivoModal}
        toggle={() => {}}
        style={{ maxWidth: "50%", height: "600px", padding: "20px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              archivoModal: false,
            });
            setCom({
              ...img,
              imagenes: [],
            });
          }}
        >
          SUBIR ARCHIVOS COMPLEMENTARIOS
        </ModalHeader>
        <ModalBody style={{ background: "#f0f2f5" }}>
          <form className="my-0">
            <div className="col-md-12 mb-5">
              <div className="row">
                <>
                  <div className="col-md-12">
                    <div
                      className="row mx-5 d-flex justify-content-center align-items-center"
                      style={{ border: "1px solid #f0f2f5" }}
                    >
                      <Input></Input>
                    </div>
                    <div
                      className="row mx-12 d-flex justify-content-center align-items-center align-self-center"
                      style={{
                        border: "1px solid #f0f2f5",
                        textAlign: "center",
                      }}
                    >
                      <h6>
                        Solo se admiten archivos de imagenes (PNG,JPG,JPEG) y
                        documentos PDF{" "}
                      </h6>
                    </div>
                    <br />
                    <div>
                      {LoadingPorcentange > 0 ? (
                        <label>
                          Subiendo ... ({LoadingPorcentange.valor + "%"})
                        </label>
                      ) : null}

                      <Progress
                        striped
                        color="success"
                        value={LoadingPorcentange.valor}
                      />
                    </div>
                    <div
                      className="row p-5 "
                      style={{
                        textAlign: "center",
                        maxHeight: "250px",
                        overflowY: "scroll",
                        display: statepanel.dispaly,
                      }}
                    >
                      {img.imagenes.map((data, indice) => (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 floatizquierda">
                          {data.extType === "pdf" ? (
                            <>
                              <img
                                className="img-responsive"
                                style={{ width: "100%", height: "200px" }}
                                src="https://w7.pngwing.com/pngs/655/435/png-transparent-pdf-computer-icons-others.png"
                                alt
                              />
                              {data.imagen.name}
                            </>
                          ) : (
                            <>
                              <img
                                className="img-responsive"
                                style={{ width: "100%", height: "200px" }}
                                src={data.reader}
                                alt
                              />
                              {data.imagen.name}
                            </>
                          )}
                          <div
                            className=""
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                            }}
                          >
                            <button
                              style={{
                                background: "red",
                                padding: "5px 9px",
                                borderRadius: "20px",
                              }}
                              onClick={(e) => removeItemFromArr(e, indice)}
                            >
                              <i
                                className="fas fa-trash"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              </div>
            </div>

            {img.imagenes.length > 0 ? (
              <button
                type="button"
                className="btn btn-success"
                style={{
                  float: "right",
                }}
                onClick={(e) => OnSubtmit(e)}
              >
                Publicar
              </button>
            ) : null}
          </form>
        </ModalBody>
      </Modal>
      {/* FIN MODAL*/}
    </>
  );
};

export default ExamenesComplementarios;
