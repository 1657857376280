import React, { useState } from 'react'
import PacientesContext from "./pacientesContext";
import Axios from '../../config/Axios'
const PacientesState = props => {

  const initialState = {
    paciente: [],
    tipoExamenes:[],
    urlFotoMascota:""
  };

  const [state, setState] = useState(initialState);

  const BuscarDataPaciente = async (mascota_id) => {
    try {
      const respuesta = await Axios.get(
        "/CargarDatosDelPaciente/" + mascota_id
      );
      //console.log(respuesta.data.result[0])
      if (respuesta.data.sms === "ok") {
        const firmaFotoMascota = await Axios.post("/downloadStorageFile", {
          file: respuesta.data.result[0].foto,
        });

        console.log(firmaFotoMascota.data.url);
        setState({
          ...state,
          paciente: respuesta.data.result,
          urlFotoMascota: firmaFotoMascota.data.url,
        });
      } else {
        alert(`${respuesta.data.mensaje}`);
      }
    } catch (e) {}
  };



//FUNCION QUE EXTRAE LOS TIPO DE EXAMENES EN EL PANEL REGISTRO MEDICO
  const getAllTipoExamenes = async (mascota_id) => {
    try {
      const respuesta = await Axios.get('/getAllTipoExamenes/')
      //console.log(respuesta.data.result)
      if (respuesta.data.sms === "ok") {
        setState({
          ...state,
          tipoExamenes: respuesta.data.result
        })
      } else {
        alert(`${respuesta.data.mensaje}`)
      }
    } catch (e) {

    }

  }


  

  const limpiarEstado = ( )=>{
    setState({
      ...state,
      paciente:[]
    })
  }


  return (
    <PacientesContext.Provider
      value={{
        paciente: state.paciente,
        tipoExamenes:state.tipoExamenes,
        urlFotoMascota:state.urlFotoMascota,
        BuscarDataPaciente,
        getAllTipoExamenes,
        limpiarEstado
      }}
    >
      {props.children}
    </PacientesContext.Provider>
  );
};

export default PacientesState;
