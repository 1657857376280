export const validarfactura = (data) => {
  
  if (data.fecha_venta === ""){
        return {
          sms: "err",
          mensaje: "Se debe seleccionar la fecha de venta !",
        };
  }
  if (data.comprador.tipo_identificacion === "") {
    return {
      sms: "err",
      mensaje: "Se debe seleccionar el tipo de identificación !",
    };
  }

  if (data.comprador.tipo_identificacion === "07") {
    if (parseFloat(data.valor_total).toFixed(2) > 50) {
      return {
        sms: "err",
        mensaje:
          "La emisión a nombre de CONSUMIDOR FINAL no puede superar los $50",
      };
    }
  }

  if (data.comprador.identificacion === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar una identificación  válida!",
    };
  }
  if (data.comprador.razon_social === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar la razon social del comprador!",
    };
  }
  if (data.comprador.direccion === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar la dirección del comprador!",
    };
  }
  if (data.comprador.correo === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar el correo del comprador!",
    };
  }
  if (data.comprador.telefono === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar el teléfono del comprador!",
    };
  }
  if (data.pagos.length ===0) {
    return {
      sms: "err",
      mensaje: "Se debe ingresar una forma de pago!",
    };
  }

  if(data.pagos.length>0){
     let totalaux = 0;
       for (let index = 0; index < data.pagos.length; index++) {
         totalaux = parseFloat(totalaux) + parseFloat(data.pagos[index].total);
       }
     
       const valida =
         parseFloat(totalaux) === parseFloat(0) ? true : false;
       if(valida)return {
        sms:"err",
        mensaje:"Hay valores pendientes por pagar del total"
       }
     
  }

  if (data.ventas_detalle.length===0) {
    return {
      sms: "err",
      mensaje: "No se ingresó ningún item en la venta !",
    };
  }

  return {
    sms: "ok",
    mensaje: "Validación completa !",
  }; 
};

export const validarRetencion = (data) => {
  if (data.fecha_venta === "") {
    return {
      sms: "err",
      mensaje: "Se debe seleccionar la fecha de la retención !",
    };
  }
  if (data.comprador.tipo_identificacion === "") {
    return {
      sms: "err",
      mensaje: "Se debe seleccionar el tipo de identificación del sujeto retenido !",
    };
  }

  if (data.comprador.tipo_identificacion === "07") {
    if (parseFloat(data.valor_total).toFixed(2) > 50) {
      return {
        sms: "err",
        mensaje:
          "La emisión a nombre de CONSUMIDOR FINAL no puede superar los $50",
      };
    }
  }

  if (data.comprador.identificacion === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar una identificación  válida del sujeto retenido!",
    };
  }
  if (data.comprador.razon_social === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar la razón social del sujeto retenido!",
    };
  }
  if (data.comprador.direccion === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar la dirección del sujeto retenido!",
    };
  }
  if (data.comprador.correo === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar el correo del sujeto retenido!",
    };
  }
  if (data.comprador.telefono === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar el teléfono del sujeto retenido!",
    };
  }
  if (data.docsustento.pagosdocsustento === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar una forma de pago del documento de sustento!",
    };
  }

  if (data.total === "") {
    return {
      sms: "err",
      mensaje: "Se debe ingresar el total del documento de sustento!",
    };
  }

  return {
    sms: "ok",
    mensaje: "Validación completa !",
  };
};

