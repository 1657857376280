import React, { useEffect, useState, useContext } from "react";
import TextareaAutosize from "react-textarea-autosize";
import EmojiPicker from "./EmojiPicker";
import Axios from "../../config/Axios";
const Comentarios = (props) => {
  const { publicacion, usuario_id, totalp,veterinaria, init } = props;

  const [state, setState] = useState({
    comentario: "",
    comenveter_id:veterinaria,
    comenuser_id: usuario_id,
    publicacion_id: publicacion[0].pm_id,
    limit: 3,
    totalp: 0,
  });

  const [statelimit, setStatelimit] = useState({
    limit: 3,
  });

  const [statetodo, setStatetodo] = useState({
    comentarios: [],
  });

  const onchange = (e) => {
    e.preventDefault();

    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const CargarImagenes = ({ imagen }) => {
    return (
      <img
        className="w-40"
        src={
          imagen == null
            ? "https://w7.pngwing.com/pngs/855/945/png-transparent-computer-icons-camera-graphy-camera-rectangle-photography-camera-icon.png"
            : imagen
        }
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          border: `none`,
          borderRadius: "30px",
        }}
      ></img>
    );
  };

  const getComenarios = async () => {
    try {
      const result = await Axios.get(
        "/comentariosData/" +
          publicacion[0].pm_id +
          "/" +
          parseInt(statelimit.limit)
      );
      if (result.data.result.length > 0) {
         const aux = result.data.result
         aux.forEach(async data => {
           if (data.datausuario !== null) {
             const firmaFotoUsuario = await Axios.post("/downloadStorageFile", {
               file: data.datausuario[0].foto,
             });
             const dataUrl=await  toDataURL(firmaFotoUsuario)
                             data.datausuario[0].urlFotoUsuario = dataUrl;

           } else if (data.dataveterinaria !== null) {
             const firmaFotoVeterinaria = await Axios.post(
               "/downloadStorageFile",
               {
                 file: data.dataveterinaria[0].logo,
               }
             );

             data.dataveterinaria[0].urlFotoVeterinaria =
               firmaFotoVeterinaria.data.urls;
           }
           
         });

          setStatetodo({
              ...statetodo,
              comentarios: aux,
            });

        
      } else {
        setStatetodo({
          ...statetodo,
          comentarios: [],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };




   const toDataURL = (url) =>
     fetch(url)
       .then((response) => response.blob())
       .then(
         (blob) =>
           new Promise((resolve, reject) => {
             const reader = new FileReader();
             reader.onloadend = () => resolve(reader.result);
             reader.onerror = reject;
             // reader.readAsDataURL(blob)
           })
       );

  const comentar = async (e) => {
    e.preventDefault();
    try {
      if (state.comentario !== "") {
        const result = await Axios.post("/createComentario", state);
        if (result.data.sms === "ok") {
          setState({
            ...state,
            comentario: "",
          });

          getComenarios();
          init();
        } else {
          setState({
            ...state,
            comentario: "",
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };


  const deleteComenarios = async (e,comentario_id) => {
    e.preventDefault()
    alert(comentario_id)
    try {
      const result = await Axios.get("/deleteComentario/" + comentario_id );
      if(result.data.sms==="ok"){
        getComenarios()
        init();
        alert("Se eliminó el comentario")
      }else{
        alert("No se pudo eliminar el comentario")
      }
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    getComenarios();
  }, [statelimit]);

  return (
    <div className="bg-white m-2">
      <div className=" bg-white">
        <div className="navi navi-spacer-x-0 w-100 d-flex justify-content-center align-items-center">
          <div className="navi-link d-flex align-items-center w-100 p-3 ">
            {/*  <Link
                      to={`/perfilmascota/${data.cod_regis}`}
                      className="navi-item w-100"
                      style={{ textDecoration: "none", color: "black" }}
                    > */}
            <div className="symbol symbol-50 mr-3  ">
              <div
                className="symbol-label"
                style={{ borderRadius: "35px", width: "35px", height: "35px" }}
              >
                <CargarImagenes
                  imagen={localStorage.getItem("ImgUrlAuthcli")}
                ></CargarImagenes>
              </div>
            </div>
            {/* </Link> */}
            <TextareaAutosize
              //rows="2"
              className="textareapublicar bg-fondo"
              onChange={(e) => onchange(e)}
              value={state.comentario}
              id="comentario"
              name="comentario"
              placeholder={"Escribe un comentario ..."}
              style={{
                height: "70px!important",
                fontSize: "12px",
              }}
            />{" "}
            <i
              className="fas fa-paper-plane mx-5"
              style={{ fontSize: "20px" }}
              onClick={(e) => comentar(e)}
            ></i>
          </div>
        </div>
      </div>
      <hr className="my-1 w-100 borderbottom"></hr>

      {statetodo.comentarios.length > 0
        ? statetodo.comentarios.map((data, index) => {
            return data.dataveterinaria !== null ? (
              <div className="m-3">
                <div className="navi navi-spacer-x-0 w-100 d-flex justify-content-center align-items-center">
                  <div className="navi-link d-flex align-items-start w-100 ">
                    {/*  <Link
                            to={`/perfilmascota/${data.cod_regis}`}
                            className="navi-item w-100"
                            style={{ textDecoration: "none", color: "black" }}
                          > */}
                    <div className="symbol symbol-50 mr-3 ">
                      <div
                        className="symbol-label"
                        style={{
                          borderRadius: "35px",
                          width: "35px",
                          height: "35px",
                        }}
                      >
                        <CargarImagenes
                          imagen={data.dataveterinaria[0].urlFotoVeterinaria}
                        ></CargarImagenes>
                      </div>
                    </div>
                    {/* </Link> */}
                    <div
                      className="d-flex align-items-start flex-column w-100 p-2"
                      style={{ borderRadius: "12px", background: "#f0f2f5" }}
                    >
                      <div className="w-100 negrita">
                        {data.dataveterinaria[0].nombre}
                      </div>
                      {/* <TextareaAutosize
                      className="textareapublicar bg-white"
                      onChange={(e) => onchange(e)}
                      value={data.comentario}
                      id="comentario"
                      name="comentario"
                      placeholder={"comentario ..."}
                      style={{
                        height: "70px!important",
                        fontSize: "12px",
                      }}
                    />{" "} */}
                      <div class="d-flex flex-nowrap w-100">
                        <label
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-wrap",
                            display: "block",
                            width: "100%",
                            minWidth: "1px",
                          }}
                        >
                          {data.comentario}
                        </label>
                      </div>
                    </div>
                    {data.dataveterinaria[0].veterinaria_id === veterinaria ? (
                      <i
                        className="fas fa-trash mx-5"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => deleteComenarios(e, data.comentario_id)}
                      ></i>
                    ) : (
                      <i className="mx-7" style={{ fontSize: "15px" }}></i>
                    )}

                    <i className="mx-7" style={{ fontSize: "15px" }}></i>
                  </div>
                </div>
              </div>
            ) : data.datausuario !== null ? (
              <div className="m-3">
                <div className="navi navi-spacer-x-0 w-100 d-flex justify-content-center align-items-center">
                  <div className="navi-link p-0 m-0 d-flex align-items-start w-100 ">
                    {/*  <Link
                              to={`/perfilmascota/${data.cod_regis}`}
                              className="navi-item w-100"
                              style={{ textDecoration: "none", color: "black" }}
                            > */}
                    <div className="symbol symbol-50 mr-3 ">
                      <div
                        className="symbol-label"
                        style={{
                          borderRadius: "35px",
                          width: "35px",
                          height: "35px",
                        }}
                      >
                        <CargarImagenes
                          imagen={data.datausuario[0].urlFotoUsuario}
                        ></CargarImagenes>
                      </div>
                    </div>
                    {/* </Link> */}
                    <div
                      className="d-flex align-items-start flex-column w-100 p-2"
                      style={{ borderRadius: "12px", background: "#f0f2f5" }}
                    >
                      <div className="w-100 negrita">
                        {data.datausuario[0].nombres}
                      </div>
                      {/* <TextareaAutosize
                        className="textareapublicar bg-white"
                        onChange={(e) => onchange(e)}
                        value={data.comentario}
                        id="comentario"
                        name="comentario"
                        placeholder={"comentario ..."}
                        style={{
                          height: "70px!important",
                          fontSize: "12px",
                        }}
                      />{" "} */}
                      <div class="d-flex flex-nowrap w-100">
                        <label
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-wrap",
                            display: "block",
                            width: "100%",
                            minWidth: "1px",
                          }}
                        >
                          {data.comentario}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {data.datausuario[0].usuario_id === usuario_id ? (
                      <i
                        className="fas fa-trash mx-5"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => deleteComenarios(e, data.comentario_id)}
                      ></i>
                    ) : (
                      <i className="mx-7" style={{ fontSize: "15px" }}></i>
                    )}
                  </div>
                </div>
              </div>
            ) : null;
          })
        : null}

      <hr className="my-1 w-100 borderbottom"></hr>
      <div>
        <button
          className="d-flex justify-content-center p-5 flex-column align-items-center w-100"
          style={{ background: "none", border: "none" }}
          onClick={(e) => {
            alert("iniciando");
            setStatelimit({
              ...statelimit,
              limit: parseInt(statelimit.limit) + parseInt(3),
            });
          }}
        >
          <label>Mostrar más</label>
          <i className="fas fa-arrow-circle-down"></i>
        </button>
      </div>
    </div>
  );
};

export default Comentarios;
