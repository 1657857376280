import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Axios from "../../../config/Axios";
import ReactTooltip from "react-tooltip";

import PanelCrearUsuario from "../Usuarios/crearUsuarios";
import PanelCrearMascota from "../pacientes/crearPacientes";
import EditarPaciente from "../pacientes/editarPaciente";
import InsertarPacientePersona from "../pacientes/insertarPacientePersona";
import EditarPacientePersona from "../pacientes/editarPacientePersona";
import AuthContext from "../../../Context/auth/authContext";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";
import { ReactComponent as CarpetaMedicaIcon } from "../../../icons/carpetamedica.svg";


const ConsultaMedica = () => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const ref = useRef([]);

  const [state, setState] = useState({
    todos: [],
    persona: [],
    currentPage: 1,
    todosPerPage: 50,
    search: "",
    preloadpaciente: false,
    endfetch: false,
  });
const [buscar, setbuscar] = useState("");
  const [fetch, setFetch] = useState(false);

  const [stateModal, setStateModal] = useState({
    nuevoUsuarioModal: false,
    nuevoPacienteModal: false,
    nuevoPecientePersonaModal: false,
    editarPecientePersonaModal: false,
    vovlercliente: false,
    userdata: {},
    configModal: false,
  });

  const { todos, currentPage, todosPerPage } = state;

  /* const handleClick = (event) => {
    setState({
      ...state,
      currentPage: Number(event.target.id),
    });
  }; */

  useEffect(() => {
    ref.current["consultaCliente"].focus();
  }, []);

  const onchange = (e) => {
    setbuscar(e.target.value);

  };
  const onchange2 = (e) => {
    BuscarUsers(e.target.value);
  };



/*   const abrirConfiguraciones = (dato, e) => {
    e.preventDefault();

    setStateModal({
      ...stateModal,
      configModal: true,
      id_persona: dato.id_persona,
      userdata: dato,
    });
  }; */

  const cerrarModalConfiguraciones = (e) => {
    e.preventDefault();
    setStateModal({
      ...stateModal,
      configModal: false,
    });

    setState({
      ...state,
      infoUsuario: {},
    });
  };

  const abrirInsertarPacientePersona = (dato, e) => {
    e.preventDefault();

    setStateModal({
      ...stateModal,
      nuevoPecientePersonaModal: true,
      userdata: {
        mascota_id: dato.mascota_id,
        nombreMascota: dato.nombres,
      },
    });
  };

/*   const abrirEditarPacientePersona = (dato, e) => {
    e.preventDefault();

    setStateModal({
      ...stateModal,
      editarPecientePersonaModal: true,
      userdata: {
        persona_id: dato.tieneduenio[0].persona_id,
        nombres: dato.tieneduenio[0].nombres,
        apellidos: dato.tieneduenio[0].apellidos,
        correo: dato.tieneduenio[0].correo,
        provincia: dato.tieneduenio[0].provincia,
        ciudad: dato.tieneduenio[0].ciudad,
        mascota_id: dato.mascota_id,
        nombreMascota: dato.nombres,
      },
    });
    console.log(dato);
  }; */

  const actualizarListaDespuesDeingresarMascota = async (
    dato_id,
    dato_nombre
  ) => {
    console.log(dato_nombre);
    setStateModal({
      ...stateModal,
      nuevoPacienteModal: false,
      nuevoPecientePersonaModal: false,
      userdata: {
        mascota_id: dato_id,
        nombreMascota: dato_nombre,
      },
    });
  };

  // Logic for displaying current todos
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

  const renderTodos = currentTodos.map((todo, index) => {
    return (
      <div key={todo.mascota_id} className=" bordevertical bgfondo  py-2">
        <div className="d-flex justify-content-center">{todo.nombres} </div>
        <div className="d-flex justify-content-start px-2">
          <label for=""> CODE: {todo.cod_regis} </label>
        </div>
        <div>
          {todo.tieneduenio !== null ? (
            <div className="d-flex justify-content-start px-2">
              <label for=""> MASTER: {todo.tieneduenio[0].nombres}</label>
            </div>
          ) : todo.tieneduenio !== null ? null : (
            <div className="d-flex justify-content-between align-items-center px-2">
              <label for="">MASTER : (NO)</label>

              <ReactTooltip />
              <button
                data-effect="solid"
                data-tip="Asignarle un dueño"
                data-place="bottom"
                data-type="info"
                onClick={(e) => abrirInsertarPacientePersona(todo, e)}
                type=""
                className="bg-btn-vet bordecompleto bordesombreado"
              >
                <AddIcon width={35} fill="white" className="svgIv"></AddIcon>
                Asignar dueño
              </button>
            </div>
          )}{" "}
        </div>
        {todo.escliente === null ? (
          <div className="d-flex justify-content-between align-items-center px-2">
            <label for="">CLIENTE : (NO)</label>
            <ReactTooltip />
            <button
              data-effect="solid"
              data-tip="Volver cliente"
              data-place="bottom"
              data-type="info"
              style={{ width: "150px" }}
              className="bg-btn-vet bordecompleto bordesombreado"
              onClick={() => {
                setStateModal({
                  ...stateModal,
                  vovlercliente: true,
                  userdata: todo,
                });
              }}
            >
              <i className="fa fa-plus-circle faIv"></i>
              hacer cliente
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center px-2">
            <label for="">CLIENTE : (SI)</label>
            <ReactTooltip />
            <Link
              data-effect="solid"
              data-tip="Perfil clínico"
              data-type="info"
              data-place="bottom"
              style={{ width: "150px" }}
              to={"/registromedico/" + btoa(todo.mascota_id)}
              className="bg-btn-vet bordecompleto bordesombreado"
            >
              <CarpetaMedicaIcon className="svgIv" fill="white" />
              Perfil clínico
            </Link>
          </div>
        )}
      </div>
    );
  });

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
    pageNumbers.push(i);
  }

/*   const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        style={{ border: "1px solid", padding: "5px" }}
      >
        {number}
      </li>
    );
  }); */

  const BuscarUsers = async (data) => {
  

      if (data === "") {
        //allpersonsData()
        setState({
          ...state,
          todos: [],
          currentPage: 1,
          preloadpaciente: false,
        });
        setbuscar("")
      } else {
        //
        try {
          setState({
            ...state,
            preloadpaciente: true,
          });
          const respuesta = await Axios.get(
            "/filtrarPorCoincidenciasMascotas/" + buscar + "/" + veterinaria_id
          );
          setFetch(respuesta.data.result.length === 0 ? true : false);
          setState({
            ...state,
            todos: respuesta.data.result,
            persona: respuesta.data.persona,
            currentPage: 1,
            preloadpaciente: false,
          });
        } catch (e) {}
      }
  };

  const hacerCliente = async (mascota_id) => {
    try {
      const respuesta = await Axios.post("/createClientVet", {
        veterinaria_id: veterinaria_id,
        mascota_id: mascota_id,
      });
      console.log(respuesta.data.result);
      if (respuesta.data.sms === "ok") {
        window.location = "/registromedico/" + btoa(mascota_id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  
  return (
    <>
      <ReactTooltip></ReactTooltip>
      <div className="row p-0 m-0">
        <div className="col-md-12 p-4">
          <div className=" d-flex flex-row align-items-center justify-content-center">
            <button
              type="button"
              className="bg-btn-vet bordecompleto bordesombreado mx-1"
              onClick={(e) =>
                setStateModal({ ...stateModal, nuevoUsuarioModal: true })
              }
            >
              <i className="fa  fa-user-plus faIv"></i> Nuevo Usuario
            </button>
            <button
              type="button"
              className="bg-btn-vet bordecompleto bordesombreado mx-1"
              onClick={(e) =>
                setStateModal({ ...stateModal, nuevoPacienteModal: true })
              }
            >
              <i className="fa  fa-user-plus faIv"></i>
              Nuevo Paciente
            </button>
          </div>
        </div>

        <div className="col-md-12 col-sm-12 bgfondo  px-0 py-3 bordevertical">
          <div className="row ">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <span className="titulosSize">Consultar (usuarios/mascotas)</span>
            </div>{" "}
            <div className="w-100">
              <input
                ref={(r) => (ref.current["consultaCliente"] = r)}
                type="search"
                className="inputtext bordecompleto"
                placeholder="Ingrese un valor y presione Enter"
                onChange={onchange}
                onKeyUp={onchange2}
                id="buscar"
                name="buscar"
                value={buscar}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-sm-12 p-0 bgfondo buscarpacientelist">
          {renderTodos}
        </div>
        {state.todos.length > 0 ? null : (
          <div style={{ width: "100%", padding: "12px", textAlign: "center" }}>
            {state.preloadpaciente ? (
              <div
                className="spinner-border spinner-border-sm text-primary"
                style={{ width: "2rem", height: "2rem" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : state.persona.length > 0 ? (
              <div className="d-flex flex-column justify-content-center ">
                <h5>
                  {" "}
                  {state.persona[0].apellidos} {state.persona[0].nombres}
                </h5>
                <p> no tiene registrada ninguna mascota</p>
              </div>
            ) : fetch ? (
              "No encontramos ningun resultado"
            ) : (
              <label
                style={{ width: "100%", padding: "12px", textAlign: "center" }}
              >
                FILTRE UN CLIENTE/MASCOTA PARA OBTENER LA INFORMACIÓN
              </label>
            )}
          </div>
        )}
      </div>

      {/* MODAL REGISTRO DE MASCOTAS*/}
      <Modal
        isOpen={stateModal.nuevoPacienteModal}
        toggle={() => {}}
        style={{ maxWidth: "60%", padding: "0px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              nuevoPacienteModal: false,
            });
          }}
          className="borde"
        >
          REGISTRO DE MASCOTAS
        </ModalHeader>
        <ModalBody>
          <PanelCrearMascota
            actualizaLista={actualizarListaDespuesDeingresarMascota}
          ></PanelCrearMascota>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL CONFIGURACIONES MASCOTA */}
      <Modal
        isOpen={stateModal.configModal}
        toggle={() => {}}
        style={{ maxWidth: "90%" }}
      >
        <ModalHeader
          toggle={cerrarModalConfiguraciones}
          style={{ background: "white" }}
        >
          EDITAR INFORMACIÓN DE : {stateModal.userdata.nombres}{" "}
          {stateModal.userdata.apellidos}{" "}
        </ModalHeader>
        <ModalBody>
          <EditarPaciente data={stateModal.userdata}></EditarPaciente>
        </ModalBody>
      </Modal>
      {/* FIN  MODAL CONFIGURACIONES MASCOTA */}

      {/* MODAL REGISTRO DE USUARIOS*/}
      <Modal
        isOpen={stateModal.nuevoUsuarioModal}
        toggle={() => {}}
        style={{ maxWidth: "60%", padding: "0px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              nuevoUsuarioModal: false,
            });
          }}
          className="borde"
        >
          REGISTRO DE USUARIO
        </ModalHeader>
        <ModalBody>
          <PanelCrearUsuario></PanelCrearUsuario>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL REGISTRO DE MASCOTAS*/}
      <Modal
        isOpen={stateModal.nuevoPecientePersonaModal}
        toggle={() => {}}
        style={{ maxWidth: "40%", padding: "0px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              nuevoPecientePersonaModal: false,
            });
          }}
          className="borde"
        >
          INSERTAR DUEÑO
        </ModalHeader>
        <ModalBody>
          <InsertarPacientePersona
            data={stateModal.userdata}
          ></InsertarPacientePersona>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL REGISTRO DE MASCOTAS*/}
      <Modal
        isOpen={stateModal.editarPecientePersonaModal}
        toggle={() => {}}
        style={{ maxWidth: "40%", padding: "0px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              editarPecientePersonaModal: false,
            });
          }}
          className="borde"
        >
          INSERTAR DUEÑO
        </ModalHeader>
        <ModalBody>
          <EditarPacientePersona
            data={stateModal.userdata}
          ></EditarPacientePersona>
        </ModalBody>
      </Modal>
      {/* FIN MODAL REGISTRO DE USUARIOS*/}

      {/* MODAL VOLVER CLIENTE*/}
      <Modal
        isOpen={stateModal.vovlercliente}
        toggle={() => {
          setStateModal({
            ...stateModal,
            vovlercliente: false,
            userdata: [],
          });
        }}
        style={{ maxWidth: "40%", padding: "0px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              vovlercliente: false,
              userdata: [],
            });
          }}
          className="borde"
        >
          CONFIRMAR ACCION{" "}
        </ModalHeader>
        <ModalBody>
          <div className="h5 text-center">
            <span className="">ESTA SEGURO DE HACER CLIENTE A </span>
            {stateModal.userdata.nombres} ?{" "}
          </div>
          <div>
            <hr className="my-4" />
            <div className="text-center">
              <button
                className="btn btn-primary h2 mr-2"
                style={{
                  padding: "5px 20px 5px 20px",
                }}
                onClick={() =>
                  setStateModal({
                    ...stateModal,
                    vovlercliente: false,
                    userdata: [],
                  })
                }
              >
                NO
              </button>
              <button
                className="btn btn-primary h2"
                style={{
                  padding: "5px 20px 5px 20px",
                }}
                onClick={() => {
                  hacerCliente(stateModal.userdata.mascota_id);
                }}
              >
                SI
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* FIN MODAL VOLVERR CLIENTE*/}
    </>
  );
};

export default ConsultaMedica;
