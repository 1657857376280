import React, { useEffect, useState, useContext } from "react";

import Menu from "../Components/Sidebar/SideBar";
import "../Components/contable/menucontable.css";
import Socios from "../Components/afiliaciones/socios/index";
import Servicios from "../Components/afiliaciones/servicios";

import { useParams } from "react-router-dom";
const routes = [
  {
    path: "/inicio",
    name: "afiliados ",
    icon: "fas fa-caret-right",
    exact: true,
    type: "",
  },
  {
    path: "/servicios",
    name: "servicios ",
    icon: "fas fa-caret-right",
    exact: true,
    type: "",
  },
];
const Afiliaciones = () => {
  const { panel } = useParams();
  const [stateContenedor, setContenedor] = useState(null);
  useEffect(() => {
    if (panel === "inicio") setContenedor(<Socios />);
    if (panel === "servicios") setContenedor(<Servicios />);

    return () => {};
  }, [panel]);
  return (
    <div className="w-100 d-flex justify-content-between ">
     {/*  <Menu
        routes={routes}
        titulo=" Opciones afiliados"
        subpath={"/afiliados"}
      ></Menu> */}
      <div className="w-100 bgfondo2  h-100 " style={{ minHeight: "90vh" }}>
        {stateContenedor}
      </div>
    </div>
  );
};

export default Afiliaciones;
