import React, { useEffect, useState } from "react";
import Barcode from "react-jsbarcode";
import moment from "moment"
import "./factura.css"
const DetalleVenta = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setData(props.data);
  };


  const regimen = (v1) => {
    return v1 === "RIMPE" || v1 === "rimpe"
      ? "Contribuyente Régimen RIMPE"
      : v1 === "POPULAR"
      ? "Régimen RIMPE - Contribuyente negocio popular"
      : v1 === "GENERAL"
      ? "Régimen general"
      : "";
  };
  const tipodocumento = (v1) => {
    return v1 === "01"
      ? "FACTURA"
      : v1 === "03"
      ? "LIQUIDACIÓN DE COMPRA"
      : v1 === "04"
      ? "NOTA DE CRÉDITO"
      : v1 === "05"
      ? "NOTA DE DÉBITO"
      : v1 === "06"
      ? "GUIA DE REMISIÓN"
      : v1 === "07"
      ? "COMPROBANTE DE RETENCIÓN"
      : "RECIBO";
  };

  const ifartesanal = (v1) => {
    return v1 === "" || v1 === null
      ? ""
      : `
         Calificación artesanal : ${v1}  
         `;
  };

  const tipopago = (v1) => {
    return v1 === "efectivo"
      ? "Efectivo"
      : v1 === "chueque"
      ? "Cheque"
      : v1 === "debito_cuenta_bancaria"
      ? "Débito de cuenta bancaria"
      : v1 === "transferencia"
      ? "Transferencia bancaria"
      : v1 === "deposito_cuenta_bancaria"
      ? "Depósito en cuenta(Corriente / Ahorros)"
      : v1 === "tarjeta_debito"
      ? "Tarjeta de débito"
      : v1 === "tarjeta_prepago"
      ? "Tarjeta prepago"
      : v1 === "tarjeta_credito"
      ? "Tarjeta de crédito"
      : v1 === "otros"
      ? "Otros"
      : v1 === "endoso_titulos"
      ? "Endoso de títulos"
      : v1 === "01"
      ? "Sin utilizacion del sistema financiero"
      : v1 === "15"
      ? "Compensación de deudas"
      : v1 === "16"
      ? "Tarjeta de débito"
      : v1 === "17"
      ? "Dinero electrónico"
      : v1 === "18"
      ? "Tarjeta prepago"
      : v1 === "19"
      ? "Tarjeta de crédito"
      : v1 === "20"
      ? "Otros con utilización del sistema financiero"
      : v1 === "21"
      ? "Endoso de títulos"
      : "NO DEFINIDO";
  };

  return (
    <div className="row">
      {props.data ? (
        <div className="col-md-12">
          <div className="row">
            <div className="">
              {props.data ? (
                <div className="">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      padding: "15px",
                    }}
                  >
                    <div className="" style={{ width: "33%!important",height:"auto" }}>
                      <div className="">
                        <div className=" ">
                          <div></div>
                          <div className="" style={{ height: "150px" }}>
                            {" "}
                            <img
                              src={props.ima}
                              style={{ width: "250px", height: "160px" }}
                              alt=""
                            />
                          </div>
                          <br />
                          <div className="bordecompleto  p-2" style={{minHeight:"269px"}}>
                            <div className="">
                              <table className="">
                                <tbody>
                                  <tr>
                                    <td>Razón social:</td>
                                    <td>
                                      {
                                        JSON.parse(props.data.emisor)
                                          .razon_social
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>nombre comercial:</td>
                                    <td>
                                      {
                                        JSON.parse(props.data.emisor)
                                          .nombre_comercial
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Obligado a llevar contabilidad:</td>
                                    <td>
                                      {" "}
                                      {JSON.parse(props.data.emisor)
                                        .obligado_contabilidad === true
                                        ? "si"
                                        : "no"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      {regimen(
                                        JSON.parse(props.data.emisor)
                                          .regimen
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      {ifartesanal(
                                        JSON.parse(props.data.emisor)
                                          .calificacion_artesanal
                                      )}
                                    </td>
                                  </tr>

                                  <tr
                                    className="w-100"
                                    style={{ width: "100%" }}
                                  >
                                    <td>Dirección:</td>
                                  </tr>
                                  <tr className="w-100">
                                    <td colspan="2">
                                      {
                                        JSON.parse(props.data.emisor)
                                          .establecimiento.direccion
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 m-0 p-0 ">
                      <div
                        className="row bordecompleto  p-2"
                        style={{ minHeight: "390px" }}
                      >
                        <div className=" d-flex flex-column ">
                          <table className="emisor">
                            <tbody>
                              <tr>
                                <td className="ruc">Ruc:</td>
                                <td className="ruc">
                                  {JSON.parse(props.data.emisor).ruc}
                                </td>
                              </tr>
                              <tr>
                                <td colspan={2} className="codigo_documento">
                                  {tipodocumento(
                                    props.data.codigo_documento
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Punto de emisión:</td>
                                <td>
                                  {" "}
                                  {
                                    JSON.parse(props.data.emisor)
                                      .establecimiento.punto_emision
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Código:</td>
                                <td>
                                  {" "}
                                  {
                                    JSON.parse(props.data.emisor)
                                      .establecimiento.codigo
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>N° Factura</td>
                                <td>{props.data.numero}</td>
                              </tr>

                              <tr>
                                <td colspan="2">NÚMERO DE AUTORIZACIÓN</td>{" "}
                              </tr>

                              <tr>
                                <td colspan="2">
                                  {props.data.clave_acceso}
                                </td>{" "}
                              </tr>

                              <tr className="">
                                <td>
                                  <div className="my-3">
                                    {props.data.autorizacion
                                      ? JSON.parse(props.data.autorizacion)
                                          .autorizacion.estado
                                      : "NO AUTORIZADO"}
                                  </div>
                                </td>
                              </tr>
                              <tr className="">
                                <div className="">
                                  {props.data.autorizacion ? (
                                    JSON.parse(props.data.autorizacion)
                                      .autorizacion.estado === "AUTORIZADO" ? (
                                      <div>
                                        <td>Fecha de autorización</td>
                                        <td>
                                          {moment(
                                            JSON.parse(
                                              props.data.autorizacion
                                            ).autorizacion.fechaAutorizacion
                                          ).format("DD/MM/YYYY HH:MM:SS")}
                                        </td>
                                      </div>
                                    ) : null
                                  ) : null}
                                </div>
                              </tr>

                              {/*  <tr>
                        <td>Fecha de venta:</td>
                        <td>{props.data.fecha_venta}</td>
                      </tr> */}
                              <tr>
                                <td>Ambiente:</td>
                                <td>
                                  {props.data.ambiente === 1
                                    ? "PRUEBA"
                                    : "PRODUCCIÓN"}
                                </td>
                              </tr>
                              <tr>
                                <td>Emisión:</td>
                                <td>
                                  {parseInt(props.data.tipo_emision) === 1
                                    ? "NORMAL"
                                    : "XXX"}
                                </td>
                              </tr>

                              <tr>
                                <td colspan="2">Clave de acceso</td>{" "}
                              </tr>

                              <tr>
                                {
                                  <td colspan="2">
                                    <Barcode
                                      className="svg2"
                                      value={props.data.clave_acceso}
                                    />
                                  </td>
                                }
                              </tr>
                              {/*  <tr>
                          <td colspan="2">
                            {props.data.autorizacion
                              ? JSON.parse(props.data.autorizacion).estado ===
                                "AUTORIZADO"
                                ? JSON.parse(props.data.autorizacion).numero
                                : "NO AUTORIZADA"
                              : "NO AUTORIZADO"}
                          </td>{" "}
                        </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      marginBottom: "12px",
                      width: "100%",
                    }}
                  >
                    <div className=" bordecompleto   p-3">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            width: "50%",
                          }}
                        >
                          Razon_social:
                          {JSON.parse(props.data.comprador).razon_social}
                        </div>
                        <div
                          className=""
                          style={{
                            width: "50%",
                          }}
                        >
                          Identificación:
                          {JSON.parse(props.data.comprador).identificacion}
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            width: "50%",
                          }}
                        >
                          Teléfono:
                          {JSON.parse(props.data.comprador).telefono}
                        </div>
                        <div
                          className=""
                          style={{
                            width: "50%",
                          }}
                        >
                          Correo:{JSON.parse(props.data.comprador).correo}
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className=" col-sm-12">
                          Dirección:
                          {JSON.parse(props.data.comprador).direccion}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className=" m-0 p-0 tablares bordecompleto  ">
                      <table className="w-100 tabla-detalle-factura">
                        <thead>
                          <tr className="bordecompleto">
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>pvp</th>
                            <th>Descuento</th>
                            <th>iva</th>
                            <th>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.data.ventas_detalle.length > 0
                            ? props.data.ventas_detalle.map((data) => (
                                <tr className="bordecompleto">
                                  <td>{data.codigo_producto}</td>
                                  <td>{data.descripcion_producto}</td>
                                  <td>{data.cantidad}</td>
                                  <td>${data.precio_venta}</td>
                                  <td>${data.descuento_total}</td>
                                  <td>${data.iva_valor}</td>

                                  <td>${data.subtotal}</td>
                                </tr>
                              ))
                            : "cargando..."}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "start",
                      alignItems: "start",
                      width: "100%",
                    }}
                  >
                    <div
                      className="bordecompleto  p-1"
                      style={{ width: "60%" }}
                    >
                      <div
                        className=" p-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignContent: "start",
                          alignItems: "start",
                          width: "100%",
                          float: "start",
                        }}
                      >
                        <label
                          for=""
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          Información adicional
                        </label>
                        <label for="">
                          {JSON.parse(props.data.pagos)[0].notas}
                        </label>
                      </div>
                      <div className="" style={{ width: "100%" }}>
                        <table className="w-100 bordecompleto">
                          <tr className="">
                            <td className="">Forma de pago</td>
                            <td className="">valor</td>
                          </tr>
                          <tr className="">
                            <td className="">
                              {JSON.parse(props.data.pagos)[0].medio} -{" "}
                              {tipopago(
                                JSON.parse(props.data.pagos)[0].medio
                              )}
                            </td>
                            <td className=""> {props.data.valor_total}</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div
                      className="bordecompleto  p-4"
                      style={{ width: "40%" }}
                    >
                      <table className="tbfactura " style={{ width: "100%" }}>
                        <tr>
                          <td className="tb-left">Total_descuento</td>
                          <td>${props.data.total_descuento}</td>
                        </tr>
                        <tr>
                          <td className="tb-left">Subtotal 12%</td>
                          <td>${props.data.subtotal_iva}</td>
                        </tr>
                        <tr>
                          <td className="tb-left">Subtotal 0% </td>
                          <td>${props.data.subtotal_siniva}</td>
                        </tr>
                        <tr>
                          <td className="tb-left">
                            Subtotal no objeto de iva{" "}
                          </td>
                          <td>${props.data.subtotal_no_objeto_iva}</td>
                        </tr>
                        <tr>
                          <td className="tb-left">Subtotal exento de iva</td>
                          <td>${props.data.subtotal_exento_iva}</td>
                        </tr>
                        <tr>
                          <td className="tb-left">Subtotal sin impuestos </td>
                          <td>${props.data.subtotal_sin_impuestos}</td>
                        </tr>
                        <tr>
                          <td className="tb-left"> Iva</td>
                          <td>${props.data.iva}</td>
                        </tr>
                        <tr>
                          <td className="tb-left"> ICE </td>
                          <td>${props.data.ice}</td>
                        </tr>
                        <tr>
                          <td className="tb-left">IRBPNR </td>
                          <td>${props.data.irbpnr}</td>
                        </tr>
                        <tr className="bordecompleto ">
                          <td className="tb-left negrita p-2"> Valor total </td>
                          <td className="negrita py-2">
                            {" "}
                            ${props.data.valor_total}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row d-flex justify-content-start flex-column">
            <hr />
            <div className="col-md-12">
              <div className="row py-2">
                <div className="py-2">MENSAJES DE RECEPCIÓN </div>
                <br />
                <div>
                  {props.data.recepcion ? (
                    <div>
                      <div>
                        Estado:{JSON.parse(props.data.recepcion).estado}
                      </div>
                      <div>
                        {JSON.parse(props.data.recepcion).comprobantes ? (
                          <div>
                            <div>
                              Mensaje:
                              {
                                JSON.parse(props.data.recepcion).comprobantes
                                  .comprobante.mensajes.mensaje.mensaje
                              }
                            </div>
                            <div>
                              informacionAdicional:
                              {
                                JSON.parse(props.data.recepcion).comprobantes
                                  .comprobante.mensajes.mensaje
                                  .informacionAdicional
                              }
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row py-2">
                <div className="py-2">MENSAJES DE AUTORIZACIÓN</div>
                <div>
                  {" "}
                  {props.data.autorizacion ? (
                    <div>
                      <div>
                        Estado:
                        {
                          JSON.parse(props.data.autorizacion).autorizacion
                            .estado
                        }
                      </div>
                      <div>
                        {JSON.parse(props.data.autorizacion).autorizacion
                          .mensajes ? (
                          <div>
                            <div>
                              Mensaje:
                              {
                                JSON.parse(props.data.autorizacion).autorizacion
                                  .mensajes.mensaje.mensaje
                              }
                            </div>
                            <div>
                              informacionAdicional:
                              {
                                JSON.parse(props.data.autorizacion).autorizacion
                                  .mensajes.mensaje.informacionAdicional
                              }
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DetalleVenta;
