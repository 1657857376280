import { io } from "socket.io-client";
 
export const url = process.env.REACT_APP_URL_API
export const urlmail = process.env.REACT_APP_URL_API_MAIL;
export const url_contable = process.env.REACT_APP_URL_API_CONTABLE;
export const url_facturacion = process.env.REACT_APP_URL_API_CONTABLE; //process.env.REACT_APP_URL_API_FACTURAS_VENTAS
export const url_clientes_facturacion = process.env.REACT_APP_URL_API_CLIENTES_FACTURACION;
export const url_facturacion_electronica_api =process.env.REACT_APP_URL_FACTURACION_ELECTRONICA_API;
export const url_allmetales_api = process.env.REACT_APP_URL_ALLMETALES_API;


export const socket = null /* io(process.env.REACT_APP_URL_API_SOCKET, {
  transports: ["websocket"],
});
 */

export const urlLogoImagen = "";
export const urlPortadaImagen = "";
export const urlMascotaImagen = "";
export const urlUsuarioImagen = "";
export const urlArchivosMedicosImagen = url + "";