import React,{useEffect,useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import { ReactComponent as UploadIcon} from "../icons/upload.svg"
const InputFile = (props) => {
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept:props.accept
      
    });
  
 /*    const acceptedFileItems = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  
  /*   const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map(e => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    )); */

   /*  useEffect(()=>{
    },[acceptedFiles]) */

    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
    
    const focusedStyle = {
      borderColor: '#2196f3'
    };
    
    const acceptStyle = {
      borderColor: '#00e676'
    };
    
    const rejectStyle = {
      borderColor: '#ff1744'
    };

    const readAndGetFiles = () => {
      props.getFiles(acceptedFiles)
    }

      const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);

      useEffect(()=>{
        readAndGetFiles()
      },[acceptedFiles])

    return (
      <section  className={`h-100 w-100 ${props.absolute} ${props.opacidad}`} >
        <div {...getRootProps({style})} className="d-flex h-100 w-100 justify-content-center flex-column align-items-center">
          <input {...getInputProps()} />
          <UploadIcon style={{width:"45px"}} />
          <p style={{fontWeight:"bold"}}>Arrastre el archivo aqui</p>
        </div>
         
      </section>
    );
   
  };
  
  export default InputFile;