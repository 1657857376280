import Axios from "../config/Axios";

export const createRol = async (state) => {
  try {
    const result = await Axios.post(`/createRol`,state);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};


export const eliminarRol = async (rol_id,activo,veterinaria_id,allaccess) => {
  try {
    const result = await Axios.get(`/eliminarRol/${rol_id}/${activo}/${veterinaria_id}/${allaccess}`,);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};

export const getAllRolesVets = async (veterinaria_id) => {
  try {
    const result = await Axios.get(`/allDataRolVet/${veterinaria_id}`);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};

export const createRolUsuario = async (state) => {
  try {
    const result = await Axios.post(`/createRolUsuario`, state);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};

export const getUsuariosPorRoles = async (rol_id) => {
  try {
    const result = await Axios.get(`/getUsuariosPorRol/${rol_id}`);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};


export const deleteUsuariosPorRoles = async (usuario_rol_id) => {
  try {
    const result = await Axios.get(`/deleteUsuariosPorRol/${usuario_rol_id}`);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: e,
      },
    ];
  }
};

