import React, { useEffect, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AuthContext from "../../../Context/auth/authContext";
import Axios from "../../../config/Axios"
import moment from "moment";
import DashBoardInicio from "./dashboard";
import Plan from "./plan"

const Inicio = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacli} = AuthenticationContext;

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const { usuario_id, nombres, correo, color, foto } =
    sessiondatacli[0].persona[0];
  const { logo, atencion,veterinaria_id,nombre_comercial,type } = sessiondatacli[0].veterinaria[0];

  const [stateModal, setStateModal] = useState({
    modalPublicacion: false,
  });
  const cerrarModalPublicacion = () => {
    setStateModal({
      ...stateModal,
      modalPublicacion: false,
    });
  };

  useEffect(() => {}, []);

 

  const startOnlineVet = async (e,online) => {
    e.preventDefault()
    try {
      const result = await Axios.get(`/vetOnline/${online}/${veterinaria_id}`);
      if (result.data.sms === "ok") {
        window.location="/"
      }
    } catch (e) {
      console.log(e);
    }
  };

  const obtenerdiasdecaducidad = (expire_access) => {
    const hoy = moment();
    const expire = moment(expire_access);
    return `${expire.diff(hoy, "days")} dias`;
  };

  const obtenersiesperiododeprueba = (create_at) => {
    const hoy = moment();
    const expire = moment(create_at);
    return expire.diff(hoy, "months");
  };

  return (
    <>
      <div className="col-md-12 bgfondo2 h-100">
        <div className="row">
          <div className="col-md-12 bgfondo2">
            <Plan/>
          </div>
          <div className="col-md-12 bgfondo2">
            <DashBoardInicio empresa_id={veterinaria_id} />
          </div>
          {/* <div className="col-md-12 d-flex justify-content-center">
            <div class=" row card  bordecircular bordecompleto   mt-4">
              <div class="card-body d-flex">BIENVENIDO {nombre_comercial}</div>
            </div>
            {type !== "veterinaria" ? null : (
              <div class=" row card  bordecircular bordecompleto   mt-4">
                <div class="card-body d-flex">
                  {" "}
                  <p class="card-text">
                    La veterinaria se encuentra actualmente{" "}
                    {atencion === 1 ? (
                      <button
                        class="badge btn rounded-pill bg-primary"
                        onClick={(e) => startOnlineVet(e, 0)}
                      >
                        abierta
                      </button>
                    ) : (
                      <button
                        class="badge btn rounded-pill bg-danger"
                        onClick={(e) => startOnlineVet(e, 1)}
                      >
                        cerrada
                      </button>
                    )}
                  </p>
                </div>
              </div>
            )} */}
          {/* <div className="card  bordecircular bordecompleto  mt-4">
            <div className="card-body">
              <h5>Estado del servicio</h5>
              {obtenersiesperiododeprueba(create_at) > 0
                ? "Plan de Pago"
                : "Periodo de prueba"}
              <br />
              {`Faltan ${obtenerdiasdecaducidad(
                expire_access
              )} para caducar el ${
                obtenersiesperiododeprueba(create_at) > 0
                  ? "Plan de Pago"
                  : "Periodo de prueba"
              }`}
              <br />
              {"F/Caducidad : " + moment(expire_access).format("YYYY-MM-DD")}
            </div>
          </div> */}
        </div>
      </div>

      {/* MODAL PUBLICAR*/}
      <Modal
        isOpen={stateModal.modalPublicacion}
        toggle={() => {
          setStateModal({
            ...stateModal,
            modalPublicacion: false,
          });
        }}
        style={{ maxWidth: "50%", padding: "55px" }}
      >
        <ModalHeader
          toggle={() => {
            setStateModal({
              ...stateModal,
              modalPublicacion: false,
            });
          }}
        >
          Publicación
        </ModalHeader>
        <ModalBody>
          {/*           <Publicar cerrarModal={cerrarModalPublicacion}></Publicar>
           */}{" "}
        </ModalBody>
      </Modal>
      {/* FIN MODAL PUBLICARs*/}
    </>
  );
};

export default Inicio;
