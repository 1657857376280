import Axios from "../../config/AxiosContable";

export const GETTipoSustentoApi = async () => {
  try {
    const res = await Axios.get("/GETTiposSustentosRetenciones");
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};

export const GETPorcentajeSustentoApi = async () => {
  try {
    const res = await Axios.get("/GETPorcentajesRetenciones");
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};

export const GETtipoImpuestosRetencionesApi = async () => {
  try {
    const res = await Axios.get("/GETTipoImpuestosRetenciones");
    return [res.data];
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};




