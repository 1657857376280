export const getPagosData = async (pagoactual, nuevopago) => {
  let generadatapagos = [];
  const { medio, total,propiedades, notas } = nuevopago;
  const pagoactualverifica = pagoactual.filter((op) => op.medio === medio);

  if (pagoactualverifica.length > 0) {
    generadatapagos = await pagoactual.map((op) => {
      if (op.medio === medio) {
        op.total = parseFloat(total).toFixed(2) 
        return op;
      } else {
        return op;
      }
    });
  } else {
    let pago = {
      medio: medio,
      total: parseFloat(total).toFixed(2),
      propiedades: {
        numero: propiedades.numero,
        banco: propiedades.banco,
      },
      notas: notas,
    };
    generadatapagos=pagoactual
    generadatapagos.push(pago);
  }

  return generadatapagos;
};
