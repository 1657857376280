import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { GetAfiliadosServiciosEmpresaApi } from "../../../api/afiliaciones_socios/servicios";
import {
  EditarServicioAsignacionApi,
  crearServicioAsignacionApi,
} from "../../../api/afiliaciones_socios/servicioContratado";
import { getTipoPago } from "../../../api/contable/configuraciones";
import { uploadFile } from "../../../s3";

const BANCOS = [
  { banco: "BANCO DE GUAYAQUIL", code: "bgye", tipo: "BANCO" },
  { banco: "BANCO PICHINCHA", code: "bpichincha", tipo: "BANCO" },
  { banco: "BANCO DEL PACIFICO", code: "bpacifico", tipo: "BANCO" },
  { banco: "BANCO INTERNACIONAL", code: "binternacional", tipo: "BANCO" },
  { banco: "BANCO BOLIVARIANO", code: "bbolivariano", tipo: "BANCO" },
  { banco: "BANCO DEL AUSTRO", code: "austro", tipo: "BANCO" },
  { banco: "BAN ECUADOR", code: "becuador", tipo: "BANCO" },
  { banco: "BANCO SOLIDARIO", code: "bsolidario", tipo: "BANCO" },
  { banco: "BANCO COMERCIAL DE MANABI", code: "bcomercial", tipo: "BANCO" },
  { banco: "BANCO PRODUBANCO", code: "bprodub", tipo: "BANCO" },
  { banco: "BANCO AMAZONAS", code: "bamaz", tipo: "BANCO" },
  { banco: "BANCO DEL MIRO SA", code: "bdelmir", tipo: "BANCO" },
  { banco: "BANCO DE MACHALA", code: "bmachal", tipo: "BANCO" },
  { banco: "BANCO DEL LITORAL", code: "blitor", tipo: "BANCO" },
  { banco: "BANCO GENERAL RUMIÑAUI", code: "brumi", tipo: "BANCO" },
  { banco: "BANCO PROCREDIT", code: "bprocred", tipo: "BANCO" },
  { banco: "BANCO SOLIDARIO", code: "bsoli", tipo: "BANCO" },
  { banco: "BANCO CITIBANK", code: "bciti", tipo: "BANCO" },
  { banco: "BANCO COOPNACIONAL S.A.", code: "bcoopnac", tipo: "BANCO" },
  { banco: "BANCO DE FOMENTO", code: "bfoment", tipo: "BANCO" },
  { banco: "BANCO DELBANK", code: "bdelban", tipo: "BANCO" },
  {
    banco: "COOP. AHORRO Y CREDITO LA DOLOROSA",
    code: "cladolo",
    tipo: "COOP",
  },
  { banco: "COOP. AHORRO Y CREDITO CREDI YA", code: "ccredi", tipo: "COOP" },
  { banco: "COOP. AHORRO Y CREDITO LA MERCED", code: "clamer", tipo: "COOP" },
  { banco: "COOP. AHORRO Y CREDITO OSCUS", code: "coscu", tipo: "COOP" },
  {
    banco: "COOP. AHORRO Y CREDITO POLICIA NACIONAL",
    code: "cpolic",
    tipo: "COOP",
  },
  {
    banco: "COOP. AHORRO Y CREDITO DE LA CAMARA DE AGRICULTURA",
    code: "ccamaracult",
    tipo: "COOP",
  },
  {
    banco: "COOP. AHORRO Y CREDITO COOPERA LTDA",
    code: "ccooper",
    tipo: "COOP",
  },
  { banco: "COOP. AHORRO Y CREDITO JEP", code: "cjep", tipo: "COOP" },
  { banco: "COOP. AHORRO Y CREDITO ARTESANOS", code: "cartesa", tipo: "COOP" },
  {
    banco: "COOP. AHORRO Y CREDITO SALITRE LTDA",
    code: "copsalit",
    tipo: "COOP",
  },
  {
    banco: "COOP. AHORRO Y CREDITO 29 DE OCTUBRE LTDA",
    code: "b29oc",
    tipo: "COOP",
  },
];

const CrearEditarAfiliadoServicios = ({
  editar,
  servicioContratado,
  setServicioContratado,
  actualizarLista,
  resetServicioContratado,
  setEditar,
  empresa,
  empresa_code,
}) => {
  const [servicios, setServicios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fomasPago, setFormasPago] = useState([]);
  const [progress, setProgress] = useState({
    status: false,
    progress: 0,
    file: "",
  });

  useEffect(() => {
    const getServiciosEmpresaFn = async () => {
      const respuesta = await GetAfiliadosServiciosEmpresaApi(empresa);
      const servicios = respuesta?.data?.filter(
        (data) => data.tipo_servicio === "BENEFICIOS_SOCIOS"
      );
      setServicios(servicios);
    };

    const getFormasPago = async () => {
      const formas = await getTipoPago();
      setFormasPago(formas[0].data);
    };

    getFormasPago();
    getServiciosEmpresaFn();
  }, [empresa]);

  useEffect(() => {
    if (servicioContratado.forma_pago !== "debito_cuenta_bancaria") {
      setServicioContratado((prevState) => ({
        ...prevState,
        cta_institucion: "",
        cta_bancaria: "",
        auth_debito_bancario: "",
      }));
    }
  }, [servicioContratado.forma_pago]);

  const onchange = (e) => {
    const { name, value } = e.target;

    let update = { [name]: value };

    if (name === "auth_debito_bancario") {
      update = { [name]: e.target.files[0] };
    }

    setServicioContratado((prevConfig) => ({
      ...prevConfig,
      ...update,
    }));
  };

  const guardarServicioContratado = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (servicioContratado.servicio_afiliado_config_id === "") {
      toast.error("Todos los campos son obligatorios");
      setLoading(false);
      return;
    }

    if (servicioContratado.forma_pago === "debito_cuenta_bancaria") {
      if (
        //servicioContratado.auth_debito_bancario === "" ||
        servicioContratado.cta_bancaria === "" ||
        servicioContratado.cta_institucion === ""
      ) {
        toast.error("Todos los campos son obligatorios");
        setLoading(false);
        return;
      }
    }
    let servicio = { ...servicioContratado };

    if (servicio.aplica_debito_bancario) {
      const res = await uploadFile(
        servicio.auth_debito_bancario,
        setProgress,
        empresa_code
      );

      if (res.success) {
        servicio.auth_debito_bancario = res.key;
      } else {
        setServicioContratado((prevState) => ({
          ...prevState,
          auth_debito_bancario: "",
        }));
        toast.error("Error al subir el archivo");
        resetServicioContratado();
        setEditar(false);
        setLoading(false);
        setProgress({ status: false, progress: 0, file: "" });
        return;
      }
    }

    setLoading(true);
    try {
      const apiFunc = editar
        ? EditarServicioAsignacionApi
        : crearServicioAsignacionApi;

      const respuesta = await apiFunc(servicio);
      if (respuesta.sms === "ok") {
        toast.success(respuesta.mensaje);
        actualizarLista();
        resetServicioContratado();
        setProgress({ status: false, progress: 0, file: "" });
        setEditar(false);
      } else {
        toast.error(respuesta.mensaje);
      }
    } catch (error) {
      toast.error("Error al procesar la petición");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {servicios.length > 0 ? (
        <Form inline className="row py-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className=" negrita">Servicio</label>
              <select
                className=" inputselect bordecompleto"
                id="servicio_afiliado_config_id"
                name="servicio_afiliado_config_id"
                style={{ width: "100%" }}
                onChange={onchange}
                value={servicioContratado.servicio_afiliado_config_id}
              >
                <option value="">Seleccione el servicio</option>
                {servicios.length > 0
                  ? servicios.map((data, index) => {
                      return (
                        <option
                          value={data.servicio_afiliado_config_id}
                          key={index}
                        >
                          {data.detalle_pago} - ${data.valor}{" "}
                          {data.periodo_pagos
                            ? ` ${data.periodo_pagos.periodo}`
                            : null}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="negrita">Forma de pago</label>
              <select
                className="inputselect bordecompleto"
                id="forma_pago"
                name="forma_pago"
                style={{ width: "100%" }}
                onChange={onchange}
                value={servicioContratado.forma_pago}
              >
                <option value="">Seleccione la forma de pago</option>
                {fomasPago.map((data, index) => {
                  return (
                    <option value={data.codigo_forma_pago} key={index}>
                      {data.codigo_forma_pago}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {servicioContratado.forma_pago === "debito_cuenta_bancaria" && (
            <div className="col-md-12 m-0 p-0">
              <div className="bordecompleto bgfondo2 py-2 row">
                <div className="col-md-12 col-sm-12 col-lg-12 py-2">
                  <label style={{ color: "blue" }} for="">
                    Al seleccionar el metodo de pago por débito bancario es
                    necesario ingresar el banco y el certificado de autorización
                    del socio.
                  </label>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label className="negrita">
                    Seleccione la entidad bancaria
                  </label>
                  <div className="form-group d-flex">
                    <select
                      className="inputselect bordecompleto"
                      id="cta_institucion"
                      name="cta_institucion"
                      style={{ width: "100%" }}
                      onChange={onchange}
                      value={servicioContratado.cta_institucion}
                    >
                      <option value="">Seleccione la entidad bancaria</option>
                      {BANCOS.map((data, index) => {
                        return (
                          <option value={data.code} key={index}>
                            {data.banco}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label className="negrita">Numero de cuenta</label>
                  <div className="form-group d-flex">
                    <input
                      className="inputselect bordecompleto"
                      type="text"
                      id="cta_bancaria"
                      name="cta_bancaria"
                      onChange={onchange}
                      value={servicioContratado.cta_bancaria}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex flex-column py-2 bgfondo2">
                  <label className="negrita">
                    Adjunte documento de autorización de dóbito
                  </label>
                  <div className="form-group d-flex flex-column">
                    <input
                      className="inputselect bordecompleto"
                      type="file"
                      id="auth_debito_bancario"
                      name="auth_debito_bancario"
                      onChange={onchange}
                    />
                    {progress.status && (
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${progress.progress}%` }}
                          aria-valuenow={progress.progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress.progress}%
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-center flex-row align-items-center my-3">
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </div>
            ) : (
              <>
                <button
                  className="w-50 bg-btn-vet-opciones bordesombreado mx-1"
                  disabled={loading}
                  onClick={guardarServicioContratado}
                >
                  {editar ? "Editar" : "Agregar"}
                </button>
                {editar && (
                  <button
                    className="w-5 bg-btn-vet-opciones bordesombreado"
                    onClick={() => {
                      resetServicioContratado();
                      setEditar(false);
                    }}
                  >
                    X
                  </button>
                )}
              </>
            )}
          </div>
        </Form>
      ) : (
        <div className="flex-column d-flex justify-content-between py-5 align-items-center w-100">
          <i
            class="fa-solid fa-triangle-exclamation "
            style={{ fontSize: "100px" }}
          ></i>
          <h5>No se ah configurado ningún beneficio para los afiliados/socios</h5>
        </div>
      )}
    </>
  );
};

export default CrearEditarAfiliadoServicios;
