import React, { useState, useContext } from "react";
import TablaPanelServicios from "../PanelProductos.componentes/tabla.panelServicios";
import ModalDefault from "../../modalDefault";
import { ReactComponent as AdminIcon } from "../../../icons/admin.svg";
import CrearEditarProductos from "../PanelProductos.componentes/crearEditarProductos";
import AuthContext from "../../../Context/auth/authContext";

const Servicios = () => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;
  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];

  const [modalCrearProductos, setmodalCrearProductos] = useState(false);
  const [actualiza, setActualiza] = useState(false);

  const modalActions = (modal, bandera) => {
    setmodalCrearProductos(modal);
    actualizarTabla();
  };

  const actualizarTabla = () => {
    setmodalCrearProductos(false);
    setActualiza(!actualiza);
  };

  return (
    <>
      <div className="col-md-12 m-0 p-0 ">
        <div className="row  d-flex flex-column ">
          <div
            className="d-flex justify-content-start align-items-center bgfondo "
            style={{ height: "60px" }}
          >
            <h5 className="titulosubmenusize">PANEL SERVICIOS</h5>
            <div className="mx-2">
              <button
                type=""
                className="bg-btn-vet-opciones px-2 bordesombreado mx-2 bordecompleto d-flex align-items-center"
                onClick={() => {
                  setmodalCrearProductos(true);
                }}
              >
                <i className="fas fa-plus size16  "></i>
                <label for="" className="mx-1" style={{ fontSize: "12px" }}>
                  Nuevo servicio
                </label>
              </button>
            </div>
          </div>

          <div ref={null} className="py-3 bordecircular">
            <TablaPanelServicios
              actualizar={actualiza}
              empresa_id={veterinaria_id}
              crearrModalActualizar={actualizarTabla}
              type={"servicio"}
            />
          </div>
        </div>

        <ModalDefault
          bandera={"crearproductos"}
          modal={modalCrearProductos}
          size={"md"}
          maxWidth="50%"
          icontitulo={<AdminIcon />}
          titulo={`Crear Producto`}
          closeAction={modalActions}
        >
          <CrearEditarProductos
            crearrModalActualizar={actualizarTabla}
            type={"servicio"}
          />
        </ModalDefault>
      </div>
    </>
  );
};

export default Servicios;
