import React from "react";
import DataTable from "react-data-table-component";
import { customStylesTable } from "../customStyles/dataTable";
const DataTableDefault = (props) => {
  //normalize('NFD').replace(/[\u0300-\u036f]/g,"")
  const paginationController = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos los datos",
  };
  return (
    <div className="w-100">
      <DataTable
        columns={props.columns}
        data={props.data}
        dense
        title={props.title}
        /*         responsive={false}
         */ pagination={false}
        paginationComponentOptions={paginationController}
        fixedHeader={true}
        fixedHeaderScrollHeight={props.height}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[5, 10, 20]}
        customStyles={customStylesTable}
        conditionalRowStyles={props.conditionalRowStyles}
        pointerOnHover
        overflowYOffset="250px"
        highlightOnHover
        //progressPending={true}
      />
    </div>
  );
};

export default DataTableDefault;
