import React, { useContext, useEffect } from "react";
import PacienteContext from "../../Context/pacientes/pacientesContext";
import AuthContext from "../../Context/auth/authContext";

const CustomOverlay = (props) => {

  const authContext = useContext(AuthContext);
  const { sessiondatacli,color,textColor,index } = authContext;


    const PacientesContext = useContext(PacienteContext);
    const {
      BuscarDataPaciente,
      paciente,
    } = PacientesContext;
    
    useEffect(() => {
        BuscarDataPaciente(props.mascota);
      
      return ()=>{
        //limpiarEstado()
      }
      
    }, []);


    const verifica =(dat,vet_id)=>{
      let aux = false
      dat.map((data,index)=>{
          if(data.veterinaria_id===vet_id){
            aux=true
          }
      })
      return aux     
    }

    return paciente.length > 0 ?
    (paciente[0].escliente === null ?
      (
        <div 
            className="position-absolute"
            style={{
              width: "calc( 100% + 4px)",
              height: "calc( 100% + 4px)",
              background: "#2b2b2ba2",
              zIndex: 10,
              margin: 0,
            }}
        >
        </div>
      ) : !verifica(paciente[0].escliente,sessiondatacli[index].veterinaria[0].veterinaria_id)?(
        <div 
            className="position-absolute"
            style={{
              width: "calc( 100% + 4px)",
              height: "calc( 100% + 4px)",
              background: "#2b2b2ba2",
              zIndex: 10,
              margin: 0,
            }}
        >
        </div>
      ):null
    ) : null
} 

export default CustomOverlay

{/*(
      <div 
          className="position-absolute"
          style={{
            width: "calc( 100% + 4px)",
            height: "calc( 100% + 4px)",
            background: "#2b2b2ba2",
            zIndex: 10,
            margin: 0,
          }}
      >
      </div>
    )  */}