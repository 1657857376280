const TABLE_HEAD_COLOR="#e9e9e9"
const TABLE_TEXT_COLOR="black"


export const customStylesTable = {
  tableWrapper: {
    style: {
      display: "table",
        maxHeight: "50px",
        minHeight:"50px"
    },
  },
  rows: {
    style: {
      minHeight: "10px",
      border: "1px solid #ced4da !important",
    },
  },
  headCells: {
    style: {
      color: TABLE_TEXT_COLOR,
      background: TABLE_HEAD_COLOR,
      fontWeight: "bold",
      paddingLeft: "2px", // override the cell padding for head cells
      paddingRight: "2px",
    },
  },
  cells: {
    style: {
      paddingLeft: "0px", // override the cell padding for data cells
      paddingRight: "0px",
    },
  },
};