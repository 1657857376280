import React, { useState, useEffect, useContext } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { GetAllPeriodosPagosApi } from "../../../api/afiliaciones_socios/periodos_pagos";

import {
  guardarProductosApi,
  guardarServiciosApi,
  updateProductosApi,
} from "../../../api/contable/productos";
import {
  getPorcentajeImpuesto,
  getTipoImpuesto,
} from "../../../api/contable/configuraciones";
import { toast } from "react-toastify";
import { getCategoriasEmpresaApi } from "../../../api/contable/categorias";
import { getMedidasEmpresaApi } from "../../../api/contable/medidas";
import AuthContext from "../../../Context/auth/authContext";

const CrearEditarProductos = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;
  const { veterinaria_id } = sessiondatacli[index].veterinaria[0];
  const [periodosPagos, setPeridosPagos] = useState([]);

  const [editar, setEditar] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loadingCrearProductos, setLoadingCrearProductos] = useState(false);
  const [porcentajeImpuestos, setPorcentajeImpuestos] = useState([]);
  const [tipoImpuestos, setTipoImpuestos] = useState([]);
  const [state, setState] = useState({
    codigo_producto: "",
    codigo_barras: "",
    descripcion_producto: "",
    categoria_id: "",
    empresa_id: veterinaria_id,
    stock_minimo: 0,
    precio_compra: 0,
    precio_venta: 0,
    servicio: "",
    tipoitem: props.type,
    medida_id: "",
    iva_code_porcent: "",
    iva_code: "",
    rice_code_porcent: "",
    rice_code: "",
    irbpnr_code_porcent: "",
    irbpnr_code: "",
    iva_tarifa: "",
    rice_tarifa: "",
    irbpnr_tarifa: "",
    recurrente: false,
    periodo_pago_id: "",
  });

  const [stateReset, setStateReset] = useState({
    codigo_producto: "",
    codigo_barras: "",
    descripcion_producto: "",
    categoria_id: "",
    empresa_id: veterinaria_id,
    stock_minimo: 0,
    precio_compra: 0,
    precio_venta: 0,
    servicio: "",
    tipoitem: props.type,
    medida_id: "",
    iva_code_porcent: "",
    iva_code: "",
    rice_code_porcent: "",
    rice_code: "",
    irbpnr_code_porcent: "",
    irbpnr_code: "",
    iva_tarifa: "",
    rice_tarifa: "",
    irbpnr_tarifa: "",
    recurrente: false,
    periodo_pago_id: "",
  });
  const [editState, setEditState] = useState({
    campoedit: "",
    nuevovalor: "",
    id: "",
  });

  const [editStatereset, setEditStatereset] = useState({
    campoedit: "",
    nuevovalor: "",
    id: "",
  });

  const [respaldoEditField, setRespaldoEditField] = useState("");

  const editarFn = async (e) => {
    e.preventDefault();
    setButtonState(true);
    const res = await updateProductosApi(editState);
    if (res[0].sms === "ok") {
      toast.success(`${res[0].mensaje}`, {
        theme: "colored",
      });
      setButtonState(false);
      setEditState({
        ...editState,
        campoedit: "",
        nuevovalor: "",
      });
    } else {
      toast.error(`${res[0].mensaje}`, {
        theme: "colored",
      });
      setButtonState(false);
    }
  };

  const editbtn = (campoeditar) => {
    /* if (editState.campoedit === campoeditar) {
      document.getElementById(campoeditar).disabled = false;
    } */
    return (
      <div className="mx-2">
        {editar ? (
          <button
            className=" bg-btn-vet bordecompleto "
            onClick={(e) => {
              editarFn(e);
            }}
          >
            Guardar
          </button>
        ) : null}
      </div>
    );
  };
  const ed = (name, valor) => {
    setEditState({
      ...editState,
      nuevovalor: valor,
      campoedit: name,
    });
  };

  const onchange = async (e) => {
    if (e.target.name === "ice_code_porcent") {
      /* const res = await porcentajeImpuestos.filter(
        (elemento) => parseInt(elemento.codigo) === parseInt(e.target.value)
      ); */
      setState({
        ...state,
        ice_code: "3",
        ice_code_porcent: e.target.value,
      });
      ed(e.target.name, e.target.value);
      return;
    }
    if (e.target.name === "iva_code_porcent") {
      const res = await porcentajeImpuestos.filter(
        (elemento) => parseInt(elemento.codigo) === parseInt(e.target.value)
      );
      console.log(e.target.value);
      console.log("===========================");
      console.log(res);
      setState({
        ...state,
        iva_code: "2",
        iva_code_porcent: res[0].codigo,
        iva_tarifa: res[0].tarifa,
      });
      ed(e.target.name, res[0].codigo);
      return;
    }

    if (e.target.name === "servicio") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
      ed(e.target.name, e.target.value);
      return;
    }
    if (e.target.name === "aplica_iva") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
      ed(e.target.name, e.target.value);

      return;
    }
    if (e.target.name === "codigo_producto") {
      setState({
        ...state,
        codigo_producto: e.target.value.replace(/ /g, "-"),
      });
      ed(e.target.name, e.target.value.replace(/ /g, "-"));
      return;
    }
    if (e.target.name === "codigo_barras") {
      setState({
        ...state,
        codigo_barras: e.target.value.replace(/ /g, "-"),
      });
      ed(e.target.name, e.target.value.replace(/ /g, "-"));

      return;
    }

    if (e.target.name === "periodo_pago_id") {
      setState({
        ...state,
        periodo_pago_id: e.target.value,
        recurrente: true,
      });
      ed(e.target.name, e.target.value);

      return;
    }

    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    ed(e.target.name, e.target.value);
  };

  const guardarProductos = (e) => {
    e.preventDefault();
    if (state.precio_venta === "") {
      toast.error(`Debe agregar un precio al ${state.tipoitem}`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    if (state.categoria_id === "") {
      toast.error(`Debe seleccionar una categoría`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (state.medida_id === "") {
      toast.error(`Debe seleccionar una medida`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }

    if (state.iva_code_porcent === "") {
      toast.error(`Seleccione el iva`, {
        theme: "colored",
        position: "bottom-left",
      });
      return;
    }
    saveProductos();
  };

  const saveProductos = async () => {
    setLoadingCrearProductos(true);
    const respuesta =
      state.tipoitem === "servicio"
        ? await guardarServiciosApi(state)
        : await guardarProductosApi(state);
    if (respuesta[0].sms === "ok") {
      toast.success(
        respuesta[0].mensaje,
        { position: "bottom-left" },
        {
          theme: "colored",
          position: "bottom-left",
        }
      );
      cerrarModalActualizarTablaProductos();
    } else {
      toast.error(
        respuesta[0].mensaje,
        { position: "bottom-left" },
        {
          theme: "colored",
          position: "bottom-left",
        }
      );
    }
    setLoadingCrearProductos(false);
  };

  const cerrarModalActualizarTablaProductos = () => {
    props.crearrModalActualizar();
  };

  const ActualizarTablaProductos = () => {
    props.crearrModalActualizar();
  };

  useEffect(() => {
    if(state.recurrente){
      getPeriodosPafosFn();
    }
  }, [state.recurrente]);

  useEffect(() => {
    if (props.selectData) {
      setEditar(true);
      initEditar();
    }
  }, []);
  useEffect(() => {
    llenar_categorias();
    llenar_medidas();
    llenarPorcentajesImpuestos();
    llenarTipoImpuestos();
  }, []);
  

  const llenarPorcentajesImpuestos = async () => {
    const porcentajeImpuestos = await getPorcentajeImpuesto();
    setPorcentajeImpuestos(porcentajeImpuestos[0].data);
  };
  const llenarTipoImpuestos = async () => {
    const tipoimpuestos = await getTipoImpuesto();
    setTipoImpuestos(tipoimpuestos[0].data);
  };

  const llenar_categorias = async () => {
    const categorias = await getCategoriasEmpresaApi(veterinaria_id);
    const options = categorias[0].data.map((item) => {
      return {
        value: item.categoria_id,
        label: `${item.categoria}`,
      };
    });
    setCategorias(options);
  };
  const llenar_medidas = async () => {
    const medidas = await getMedidasEmpresaApi(veterinaria_id);
    setMedidas(medidas[0].data);
  };
  const initEditar = () => {
    setState({
      ...state,
      codigo_producto: props.selectData.codigo_producto,
      codigo_barras: props.selectData.codigo_barras,
      descripcion_producto: props.selectData.descripcion_producto,
      categoria_id: props.selectData.categorias.categoria_id,
      stock_minimo: props.selectData.stock_minimo,
      precio_compra: props.selectData.precio_compra,
      precio_venta: props.selectData.precio_venta,
      servicio: props.selectData.servicio,
      tipoitem: props.selectData.tipoitem,
      medida_id: props.selectData.medidas
        ? props.selectData.medidas.medida_id
        : "",
      iva_code: props.selectData.iva_code,
      iva_code_porcent: props.selectData.iva_code_porcent,
      rice_code: props.selectData.rice_code,
      rice_code_porcent: props.selectData.rice_code_porcent,
      iva_tarifa: props.selectData.iva_tarifa,
      rice_tarifa: props.selectData.rice_tarifa,
      irbpnr_tarifa: props.selectData.irbpnr_tarifa,
      recurrente: props.selectData.recurrente,
      periodo_pago_id: props.selectData.periodo_pago_id,
    });

    setEditState({
      ...editState,
      id: props.selectData.producto_id,
    });
  };

  const getPeriodosPafosFn = async (e) => {
    const respuesta = await GetAllPeriodosPagosApi();
    setPeridosPagos(respuesta.data);
  };

  return (
    <div inline className="row">
      <div className="col-md-12">
        <div
          className={`w-100  d-flex justify-content-center negrita py-2 ${
            editar ? "alert alert-secondary" : "alert alert-secondary"
          }`}
        >
          <label style={{ fontSize: "20px" }}>
            {editar ? "SE MODIFICARÁ UN  " : "SE CREARA UN NUEVO"}{" "}
            {state.tipoitem.toUpperCase()}
          </label>
        </div>
        {/* <div className="form-group ">
          <label>Tipo de item:</label>
          <div className={"d-flex justify-content-between"}>
            {" "}
            <select
              className="inputselect bordecompleto"
              id="tipoitem"
              name="tipoitem"
              style={{ width: "100%" }}
              onChange={onchange}
              value={state.tipoitem}
            >
              <option value={"servicio"}>SERVICIO</option>
              <option value={"producto"}>PRODUCTO</option>
            </select>
            {editbtn("tipoitem")}
          </div>
        </div> */}
      </div>
      <div className="col-md-12 py-2">
        <div className="d-flex flex-column">
          <label className=" py-2 negrita">Seleccione una categoría</label>
        </div>
        <div className="d-flex justify-content-bewteen w-100 align-items-center">
          <Select
            name="categoria_id"
            value={categorias.filter(
              (option) => option.value === state.categoria_id
            )}
            options={categorias}
            className="basic-multi-select w-100 "
            classNamePrefix="select"
            placeholder="Seleccione una categoría de la lista"
            closeMenuOnSelect={true}
            onChange={(e) => {
              setState({
                ...state,
                categoria_id: e.value,
              });
              ed("categoria_id", e.value);
            }}
          />
          {!editar ? (
            <button
              type=""
              className="bg-btn-vet  mx-1"
              onClick={(e)=>getPeriodosPafosFn(e)}
              style={{ width: "35px" }}
            >
              <i className="fas fa-redo"></i>
            </button>
          ) : (
            editbtn("categoria_id")
          )}
        </div>
      </div>
      {/*  <div className="col-md-12">
        <div className="form-group ">
          <label>Seleccione una categorias :</label>
          <div className={"d-flex justify-content-between"}>
            {" "}
            <select
              className="inputselect bordecompleto"
              id="categoria_id"
              name="categoria_id"
              style={{ width: "100%" }}
              onChange={onchange}
              value={state.categoria_id}
            >
              <option value="0">Seleccione ...</option>
              {categorias.map((data, index) => (
                <option value={data.categoria_id} key={index}>
                  {data.categoria}
                </option>
              ))}
            </select>
            {editbtn("categoria_id")}
          </div>
        </div>
      </div> */}
      <div className="col-md-12">
        <div className="form-group ">
          <label className="negrita">Nombre del {props.type} :</label>
          <div className={"d-flex justify-content-between"}>
            {" "}
            <div className="align-items-center d-flex justify-content-center w-100 ">
              <input
                className="inputtext2 bordecompleto w-100"
                id="descripcion_producto"
                name="descripcion_producto"
                value={state.descripcion_producto}
                placeholder="Ingrese el nombre del producto"
                type="text"
                onChange={onchange}
              />
              {editbtn("descripcion_producto")}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 ">
        <hr className="w-100 my-2" />
      </div>
      <div className="col-md-12 ">
        <div className="form-group ">
          <label className="negrita">Código :</label>
          <div className={"d-flex justify-content-between"}>
            {" "}
            <input
              className="inputtext2 bordecompleto"
              id="codigo_producto"
              name="codigo_producto"
              value={state.codigo_producto}
              placeholder="Ingrese el código del producto máximo (25) caracteres"
              type="text"
              onChange={onchange}
            />
            {editbtn("codigo_producto")}
          </div>
        </div>
      </div>
      <div className="col-md-12 ">
        <div className="form-group ">
          <label className="negrita">Código auxiliar:</label>
          <div className={"d-flex justify-content-between"}>
            {" "}
            <input
              className="inputtext2 bordecompleto"
              id="codigo_barras"
              name="codigo_barras"
              value={state.codigo_barras}
              placeholder="Ingrese el código auxiliar  máximo (25) caracteres"
              type="text"
              onChange={onchange}
            />
            {editbtn("codigo_barras")}
          </div>
        </div>
      </div>
      <div className="col-md-12 py-0 my-0">
        <label for="" style={{ color: "blue" }}>
          <strong>Nota :</strong> Si no se ingresa ningún valor en el código del{" "}
          {state.tipoitem} el sistema generará y asignará de forma automática un
          código aleatorio de 15 dígitos. si solo se ingresa el código del{" "}
          {state.tipoitem} el código auxiliar tomará el mismo valor
        </label>
      </div>
      <div className="col-md-12 ">
        <hr className="w-100 my-2" />
      </div>
      {/* {esServicio === true ? null : (
        <FormGroup row className="d-flex align-items-center">
          <Label for="precio_compra" sm={2}>
            Precio compra :
          </Label>
          <Col sm={editar ? 8 : 10}>
            <Input
              className="inputtext2"
              
              id="precio_compra"
              name="precio_compra"
              value={state.precio_compra}
              placeholder="Ingrese el precio de compra producto"
              type="number"
              onChange={onchange}
            />
          </Col>
          {editbtn("precio_compra")}
        </FormGroup>
      )}{" "} */}
      <div className="col-md-12">
        <div className="form-group ">
          <label className="negrita">Precio del {props.type} :</label>
          <div className={"d-flex justify-content-between"}>
            {" "}
            <input
              className="inputtext2 bordecompleto"
              id="precio_venta"
              name="precio_venta"
              value={state.precio_venta}
              placeholder="Ingrese el precio de venta producto"
              type="number"
              onChange={onchange}
            />
            {editbtn("precio_venta")}
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group ">
          <label className="negrita">Medida :</label>
          <div className={"d-flex justify-content-between"}>
            <select
              className="inputselect bordecompleto"
              id="medida_id"
              name="medida_id"
              style={{ width: "100%" }}
              onChange={onchange}
              value={state.medida_id}
            >
              <option value="">Seleccione ...</option>
              {medidas.map((data, index) => (
                <option value={data.medida_id} key={index}>
                  {data.medidas}
                </option>
              ))}
            </select>
            {editbtn("medida_id")}
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group ">
          <label className="negrita">Iva :</label>
          <div className={"d-flex justify-content-between"}>
            <select
              className="inputselect bordecompleto"
              id="iva_code_porcent"
              name="iva_code_porcent"
              style={{ width: "100%" }}
              onChange={onchange}
              value={state.iva_code_porcent}
            >
              <option value="">Seleccione ...</option>
              {porcentajeImpuestos.map((data, index) => (
                <option value={data.codigo} key={index}>
                  {data.porcentaje}
                </option>
              ))}
            </select>
            {editbtn("iva_code_porcent")}
          </div>
        </div>
      </div>
      {props.type === "servicio" ? null : (
        <div className="col-md-12">
          <div className="form-group ">
            <label className="negrita">Stock mínimo :</label>
            <div className={"d-flex justify-content-between"}>
              {" "}
              <input
                className="inputtext2 bordecompleto"
                id="stock_minimo"
                name="stock_minimo"
                value={state.stock_minimo}
                placeholder="Ingrese el stock minimo del producto"
                type="number"
                onChange={onchange}
              />
              {editbtn("stock_minimo")}
            </div>
          </div>
        </div>
      )}
      {/* {state.tipoitem==="servicio"?<div className="col-md-12 my-3 d-flex justify-content-between">
        <FormGroup
          switch
          className="d-flex justify-content-start align-items-center"
        >
          <Input
            style={{ width: "50px", height: "20px" }}
            type="switch"
            checked={state.recurrente}
            onClick={() => {
              setState({
                ...state,
                recurrente: !state.recurrente,
              });
            }}
          />
          <Label className="mx-2" check>
            Pago recurrente
          </Label>
        </FormGroup>
      </div>:null} */}

      {/* {state.tipoitem === "servicio" && state.recurrente === true ? (
        <div className="col-md-12">
          <label className="negrita">
            Al activar el pago recurrente debe asignar el periodo de tiempo.
          </label>

          <div className="form-group d-flex justify-content-center">
            <select
              select
              className=" inputselect2 bordecompleto"
              id="periodo_pago_id"
              name="periodo_pago_id"
              style={{ width: "100%" }}
              onChange={onchange}
              value={state.periodo_pago_id}
            >
              <option value={""} key={index}>
                Seleccione
              </option>
              {periodosPagos.length > 0
                ? periodosPagos.map((data, index) => {
                    return (
                      <option value={data.periodo_pago_id} key={index}>
                        {data.periodo}
                      </option>
                    );
                  })
                : null}
            </select>
            {editbtn("periodo_pago_id")}
          </div>
        </div>
      ) : null} */}
      {/*  <div className="form-group row">
          <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
            {" "}
            <label>ICE:</label>
          </div>
          <div className={"col-sm"}>
            {" "}
            <select
              className="inputselect form-control  select2bs4"
              id="ice_code_porcent"
              name="ice_code_porcent"
              style={{ width: "100%" }}
              onChange={onchange}
              value={state.ice_code_porcent}
            >
              <option value="">Seleccione ...</option>
              {porcentajeImpuestos.map((data, index) => (
                  <option value={data.codigo} key={index}>
                    {data.porcentaje}
                  </option>
                ))} 
            </select>
          </div>
          <div className="col-md-2">{editbtn("ice_code_porcent")}</div>
        </div> */}
      {!editar ? (
        <div className="d-flex justify-content-center flex-column align-items-center my-3">
          <hr className="w-100" />
          {buttonState ? (
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <button
              className="w-50 bg-btn-vet-opciones bordesombreado"
              disabled={loadingCrearProductos ? true : false}
              onClick={(e) => {
                guardarProductos(e);
              }}
            >
              Guardar nuevo {state.tipoitem}
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CrearEditarProductos;
