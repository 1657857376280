import Axios from "../../config/AxiosContable";

export const crearPeriodosPagosApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTCrearPeriodoPago`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const crearEditarPeriodosPagosApi = async (data) => {
  try {
    const result = await Axios.get(`/POSTEditarPeriodoPago`, data);
    return [result.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const GetAllPeriodosPagosApi = async () => {
  try {
    const result = await Axios.get(`/GETAllPeriodosPagos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};


