import React, { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../../Context/auth/authContext";
import Banner from "../../assets/portada.gif";
import Logo from "../../assets/logo.png";

import { ReactComponent as ArrobaIcon } from "../../icons/mail.svg";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";

const Login = () => {
  const ref = useRef([]);
  const [loading, setloading] = useState(false);
  let authContext = useContext(AuthContext);
  const { authenticationfn } = authContext;
  const [state, setstate] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    ref.current["username"].focus();
  }, []);

  const onChange = (e) => {
    if (e.target.name === "username")
      setstate({
        ...state,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    else
      setstate({
        ...state,
        [e.target.name]: e.target.value,
      });
  };

  const logear = async (e) => {
    setloading(true);
    const res = await authenticationfn(state);
    if (res) {
      window.location = "/";
    } else {
      setloading(false);
    }
  };

  const detectarBotonPresionado = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "username") ref.current["password"].focus();
      else if (e.target.name === "password") logear();
    }
  };
  return (
    <div
      className="w-100 d-flex justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <div
        className=" d-flex justify-content-center align-items-center flex-column bgfondo w-70 "
        /*  style={{
          height: "100%",
          backgroundImage: "url(https://i.ibb.co/3p1B1V9/logo-4.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }} */
      >
        <img
          //src="https://cloudfront-us-east-1.images.arcpublishing.com/eluniverso/SYM35JYEY5EC7KL54IABNIUG2M.jpg"
          src={Banner}
          className="w-100 h-100"
          alt=""
          style={{ opacity: "0.8" }}
        />
      </div>
      <div className="w-30 d-flex justify-content-center flex-column azulmarino ">
        <div className=" w-100">
          {/*begin::Form*/}
          <form className="form card-body " id="">
            {/*begin::Title*/}
            <div className="pb-13 pt-lg-0 py-5 text-center">
              <img
                src={Logo}
                alt=""
                width={230}
                height={230}
                className=" bordesombreado"
                style={{ borderRadius: "200px", border: "3px solid #dcd3d4" }}
              />
            </div>
            {/*begin::Title*/}
            {/*begin::Form group*/}
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center inputIcon bordecompleto bordesombreado">
                  <ArrobaIcon />
                </div>
                <input
                  className="inputtext  w-80"
                  type="text"
                  placeholder="Digite su correo"
                  name="username"
                  ref={(r) => (ref.current["username"] = r)}
                  value={state.username}
                  onChange={onChange}
                  onKeyPress={detectarBotonPresionado}
                />
              </div>
            </div>
            {/*end::Form group*/}
            {/*begin::Form group*/}
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center inputIcon bordecompleto bordesombreado">
                  <LockIcon />
                </div>
                <input
                  className="inputtext "
                  type="password"
                  name="password"
                  ref={(r) => (ref.current["password"] = r)}
                  placeholder="Digite su contraseña"
                  value={state.password}
                  onChange={onChange}
                  onKeyPress={detectarBotonPresionado}
                />
              </div>
            </div>
            {/*end::Form group*/}
            {/*begin::Action*/}
            <div className="">
              <button
                type="button"
                onClick={logear}
                disabled={loading}
                className="bg-btn-vet-login bordecompleto w-100 p-3 my-3  naranja bordesombreado"
              >
                {loading ? (
                  <div class="spinner-border text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <label className="text-btn negrita ">Acceder al sistema</label>
                )}
              </button>
            </div>
            <div className="w-100">
              <label for="" className="color-plomo">Nos estamos renovando , para brindarte nuevas funccionalidades.</label>
              <label for="" className="color-plomo negrita">Att: SisproEc </label>
            </div>
            {/*end::Action*/}
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  );
};

export default Login;
