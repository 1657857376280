export const filtrarTablaDocumentos = async (array, dato) => {
  console.log(array);
  console.log(dato);
  var listado = [];
  listado = array;
  var resutadobusqueda = await listado.filter(
    (elemento) =>
      elemento.identificacion_cliente.toString().includes(dato.toString()) ||
      JSON.parse(elemento.comprador)
        .razon_social.toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(dato.toString().toLowerCase())
  );
  /* JSON.parse(elemento.comprador)
        .nombres.toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(dato.toString().toLowerCase()) */

  return resutadobusqueda;
};
