import moment from "moment";
import { getEmisorData } from "./getEmisor";

export const getInfoDocumento = async(
  infovet,
  usuario_id,
  codigo_documento,
  ambiente
) => {


const emisorValidate = await  getEmisorData(infovet) 

if(emisorValidate.validate==="ok"){
    const { veterinaria_id, api_key, firma_certificado_clave } = infovet;
    const state = {
      venta_cabecera_id: "",
      usuario_id: usuario_id,
      editar: "",
      x_key: api_key,
      x_password: firma_certificado_clave,
      anulado: false,
      facturado: "true",
      identificacion_cliente: "",
      identificacion_transportista: "",
      tipo_emision: 1,
      codigo_identificacion: "",
      codigo_identificacion_transportista: "",
      codigo_documento: codigo_documento,
      codigo_forma_pago: "",
      codigo_forma_pago_sri: "",
      fecha_venta: moment(new Date()).format("YYYY-MM-DD"),
      n_establecimiento: "",
      n_punto_venta: "",
      n_factura: "",
      subtotal_iva: "0.00",
      subtotal_cinco:"0.00",
      subtotal_quince:"0.00",
      subtotal_siniva: "0.00",
      subtotal_no_objeto_iva: "0.00",
      subtotal_exento_iva: "0.00",
      subtotal_sin_impuestos: "0.00",
      total_descuento: "0.00",
      ice: "0.00",
      iva: "0.00",
      ivacinco:"0.00",
      ivaquince:"0.00",
      total_devolucion_iva: "",
      irbpnr: "0.00",
      propina: "0.00",
      valor_total: "0.00",
      empresa_id: veterinaria_id,
      activo: "",
      ambiente: ambiente,
      ventas_detalle: [],
      emisor: emisorValidate.data,
      moneda: "DOLAR",
      traslado: {
        tipoidentificaciontransportista: "",
        ructransportista: "",
        razonsocialtransportista: "",
        fechainitransporte: "",
        fechafintransporte: "",
        correotranportista: "",
        placa: "",
        telefonotransportista: "",
        dirpartida: "",
      },
      comprador: {
        correo: "",
        identificacion: "",
        tipo_identificacion: "",
        razon_social: "",
        direccion: "",
        telefono: "",
        motivotraslado: "",
        ruta: "",
        periodofiscal: "",
        parterel: "",
      },
      docsustento: {
        codsustento: "",
        coddocsustento: "",
        numdocsustento: "",
        numautdocsustento: "",
        fechaemisiondocsustento: "",
        pagolocext: "01",
        totalSinImpuestos: "",
        importeTotal: "",
        impuestosdocsustento: [],
        pagosdocsustento: [],
        retencionesdocsustento: [],
      },
      retenciones: [],
      pagos: [],
      deletes: [],
      info_adicional: [],
    };

    return { validate: "ok", data: state ,message:""};
}else{
  console.log(emisorValidate)
   return { validate: "error", data: null, message: emisorValidate.message };

}

};
