import Axios from "../../config/AxiosFacturacion";

export const getTipoIdentificacion = async () => {
  try {
    const res = await Axios.get("/getTipoIdentificacion");
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getTipoDeDocumento= async () => {
  try {
    const res = await Axios.get("/getTipoDocumentos")
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getTipoPago = async () => {
  try {
    const res = await Axios.get("/getTipoPago");
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getTipoPagoSri = async () => {
  try {
    const res = await Axios.get("/getTipoPagoSri");
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const getTipoImpuesto = async () => {
  try {
    const res = await Axios.get("/getTipoImpuestos");
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};


export const getPorcentajeImpuesto= async () => {
  try {
    const res = await Axios.get("/getCodigoPorcentajes");
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

