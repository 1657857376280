import Axios from "../../config/AxiosContable";

export const ApiPOSTCrearCaja = async (state) => {
  try {
    const res = await Axios.post("/POSTCrearCaja", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};


export const ApiPOSTEditarCaja = async (state) => {
  try {
    const res = await Axios.post("/POSTEditarCaja", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const ApiPOSTEliminarCaja = async (state) => {
  try {
    const res = await Axios.post("/POSTEliminarCaja", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const ApiPOSTActivarDesactivarCaja = async (state) => {
  try {
    const res = await Axios.post("/POSTActivarDesactivarCaja", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};

export const ApiPOSTGetCajasXEmpresas = async (state) => {
  try {
    const res = await Axios.post("/POSTGetCajasXEmpresas", state);
    return [res.data];
  } catch (e) {
    return [{ sms: "ok", mensaje: e, data: [] }];
  }
};


