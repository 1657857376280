import Axios from "../../config/AxiosContable";

export const crearAfiliadoSocioServiciosApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTCrearAfiliadoServiciosConfig`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const EditarAfiliadoSocioServiciosApi = async (data) => {
  try {
    const result = await Axios.post(`/POSTEditarAfiliadoServiciosConfig`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const GetAfiliadosServiciosEmpresaApi = async (veterinaria_id) => {
  try {
    const result = await Axios.get(
      `/GETAllAfiliadoServiciosConfigEmpresa/${veterinaria_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const GetAfiliadosServiciosEmpresaActivarDesactivarApi = async (
  afiliado_servicio_id,
  estado
) => {
  try {
    const result = await Axios.get(
      `/GETactivarDesactivarAfiliadoServiciosConfig/${afiliado_servicio_id}/${estado}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};

export const EliminarAfiliadoServiciosApi = async (data) => {
  try {
    const result = await Axios.post(
      `/POSTEliminarAfiliadoServiciosConfig`,
      data
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: "",
        mensaje: "Ocurrio un problemas",
      },
    ];
  }
};
