import React, { useEffect, useState, useContext } from "react";
import Axios from "../../../../config/Axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import AuthContext from "../../../../Context/auth/authContext";
import CustomOverlay from "../../../extras/customOverlay";
import TextareaAutosize from "react-textarea-autosize";
const ExamenFisico = (props) => {
  const authContext = useContext(AuthContext);
  const { sessiondatacli, index } = authContext;

  const [state, setState] = useState({
    itemsAtp: [],
    todos: [],
    currentPage: 1,
    todosPerPage: 7,
    search: "",
    fecha_buscar: "",
  });

  const [stateMedicinaGeneral, setstateMedicinaGeneral] = useState({
    medicina_general_id: "",
    fecha_atencion: "",
    temperatura: 0.0,
    peso: 0.0,
    hidratacion: "",
    frecuencia_cardiaca: 0,
    pulso: 0,
    frecuencia_respiratoria: 0,
    general: "",
    ojos_ong: "",
    piel_tugumento: "",
    musculo_esqueletico: "",
    cardiovascular: "",
    respiratorio: "",
    gastrointestinal: "",
    genital_reproductor: "",
    neurologico: "",
    linfatico: "",
    otros: "",
    mascota_id: null,
    veterinaria_id: sessiondatacli[index].veterinaria[0].veterinaria_id,
    usuario_id: sessiondatacli[index].persona[0].usuario_id,
    registro_ficha_id: props.registro_ficha_id,
  });

  const [stateModal, setStateModal] = useState({
    UsuarioModal: false,
    EliminarModal: false,
    userdata: {},
    configModal: false,
    medicina_general_id: "",
  });

  const onchangeMedicinaGeneral = (e) => {
    e.preventDefault();
    setstateMedicinaGeneral({
      ...stateMedicinaGeneral,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  useEffect(() => {
    inicializarTodasMedicinaGeneral();
  }, [props]);

  const CrearMedicinaGeneral = async (e) => {
    e.preventDefault();

    try {
      const result = await Axios.post(
        "/createMedicinaGeneral",
        stateMedicinaGeneral
      );

      if (result.data.sms === "ok") {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
        inicializarTodasMedicinaGeneral();
      } else {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
      }
    } catch (e) {}
  };

  const ActualizarMedicinaGeneral = async (e) => {
    e.preventDefault();

    try {
      const result = await Axios.post(
        "/editarMedicinaGeneral",
        stateMedicinaGeneral
      );

      if (result.data.sms === "ok") {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "success");
        inicializarTodasMedicinaGeneral();
      } else {
        Swal.fire("Aviso!", `${result.data.mensaje}!`, "error");
      }
    } catch (e) {}
  };

  const EditarMedicinaGeneral = (todo) => {
    setstateMedicinaGeneral({
      ...stateMedicinaGeneral,
      medicina_general_id: todo.medicina_general_id,
      fecha_atencion: todo.fecha_atencion,
      temperatura: todo.temperatura,
      peso: todo.peso,
      hidratacion: todo.hidratacion,
      frecuencia_cardiaca: todo.frecuencia_cardiaca,
      pulso: todo.pulso,
      frecuencia_respiratoria: todo.frecuencia_respiratoria,
      general: todo.general,
      ojos_ong: todo.ojos_ong,
      piel_tugumento: todo.piel_tugumento,
      musculo_esqueletico: todo.musculo_esqueletico,
      cardiovascular: todo.cardiovascular,
      respiratorio: todo.respiratorio,
      gastrointestinal: todo.gastrointestinal,
      genital_reproductor: todo.genital_reproductor,
      neurologico: todo.neurologico,
      linfatico: todo.linfatico,
      otros: todo.otros,
    });
  };

  const inicializarTodasMedicinaGeneral = async () => {
    try {
      const respuesta = await Axios.get(
        `/filtrarPorCoincidenciasMedicinaGeneral/` + props.registro_ficha_id
      );

      if (respuesta.data.sms === "ok") {
        if (respuesta.data.result.length > 0) {
          console.log("si");
          EditarMedicinaGeneral(respuesta.data.result[0]);
          setState({
            ...state,
            todos: respuesta.data.result,
          });
        }
      } else {
      }
    } catch (e) {}
  };

  return (
    <dv>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">Temperatura:</div>
              <div className="col-md-12 d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="0"
                  className="form-control"
                  id="temperatura"
                  name="temperatura"
                  onChange={onchangeMedicinaGeneral}
                  value={stateMedicinaGeneral.temperatura}
                />
                <label className="mx-2 d-flex justify-content-center align-items-center">
                  °C
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">Peso:</div>
              <div className="col-md-12 d-flex justify-content-start align-items-center">
                <input
                  type="text"
                  placeholder="0.00"
                  className="form-control"
                  id="peso"
                  name="peso"
                  onChange={onchangeMedicinaGeneral}
                  value={stateMedicinaGeneral.peso}
                />
                <label className="mx-2 d-flex justify-content-center align-items-center">
                  Kg
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12 ">Hidratación:</div>
              <div className="col-md-12 ">
                <input
                  type="text"
                  placeholder=""
                  className="form-control"
                  id="hidratacion"
                  name="hidratacion"
                  onChange={onchangeMedicinaGeneral}
                  value={stateMedicinaGeneral.hidratacion}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">F.C.:</div>
              <div className="col-md-12 d-flex justify-content-start align-items-center">
                <input
                  type="text"
                  placeholder="0"
                  className="form-control"
                  id="frecuencia_cardiaca"
                  name="frecuencia_cardiaca"
                  onChange={onchangeMedicinaGeneral}
                  value={stateMedicinaGeneral.frecuencia_cardiaca}
                />
                <label className="mx-2 d-flex justify-content-center align-items-center">
                  /minuto
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">Pulso:</div>
              <div className="col-md-12 d-flex justify-content-start">
                <input
                  type="text"
                  placeholder="0"
                  className="form-control"
                  id="pulso"
                  name="pulso"
                  onChange={onchangeMedicinaGeneral}
                  value={stateMedicinaGeneral.pulso}
                />
                <label className="mx-2 d-flex justify-content-center align-items-center">
                  /minuto
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">F.R.:</div>
              <div className="col-md-12 d-flex justify-content-start">
                <input
                  type="text"
                  placeholder=""
                  className="form-control"
                  id="frecuencia_respiratoria"
                  name="frecuencia_respiratoria"
                  onChange={onchangeMedicinaGeneral}
                  value={stateMedicinaGeneral.frecuencia_respiratoria}
                />
                <label className="mx-2 d-flex justify-content-center align-items-center">
                  /minuto
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row m-5 "
          style={{ borderBottom: "1px solid #d8d8d8", width: "100%" }}
        >
          <div className="col-md-4">
            <label style={{ textAlign: "center" }}>
              <b>SISTEMA</b>
            </label>
          </div>
          <div className="col-md-8">
            <label style={{ textAlign: "center" }}>
              <b>ESTADO / BREVE DESCRIPCIÓN</b>
            </label>
          </div>
        </div>

        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>GENERAL:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.general}
              id="general"
              name="general"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="general"
              name="general"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.general}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>OJOS - OREJAS - NARIZ - GARGANTA:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.ojos_ong}
              id="ojos_ong"
              name="ojos_ong"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="ojos_ong"
              name="ojos_ong"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.ojos_ong}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>PIEL TUGUMENTO:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.piel_tugumento}
              id="piel_tugumento"
              name="piel_tugumento"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="piel_tugumento"
              name="piel_tugumento"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.piel_tugumento}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>MÚSUCLO ESQUELÉTICO:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.musculo_esqueletico}
              id="musculo_esqueletico"
              name="musculo_esqueletico"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/* <input
              type="text"
              className="form-control"
              id="musculo_esqueletico"
              name="musculo_esqueletico"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.musculo_esqueletico}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>CARDIOVASCULAR:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.cardiovascular}
              id="cardiovascular"
              name="cardiovascular"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="cardiovascular"
              name="cardiovascular"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.cardiovascular}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>RESPIRATORIO:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.respiratorio}
              id="respiratorio"
              name="respiratorio"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="respiratorio"
              name="respiratorio"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.respiratorio}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>GASTROINTESTINAL:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.gastrointestinal}
              id="gastrointestinal"
              name="gastrointestinal"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="gastrointestinal"
              name="gastrointestinal"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.gastrointestinal}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>GENITAL - REPRODUCTOR:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.genital_reproductor}
              id="genital_reproductor"
              name="genital_reproductor"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="genital_reproductor"
              name="genital_reproductor"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.genital_reproductor}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>NEUROLÓGICO:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.neurologico}
              id="neurologico"
              name="neurologico"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="neurologico"
              name="neurologico"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.neurologico}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>LINFÁTICO:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.linfatico}
              id="linfatico"
              name="linfatico"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="linfatico"
              name="linfatico"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.linfatico}
            /> */}
          </div>
        </div>
        <div className="col-md-3 margenRegistroExamen">
          <div className="form-group">
            <label>OTROS:</label>
          </div>
        </div>
        <div className="col-md-9 margenRegistroExamen">
          <div className="form-group">
            <TextareaAutosize
              rows="1"
              className="textareapublicar"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.otros}
              id="otros"
              name="otros"
              placeholder={"Ingrese el detalle"}
              style={{
                height: "0px!important",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {/*  <input
              type="text"
              className="form-control"
              id="otros"
              name="otros"
              onChange={onchangeMedicinaGeneral}
              value={stateMedicinaGeneral.otros}
            /> */}
          </div>
        </div>
        <div className="col-md-12">
          <div className="">
            {state.todos.length > 0 ? (
              <>
                {props.estado === 1 ||
                props.veterinaria_id !== props.vetregistro ? null : (
                  <button
                    type="button"
                    className="btn btn-success  w-100 p-2 my-2"
                    style={{ marginLeft: "5px", float: "right" }}
                    onClick={ActualizarMedicinaGeneral}
                  >
                    Guardar cambios
                  </button>
                )}
              </>
            ) : (
              <>
                {props.estado === 1 ||
                props.veterinaria_id !== props.vetregistro ? null : (
                  <button
                    type="button"
                    className="btn btn-success w-100 p-2 my-2"
                    style={{ float: "right" }}
                    onClick={CrearMedicinaGeneral}
                  >
                    Guardar Examen físco
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </dv>
  );
};

export default ExamenFisico;
